import { SxProps, Theme } from "@mui/system";

export const inlineIconStyle = {
  fontSize: "1.5em",
  verticalAlign: "middle",
};

export const InlineIcon = (props: { icon: any; active?: boolean; sx?: SxProps<Theme> }) => (
  <props.icon
    color={props.active ? "primary" : "default"}
    sx={{
      ...inlineIconStyle,
      ...props.sx,
    }}
  />
);

export default InlineIcon;
