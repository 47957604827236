import { PayloadAction } from "@reduxjs/toolkit";
import { IIfcMangerState } from "app/state/slices/ifcManagerSlice";
import { Dimension, DimensionId } from "app/common/types";
import { viewerAPI } from "app/common/ViewerAPI";
import { RootState } from "app/state/store";

import { invalidateCloudStateReducer } from "./common";

export interface IDimensionsState {
  isMeasurementCreationActive: boolean;
  dimensions: Dimension[];
}

export const initialDimensionsState: IDimensionsState = {
  isMeasurementCreationActive: false,
  dimensions: [],
};

export const createOrFinishMeasurementReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();

  const createdDimension = viewer.createOrFinishDimension();
  unhighlightAllDimensionsReducer(state);
  // null means first point was chosen, second returns id
  if (createdDimension) {
    state.dimensions.push({
      id: createdDimension,
      isVisible: true,
      isHighlighted: true,
      length: viewer.getDimensionLength(createdDimension),
      startPoint: viewer.getDimensionStartPoint(createdDimension),
      endPoint: viewer.getDimensionEndPoint(createdDimension),
    });
    state.isMeasurementCreationActive = false;
    viewer.turnOffTargetCursor();
    invalidateCloudStateReducer(state);
  }
};

export const startMeasurementCreationReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();
  viewer.turnOnDimensionTool();
  viewer.turnOnTargetCursor();

  unhighlightAllDimensionsReducer(state);
  state.isMeasurementCreationActive = true;
};

export const cancelMeasurementCreationReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();
  viewer.turnOffDimensionTool();
  viewer.turnOffTargetCursor();

  state.isMeasurementCreationActive = false;
};

export const removeDimensionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ id: DimensionId }>
) => {
  const viewer = viewerAPI();

  viewer.deleteDimension(payload.id);
  state.dimensions = state.dimensions.filter(x => x.id != payload.id);
  invalidateCloudStateReducer(state);
};

export const setIsDimensionHighlightedReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ id: DimensionId; value: boolean }>
) => {
  const viewer = viewerAPI();

  for (const dimension of state.dimensions) {
    if (dimension.id == payload.id) {
      dimension.isHighlighted = payload.value;
      viewer.setIsDimensionHighlighted(payload.id, payload.value);

      // auto visible on click
      if (payload.value) {
        dimension.isVisible = true;
        viewer.setDimensionVisibility(payload.id, true);
      }
    } else {
      dimension.isHighlighted = false;
      viewer.setIsDimensionHighlighted(dimension.id, false);
    }
  }
  invalidateCloudStateReducer(state);
};

export const setIsDimensionTempHighlightedReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ id: DimensionId; value: boolean }>
) => {
  const viewer = viewerAPI();

  for (const dimension of state.dimensions) {
    if (dimension.id == payload.id) {
      // auto on when hovering
      viewer.setDimensionVisibility(payload.id, payload.value || dimension.isVisible);
      viewer.setIsDimensionHighlighted(payload.id, payload.value || dimension.isHighlighted);
    } else {
      viewer.setDimensionVisibility(dimension.id, dimension.isVisible);
      viewer.setIsDimensionHighlighted(dimension.id, !payload.value && dimension.isHighlighted);
    }
  }
};

export const unhighlightAllDimensionsReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();

  for (const dimension of state.dimensions) {
    dimension.isHighlighted = false;
    viewer.setIsDimensionHighlighted(dimension.id, false);
  }
  invalidateCloudStateReducer(state);
};

export const setDimensionVisibilityReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ id: DimensionId; value: boolean }>
) => {
  const viewer = viewerAPI();

  for (const dimension of state.dimensions) {
    if (dimension.id == payload.id) {
      dimension.isVisible = payload.value;

      // special case for auto off
      if (!payload.value && dimension.isHighlighted) {
        dimension.isHighlighted = false;
      }
    }
    viewer.setDimensionVisibility(dimension.id, dimension.isVisible);
  }
  invalidateCloudStateReducer(state);
};

const removeAllDimensionsReducer = (state: IIfcMangerState) => {
  viewerAPI().deleteAllDimensions();
  state.dimensions = [];
  invalidateCloudStateReducer(state);
};

export const dimensionsSelectors = {
  selectDimensions: (state: RootState) => state.ifcManager.dimensions,
  selectIsMeasurementCreationActive: (state: RootState) =>
    state.ifcManager.isMeasurementCreationActive,
};

export const dimensionsReducers = {
  startMeasurementCreation: startMeasurementCreationReducer,
  createOrFinishMeasurement: createOrFinishMeasurementReducer,
  cancelMeasurementCreation: cancelMeasurementCreationReducer,
  setIsDimensionHighlighted: setIsDimensionHighlightedReducer,
  setIsDimensionTempHighlighted: setIsDimensionTempHighlightedReducer,
  setDimensionVisibility: setDimensionVisibilityReducer,
  unhighlightAllDimensions: unhighlightAllDimensionsReducer,
  removeDimension: removeDimensionReducer,
  removeAllDimensions: removeAllDimensionsReducer,
};
