import { Box } from "@mui/material";
import theme, { styled } from "app/theme";

export const BoxPaddedCenterCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: `${theme.sppx.spaciousV} ${theme.sppx.spaciousH}`,
  gap: theme.sppx.gapTiny,
});
