import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
  FormControl,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Heavy } from "app/components/common/Heavy";
import CloseIcon from "@mui/icons-material/Close";
import GooogleButton from "app/static/signin-assets/google/light/web_light_sq_SI.svg";
import MicrosoftButton from "app/static/signin-assets/microsoft/ms-symbollockup_signin_light.svg";
import { firebase } from "app/utils/firebase";
import {
  dismissLoginAlert,
  dismissModal,
  selectLoginMessage,
  selectLoginSeverity,
  selectSelectedModal,
  selectLoginEmail,
  selectLoginPassword,
  updateLoginDetails,
  selectLoginName,
  selectIsLoginPassMatch,
  selectIsLoginSignInActive,
  selectAuthProvider,
  relogin,
  selectLoginNewPasswordConfirmation,
  selectLoginNewPassword,
  saveAccountChanges,
  selectActiveAccountField,
  setActiveAccountField,
  selectIsNewPasswordStrong,
  deleteAccount,
} from "app/state/slices/ifcManagerSlice";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { AUTHPROVIDERS, INPUTTYPES, MODALS, inputTypeProps } from "app/common/types";
import { TransactionTextField } from "app/common/TransactionTextField";

import { OAuthButton } from "./common/OAuthButton";
import { PasswordAlert } from "./common/PasswordAlert";

export default function AccountModal() {
  const dispatch = useAppDispatch();
  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.ACCOUNT === selectedModal;

  const loginMessage = useAppSelector(selectLoginMessage);
  const loginSeverity = useAppSelector(selectLoginSeverity);
  const loginName = useAppSelector(selectLoginName);
  const loginEmail = useAppSelector(selectLoginEmail);
  const loginPassword = useAppSelector(selectLoginPassword);
  const loginNewPassword = useAppSelector(selectLoginNewPassword);
  const loginNewPasswordConfirmation = useAppSelector(selectLoginNewPasswordConfirmation);
  const isLoginPassMatch = useAppSelector(selectIsLoginPassMatch);
  const isNewPasswordStrong = useAppSelector(selectIsNewPasswordStrong);
  const isLoginSignInActive = useAppSelector(selectIsLoginSignInActive);
  const calcNeedsAuth = (utcDate: string) => +Date.now() - +new Date(utcDate) > 300_000; // 5 mins
  // note: this would need an external ticker + reducer to avoid direct access
  const [needsReauth, setNeedsReauth] = useState(
    calcNeedsAuth(firebase.auth.currentUser?.metadata.lastSignInTime ?? "")
  );
  const authProvider = useAppSelector(selectAuthProvider);
  const activeAccountField = useAppSelector(selectActiveAccountField);

  const focusField = (field: INPUTTYPES) => {
    if (!inputTypeProps[field]) return;
    const fields = document.getElementsByName(inputTypeProps[field].name);
    if (fields[0]) (fields[0] as HTMLElement).focus();
  };

  useEffect(() => {
    if (activeAccountField) {
      focusField(activeAccountField);
    }
  }, [activeAccountField]);

  useEffect(() => {
    // selectNeedsReauth is time dependent, should also update if modal is left open
    const interval = setInterval(() => {
      const newNeedsReauth = calcNeedsAuth(
        firebase.auth.currentUser?.metadata.lastSignInTime ?? ""
      );

      setNeedsReauth(newNeedsReauth);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // auto clear field data to avoid weird partial states & password leak
    if (
      needsReauth &&
      (activeAccountField == INPUTTYPES.EMAIL ||
        activeAccountField == INPUTTYPES.NEWPASSWORD ||
        activeAccountField == INPUTTYPES.NEWPASSWORDCONFIRMATION)
    ) {
      dispatch(setActiveAccountField(null));
    }
  }, [activeAccountField, needsReauth]);

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      sx={{ p: 2 }}
      onClose={() => dispatch(dismissModal())}
      open={isOpen}
    >
      <DialogTitle>My Account</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(dismissModal())}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ maxHeight: 900, textAlign: "justify" }}>
        {loginMessage && (
          <Alert severity={loginSeverity} onClose={() => dispatch(dismissLoginAlert())}>
            {loginMessage}
          </Alert>
        )}
        <Typography
          component="div"
          sx={{
            lineHeight: "2em",
          }}
        >
          <FormControl fullWidth>
            <TransactionTextField
              type={INPUTTYPES.NAME}
              enabled={activeAccountField == INPUTTYPES.NAME}
              editEnabled={true}
              value={loginName}
              onChange={e => dispatch(updateLoginDetails({ loginName: e.target.value }))}
              onSave={() => dispatch(saveAccountChanges({ name: true }))}
              onEdit={() => dispatch(setActiveAccountField(INPUTTYPES.NAME))}
              onCancel={() => dispatch(setActiveAccountField(null))}
            />
            {needsReauth && (
              <>
                <br />
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  You need to log in again to change the fields below.
                </Box>
                {authProvider == AUTHPROVIDERS.EMAIL && (
                  <>
                    <TransactionTextField
                      required
                      enabled
                      showButtons={false}
                      type={INPUTTYPES.PASSWORD}
                      value={loginPassword}
                      onChange={e =>
                        dispatch(updateLoginDetails({ loginPassword: e.target.value }))
                      }
                      onSave={() => dispatch(relogin())}
                    />
                    <Button
                      variant="contained"
                      disabled={!isLoginSignInActive}
                      onClick={() => dispatch(relogin())}
                    >
                      Sign in
                    </Button>
                  </>
                )}
                {authProvider == AUTHPROVIDERS.GOOGLE && (
                  <>
                    <OAuthButton
                      onClick={() => dispatch(relogin())}
                      alt="Google sign in"
                      src={GooogleButton}
                    />
                  </>
                )}
                {authProvider == AUTHPROVIDERS.MICROSOFT && (
                  <>
                    <OAuthButton
                      onClick={() => dispatch(relogin())}
                      alt="Microsoft sign in"
                      src={MicrosoftButton}
                    />
                  </>
                )}
              </>
            )}
            {authProvider == AUTHPROVIDERS.EMAIL && (
              <TransactionTextField
                type={INPUTTYPES.EMAIL}
                enabled={!needsReauth && activeAccountField == INPUTTYPES.EMAIL}
                editEnabled={!needsReauth}
                value={loginEmail}
                onChange={e => dispatch(updateLoginDetails({ loginEmail: e.target.value }))}
                onSave={() => dispatch(saveAccountChanges({ email: true }))}
                onEdit={() => dispatch(setActiveAccountField(INPUTTYPES.EMAIL))}
                onCancel={() => dispatch(setActiveAccountField(null))}
              />
            )}
            {authProvider == AUTHPROVIDERS.EMAIL && (
              <>
                <TransactionTextField
                  type={INPUTTYPES.NEWPASSWORD}
                  enabled={!needsReauth && activeAccountField == INPUTTYPES.NEWPASSWORD}
                  isValidationError={!isNewPasswordStrong && loginNewPassword.length > 0}
                  editEnabled={!needsReauth}
                  saveEnabled={!needsReauth && isNewPasswordStrong && isLoginPassMatch}
                  value={loginNewPassword}
                  onChange={e => dispatch(updateLoginDetails({ loginNewPassword: e.target.value }))}
                  onSave={() => dispatch(saveAccountChanges({ password: true }))}
                  onNext={() =>
                    isNewPasswordStrong &&
                    loginNewPassword.length > 0 &&
                    focusField(INPUTTYPES.NEWPASSWORDCONFIRMATION)
                  }
                  onEdit={() => dispatch(setActiveAccountField(INPUTTYPES.NEWPASSWORD))}
                  onCancel={() => dispatch(setActiveAccountField(null))}
                />
                {!isNewPasswordStrong && loginNewPassword.length > 0 && <PasswordAlert />}
                <TransactionTextField
                  type={INPUTTYPES.NEWPASSWORDCONFIRMATION}
                  showButtons={false}
                  enabled={!needsReauth && activeAccountField == INPUTTYPES.NEWPASSWORD}
                  isValidationError={!isLoginPassMatch && loginNewPasswordConfirmation.length > 0}
                  editEnabled={!needsReauth}
                  saveEnabled={!needsReauth && isNewPasswordStrong && isLoginPassMatch}
                  value={loginNewPasswordConfirmation}
                  onChange={e =>
                    dispatch(updateLoginDetails({ loginNewPasswordConfirmation: e.target.value }))
                  }
                  onSave={() => dispatch(saveAccountChanges({ password: true }))}
                  onEdit={() => dispatch(setActiveAccountField(INPUTTYPES.NEWPASSWORD))}
                  onCancel={() => dispatch(setActiveAccountField(null))}
                />
              </>
            )}
            <br />
          </FormControl>

          <Box
            sx={{
              mt: "15px",
              textAlign: "center",
              lineHeight: "1em",
              color: needsReauth ? "#777777" : undefined,
            }}
          >
            <Heavy>
              I want to{" "}
              <Link
                color={needsReauth ? "#777777" : "error"}
                href={needsReauth ? undefined : "#"}
                onClick={() => !needsReauth && dispatch(deleteAccount())}
              >
                Delete my account
              </Link>{" "}
              and all my data.
            </Heavy>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
