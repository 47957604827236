import { ReactNode } from "react";
import Navbar from "app/Layout/Navbar";
import Footer from "app/Layout/Footer";

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <div>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
