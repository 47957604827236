import { useAppDispatch, useAppSelector } from "app/state/hooks";
import theme from "app/theme";
import { Dialog, DialogTitle, DialogContent, Typography, Link, IconButton } from "@mui/material";
import { dismissModal, selectSelectedModal } from "app/state/slices/ifcManagerSlice";
import { MODALS } from "app/common/types";
import CloseIcon from "@mui/icons-material/Close";

export default function UnauthorizedDomain() {
  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.UNAUTHDOMAIN === selectedModal;
  const dispatch = useAppDispatch();

  return (
    <Dialog
      sx={{ p: theme.sppx.spacious2V }}
      onClose={() => dispatch(dismissModal())}
      open={isOpen}
    >
      <DialogTitle>
        <b>Unauthorized Domain</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(dismissModal())}
        sx={{
          position: "absolute",
          right: theme.sppx.compactH,
          top: theme.sppx.compactH,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers sx={{ maxHeight: 400, textAlign: "justify" }}>
        <Typography component="div" sx={{ fontWeight: 500 }}>
          The file you requested is not part of our beta whitelist. To join our beta program for
          enterprise customers, contact us at:
        </Typography>
        <Typography
          component="div"
          sx={{
            marginTop: theme.sppx.spaciousV,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontWeight: 500,
          }}
        >
          <Link target="_blank" href="mailto:support@sortdesk.com">
            support@sortdesk.com
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
