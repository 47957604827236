import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import FeedbackButton from "../FeedbackButton";

interface Props {
  open: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  HelpContent: React.FC;
}

const HelpDialog: React.FC<Props> = ({ open, setDialogOpen, HelpContent }) => {
  return (
    <Dialog sx={{ p: 2 }} onClose={() => setDialogOpen(false)} open={open}>
      <DialogTitle>
        Sortdesk IFC Viewer
        <IconButton
          onClick={() => setDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ maxHeight: 400, textAlign: "justify" }}>
        <Typography>
          <HelpContent />
        </Typography>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <FeedbackButton />
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
