import HelpOutlineRounded from "@mui/icons-material/HelpOutlineRounded";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, BoxProps, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import HelpDialog from "app/components/help/HelpDialog";
import { toggleTool } from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

interface Props {
  title: string;
  placedOnLeftSidebar: boolean;
  hasHelpIcon?: boolean;
  HelpContent?: React.FC;
}

const SidebarHeader: React.FC<Props & BoxProps & { selectedTool: TOOLS }> = ({
  title,
  placedOnLeftSidebar,
  HelpContent,
  hasHelpIcon = true,
  sx,
  selectedTool,
  ...otherProps
}) => {
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `${theme.sppx.compact2V} ${theme.sppx.compact2H}`,
          minHeight: `${theme.sppx.iUnit2}`,
          ...sx,
        }}
        {...otherProps}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">{title}</Typography>
          {hasHelpIcon && (
            <Tooltip arrow placement="right" title="Show Help">
              <IconButton
                onClick={() => {
                  setHelpOpen(!helpOpen);
                }}
              >
                <HelpOutlineRounded />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Tooltip placement="left" arrow title="Collapse panel">
          <IconButton onClick={() => dispatch(toggleTool({ selectedTool }))}>
            {placedOnLeftSidebar ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />

      {hasHelpIcon && HelpContent && (
        <HelpDialog open={helpOpen} setDialogOpen={setHelpOpen} HelpContent={HelpContent} />
      )}
    </>
  );
};

export default SidebarHeader;
