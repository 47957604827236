import { IfcPropertiesUtils } from "app/components/ifcjs/core";
import { FragmentIdMap, FragmentsGroup } from "app/components/ifcjs/fragments";
import { FragmentsGroupExtended, ViewerSingleton } from "app/common/ViewerAPI";
import { IProperty, SpatialNode } from "app/common/types";

export type QuantityValue = {
  name: string;
  values: any[];
};

// note: mirors id scheme from FragmentIfcLoader spatial tree
export const fullIdFrom = (modelID: string, expressID: string) => {
  return `${modelID}/${expressID}`;
};

/**
 * @param quantityID: the ExpressID of the quantity
 */
export const getQuantityValues = (
  model: FragmentsGroup | null,
  quantityIDorObject: number | any
) => {
  const isID = typeof quantityIDorObject == "number";
  if (isID && !model) return [];

  const quantity = isID && model ? model?.getProperties(quantityIDorObject) : quantityIDorObject;

  const values: QuantityValue[] = [];
  if (!quantity) {
    console.error("Quantity not found:", quantityIDorObject);
    return values;
  }
  /* note: we support all subtypes: AreaValue, CountValue, LengthValue,
   * TimeValue, VolumeValue, WeightValue
   */
  const key = Object.keys(quantity).find(key => key.endsWith("Value")) ?? null;
  if (key != null) {
    values.push({
      name: quantity.Name?.value,
      values: [quantity[key].value],
    });
    const unit = quantity.Unit;
    if (unit) {
      console.error("UNIT is not embedded in *Value as expected", quantity, unit);
    }
  }

  if ("HasQuantities" in quantity) {
    // should be of type WebIFC.IFCCOMPLEXPROPERTY
    for (const subQuantity of quantity.HasQuantities) {
      values.push(
        ...getQuantityValues(model, subQuantity.value).map(item => ({
          name: `${quantity.Name?.value ?? "no name"} / ${item.name ?? "no name"}`,
          values: item.values,
        }))
      );
    }
  }

  if (values.length == 0) {
    console.error("Unsupported quantity:", quantity);
  }

  return values;
};

export type PropertyValue = {
  name: string;
  values: any[];
};

/**
 * @param propertyID: the ExpressID of the property
 */
export const getPropertyValues = (
  model: FragmentsGroup | null,
  propertyIDorObject: number | any
) => {
  const isID = typeof propertyIDorObject == "number";
  if (isID && !model) return [];

  const property = isID && model ? model.getProperties(propertyIDorObject) : propertyIDorObject;

  const values: PropertyValue[] = [];

  if (!property) {
    console.error("Property not found:", propertyIDorObject);
    return values;
  }

  /* note: we are supporting: IfcPropertyBoundedValue,
   * IfcPropertyListValue and IfcPropertySingleValue
   * the others: IfcPropertyTableValue and IfcPropertyReferenceValue
   * are untested/unsupported
   */

  if ("NominalValue" in property) {
    values.push({
      name: property.Name?.value,
      values: [property.NominalValue?.value ?? null],
    });
    const unit = property.Unit;
    if (unit) {
      console.error("UNIT is not embedded in NominalValue as expected", property, unit);
    }
  } else if ("SetPointValue" in property) {
    values.push({
      name: property.Name?.value,
      values: [property.SetPointValue?.value ?? null],
    });
  } else if ("EnumerationValues" in property) {
    values.push({
      name: property.Name?.value,
      values: property.EnumerationValues?.map((ev: Record<string, unknown>) => ev?.value) ?? [null],
    });
  }
  // todo: find test file for this case, "Sample-Test-Files" doesn't return any matches
  else if ("ListValues" in property) {
    values.push({
      name: property.Name?.value,
      values: property.ListValues?.map((ev: Record<string, unknown>) => ev?.value) ?? [null],
    });
  }

  if ("HasProperties" in property) {
    // should be of type WebIFC.IFCCOMPLEXPROPERTY
    for (const subProperty of property.HasProperties) {
      values.push(
        ...getPropertyValues(model, subProperty.value).map(item => ({
          name: `${property.Name?.value ?? "no name"} / ${item.name ?? "no name"}`,
          values: item.values,
        }))
      );
    }
  }

  if (values.length == 0) {
    console.error("Unsupported property:", property);
  }

  return values;
};

const _collectReccursiveFragmentsByID = (
  modelUUID: string,
  expressID: string | string[],
  spatiaNodeUpdate?: (spatialNode: SpatialNode) => void,
  isCheckVisibilityIsNot?: boolean,
  isOnlyFirstLayer?: boolean
) => {
  const viewer = ViewerSingleton.getInstance();

  const collectedIds: string[] = [];

  const group = viewer._fragments.groups.get(modelUUID) as FragmentsGroupExtended | undefined;
  const spatialNodesMap = group?.spatialNodesMap?.[modelUUID] as {
    [key: string]: SpatialNode;
  };

  const collectChildren = (expressID: string) => {
    const spatialNode = spatialNodesMap[expressID];
    collectedIds.push(expressID);

    if (!isOnlyFirstLayer) {
      if (spatialNode && spatialNode.children) {
        for (const child of spatialNode.children) {
          collectChildren(child.metadata.expressID);
        }
      }
    }
  };

  if (Array.isArray(expressID)) {
    for (const id of expressID) {
      if (id in spatialNodesMap) {
        collectChildren(id);
      }
    }
  } else {
    collectChildren(expressID);
  }

  const collectedFragMap: FragmentIdMap = {};

  for (const itemExpressID of collectedIds) {
    const spatialNode = spatialNodesMap[itemExpressID];
    // not indexed by one of the IFCREL*
    if (spatialNode == null) {
      continue;
    }
    const metadata = spatialNode.metadata;
    const fragmentIDs = metadata.fragmentIDs;
    for (const fragmentID of fragmentIDs) {
      let shouldAdd = true;

      if (isCheckVisibilityIsNot !== undefined) {
        shouldAdd = metadata.isVisible != isCheckVisibilityIsNot;
      }

      if (shouldAdd) {
        if (!(fragmentID in collectedFragMap)) {
          collectedFragMap[fragmentID] = new Set<number>();
        }
        for (const expressID of metadata.expressIDs) {
          collectedFragMap[fragmentID].add(expressID);
        }
      }
    }

    if (spatialNode && spatiaNodeUpdate) {
      spatiaNodeUpdate(spatialNode);
    }
  }

  return { collectedFragMap, collectedIds };
};

export const _getIfcProperties = () => {
  const viewer = ViewerSingleton.getInstance();

  const error: string | null = null;
  if (!(viewer._highlighterModel && viewer.highlighterExpressId)) {
    return {
      name: "",
      properties: [],
      quantityValues: [] as IProperty[],
      propertyValues: [] as IProperty[],
      error: "Uninitialized",
    };
  }

  let name = "Undefined"; // consistent with "undefined" from convertIfcString
  try {
    if (viewer?._highlighterModel) {
      const entityName = IfcPropertiesUtils.getEntityName(
        viewer?._highlighterModel,
        viewer?.highlighterExpressId
      ).name;
      if (entityName) {
        name = entityName;
      }
    }
  } catch (ex) {
    console.error("Couldn't get name:", ex);
  }
  // todo: this should also expand quantities expressIDs to move
  // the responsibility away from UI
  // const properties =
  //   viewer._prop.getProperties(
  //     viewer._highlighterModel,
  //     String(viewer.highlighterExpressId)
  //   ) || ([] as Array<IProperty>); // expressID: num -> propertyType: string

  const model = viewer._highlighterModel;
  const properties = model.getProperties(viewer.highlighterExpressId);

  if (!properties) {
    return {
      name: name,
      properties: [],
      quantityValues: [] as IProperty[],
      propertyValues: [] as IProperty[],
      error: "Failed to get properties",
    };
  }

  // note the type number needs a lookup in ifcTypeNames to get the actual name
  const type = properties?.type as number | undefined;
  // note: in debug mode this also gives the type's name in PascalCase
  // Object.getPrototypeOf(properties).constructor.name

  const noGeometry =
    viewer._highlighterSelection == null || Object.keys(viewer._highlighterSelection).length == 0;

  // note: each property/quantity can be parsed as a handle to the node(an expressID)
  //       or be embedded as an object

  const psets = (
    viewer._propsIndexer.getEntityRelations(model, viewer.highlighterExpressId, "IsDefinedBy") ?? []
  ).map(expressID => viewer?._highlighterModel?.getProperties?.(expressID));

  const propertyValues = psets
    .filter((x: any) => x?.HasProperties)
    .map((x: any) => ({
      ...x,
      propertyValues: x.HasProperties.map((prop: any) => getPropertyValues(model, prop.value)),
    }));

  for (const propertyValue of propertyValues) {
    propertyValue.propertyValues = propertyValue.propertyValues.flatMap((x: any) => x);
  }

  const quantityValues = psets
    .filter((x: any) => x?.Quantities)
    .map((x: any) => ({
      ...x,
      quantityValues: x.Quantities.map((quant: any) => getQuantityValues(model, quant.value)),
    }));

  for (const quantityValue of quantityValues) {
    quantityValue.quantityValues = quantityValue.quantityValues.flatMap((x: any) => x);
  }

  return {
    noGeometry,
    type,
    name: name,
    properties,
    quantityValues: quantityValues as IProperty[],
    propertyValues: propertyValues as IProperty[],
    error,
  };
};

export const _getIfcAttributes = (expressID: number) => {
  const viewer = ViewerSingleton.getInstance();
  // todo: federated fixme: get relevant model
  let model: FragmentsGroup | null = null;
  for (const value of viewer._fragments.groups.values()) {
    model = value;
  }
  if (!model) return {};

  const allProperties = model._properties;
  if (!allProperties) return {};

  const plainProps = allProperties[expressID];

  //resolve Handles
  Object.keys(plainProps).forEach(key => {
    if (plainProps[key]?.type == 5 && typeof plainProps[key]?.value == "number") {
      plainProps[key] = allProperties[plainProps[key].value];
    }
  });

  return plainProps;
};

export const _clearHighlightedSelection = () => {
  const viewer = ViewerSingleton.getInstance();

  viewer._highlighterSelection = null;
  viewer._highlighterModel = null;
  viewer.highlighterModelUUID = null;
  viewer.highlighterExpressId = null;
  viewer._highlighterFragmentID = null;
};

export const _pickHoveredItemProps = (autoHighlight = true) => {
  const viewer = ViewerSingleton.getInstance();

  // viewer._highlighter.highlightByID("default", viewer._highlighterSelection);
  // note: of course this automagically highlights the right thing
  if (autoHighlight) {
    viewer._highlighter.highlight("default", true, false);
    // viewer._highlighter.highlight("default");
  }

  const selection = viewer._highlighter.selection["default"];
  const fragmentID = Object.keys(selection)[0];
  if (!selection[fragmentID]) {
    _clearHighlightedSelection();
    return;
  }
  // // can't be directly deconstructed because no es2015
  const aSelectedFrament = selection[fragmentID].values().next().value;
  // aSelectedFrament.
  const expressID = Number(aSelectedFrament);

  // const fragments = viewer._components.get(FragmentsManager);
  // const fragment = fragments.list.get(fragmentID);

  // if (fragment) {
  //   const materials = fragment.mesh.material as THREE.MeshLambertMaterial[];
  //   for (const material of materials) {
  //     material.color = new Color(0xff0000);
  //     // material.opacity = 0.9;
  //     // material.depthWrite = false;
  //     // material.depthTest = false;
  //   }
  // }

  // let model = null;
  // for (const [, group] of viewer._fragments.groups) {
  //   const fragmentFound = Object.values(group.keyFragments).find(id => id === fragmentID);
  //   if (fragmentFound) {
  //     model = group;
  //   }
  // }

  //todo: federated: get correct model
  const model = viewer._fragments.groups.values().next().value as FragmentsGroupExtended;

  viewer._highlighterSelection = selection;
  viewer._highlighterModel = model;
  viewer.highlighterModelUUID = model?.id ?? null;
  viewer.highlighterExpressId = expressID;
  viewer._highlighterFragmentID = fragmentID;
};

export const _highlightByID = (props: {
  modelUUID: string | null;
  expressID: string | string[] | null;
  isTempHover?: boolean;
  isSpatiallyReccursive?: boolean;
}) => {
  const viewer = ViewerSingleton.getInstance();

  const { modelUUID, expressID } = props;

  if (expressID == null || modelUUID == null) {
    viewer._highlighter.highlightByID(props.isTempHover ? "hover" : "default", {}, true, false);
    if (!props.isTempHover) {
      _clearHighlightedSelection();
    }
    return;
  }

  if (props.isSpatiallyReccursive) {
    const { collectedFragMap, collectedIds } = _collectReccursiveFragmentsByID(
      modelUUID,
      expressID
    );
    viewer._highlighter.highlightByID(
      props.isTempHover ? "hover" : "default",
      collectedFragMap,
      true,
      !props.isTempHover,
      props.isSpatiallyReccursive && collectedIds.length > 100
    );
  } else {
    const { collectedFragMap: filteredFragList } = _collectReccursiveFragmentsByID(
      modelUUID,
      expressID,
      undefined,
      undefined,
      true
    );

    if (!filteredFragList) {
      _clearHighlightedSelection();
      return;
    }

    viewer._highlighter.highlightByID(
      props.isTempHover ? "hover" : "default",
      filteredFragList,
      true,
      !props.isTempHover
    );
  }

  if (!props.isTempHover) {
    // todo?: abstract as cache highlighted selection
    const selection = viewer._highlighter.selection["default"];
    const fragmentID = Object.keys(selection)[0];
    if (!selection[fragmentID]) {
      _clearHighlightedSelection();
    }

    viewer._highlighterSelection = selection;
    // todo: federation: use seletected model
    viewer._highlighterModel =
      (viewer._fragments.groups.get(modelUUID) as FragmentsGroupExtended) ?? null;
    viewer.highlighterModelUUID = Number(viewer._highlighterModel?.uuid);
    viewer.highlighterExpressId = Number(Array.isArray(expressID) ? expressID[0] : expressID);
    viewer._highlighterFragmentID = fragmentID;
  }
  viewer._ifcStreamer.culler.needsUpdate = true;
};

export const _setVisibilityByID = (props: {
  modelUUID: string | null;
  expressID: string | null;
  value: boolean;
}) => {
  try {
    const viewer = ViewerSingleton.getInstance();

    if (props.expressID == null || props.modelUUID == null) {
      const spatialTreeRoots = viewer._fragments.groups.values().next().value.spatialTreeRoots;
      for (const root of spatialTreeRoots) {
        const { collectedFragMap } = _collectReccursiveFragmentsByID(
          root.metadata.modelUUID,
          root.metadata.expressID,
          node => (node.metadata.isVisible = props.value),
          props.value
        );

        // viewer._hider.set(props.value, collectedFragMap);
        // viewer._ifcStreamer.culler.setVisibility(props.value, root.metadata.modelUUID, collectedFragMap);
        viewer._ifcStreamer.setVisibility(props.value, collectedFragMap);
      }
      viewer._ifcStreamer.culler.needsUpdate = true;

      return;
    }

    const { collectedFragMap } = _collectReccursiveFragmentsByID(
      props.modelUUID,
      props.expressID,
      node => (node.metadata.isVisible = props.value),
      props.value
    );

    viewer._ifcStreamer.setVisibility(props.value, collectedFragMap);

    // viewer._hider.set(props.value, collectedFragMap);
    viewer._ifcStreamer.culler.needsUpdate = true;
  } catch (ex: any) {
    console.error(ex);
  }
};
