let _timeoutId: NodeJS.Timeout | null = null;
let _timeoutCallback: () => void = () => {
  return;
};
let _invalidateCallback: () => void = () => {
  return;
};
let _timeoutDuration = 5000;

export const initAutosave = (
  callback: () => void,
  invalidateCallback: () => void,
  timeoutDuration?: number
) => {
  _timeoutCallback = callback;
  _invalidateCallback = invalidateCallback;
  if (timeoutDuration) {
    _timeoutDuration = timeoutDuration;
  }
};

/* reset the timeout to its initial value to extend the duration with one more period */
export const refreshAutosave = () => {
  if (_timeoutId != null) {
    clearTimeout(_timeoutId);
  }
  _timeoutId = setTimeout(_timeoutCallback, _timeoutDuration);
};

/* call the provided invalidateCallback then refreshAutosave */
export const invalidateAutosave = () => {
  if (_invalidateCallback) _invalidateCallback();
  refreshAutosave();
};
