import { Sphere, Vector3 } from "three";
import { ViewerSingleton } from "app/common/ViewerAPI";

export const _cameraGoHome = () => {
  const viewer = ViewerSingleton.getInstance();

  // make sure it's perspective instead of ortho
  viewer._camera.set("Orbit");
  // the 3/4 view, exactly 45deg looks like isometric grid rather than 3D
  viewer._camera.controls.rotateTo(Math.PI / 4, (Math.PI * 3) / 8, true);
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
};

export const _toggleOrtho = () => {
  const viewer = ViewerSingleton.getInstance();
  if (!viewer) return;
  const camera = viewer._camera;

  const isOrtho = camera.mode.id == "Plan";
  camera.set(isOrtho ? "Orbit" : "Plan");
  camera.projection.set(isOrtho ? "Perspective" : "Orthographic");
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
  return;
};

export const _refreshSize = () => {
  const viewer = ViewerSingleton.getInstance();
  viewer._renderer.three.setPixelRatio(window.devicePixelRatio ?? 1);
  viewer._renderer.resize();
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
};

export const _refreshCursor = () => {
  const viewer = ViewerSingleton.getInstance();
  //@ts-ignore for some reason zoom doesn't trigger openbim updates
  // viewer._dimensions._vertexPicker.update();
  viewer._targetCursor.update();
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
};

export const _isOrthoEnabled = () => {
  return ViewerSingleton.getInstance()?._camera?.mode.id === "Plan";
};

export const _setIsOrthoEnabled = (value: boolean) => {
  const viewer = ViewerSingleton.getInstance();
  if (!viewer) return undefined;

  const res = viewer._camera?.set(value ? "Plan" : "Orbit");
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;

  return res;
};

/* This version is meant to be called internally
 *  without the slow dispatch(fitModelToFrame) */
export const _fitModelToFrame = async () => {
  const viewer = ViewerSingleton.getInstance();

  // proxy expires after await
  const boundingSphereCenterValue = [...viewer._boundingSphereCenter];
  // camera bug: recentering doesn't work properly in ortographic mode
  const tempPojection = _isOrthoEnabled();
  if (tempPojection) {
    await viewer._camera.set("Orbit");
  }
  viewer._camera.controls.fitToSphere(
    new Sphere(new Vector3(...boundingSphereCenterValue), viewer._boundingSphereRadius * 1.0),
    true
  );
  if (tempPojection) {
    await viewer._camera.set("Plan");
  }

  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;

  // this is slow and finds values 1000s of units away
  // viewer.camera.controls.fitToSphere(viewer.scene.get(), false);
};

export const _rotateToAngle = ({
  azimuthAngle,
  polarAngle,
  enableTransition,
}: {
  azimuthAngle: number;
  polarAngle: number;
  enableTransition: boolean;
}) => {
  const viewer = ViewerSingleton.getInstance();
  viewer._camera.controls.rotateTo(azimuthAngle, polarAngle, enableTransition ?? false);
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
};
