import { Box, IconButton } from "@mui/material";

export const OAuthButton = ({ onClick, ...props }: any) => (
  <IconButton disableRipple onClick={onClick}>
    <Box
      component="img"
      sx={{ height: "1.8em" }}
      // alt="Provider name"
      // src="{imported svg}"
      {...props}
    />
  </IconButton>
);
