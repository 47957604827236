import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import BorderAll from "@mui/icons-material/BorderAll";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import Category from "@mui/icons-material/Category";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import ContentCut from "@mui/icons-material/ContentCut";
import Description from "@mui/icons-material/Description";
import Straighten from "@mui/icons-material/Straighten";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { TOOLS } from "app/common/types";
import { Heavy } from "app/components/common/Heavy";
import { LocalIFCPicker } from "app/components/common/LocalIFCPicker";
import { useFileLoad } from "app/components/common/useFileLoad";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  selectAccountIsLoggedIn,
  selectFileName,
  selectIsFirstLoad,
  setTool,
} from "app/state/slices/ifcManagerSlice";
import theme, { styled } from "app/theme";
import { ReactNode } from "react";
import InlineIcon from "app/components/common/InlineIcon";
import { BoxCol } from "app/components/common/BoxCol";
import { LinkArrow } from "app/components/common/LinkArrow";

const MainList = styled(List)({
  padding: 0,
  marginBottom: "0.5em",
  "& .MuiListItem-root": {
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
});

const CompactList = styled(List)({
  padding: 0,
  marginTop: "0.0rem",
  "& .MuiListItem-root": {
    paddingTop: "0.4rem",
    paddingBottom: "0.25rem",
  },
});

const ToolItem = ({
  Icon,
  children,
  chsx,
  ...props
}: ListItemProps & { Icon: ReactNode; children: ReactNode; chsx?: SxProps<Theme> }) => {
  return (
    <ListItem {...props}>
      <ListItemIcon sx={{ minWidth: "auto", color: theme.palette.primary.main }}>
        {Icon}
      </ListItemIcon>
      <Typography sx={{ ml: "1em", ...chsx }}>{children}</Typography>
    </ListItem>
  );
};
const FeatureItem = ({ children }: { children: ReactNode }) => {
  return (
    <ListItem>
      <Typography>{children}</Typography>
    </ListItem>
  );
};

const centeredBlockStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const borderedBlockStyle = {
  borderRadius: "12px",
  border: `1px solid ${theme.palette.subtleGreyBorder}`,
  boxSizing: "border-box",
  padding: theme.pad.spacious,
  alignItems: "flex-start",
  background: "white",
};

const fullWidthBlockStyle = {
  width: "calc(100% - 200px)",
  marginLeft: "100px",
  marginRight: "100px",
  maxWidth: "1200px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 50px)",
  },
};

export default function LandingSplash(props: { isViewerLoaded: boolean }) {
  const dispatch = useAppDispatch();
  const onFileLoad = useFileLoad();

  const fileName = useAppSelector(selectFileName);
  const isFirstLoad = useAppSelector(selectIsFirstLoad);
  /* firebase (and direct indexedDB access) is too slow: useAppSelector(selectAccountIsLoggedIn);
     the cached localStorage version is used */
  const accountIsLoggedIn =
    useAppSelector(selectAccountIsLoggedIn) || localStorage.getItem("isLoggedIn") == "true";
  console.log("accountIsLoggedIn:", accountIsLoggedIn);

  return (
    <>
      <Box
        sx={{
          display:
            //todo: extract and reuse this logic: PropertiesSidebarAnchor, NavBar, ViewerPage
            !accountIsLoggedIn && (!props.isViewerLoaded || (!fileName && isFirstLoad))
              ? "flex"
              : "none",
          alignItems: "center",
          alignContent: "space-around",
          justifyContent: "space-around",
          flexDirection: "column",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          minHeight: "max-content",
          overflowX: "hidden",
          overflowY: "auto",
          background: "white",
          zIndex: "-10",
          gap: theme.sppx.spaciousV,
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
            height: "325px",
            width: "100vw",
            position: "absolute",
            top: 0,
            zIndex: "-1",
            display: "flex",
            flexWrap: "wrap",
            transformStyle: "preserve-3d",
            transform: "perspective(1000px) rotateX(50deg) scale(1.5, 1.5) translate3d(0, 0, 0px)",
            "& .bgGridCell": {
              border: "1px solid #ffffff22",
              width: "100px",
              height: "100px",
              background: "transparent",
            },
            overflow: "hidden",
          }}
        >
          {[...new Array(256).keys()].map(x => (
            <Box className="bgGridCell" key={x} />
          ))}
        </Box>
        <Box
          sx={{
            ...centeredBlockStyle,
            marginTop: "120px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: "400",
              color: "white",
              [theme.breakpoints.down("sm")]: {
                marginLeft: theme.sppx.spaciousV,
                marginRight: theme.sppx.spaciousV,
              },
              textAlign: "center",
            }}
            component="div"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  color: "white",
                  background: theme.palette.primary.main,
                }}
                component="h1"
              >
                Free Online IFC Viewer
              </Typography>
            </Box>
            a <i>fast</i>, free, browser-based local .ifc explorer
          </Typography>
        </Box>
        <Box sx={{ ...centeredBlockStyle }}>
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: "400",
              color: "white",
            }}
          >
            700+ visitors weekly
          </Typography>
        </Box>
        <Box
          sx={{
            ...centeredBlockStyle,
            ...borderedBlockStyle,
            ...fullWidthBlockStyle,
            alignItems: "center",
            flexGrow: 0,
            padding: theme.sppx.compactH,
            marginTop: theme.sppx.spaciousV,
          }}
        >
          <BoxCol
            sx={{
              background: theme.palette.bgLightGrey,
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              alignItems: "center",
              gap: theme.sppx.spaciousV,
              padding: theme.pad.spacious2,
              borderRadius: "6px",
              border: `1px solid ${theme.palette.hoverGreyBackground}`,
            }}
          >
            <Typography sx={{ color: theme.palette.primary.main }}>
              <Typography
                component="span"
                sx={{ fontWeight: "600", fontSize: "1rem", color: theme.palette.primary.main }}
              >
                <InlineIcon
                  icon={GppGoodOutlinedIcon}
                  sx={{
                    verticalAlign: "top",
                    marginRight: "2px",
                  }}
                />
              </Typography>{" "}
              <b>Privacy First:</b> Your data won&apos;t be uploaded anywhere.
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<InsertDriveFileOutlinedIcon />}
            >
              <Typography variant="button" sx={{ textTransform: "none" }}>
                Open a local .ifc file
              </Typography>
              <LocalIFCPicker onChange={onFileLoad} />
            </Button>
            <Typography sx={{ fontSize: "0.85rem" }}>
              <CheckOutlinedIcon
                sx={{
                  height: "0.85em",
                  verticalAlign: "top",
                  color: theme.palette.cloudDoneGreen,
                }}
              />{" "}
              No registration required
            </Typography>
          </BoxCol>
        </Box>
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              flexWrap: "wrap",
            },
            gap: theme.sppx.compact2H,
            ...fullWidthBlockStyle,
          }}
        >
          <Box
            sx={{
              ...centeredBlockStyle,
              ...borderedBlockStyle,
              flexGrow: "1",
              [theme.breakpoints.down("md")]: {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <MainList>
              <FeatureItem>
                <b>Explore models using:</b>
              </FeatureItem>
            </MainList>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                [theme.breakpoints.down("sm")]: {
                  flexWrap: "wrap",
                },
                overflow: "hidden",
              }}
            >
              <CompactList>
                <ToolItem Icon={<ContentCut />}>Section tool</ToolItem>
                <ToolItem Icon={<Straighten />}>Dimension tool</ToolItem>
                <ToolItem Icon={<Description />}>Plan manager</ToolItem>
              </CompactList>
              <Divider
                orientation="vertical"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              />
              <CompactList>
                <ToolItem Icon={<Category />}>Property picking</ToolItem>
                <ToolItem Icon={<AccountTreeOutlinedIcon />}>Object Tree</ToolItem>
                <ToolItem Icon={<BorderAll />}>Ortographic mode</ToolItem>
              </CompactList>
            </Box>
          </Box>
          <Box
            sx={{
              ...centeredBlockStyle,
              ...borderedBlockStyle,
              flexGrow: "1",
              [theme.breakpoints.down("md")]: {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <MainList>
              <FeatureItem>
                <b>With a free account</b> you can also access:
              </FeatureItem>
            </MainList>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <CompactList>
                <Link
                  href="#"
                  onClick={() =>
                    dispatch(setTool({ selectedTool: TOOLS.IDSEDITOR, isToggle: true }))
                  }
                  sx={{
                    color: "inherit",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                    textUnderlineOffset: "0.5em",
                  }}
                >
                  <ToolItem
                    Icon={<ChecklistIcon color="primary" />}
                    chsx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <b>IDS Editor & IFC validation</b>
                  </ToolItem>
                </Link>
                <Link
                  href="#"
                  onClick={() => dispatch(setTool({ selectedTool: TOOLS.QUERY, isToggle: true }))}
                  sx={{
                    color: "inherit",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                    textUnderlineOffset: "0.5em",
                  }}
                >
                  <ToolItem
                    Icon={<CalendarViewMonthIcon color="primary" />}
                    chsx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <b>Querying & Quantity Take-offs</b>
                  </ToolItem>
                </Link>
                <ToolItem Icon={<CloudDoneIcon sx={{ color: theme.palette.cloudDoneGreen }} />}>
                  <b>Cloud Sync</b> your models <Heavy>across devices</Heavy>
                </ToolItem>
              </CompactList>
            </Box>
          </Box>
        </Box>
        <Link
          target="_blank"
          href="https://sortdesk.com"
          sx={{
            textDecoration: "none",
            ...centeredBlockStyle,
            ...borderedBlockStyle,
            ...fullWidthBlockStyle,
            padding: theme.pad.compact2,
            flexGrow: 0,
            background: theme.palette.bgLightGrey,
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.primary.main }}>
            Proudly built by{" "}
            <b>
              <u>sortdesk.com</u>
            </b>
            {", "}
            your software agency for construction
            <LinkArrow />
          </Typography>
        </Link>
        <Box
          sx={{ ...centeredBlockStyle, flexGrow: "5", minHeight: "8rem", minWidth: "100%" }}
        ></Box>
      </Box>
    </>
  );
}
