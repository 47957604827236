import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { initSentry } from "app/utils/sentry";
import { firebase } from "app/utils/firebase";

export type Event = {
  event: string; // must correspond with the name inside Google Analytics
};

/* The state is internally managed by google */
const initialState = {
  initialized: false,
};

export const telemetrySlice = createSlice({
  name: "telemetry",
  initialState,
  reducers: {
    initializeTelemetry: state => {
      if (state.initialized) return state;

      initSentry();
      firebase.initializeAnalytics();
      return { initialized: true };
    },
    deinitializeTelemetry: state => {
      if (!state.initialized) return state;

      // remove added google scripts
      // Array.from(document.getElementsByTagName("script"))
      //   .filter(x => x.src.includes("googletagmanager"))
      //   .forEach(x => x.remove());
      // Cookies.remove("_ga", { path: "/", domain: ".sortdesk.com" });
      // Cookies.remove("_ga_" + import.meta.env.REACT_APP_GA_CONTAINER_ID, {
      //   path: "/",
      //   domain: ".sortdesk.com",
      // });

      // GA will somehow persist in memory if not reloaded
      window.location.reload();
      return { initialized: false };
    },
    sendCustom: (state, action: PayloadAction<Event>) => {
      if (state.initialized) {
        firebase.ga_event(action.payload.event);
      }
    },
  },
});

export const { initializeTelemetry, deinitializeTelemetry, sendCustom } = telemetrySlice.actions;
export default telemetrySlice.reducer;
