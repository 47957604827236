import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  Box,
  Divider,
  IconButton,
  Input,
  List,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDSApplicability,
  IDSRequirement,
  IDSSpecificationSections,
  readableIDSRestrictionAttribute,
  readableIDSRestrictionClassification,
  readableIDSRestrictionEntity,
  readableIDSRestrictionMaterial,
  readableIDSRestrictionPartOf,
  readableIDSRestrictionProperty,
} from "app/common/idsSpec";
import { TOOLS } from "app/common/types";
import StyledDrawer from "app/components/StyledDrawer";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxColE2E } from "app/components/common/BoxColE2E";
import { BoxRow } from "app/components/common/BoxRow";
import { BoxRowE2E } from "app/components/common/BoxRowE2E";
import { Heavy } from "app/components/common/Heavy";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  idsSetFileName,
  idsSetPanelState,
  idsSpecAddApplicabilityRestriction,
  idsSpecAddRequirementsRestriction,
  idsSpecDeleteRestriction,
  moveToIDSRestriction,
  moveToIDSSection,
  selectIDSFileName,
  selectIDSMode,
  selectIDSPanelSection,
  selectIDSPanelState,
  selectIDSParsingError,
  selectIDSSpecSelectedId,
  selectIDSSpecSelectedRestrictionPath,
  selectIDSSpecs,
  selectIsLeftPanelOpen,
  selectIsRightPanelOpen,
  selectIsToolActive,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { useEffect, useState } from "react";

import { AddIconInline, AnimatedAddIcon, ToolsListItem, UsageHint } from "../common";
import IDSSectionFileInfo from "./IDSSectionFileInfo";
import { IDSSectionRequirement } from "./IDSSectionRequirement";
import { IDSSectionResultItem } from "./IDSSectionResultItem";
import { IDSSectionSpecification } from "./IDSSectionSpecification";
import { IDSRestrictionListItem, presentIDSValue } from "./common";

const presentRestrictionAsEditableList = (
  specId: string,
  restrictionType: IDSSpecificationSections,
  restrictions: IDSRequirement | IDSApplicability | null
): IDSRestrictionListItem[] | null => {
  const nodes: IDSRestrictionListItem[] = [];
  let id = 0;

  console.log("restrictions:", restrictions);

  if (restrictions && restrictions?.entity) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.entity.length; index++) {
      nodes.push({
        name: presentIDSValue(restrictions.entity[index]?.name) ?? "No Name",
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "entity",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }

  if (restrictions && restrictions?.partOf) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.partOf.length; index++) {
      const entityName = presentIDSValue(restrictions.partOf[index]?.entity?.[0]?.name);
      const cardinality = restrictions.partOf[index].$_cardinality;
      nodes.push({
        name: `${entityName}/${
          presentIDSValue(restrictions.partOf[index]?.$_relation) ?? "No Relation"
        }`,
        cardinality:
          (cardinality == "required" && "+") || (cardinality == "prohibited" && "-") || undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "partOf",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }

  if (restrictions && restrictions?.classification) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.classification.length; index++) {
      const system = presentIDSValue(restrictions.classification[index]?.system);
      const value = presentIDSValue(restrictions.classification[index].value);
      const cardinality = restrictions.classification[index].$_cardinality;
      nodes.push({
        name: `${(system && String(system)) ?? "No System"}/${
          (value && String(value)) ?? "No Value"
        }`,
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "classification",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }

  if (restrictions && restrictions?.attribute) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.attribute.length; index++) {
      const cardinality = restrictions.attribute[index].$_cardinality;
      nodes.push({
        name: presentIDSValue(restrictions.attribute[index].name) ?? "No Name",
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "attribute",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.property) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.property.length; index++) {
      const pset = presentIDSValue(restrictions.property[index]?.propertySet);
      const baseName = presentIDSValue(restrictions.property[index].baseName);
      const cardinality = restrictions.property[index].$_cardinality;
      nodes.push({
        name: `${(pset && String(pset)) ?? "No PSET"}/${
          (baseName && String(baseName)) ?? "No Name"
        }`,
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "property",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.material) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.material.length; index++) {
      const cardinality = restrictions.material[index].$_cardinality;
      nodes.push({
        name: presentIDSValue(restrictions.material[index]?.value) ?? "No Material",
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "material",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  return (nodes.length > 0 && nodes) || null;
};

const presentRestrictionAsReadableList = (
  specId: string,
  restrictionType: IDSSpecificationSections,
  restrictions: IDSRequirement | IDSApplicability | null
): IDSRestrictionListItem[] | null => {
  const nodes: IDSRestrictionListItem[] = [];
  let id = 0;

  console.log("restrictions:", restrictions);

  if (restrictions && restrictions?.entity) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.entity.length; index++) {
      nodes.push({
        name: readableIDSRestrictionEntity(restrictions.entity[index]) ?? "Any Entity",
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "entity",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.attribute) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.attribute.length; index++) {
      const cardinality = restrictions.attribute[index].$_cardinality;
      nodes.push({
        name: readableIDSRestrictionAttribute(restrictions.attribute[index]) ?? "Any Attribute",
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "attribute",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.property) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.property.length; index++) {
      nodes.push({
        name: readableIDSRestrictionProperty(restrictions.property[index]) ?? "Any Property",
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "property",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.partOf) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.partOf.length; index++) {
      const cardinality = restrictions.partOf[index].$_cardinality;
      nodes.push({
        name: readableIDSRestrictionPartOf(restrictions.partOf[index]) ?? "Any part Of",
        cardinality:
          (cardinality == "required" && "+") || (cardinality == "prohibited" && "-") || undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "partOf",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.classification) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.classification.length; index++) {
      const cardinality = restrictions.classification[index].$_cardinality;
      nodes.push({
        name:
          readableIDSRestrictionClassification(restrictions.classification[index]) ??
          "Any Classification",
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "classification",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  if (restrictions && restrictions?.material) {
    // eslint-disable-next-line
    for (let index = 0; index < restrictions.material.length; index++) {
      const cardinality = restrictions.material[index].$_cardinality;
      nodes.push({
        name: readableIDSRestrictionMaterial(restrictions.material[index]) ?? "Any Material",
        cardinality:
          (cardinality == "optional" && "?") ||
          (cardinality == "required" && "+") ||
          (cardinality == "prohibited" && "-") ||
          undefined,
        restrictionPath: {
          specId,
          section: restrictionType,
          facet: "material",
          facetIndex: index,
          valueType: "simpleValue",
        },
        id: id,
      });
      id += 1;
    }
  }
  return (nodes.length > 0 && nodes) || null;
};

function Content() {
  const dispatch = useAppDispatch();

  const idsPanelState = useAppSelector(selectIDSPanelState);
  const idsPanelSection = useAppSelector(selectIDSPanelSection);
  const idsSpecId = useAppSelector(selectIDSSpecSelectedId);
  const idsSpecs = useAppSelector(selectIDSSpecs);
  const selectedRestrictionPath = useAppSelector(selectIDSSpecSelectedRestrictionPath);
  const idsParsingError = useAppSelector(selectIDSParsingError);
  const idsFileName = useAppSelector(selectIDSFileName);
  const idsMode = useAppSelector(selectIDSMode);

  return (
    <BoxColE2E
      // This whole box is needed because height: 0 doesn't work with padding
      sx={{ padding: `${theme.sppx.compact2V} ${theme.sppx.compact2H}` }}
    >
      <BoxRowE2E sx={{ padding: 0, height: "max-content" }}>
        {idsPanelSection != "fileInfo" ? (
          <>
            <Tabs
              defaultValue="applicability"
              value={idsPanelSection || false}
              onChange={(e, newValue) => {
                dispatch(moveToIDSSection(newValue));
              }}
              aria-label="Specification Sections"
              sx={{ flexGrow: 1 }}
            >
              {idsPanelSection != "results" && (
                <Tab value="specification" label="SPECIFICATION INFO" />
              )}

              {idsPanelSection != "results" && (
                <Tab
                  label=""
                  icon={<Divider orientation="vertical" variant="middle" />}
                  disabled
                  sx={{ minWidth: 0 }}
                />
              )}
              {idsPanelSection != "results" && <Tab value="applicability" label="APPLICABILITY" />}
              {idsPanelSection != "results" && <Tab value="requirements" label="REQUIREMENTS" />}
              {idsPanelSection == "results" && <Tab value="results" label="RESULTS" />}
              <Tab label="" disabled sx={{ flexGrow: 1, maxWidth: "none" }} />
              {/* <Tab value="fileInfo" label="File Info" onClick={deselectTabOnClick("fileInfo")} /> */}
            </Tabs>
            <Box /* spacer to avoid X mislicks */ sx={{ width: `${theme.sp.compactV}px` }} />
          </>
        ) : (
          <>
            <Typography
              sx={{ marginLeft: theme.sppx.compactH, fontSize: "1.15rem", fontWeight: "500" }}
            >
              IDS File Name:
            </Typography>
            <Input
              value={idsFileName}
              sx={{
                marginLeft: theme.sppx.compactH,
                "& input": {
                  width: "50rem",
                },
              }}
              onChange={e => dispatch(idsSetFileName(e.target.value))}
            />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <Tooltip title="Toggle Expansion">
          <IconButton
            onClick={async () => {
              dispatch(idsSetPanelState(idsPanelState == "expanded" ? "normal" : "expanded"));
            }}
          >
            <FullscreenIcon sx={{ display: idsPanelState == "expanded" ? "none" : "block" }} />
            <FullscreenExitIcon sx={{ display: idsPanelState == "expanded" ? "block" : "none" }} />
          </IconButton>
        </Tooltip>
        <Box /* spacer to avoid X mislicks */ sx={{ width: theme.sppx.gapTiny }} />
        <Tooltip title="Close Panel">
          <IconButton
            onClick={async () => {
              // minimize panel
              dispatch(moveToIDSSection(null));
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </BoxRowE2E>

      {idsPanelSection == "specification" && <IDSSectionSpecification idsSpecId={idsSpecId} />}
      {idsPanelSection == "fileInfo" && <IDSSectionFileInfo />}
      {(idsPanelSection == "requirements" || idsPanelSection == "applicability") && idsSpecId && (
        <BoxRow
          sx={{
            flexGrow: 1,
            width: "100%",
            padding: `${theme.sppx.spaciousV} ${theme.sppx.compact2H}`,
            overflow: "hidden",
          }}
        >
          <BoxCol
            sx={{
              ...(idsMode == "edit" && {
                minWidth: "30rem",
                maxWidth: "50rem",
                marginRight: theme.sppx.spaciousH,
              }),
              ...(idsMode == "check" && { width: "100%" }),
            }}
          >
            <BoxRow
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: theme.sppx.compact2V,
                borderBottom: `1px solid ${theme.palette.softBulletGrey}`,
                paddingBottom: theme.sppx.compact2V,
              }}
            >
              <BoxCol
                sx={{
                  flexGrow: 1,
                  alignItems: "flex-start",
                  paddingLeft: `${theme.sp.spaciousH}px`,
                }}
              >
                {idsMode == "edit" && (
                  <Typography
                    sx={{
                      fontSize: "1.15rem",
                      fontWeight: "500",
                      lineHeight: "1.3",
                    }}
                  >
                    Restrictions
                  </Typography>
                )}
                {idsMode == "check" && (
                  <Typography
                    sx={{
                      fontSize: "1.15rem",
                      fontWeight: "500",
                      lineHeight: "1.3",
                    }}
                  >
                    {idsPanelSection == "applicability" && "All entities which:"}
                    {idsPanelSection == "requirements" && "All matching entities must:"}
                  </Typography>
                )}
              </BoxCol>
              {idsMode == "edit" && (
                <Tooltip title="Add restriction" placement="right">
                  <IconButton
                    sx={{
                      padding: "5px",
                      margin: `-1px ${theme.sp.spaciousH - 7}px -1px ${theme.sppx.spaciousH}`,
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => {
                      if (idsPanelSection == "applicability")
                        dispatch(idsSpecAddApplicabilityRestriction());
                      if (idsPanelSection == "requirements")
                        dispatch(idsSpecAddRequirementsRestriction());
                    }}
                  >
                    <AnimatedAddIcon
                      sx={{
                        color: "inherit",
                        width: `${theme.sp.iconSize + 7}px`,
                        height: `${theme.sp.iconSize + 7}px`,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </BoxRow>
            <List
              sx={{
                overflowY: "auto",
              }}
            >
              {idsMode == "edit"
                ? presentRestrictionAsEditableList(
                    idsSpecId,
                    idsPanelSection,
                    idsSpecs[idsSpecId][idsPanelSection]
                  )?.map?.(x => (
                    <ToolsListItem
                      key={x.id}
                      selected={
                        x.restrictionPath?.facetIndex !== undefined &&
                        selectedRestrictionPath?.facetIndex !== undefined &&
                        x.restrictionPath?.facet !== undefined &&
                        selectedRestrictionPath?.facet !== undefined
                          ? x.restrictionPath.facetIndex == selectedRestrictionPath.facetIndex &&
                            x.restrictionPath.facet == selectedRestrictionPath.facet
                          : false
                      }
                      sx={{ maxWidth: "30rem" }}
                      onClick={() => dispatch(moveToIDSRestriction(x.restrictionPath ?? null))}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {(x?.restrictionPath?.facet == "entity" && "E") ||
                            (x?.restrictionPath?.facet == "attribute" &&
                              "A" + (x?.cardinality ?? "")) ||
                            (x?.restrictionPath?.facet == "property" &&
                              "P" + (x?.cardinality ?? "")) ||
                            (x?.restrictionPath?.facet == "partOf" &&
                              "O" + (x?.cardinality ?? "")) ||
                            (x?.restrictionPath?.facet == "classification" &&
                              "C" + (x?.cardinality ?? "")) ||
                            (x?.restrictionPath?.facet == "material" &&
                              "M" + (x?.cardinality ?? "")) ||
                            ""}
                        </Typography>
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          paddingLeft: theme.sppx.spaciousH, // decrowd the start icon
                          overflow: "hidden",
                          "& .MuiTypography-root": { textOverflow: "ellipsis" },
                        }}
                        primary={x.name}
                      />
                      <Tooltip placement="top" arrow title="Delete">
                        <IconButton
                          variant="inline"
                          onClick={e => {
                            dispatch(idsSpecDeleteRestriction(x.restrictionPath ?? null));
                            e.stopPropagation();
                          }}
                          sx={{ margin: "-8px", padding: "8px" }}
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </ToolsListItem>
                  ))
                : presentRestrictionAsReadableList(
                    idsSpecId,
                    idsPanelSection,
                    idsSpecs[idsSpecId][idsPanelSection]
                  )?.map?.(x => (
                    <ToolsListItem key={x.id} selected={false}>
                      <ListItemText
                        sx={{ paddingLeft: theme.sppx.spaciousH }} // decrowd the eye icon
                        primary={x.name}
                      />
                    </ToolsListItem>
                  )) || (
                    <BoxCol>
                      <UsageHint>
                        <b>Click</b> <AddIconInline /> to <Heavy>create</Heavy> a restriction
                      </UsageHint>
                    </BoxCol>
                  )}
            </List>
          </BoxCol>
          {idsMode == "edit" && <Divider orientation="vertical" sx={{ marginRight: "24px" }} />}
          {idsMode == "edit" && <IDSSectionRequirement />}
        </BoxRow>
      )}
      {idsPanelSection &&
        ["results", "requirements", "applicability"].includes(idsPanelSection) &&
        !idsSpecId &&
        idsParsingError == null && (
          <BoxRow
            sx={{
              flexGrow: 1,
              width: "100%",
              padding: `${theme.sppx.spaciousV} ${theme.sppx.compact2H}`,
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UsageHint>
              <b>Click</b> on a <Heavy>specification</Heavy> to select it
            </UsageHint>
          </BoxRow>
        )}
      {idsPanelSection == "results" && (idsSpecId || idsParsingError) && <IDSSectionResultItem />}
    </BoxColE2E>
  );
}

export default function IDSPanel() {
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const idsFileName = useAppSelector(selectIDSFileName);
  const idsPanelState = useAppSelector(selectIDSPanelState);
  const isActive =
    useAppSelector(selectIsToolActive(TOOLS.IDSEDITOR)) &&
    idsFileName != null &&
    idsPanelState != "minimized";

  const isLeftPanelOpen = useAppSelector(selectIsLeftPanelOpen);
  const isRightPanelOpen = useAppSelector(selectIsRightPanelOpen);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    setPageHeight(window.innerHeight);
    window.addEventListener(
      "resize",
      () => {
        setPageWidth(window.innerWidth);
        setPageHeight(window.innerHeight);
      },
      true
    );
  }, []);

  const height = (() => {
    if (!isActive) return "0";
    if (idsPanelState == "expanded") {
      return `${pageHeight - theme.sp.headerHeight - theme.sp.footerHeight + 1}px`; // the 1 is to collapse borders
    }
    // if (idsPanelState == "minimized") {
    //   return `${48 + theme.sp.compact2V * 2}px`;
    // }
    if (idsPanelState == "normal") {
      return "30vh";
    }

    return 0;
  })();

  const width = (() => {
    if (isLeftPanelOpen && isRightPanelOpen) {
      return `${pageWidth - 2 * theme.sp.sidepanelWidth - theme.sp.sidebarWidth - 2}px`;
    }
    if (isRightPanelOpen) {
      return `${pageWidth - theme.sp.sidepanelWidth - theme.sp.sidebarWidth - 2}px`;
    }
    if (isLeftPanelOpen) {
      return `${pageWidth - theme.sp.sidepanelWidth - 2 * theme.sp.sidebarWidth - 2}px`;
    }
    return `${pageWidth - 2 * theme.sp.sidebarWidth - 2}px`;
  })();

  return (
    <StyledDrawer
      variant="permanent"
      open={isActive}
      anchor="bottom"
      PaperProps={{
        sx: {
          mb: `${theme.sp.footerHeight}px`,
          ml: isLeftPanelOpen
            ? `${theme.sp.sidepanelWidth + theme.sp.sidebarWidth}px`
            : `${theme.sp.sidebarWidth}px`,
          width,
          height,
          border: isActive ? "1px solid " + theme.palette.subtleGreyBorder : "none",
        },
      }}
    >
      <Content />
    </StyledDrawer>
  );
}
