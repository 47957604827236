import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import theme from "app/theme";
import { ReactNode } from "react";

export function CleanTextField({
  onFocus,
  onBlur,
  sx,
  isRequired,
  value,
  options,
  //fixme: toc update
  //eslint-disable-next-line
  placeholder,
  onChange,
  hasError,
  helperText,
  ...props
}: TextFieldProps & {
  isRequired?: boolean;
  options?: Array<{ label: string }>;
  hasError?: boolean;
  helperText?: ReactNode;
}) {
  //@ts-ignore
  const fieldHasError =
    (hasError && (isRequired || (value != null && value != ""))) ||
    ((isRequired && (value == null || (value as string)?.length == 0)) ?? false);

  return options ? (
    <Autocomplete
      onInputChange={(e, option, reason) => {
        if (reason == "reset") return; // this triggers when option menu closes
        console.log("CleanTextField:onInputChange:", option, reason);
        if (onChange) {
          const ev = {
            ...e,
            target: { ...e?.target, value: option },
          };
          onChange(ev as React.ChangeEvent<HTMLInputElement>);
        }
      }}
      onChange={(e, option, reason) => {
        //@ts-ignore
        console.log("CleanTextField:onChange:", option?.label, reason);
        if (onChange) {
          const ev = {
            ...e,
            //@ts-ignore
            target: { ...e?.target, value: option?.label },
          };
          onChange(ev as React.ChangeEvent<HTMLInputElement>);
        }
      }}
      value={value ?? ""}
      // placeholder={placeholder ?? "Type to search.."}
      options={options}
      sx={{
        flexGrow: "1",
        margin: theme.sppx.compactV,
        ...sx,
      }}
      renderInput={params => (
        <TextField {...params} error={fieldHasError} helperText={helperText} {...props} />
      )}
    />
  ) : (
    <TextField
      error={fieldHasError}
      helperText={helperText}
      multiline
      sx={{
        flexGrow: "1",
        margin: theme.sppx.compactV,
        // this fixes the textarea hitbox to behave like a input
        // the only drawback is label overlap on scroll but isn't maxRows capped
        "& .MuiInputBase-root": {
          padding: "0px",
        },
        "& textarea": {
          padding: "16.5px 14px",
        },
        ...sx,
      }}
      onFocus={e => {
        onFocus && onFocus(e);
      }}
      onBlur={e => {
        onBlur && onBlur(e);
      }}
      value={value ?? ""}
      onChange={onChange}
      {...props}
    />
  );
}
