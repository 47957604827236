import { styled } from "app/theme";
import { Backdrop, Typography } from "@mui/material";

export const StyledBackdrop = styled(Backdrop)(
  ({
    theme: {
      zIndex: { drawer },
      palette: {
        common: { white },
      },
    },
  }) => ({
    color: white,
    // TODO: identify and solve issue
    zIndex: drawer + 2,
    display: "flex",
    flexDirection: "column",
  })
);

export const Disclaimer = styled(Typography)({
  marginTop: "2rem",
});
