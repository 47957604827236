import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import { IDSSpecification, ifcVersionsToString, stringToifcVersions } from "app/common/idsSpec";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxRow } from "app/components/common/BoxRow";
import { CleanMultiChoice } from "app/components/common/CleanMultiChoice";
import { Heavy } from "app/components/common/Heavy";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  deleteIDSSpec,
  idsApplUpdateAttr,
  idsSpecUpdateAttr,
  selectIDSApplAttr,
  selectIDSMode,
  selectIDSSpec,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { emptyStringTo } from "app/utils/objectMeta";
import { CleanDropdown } from "app/components/common/CleanDropdown";

import { UsageHint } from "../common";
import { EditableField } from "./EditableField";

export function IDSSectionSpecification({ idsSpecId }: { idsSpecId: string | null }) {
  const dispatch = useAppDispatch();

  const idsSpec = useAppSelector(selectIDSSpec(idsSpecId));
  const idsMode = useAppSelector(selectIDSMode);

  const applMinOccurs = useAppSelector(selectIDSApplAttr(idsSpecId ?? null, "$_minOccurs"));
  const applMaxOccurs = useAppSelector(selectIDSApplAttr(idsSpecId ?? null, "$_maxOccurs"));

  const onIDSSpecIfcVersionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentValue?: string
  ) => {
    if (idsSpecId) {
      dispatch(
        idsSpecUpdateAttr({
          _id: idsSpecId,
          $_ifcVersion:
            ifcVersionsToString({
              ...stringToifcVersions(currentValue),
              [event.target.name]: event.target.value,
            }) ?? null,
        })
      );
    }
  };

  const onInputValueChange =
    (propertyName: keyof IDSSpecification) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!idsSpecId) return;
      const value = event.target.value;
      dispatch(
        idsSpecUpdateAttr({
          _id: idsSpecId,
          // prevent empty simpleValues
          [propertyName]: emptyStringTo(value, null),
        })
      );
    };

  if (idsSpec == null) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexBasis: "1",
          flexGrow: "1",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          position: "relative",
          minHeight: 0,
          overflow: "hidden",
          gap: theme.sppx.spaciousH,
        }}
      >
        <UsageHint>
          <b>Click</b> on a <Heavy>specification</Heavy> to select it
        </UsageHint>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "1",
        flexGrow: "1",
        paddingTop: theme.sppx.spaciousV,
        paddingBottom: theme.sppx.spaciousV,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        position: "relative",
        minHeight: 0,
        overflowX: "hidden",
        overflowY: "auto",
        gap: theme.sppx.spaciousH,
        boxSizing: "border-box",
      }}
      onClick={(e: any) => {
        // unhighlight EditableField
        if (!e?.target?.className?.includes?.("nput")) {
          //@ts-ignore
          document?.activeElement?.blur?.();
        }
      }}
    >
      <BoxCol sx={{ gap: theme.sppx.compactV, height: "100%", flexGrow: 1, overflow: "visible" }}>
        <CleanDropdown
          disabled={idsMode == "check"}
          label="Cardinality"
          values={[
            ["", "empty"],
            ["required", "Required"],
            ["prohibited", "Prohibited"],
            ["optional", "Optional"],
          ]}
          value={
            (applMaxOccurs == 0 && "prohibited") ||
            (applMinOccurs == 0 && "optional") ||
            (applMinOccurs == 1 && "required") ||
            ""
          }
          onChange={(event: SelectChangeEvent<unknown>) => {
            if (idsSpecId == null) return;

            const value = event.target.value;

            let minOccurs = null;
            if (value == "empty") minOccurs = null;
            else if (value == "required") minOccurs = 1;
            else if (value == "prohibited") minOccurs = 0;
            else if (value == "optional") minOccurs = 0;

            let maxOccurs: number | null | "unbounded" = null;
            if (value == "empty") maxOccurs = null;
            else if (value == "required") maxOccurs = "unbounded";
            else if (value == "prohibited") maxOccurs = 0;
            else if (value == "optional") maxOccurs = "unbounded";

            dispatch(
              idsApplUpdateAttr({
                _id: idsSpecId ?? undefined,
                $_minOccurs: minOccurs,
              })
            );
            dispatch(
              idsApplUpdateAttr({
                _id: idsSpecId ?? undefined,
                $_maxOccurs: maxOccurs,
              })
            );
          }}
          sx={{
            margin: theme.sppx.compactV,
          }}
        />
        <EditableField
          isRequired
          disabled={idsMode == "check"}
          label="Name"
          value={idsSpec.$_name ?? ""}
          onChange={onInputValueChange("$_name")}
        />
        <EditableField
          disabled={idsMode == "check"}
          label="Identifier"
          value={idsSpec.$_identifier ?? ""}
          onChange={onInputValueChange("$_identifier")}
        />
        <BoxRow
          sx={{
            alignItems: "baseline",
            paddingLeft: theme.sppx.compactV,
          }}
        >
          <CleanMultiChoice
            disabled={idsMode == "check"}
            label={"IFC Versions:"}
            choices={stringToifcVersions(idsSpec.$_ifcVersion)}
            onChange={e => onIDSSpecIfcVersionChange(e, idsSpec.$_ifcVersion)}
          />
        </BoxRow>
        <Box sx={{ flexGrow: 1 }} />
        {idsMode == "edit" && (
          <BoxRow>
            <Button
              size="large"
              color="error"
              // variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => dispatch(deleteIDSSpec())}
            >
              Delete Specification
            </Button>
          </BoxRow>
        )}
      </BoxCol>
      <BoxCol sx={{ gap: theme.sppx.compactV, height: "100%", flexGrow: 1, overflow: "visible" }}>
        <EditableField
          disabled={idsMode == "check"}
          label="Description"
          value={idsSpec.$_description ?? ""}
          onChange={onInputValueChange("$_description")}
        />
        <EditableField
          disabled={idsMode == "check"}
          label="Instructions"
          value={idsSpec.$_instructions ?? ""}
          onChange={onInputValueChange("$_instructions")}
        />
      </BoxCol>
    </Box>
  );
}
// const MemoIDSEditorSidebar = memo(IDSEditorSidebar);

export default IDSSectionSpecification;
