import { Typography } from "@mui/material";
import React from "react";

export const SPATIAL_TREE_HELP_CONTENT: React.FC = () => (
  <Typography>
    The <b>spatial tree</b> is a tool which enables the user to hide and show parts of the model.
    Items are structured in the tree, and can be shown or hidden by clicking the &quot;Visible&quot;
    checkbox.
  </Typography>
);

export const DIMENSION_TOOL_HELP_CONTENT: React.FC = () => (
  <Typography>
    The <b>dimension tool</b> is a tool for measuring objects. To do that turn on the tool with the
    &quot;Start measuring&quot; button. A <b>double click</b> on the object marks the starting point
    of the measurement. A <b>second double click</b> marks the ending point of the measurement. All
    measurements are visible on the model and in the dimension tool drawer.
  </Typography>
);

export const PLAN_MANAGER_HELP_CONTENT: React.FC = () => (
  <Typography>
    The <b>plan manager</b> is a tool which displays a view from the top of any given floor. It can
    be <b>exited</b> by clicking &quot;Exit plan view&quot; or by hiding the tool drawer.
  </Typography>
);

export const SECTION_TOOL_HELP_CONTENT: React.FC = () => (
  <Typography>
    The <b>section tool</b> is a tool which cuts content in the given plane or planes. It is
    necessary to turn the tool on with the &quot;Turn section tool on&quot; button. Then,{" "}
    <b>double click on any surface</b>. The application will create a cutting plane, which can be{" "}
    <b>moved along its normal</b>. It is possible to persist the configuration of cutting planes -
    you just need to provide a name and click the <b>save</b> button. It is possible to{" "}
    <b>remove</b> any saved configuration through clicking the thrash can icon.{" "}
    <i>All saved configurations will be deleted after reloading the page.</i>
  </Typography>
);

export const PROPERTIES_HELP_CONTENT: React.FC = () => (
  <Typography>
    The <b>properties picking</b> is triggered by <b>double-clicking</b> on any element of the
    model. The properties drawer is located on the right side of the screen and displays all kinds
    of properties and quantity sets.
  </Typography>
);
