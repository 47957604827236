import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { Box, IconButton, List, ListItemText, Tooltip } from "@mui/material";
import {
  setIsSectionEditable,
  setIsSectionVisibilityLocked,
  selectIsSectionCreationActive,
  startSectionCreation,
  cancelSectionCreation,
  selectCustomSections,
  hideAllSections,
  deleteSection,
} from "app/state/slices/ifcManagerSlice";
import { Section } from "app/common/types";
import {
  AnimatedAddIcon,
  AddIconInline,
  CancelIconInline,
  VisibilityIconInline,
  VisibilityIcon,
  VisibilityOffIcon,
  CloseIcon,
  ToolsSidebarHeader,
  ToolsSidebarBody,
  ToolsListItem,
  UsageHint,
} from "app/components/ToolsSideBar/common";
import { Heavy } from "app/components/common/Heavy";
import { RichSpan } from "app/components/common/RichSpan";
import { ReactMouseEvent } from "app/common/types";
import theme from "app/theme";

import { SECTION_TOOL_HELP_CONTENT } from "../../help/HelpTexts";

export default function SectionToolSidebar() {
  const dispatch = useAppDispatch();

  const isSectionCreationActive = useAppSelector(selectIsSectionCreationActive);
  const customSections = useAppSelector(selectCustomSections);

  function toggleSectionCreationHandler() {
    if (isSectionCreationActive) {
      dispatch(cancelSectionCreation());
    } else {
      dispatch(startSectionCreation());
    }
  }
  const toggleSectionVisibilityHandler = (e: ReactMouseEvent, section: Section) => {
    dispatch(
      setIsSectionVisibilityLocked({
        id: section.id,
        value: !section.isVisibilityLocked,
      })
    );
    // prevent parent onClick from triggering to not toggle editable
    e.stopPropagation();
  };
  const toggleSectionEditableHandler = (section: Section) => {
    dispatch(setIsSectionEditable({ id: section.id, value: !section.isEditable }));
  };
  const deleteSectionHandler = (e: ReactMouseEvent, section: Section) => {
    dispatch(deleteSection({ id: section.id }));
    // prevent parent onClick from triggering to not toggle editable
    e.stopPropagation();
  };

  const usageHint = () =>
    (isSectionCreationActive && (
      <>
        <RichSpan key="isSectionCreationActiveuh1">
          <b>Double click</b> on any <Heavy>plane</Heavy> to <b>create</b> a section.{" "}
        </RichSpan>
        <br />
        <RichSpan key="isSectionCreationActiveuh2">
          <b>Click</b> the <CancelIconInline /> button to <b>cancel</b>.
        </RichSpan>
      </>
    )) ||
    (customSections.length == 0 && (
      <>
        <RichSpan key="nosectionsuh1">No sections yet. </RichSpan>
        <br />
        <RichSpan key="nosectionsuh2">
          <b>Click</b> the <AddIconInline /> button to <Heavy>create</Heavy> one.
        </RichSpan>
      </>
    )) ||
    (customSections.filter(s => s.isEditable).length > 0 && (
      <>
        <RichSpan key="noeditablesectionsuh1">
          <b>Click</b> the <Heavy>section</Heavy> <b>item</b> to <b>deselect</b> it.
        </RichSpan>
        <br />
        <RichSpan key="noeditablesectionsuh2">
          <b>Click</b> the <AddIconInline /> button to <b>create</b> more.
        </RichSpan>
      </>
    )) ||
    (customSections.length > 0 && (
      <>
        <RichSpan key="atleastasectionuh1">
          <b>Click</b> on any section to <Heavy>modify</Heavy> it.{" "}
        </RichSpan>
        <br />
        <RichSpan key="atleastasectionuh1">
          <b>Click</b> the <VisibilityIconInline /> to toggle <Heavy>visibility</Heavy>.
        </RichSpan>
      </>
    ));

  return (
    <Box
      sx={{ minHeight: "100%", overflowY: "hidden", display: "flex", flexDirection: "column" }} // provide clicking space for hide all
    >
      <ToolsSidebarHeader title="Sections" HelpContent={SECTION_TOOL_HELP_CONTENT}>
        <Tooltip title="Toggle Section Creation" placement="right">
          <IconButton onClick={() => toggleSectionCreationHandler()} sx={{ padding: "4px" }}>
            <AnimatedAddIcon className={isSectionCreationActive ? "CreationEnabled" : ""} />
          </IconButton>
        </Tooltip>
      </ToolsSidebarHeader>

      <ToolsSidebarBody
        onClick={e =>
          (console.log("hideAllSections:", e.target, e.currentTarget),
          e.target === e.currentTarget) && dispatch(hideAllSections())
        }
      >
        <UsageHint>{usageHint()}</UsageHint>
        <List component="nav" aria-label="main sections" sx={{ ...theme.vPad.compact2 }}>
          {customSections.map(section => (
            <ToolsListItem
              key={section.id}
              selected={section.isEditable}
              onClick={() => toggleSectionEditableHandler(section)}
            >
              <Tooltip placement="top" arrow title="Toggle visibility">
                <IconButton
                  variant="inline"
                  color={section.isVisibilityLocked ? "primary" : "default"}
                  onClick={e => toggleSectionVisibilityHandler(e, section)}
                >
                  {section.isVisibilityLocked ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Tooltip>
              <ListItemText
                primary={section.name}
                sx={{ paddingLeft: "12px" }} // decrowd the eye icon
              />
              <Tooltip placement="top" arrow title="Delete">
                <IconButton variant="inline" onClick={e => deleteSectionHandler(e, section)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </ToolsListItem>
          ))}
        </List>
      </ToolsSidebarBody>
    </Box>
  );
}
