import { IDSUIError } from "app/state/slices/ifcManager/idsEditor";
import { useAppDispatch } from "app/state/hooks";
import { Link } from "@mui/material";
import { idsFixUppercaseBooleans } from "app/state/slices/ifcManagerSlice";

const BooleanMismatchHelperText = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      {'The IFCBOOLEAN value must be "true" or "false" (lowercase). '}
      <Link href="#" onClick={() => dispatch(idsFixUppercaseBooleans())}>
        Click to auto lowercase all booleans.
      </Link>
    </>
  );
};

export const getIDSUIError = (error?: IDSUIError | null): React.ReactNode | undefined => {
  if (!error) return undefined;

  switch (error.type) {
    case "booleanMismatch":
      return <BooleanMismatchHelperText />;
    case "isNotUppercase":
      return "The value must be UPPERCASE";
  }

  return undefined;
};
