import React from "react";
import Divider from "@mui/material/Divider";
import { ListItem, ListItemText } from "@mui/material";
import { convertIfcString } from "app/utils";
import theme from "app/theme";
import { PropertyValue } from "app/common/ViewerAPI/properties";

const getPropertyStringifiedValues = (
  value: PropertyValue
): { propName: string | null; propValue: string } => {
  return {
    propName: convertIfcString(value.name),
    propValue: value.values
      .map(value => {
        if (!["string", "number", "boolean"].includes(typeof value) && value != null) {
          console.error("Property value is NOT a base type:", value, typeof value);
        }
        return value == null ? null : convertIfcString(value.toString());
      })
      .join(", "),
  };
};

interface Props {
  pset: PropertyValue[];
}

const Properties: React.FC<Props> = ({ pset }) => {
  return (
    <>
      <Divider />
      {pset
        .flatMap(prop => getPropertyStringifiedValues(prop))
        .map(({ propName, propValue }, index) => (
          <ListItem
            key={index}
            divider={true}
            sx={{
              padding: `${theme.sp.spaciousV}px ${theme.sp.spaciousH}px`,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                fontSize: "1em",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
              secondaryTypographyProps={{
                color: "#000000ff",
                paddingLeft: theme.sppx.spaciousH,
                lineHeight: "1.5",
              }}
              sx={{ textWrap: "wrap", maxWidth: "100%", overflowWrap: "break-word" }}
              primary={propName ?? "no name"}
              secondary={propValue ?? "no value"}
            />
          </ListItem>
        ))}
    </>
  );
};

export default Properties;
