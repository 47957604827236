import { Box, Button, ButtonProps } from "@mui/material";
import theme from "app/theme/theme";

export const ArrowButton = ({
  isSelected,
  hasArrowPrev,
  hasArrowNext,
  ...props
}: { isSelected: boolean; hasArrowPrev?: boolean; hasArrowNext?: boolean } & ButtonProps) => {
  const { sx, children, ...otherProps } = props;
  return (
    <Button
      disableRipple // hard to integrate with the arrows
      disableElevation
      variant={isSelected ? "contained" : "outlined"}
      size="medium"
      sx={{
        padding: "0",
        lineHeight: 1,
        height: theme.sppx.compactHeight,
        backgroundColor: "transparent",
        border: "none",
        transition: "color 0s",
        "&:hover": {
          background: "transparent",
          border: "none",
        },
        "& .arrowBtnText": {
          background: isSelected ? theme.palette.primary.main : "#FFFFFF",
        },
        "&:hover .arrowBtnText": {
          background: isSelected ? theme.palette.primary.main : theme.palette.hoverBackground,
          color: isSelected ? "#FFFFFF" : theme.palette.primary.main,
        },
        "&:hover .arrowBtnArrow": {
          color: isSelected ? theme.palette.primary.main : theme.palette.hoverBackground,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {hasArrowPrev && (
        <Box
          className="arrowBtnArrow"
          sx={{
            color: isSelected ? "#0d47a1" : "white",
            width: theme.sppx.compactHeight,
            height: theme.sppx.compactHeight,
            zIndex: "10",
            marginLeft: `-${theme.sp.compactHeight * 0.5}px`,
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              style={{ fill: "currentcolor", stroke: "#none" }}
              d="m 0,0 h 100 v 100 h -100 L 80,50 Z"
            />
            <path
              style={{
                fill: "currentcolor",
                stroke: isSelected ? theme.palette.primary.main : theme.palette.borderUnselected,
                strokeWidth: "3px",
              }}
              d="m 100,2 h -100 L 80,50 L 0,98 h 100"
            />
          </svg>
        </Box>
      )}
      <Box
        className="arrowBtnText"
        sx={{
          boxSizing: "border-box",
          height: theme.sppx.compactHeight,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          borderTopLeftRadius: hasArrowPrev ? "0px" : "4px",
          borderBottomLeftRadius: hasArrowPrev ? "0px" : "4px",
          borderTopRightRadius: hasArrowNext ? "0px" : "4px",
          borderBottomRightRadius: hasArrowNext ? "0px" : "4px",
          borderTop:
            "1px solid " +
            (isSelected ? theme.palette.primary.main : theme.palette.borderUnselected),
          borderBottom:
            "1px solid " +
            (isSelected ? theme.palette.primary.main : theme.palette.borderUnselected),
          borderLeft: hasArrowPrev
            ? "none"
            : "1px solid " +
              (isSelected ? theme.palette.primary.main : theme.palette.borderUnselected),
          borderRight: hasArrowNext
            ? "none"
            : "1px solid " +
              (isSelected ? theme.palette.primary.main : theme.palette.borderUnselected),
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: hasArrowPrev ? "0.4rem" : "0.8rem",
          paddingRight: hasArrowNext ? "0.4rem" : "0.8rem",
        }}
      >
        {children}
      </Box>
      {hasArrowNext && (
        <Box
          className="arrowBtnArrow"
          sx={{
            color: isSelected ? "#0d47a1" : "white",
            width: theme.sppx.compactHeight,
            height: theme.sppx.compactHeight,
            zIndex: "10",
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path style={{ fill: "currentcolor", stroke: "none" }} d="m 0,0 0,100 v 0 L 80,50 Z" />
            <path
              style={{
                fill: "currentcolor",
                stroke: isSelected ? theme.palette.primary.main : theme.palette.borderUnselected,
                strokeWidth: "3px",
              }}
              d="m 0,0 L 80,50 L 0,100"
            />
          </svg>
        </Box>
      )}
    </Button>
  );
};
