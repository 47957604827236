import theme from "app/theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
  FormControl,
  Tabs,
  Tab,
  Alert,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import { Heavy } from "app/components/common/Heavy";
import CloseIcon from "@mui/icons-material/Close";
import GooogleButton from "app/static/signin-assets/google/light/web_light_sq_SI.svg";
import MicrosoftButton from "app/static/signin-assets/microsoft/ms-symbollockup_signin_light.svg";
import {
  dismissLoginAlert,
  dismissModal,
  loginWithEmail,
  loginWithGoogle,
  loginWithMicrosoft,
  registerWithEmail,
  selectLoginMessage,
  selectLoginSeverity,
  selectSelectedModal,
  selectLoginEmail,
  selectLoginPassword,
  updateLoginDetails,
  selectLoginName,
  selectIsLoginPassMatch,
  selectIsLoginSignInActive,
  selectIsLoginSignUpActive,
  sendResetPasswordEmail,
  selectIsNewPasswordStrong,
  selectLoginNewPasswordConfirmation,
  selectLoginNewPassword,
  setWantsViewerNews,
  selectWantsViewerNews,
} from "app/state/slices/ifcManagerSlice";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { INPUTTYPES, MODALS, inputTypeProps } from "app/common/types";
import { TransactionTextField } from "app/common/TransactionTextField";
import { initializeTelemetry } from "app/state/slices/telemetrySlice";
import { BoxRow } from "app/components/common/BoxRow";
import { BoxCol } from "app/components/common/BoxCol";

import { OAuthButton } from "./common/OAuthButton";
import { PasswordAlert } from "./common/PasswordAlert";

export default function LoginModal() {
  const dispatch = useAppDispatch();
  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.LOGIN === selectedModal;

  const loginMessage = useAppSelector(selectLoginMessage);
  const loginSeverity = useAppSelector(selectLoginSeverity);
  const loginName = useAppSelector(selectLoginName);
  const loginEmail = useAppSelector(selectLoginEmail);
  const loginPassword = useAppSelector(selectLoginPassword);
  const loginNewPassword = useAppSelector(selectLoginNewPassword);
  const loginNewPasswordConfirmation = useAppSelector(selectLoginNewPasswordConfirmation);
  const isLoginPassMatch = useAppSelector(selectIsLoginPassMatch);
  const isLoginSignUpActive = useAppSelector(selectIsLoginSignUpActive);
  const isLoginSignInActive = useAppSelector(selectIsLoginSignInActive);
  const isNewPasswordStrong = useAppSelector(selectIsNewPasswordStrong);
  const wantsViewerNews = useAppSelector(selectWantsViewerNews);

  const [currentTab, setTab] = useState("register");

  const switchToTab = (name: string) => {
    dispatch(dismissLoginAlert());
    setTab(name);
  };

  // todo: maybe merge with other one & abstract?
  const focusField = (field: INPUTTYPES) => {
    const fields = document.getElementsByName(inputTypeProps[field].name);
    if (fields[0]) (fields[0] as HTMLElement).focus();
  };

  const commonInputProps = {
    required: true,
    enabled: true,
    editEnabled: true,
    fullWidth: true,
    showButtons: false,
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      sx={{ p: 2 }}
      onClose={() => dispatch(dismissModal())}
      open={isOpen}
    >
      <DialogTitle>
        <Tabs
          value={currentTab || false}
          onChange={(e, newValue) => switchToTab(newValue)}
          aria-label="account tabs"
        >
          <Tab value="register" label="SIGN UP" />
          <Tab value="login" label="SIGN IN" />
        </Tabs>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(dismissModal())}
        sx={{
          position: "absolute",
          right: theme.sppx.compactH,
          top: theme.sppx.compactH,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {currentTab == "register" ? (
        <DialogContent dividers sx={{ maxHeight: 900, textAlign: "justify" }}>
          {loginMessage && (
            <Alert severity={loginSeverity} onClose={() => dispatch(dismissLoginAlert())}>
              {loginMessage}
            </Alert>
          )}
          <Typography
            component="div"
            sx={{
              lineHeight: "2em",
            }}
          >
            <FormControl fullWidth>
              <TransactionTextField
                {...commonInputProps}
                autoFocus
                type={INPUTTYPES.NAME}
                value={loginName}
                onChange={e => dispatch(updateLoginDetails({ loginName: e.target.value }))}
                onSave={() => focusField(INPUTTYPES.EMAIL)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.EMAIL}
                value={loginEmail}
                onChange={e => dispatch(updateLoginDetails({ loginEmail: e.target.value }))}
                onSave={() => focusField(INPUTTYPES.NEWPASSWORD)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.NEWPASSWORD}
                isValidationError={!isNewPasswordStrong && loginNewPassword.length > 0}
                value={loginNewPassword}
                onChange={e => dispatch(updateLoginDetails({ loginNewPassword: e.target.value }))}
                onSave={() => focusField(INPUTTYPES.NEWPASSWORDCONFIRMATION)}
              />
              {!isNewPasswordStrong && loginNewPassword.length > 0 && <PasswordAlert />}
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.NEWPASSWORDCONFIRMATION}
                isValidationError={!isLoginPassMatch && loginNewPasswordConfirmation.length > 0}
                saveEnabled={isLoginSignUpActive}
                value={loginNewPasswordConfirmation}
                onChange={e =>
                  dispatch(updateLoginDetails({ loginNewPasswordConfirmation: e.target.value }))
                }
                onSave={() => {
                  // todo: abstract this
                  localStorage.setItem("acceptedTelemetry", "true");
                  dispatch(initializeTelemetry());
                  localStorage.setItem("acceptedToS", "true");
                  dispatch(registerWithEmail());
                }}
              />
              <BoxRow
                sx={{
                  alignItems: "flex-start",
                  gap: theme.sppx.compactH,
                  cursor: "pointer",
                  marginTop: theme.sppx.spaciousV,
                  marginBottom: theme.sppx.spaciousV,
                }}
                onClick={() => dispatch(setWantsViewerNews(!wantsViewerNews))}
              >
                <Checkbox sx={{ padding: "0px", paddingTop: "2px" }} checked={wantsViewerNews} />
                <BoxCol sx={{ paddingRight: theme.sppx.compactH }}>
                  <Typography
                    sx={{
                      textAlign: "justify",
                      textWrap: "wrap",
                      [theme.breakpoints.down("sm")]: {
                        textAlign: "left",
                      },
                    }}
                    component="div"
                  >
                    I want to receive <b>email updates</b> about <Heavy>new features</Heavy> (
                    <b>no spam, we promise</b>).
                  </Typography>
                </BoxCol>
              </BoxRow>
              <Button
                variant="contained"
                type="submit"
                disabled={!isLoginSignUpActive}
                onClick={() => {
                  // todo: abstract this
                  localStorage.setItem("acceptedTelemetry", "true");
                  dispatch(initializeTelemetry());
                  localStorage.setItem("acceptedToS", "true");
                  dispatch(registerWithEmail());
                }}
              >
                SIGN UP
              </Button>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Link href="#" onClick={() => switchToTab("login")} sx={{ textAlign: "center" }}>
                I already have an account
              </Link>
            </Box>
            <br />
            <BoxCol>
              <OAuthButton
                onClick={() => {
                  // todo: abstract this
                  localStorage.setItem("acceptedTelemetry", "true");
                  dispatch(initializeTelemetry());
                  localStorage.setItem("acceptedToS", "true");
                  dispatch(loginWithGoogle());
                }}
                alt="Google sign in"
                src={GooogleButton}
              />
              <OAuthButton
                onClick={() => {
                  // todo: abstract this
                  localStorage.setItem("acceptedTelemetry", "true");
                  dispatch(initializeTelemetry());
                  localStorage.setItem("acceptedToS", "true");
                  dispatch(loginWithMicrosoft());
                }}
                alt="Microsoft sign in"
                src={MicrosoftButton}
              />
            </BoxCol>
            <Box sx={{ mt: "15px", textAlign: "center", lineHeight: "1em" }}>
              <Heavy>
                By clicking <b>SIGN UP</b> or <b>Sign in</b> through a third party you accept
                Sortdesk&apos;s{" "}
                <Link href="/full-terms-account" target="_blank">
                  Full Terms of Use
                </Link>
              </Heavy>
            </Box>
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent dividers sx={{ maxHeight: 600, textAlign: "justify" }}>
          {loginMessage && (
            <Alert severity={loginSeverity} onClose={() => dispatch(dismissLoginAlert())}>
              {loginMessage}
            </Alert>
          )}
          <Typography component="div" sx={{ lineHeight: "2em" }}>
            <FormControl fullWidth>
              <TransactionTextField
                {...commonInputProps}
                autoFocus // fixme: doesn't work for sign up -> in transition
                type={INPUTTYPES.EMAIL}
                value={loginEmail}
                onChange={e => dispatch(updateLoginDetails({ loginEmail: e.target.value }))}
                onSave={() => focusField(INPUTTYPES.PASSWORD)}
              />
              <TransactionTextField
                {...commonInputProps}
                type={INPUTTYPES.PASSWORD}
                saveEnabled={isLoginSignInActive}
                value={loginPassword}
                onChange={e => dispatch(updateLoginDetails({ loginPassword: e.target.value }))}
                onSave={() => dispatch(loginWithEmail())}
              />
              <Button
                variant="contained"
                disabled={!isLoginSignInActive}
                onClick={() => dispatch(loginWithEmail())}
              >
                SIGN IN
              </Button>
            </FormControl>
            <br />
            <br />
            <br />
            <BoxCol>
              <OAuthButton
                onClick={() => dispatch(loginWithGoogle())}
                alt="Google sign in"
                src={GooogleButton}
              />
              <OAuthButton
                onClick={() => dispatch(loginWithMicrosoft())}
                alt="Microsoft sign in"
                src={MicrosoftButton}
              />
            </BoxCol>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Link href="#" onClick={() => switchToTab("register")} sx={{ textAlign: "center" }}>
                Don&apos;t have an account yet? Sign up
              </Link>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Link
                href="#"
                onClick={() => dispatch(sendResetPasswordEmail())}
                sx={{ textAlign: "center" }}
              >
                Forgot password? Send reset email
              </Link>
            </Box>
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  );
}
