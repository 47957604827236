export type NormalizeIDSOptions = {
  keepPrivateState?: boolean; // there are the "_*" fields (note: not "$_", those are attributes)
};

export const defaultNormalizeIDSOptions = {
  keepPrivateState: false,
};

export type XSPattern = {
  $_value?: string;
};
export type XSMinInclusive = {
  $_value?: string;
};
export type XSMaxInclusive = {
  $_value?: string;
};
export type XSMinExclusive = {
  $_value?: string;
};
export type XSMaxExclusive = {
  $_value?: string;
};
export type XSLength = {
  $_value?: string;
};
export type XSMinLength = {
  $_value?: string;
};
export type XSMaxLength = {
  $_value?: string;
};
export type XSEnumeration = {
  $_value?: string;
};
export type XSRestriction = {
  $_base?: "xs:string" | "xs:integer" | "xs:decimal";
  "xs:pattern"?: XSPattern;
  "xs:minInclusive"?: XSMinInclusive;
  "xs:maxInclusive"?: XSMaxInclusive;
  "xs:minExclusive"?: XSMinExclusive;
  "xs:maxExclusive"?: XSMaxExclusive;
  "xs:length"?: XSLength;
  "xs:minLength"?: XSMinLength;
  "xs:maxLength"?: XSMaxLength;
  "xs:enumeration"?: XSEnumeration[];
};

export const xsDetectBaseType = (value: (string | undefined) | (string | undefined)[]) => {
  if (!value) return undefined;
  const values = Array.isArray(value) ? value : [value];

  if (values.some(x => (console.log("x:", x), x) && x?.match?.(/\d+\.\d*|\d*\.\d+/)))
    return "xs:decimal";
  if (values.some(x => (console.log("x:", x), x) && x?.match?.(/\d+/))) return "xs:integer";
  return "xs:string";
};

export const normalizeXSRestriction = (xsRestriction: XSRestriction): XSRestriction => {
  return {
    $_base: xsRestriction.$_base,
    "xs:pattern": xsRestriction["xs:pattern"],
    "xs:minInclusive": xsRestriction["xs:minInclusive"],
    "xs:maxInclusive": xsRestriction["xs:maxInclusive"],
    "xs:minExclusive": xsRestriction["xs:minExclusive"],
    "xs:maxExclusive": xsRestriction["xs:maxExclusive"],
    "xs:length": xsRestriction["xs:length"],
    "xs:minLength": xsRestriction["xs:minLength"],
    "xs:maxLength": xsRestriction["xs:maxLength"],
    "xs:enumeration": xsRestriction["xs:enumeration"],
  };
};

export const readableXSRestriction = (xsRestriction?: XSRestriction): string | null => {
  if (xsRestriction == null) return null;
  if (xsRestriction["xs:pattern"] != null)
    return `matching '${xsRestriction["xs:pattern"].$_value}'`;
  if (xsRestriction["xs:length"] != null)
    return `of length '${xsRestriction["xs:length"].$_value}'`;
  if (xsRestriction["xs:enumeration"] != null)
    return `any of '${xsRestriction["xs:enumeration"].reduce(
      (acc, value) => `${acc}, '${value.$_value}'`,
      ""
    )}'`;

  let stringAcc = "";
  if (xsRestriction["xs:minLength"]) {
    stringAcc += `≥ ${xsRestriction["xs:minLength"].$_value}`;
  }
  if (xsRestriction["xs:minInclusive"]) {
    stringAcc += `≥ ${xsRestriction["xs:minInclusive"].$_value}`;
  }
  if (xsRestriction["xs:minExclusive"]) {
    stringAcc += `> ${xsRestriction["xs:minExclusive"].$_value}`;
  }
  if (stringAcc.length > 0) {
    stringAcc += " and ";
  }
  if (xsRestriction["xs:maxLength"]) {
    stringAcc += `≤ ${xsRestriction["xs:maxLength"].$_value}`;
  }
  if (xsRestriction["xs:maxInclusive"]) {
    stringAcc += `≤ ${xsRestriction["xs:maxInclusive"].$_value}`;
  }
  if (xsRestriction["xs:maxExclusive"]) {
    stringAcc += `< ${xsRestriction["xs:maxExclusive"].$_value}`;
  }

  if (stringAcc.length > 0) return stringAcc;
  return null;
};

export type XSRestrictionType =
  | "xs:pattern"
  | "xs:minInclusive"
  | "xs:maxInclusive"
  | "xs:minExclusive"
  | "xs:maxExclusive"
  | "xs:length"
  | "xs:minLength"
  | "xs:maxLength"
  | "xs:enumeration";

export type IDSValueObj = {
  simpleValue?: string | number;
  "xs:restriction"?: XSRestriction;
};
export const normalizeIDSValueObj = (idsValue: IDSValueObj): IDSValueObj => {
  if (idsValue["simpleValue"] != null)
    return {
      simpleValue: idsValue.simpleValue,
    };
  if (idsValue["xs:restriction"] != null)
    return {
      "xs:restriction": normalizeXSRestriction(idsValue["xs:restriction"]),
    };
  return {};
};

export const readableIDSValueObj = (idsValue?: IDSValueObj): string | null => {
  if (idsValue == null) return null;
  if (idsValue.simpleValue != null) return `'${idsValue.simpleValue}'`;
  return readableXSRestriction(idsValue["xs:restriction"]);
};

export type IDSValue = IDSValueObj | string;

export const normalizeIDSValue = (idsValue?: IDSValue): IDSValue | undefined => {
  if (idsValue == null) return undefined; // note: undefined fields get removed from export
  if (typeof idsValue == "string") return idsValue;
  return normalizeIDSValueObj(idsValue);
};

export const readableIDSValue = (idsValue?: IDSValue): string | null => {
  if (idsValue == null) return null;
  if (typeof idsValue == "string") return `'${idsValue}'`;
  return readableIDSValueObj(idsValue);
};

export type IDSConditionalCardinality = "required" | "prohibited" | "optional" | null;

export type IDSRestrictionEntity = {
  $_instructions?: string;
  name?: IDSValue; // spec mandatory
  predefinedType?: IDSValue;
};

export const normalizeIDSRestrictionEntity = (
  idsRestrictionEntity?: IDSRestrictionEntity
): IDSRestrictionEntity | undefined => {
  if (idsRestrictionEntity == null) return undefined;
  return {
    $_instructions: idsRestrictionEntity?.$_instructions,
    name: normalizeIDSValue(idsRestrictionEntity?.name),
    predefinedType: normalizeIDSValue(idsRestrictionEntity?.predefinedType),
  };
};

export const readableIDSRestrictionEntity = (
  idsRestrictionEntity: IDSRestrictionEntity
): string => {
  const namedValue = readableIDSValue(idsRestrictionEntity?.name);
  const named = namedValue && ` named ${namedValue}`;
  const ofTypeValue = readableIDSValue(idsRestrictionEntity?.predefinedType);
  const ofType = ofTypeValue && ` of type ${ofTypeValue}`;
  return `are an Entity${named ?? ""}${ofType ?? ""}`;
};

export type IDSRelations =
  | "IFCRELAGGREGATES"
  | "IFCRELASSIGNSTOGROUP"
  | "IFCRELCONTAINEDINSPATIALSTRUCTURE"
  | "IFCRELNESTS"
  | "IFCRELVOIDSELEMENT IFCRELFILLSELEMENT";
export type IDSSimpleCardinality = "required" | "prohibited";

export type IDSRestrictionPartOf = {
  $_cardinality?: IDSSimpleCardinality;
  $_instructions?: string;
  entity?: IDSRestrictionEntity[];
  $_relation?: IDSRelations;
};

export const normalizeIDSRestrictionPartOf = (
  idsRestrictionPartOf: IDSRestrictionPartOf
): IDSRestrictionPartOf => {
  const entity = idsRestrictionPartOf?.entity?.[0];
  return {
    $_instructions: idsRestrictionPartOf?.$_instructions,
    $_cardinality: idsRestrictionPartOf?.$_cardinality,
    entity: entity && [normalizeIDSRestrictionEntity(entity) as IDSRestrictionEntity],
    $_relation: idsRestrictionPartOf?.$_relation,
  };
};

export const readableIDSRestrictionPartOf = (
  idsRestrictionPartOf: IDSRestrictionPartOf
): string => {
  const entity = idsRestrictionPartOf?.entity?.[0];

  const namedValue = readableIDSValue(entity?.name);
  const named = namedValue && ` named ${namedValue}`;
  const ofTypeValue = readableIDSValue(entity?.predefinedType);
  const ofType = ofTypeValue && ` of type ${ofTypeValue}`;

  const relation = idsRestrictionPartOf?.$_relation;
  const cardinality = idsRestrictionPartOf.$_cardinality == "prohibited" ? "are not" : "are";
  return `${cardinality} an '${relation ?? "undefined"}' part of an Entity${named ?? ""}${
    ofType ?? ""
  }`;
};

export type IDSRestrictionAttribute = {
  $_instructions?: string;
  $_cardinality?: IDSConditionalCardinality;
  name?: IDSValue;
  value?: IDSValue;
  $_minOccurs?: string; // number | "unbounded"
  $_maxOccurs?: string; // fixme: why is this is spec, but not validateable?
};

export const normalizeIDSRestrictionAttribute = (
  idsRestrictionAttribute: IDSRestrictionAttribute
): IDSRestrictionAttribute => {
  return {
    $_instructions: idsRestrictionAttribute?.$_instructions,
    $_cardinality: idsRestrictionAttribute?.$_cardinality,
    name: normalizeIDSValue(idsRestrictionAttribute?.name),
    value: normalizeIDSValue(idsRestrictionAttribute?.value),
  };
};

export const readableIDSRestrictionAttribute = (
  idsRestrictionAttribute: IDSRestrictionAttribute
): string => {
  const cardinality =
    (idsRestrictionAttribute?.$_cardinality == "required" && "have a required") ||
    (idsRestrictionAttribute?.$_cardinality == "optional" && "have an optional") ||
    (idsRestrictionAttribute?.$_cardinality == "prohibited" && "not have an") ||
    "have an";

  const namedValue = readableIDSValue(idsRestrictionAttribute?.name);
  const named = namedValue && ` named ${namedValue}`;
  const withValueValue = readableIDSValue(idsRestrictionAttribute?.value);
  const withValue = withValueValue && ` with value ${withValueValue}`;

  return `${cardinality} Attribute${named ?? ""}${withValue ?? ""}`;
};

export type IDSRestrictionClassification = {
  $_uri?: string;
  $_instructions?: string;
  $_cardinality?: IDSConditionalCardinality;
  system?: IDSValue;
  value?: IDSValue;
};

export const normalizeIDSRestrictionClassification = (
  idsRestrictionClassification: IDSRestrictionClassification
): IDSRestrictionClassification => {
  return {
    $_uri: idsRestrictionClassification?.$_uri,
    $_instructions: idsRestrictionClassification?.$_instructions,
    $_cardinality: idsRestrictionClassification?.$_cardinality,
    value: normalizeIDSValue(idsRestrictionClassification?.value),
    system: normalizeIDSValue(idsRestrictionClassification?.system),
  };
};

export const readableIDSRestrictionClassification = (
  idsRestrictionClassification: IDSRestrictionClassification
): string => {
  const cardinality =
    (idsRestrictionClassification?.$_cardinality == "required" && "have a required") ||
    (idsRestrictionClassification?.$_cardinality == "optional" && "have an optional") ||
    (idsRestrictionClassification?.$_cardinality == "prohibited" && "not have an") ||
    "have an";

  const systemValue = readableIDSValue(idsRestrictionClassification?.system);
  const system = systemValue && `${systemValue}`;
  const withValueValue = readableIDSValue(idsRestrictionClassification?.value);
  const withValue = withValueValue && ` with value ${withValueValue}`;

  return `${cardinality} Classification System ${system ?? ""}${withValue ?? ""}`;
};

export type IDSRestrictionMaterial = {
  $_uri?: string;
  $_instructions?: string;
  $_cardinality?: IDSConditionalCardinality;
  value?: IDSValue;
};

export const normalizeIDSRestrictionMaterial = (
  idsRestrictionMaterial: IDSRestrictionMaterial
): IDSRestrictionMaterial => {
  return {
    $_uri: idsRestrictionMaterial?.$_uri,
    $_instructions: idsRestrictionMaterial?.$_instructions,
    $_cardinality: idsRestrictionMaterial?.$_cardinality,
    value: normalizeIDSValue(idsRestrictionMaterial?.value),
  };
};

export const readableIDSRestrictionMaterial = (
  idsRestrictionMaterial: IDSRestrictionMaterial
): string => {
  const cardinality =
    (idsRestrictionMaterial?.$_cardinality == "required" && "have a required") ||
    (idsRestrictionMaterial?.$_cardinality == "optional" && "have an optional") ||
    (idsRestrictionMaterial?.$_cardinality == "prohibited" && "not have an") ||
    "have an";

  const withValueValue = readableIDSValue(idsRestrictionMaterial?.value);
  const withValue = withValueValue && ` with value ${withValueValue}`;

  return `${cardinality} Material ${withValue ?? ""}`;
};

export type IDSRestrictionProperty = {
  $_uri?: string;
  $_instructions?: string;
  $_cardinality?: IDSConditionalCardinality;
  $_dataType?: string; // spec upperCaseName [A-Z]+
  propertySet?: IDSValue; // spec mandatory
  baseName?: IDSValue; // spec mandatory
  value?: IDSValue;
};

export const normalizeIDSRestrictionProperty = (
  idsRestrictionProperty: IDSRestrictionProperty
): IDSRestrictionProperty => {
  return {
    $_uri: idsRestrictionProperty?.$_uri,
    $_instructions: idsRestrictionProperty?.$_instructions,
    $_cardinality: idsRestrictionProperty?.$_cardinality,
    $_dataType: idsRestrictionProperty?.$_dataType,
    propertySet: normalizeIDSValue(idsRestrictionProperty?.propertySet),
    baseName: normalizeIDSValue(idsRestrictionProperty?.baseName),
    value: normalizeIDSValue(idsRestrictionProperty?.value),
  };
};

export const readableIDSRestrictionProperty = (
  idsRestrictionAttribute: IDSRestrictionProperty
): string => {
  const cardinality =
    (idsRestrictionAttribute?.$_cardinality == "required" && "have a required") ||
    (idsRestrictionAttribute?.$_cardinality == "optional" && "have an optional") ||
    (idsRestrictionAttribute?.$_cardinality == "prohibited" && "not have a") ||
    "have a";

  const namedValue = readableIDSValue(idsRestrictionAttribute?.baseName);
  const named = namedValue && ` named ${namedValue}`;

  const valueType =
    idsRestrictionAttribute?.$_dataType && ` an ${idsRestrictionAttribute?.$_dataType}`;
  const withValueValue = readableIDSValue(idsRestrictionAttribute?.value);
  const withValue = withValueValue && ` with${valueType} value ${withValueValue}`;

  const inPSETValue = readableIDSValue(idsRestrictionAttribute?.propertySet);
  const inPSET = withValueValue && ` in a PSET ${inPSETValue}`;

  return `${cardinality} Property${named ?? ""}${withValue ?? ""}${inPSET ?? ""}`;
};

export type IDSRestriction = {
  entity?: IDSRestrictionEntity;
  attribute?: IDSRestrictionAttribute;
  property?: IDSRestrictionProperty;
};

export type IDSSpecificationSections =
  | "specification"
  | "applicability"
  | "requirements"
  | "results"
  | "fileInfo";
export type IDSFacet =
  | "entity"
  | "attribute"
  | "property"
  | "partOf"
  | "classification"
  | "material";
export type IDSValueType = "plain" | "simpleValue" | "xs:restriction";
export type IDSValueRestrictionType =
  | "xs:pattern"
  | "range"
  | "xs:length"
  | "lengthRange"
  | "xs:enumeration";

export type IDSRestrictionPath = {
  specId: string;
  section: IDSSpecificationSections;
  facet?: IDSFacet;
  facetIndex: number;
  attributeWrapper?: string; // this is ised for partOf's entity which introduces another layer
  attribute?: string;
  valueType?: IDSValueType;
  xsRestrictionType?: IDSValueRestrictionType;
};

export const arePathsEqual = (a?: IDSRestrictionPath | null, b?: IDSRestrictionPath | null) => {
  if (!a || !b) return false;
  return Object.keys(a).every(
    //@ts-ignore
    key => a[key] == b[key]
  );
};

export type IDSApplicability = {
  entity: IDSRestrictionEntity[];
  partOf: IDSRestrictionPartOf[];
  classification: IDSRestrictionClassification[];
  attribute: IDSRestrictionAttribute[];
  property: IDSRestrictionProperty[];
  material: IDSRestrictionMaterial[];
  $_minOccurs?: number;
  $_maxOccurs?: number | "unbounded";
};

export const normalizeIDSApplicability = (
  idsApplicability: IDSApplicability,
  // eslint-disable-next-line
  _options?: NormalizeIDSOptions
): IDSApplicability => {
  return {
    entity: idsApplicability?.entity.map(normalizeIDSRestrictionEntity) as IDSRestrictionEntity[],
    partOf: idsApplicability?.partOf.map(normalizeIDSRestrictionPartOf) as IDSRestrictionPartOf[],
    classification: idsApplicability?.classification.map(
      normalizeIDSRestrictionClassification
    ) as IDSRestrictionClassification[],
    attribute: idsApplicability?.attribute.map(normalizeIDSRestrictionAttribute),
    property: idsApplicability?.property.map(normalizeIDSRestrictionProperty),
    material: idsApplicability?.material.map(
      normalizeIDSRestrictionMaterial
    ) as IDSRestrictionMaterial[],
    $_minOccurs: idsApplicability?.$_minOccurs,
    $_maxOccurs: idsApplicability?.$_maxOccurs,
  };
};

export type IDSRequirement = {
  $_description?: string;
  entity: IDSRestrictionEntity[];
  partOf: IDSRestrictionPartOf[];
  classification: IDSRestrictionClassification[];
  attribute: IDSRestrictionAttribute[];
  property: IDSRestrictionProperty[];
  material: IDSRestrictionMaterial[];
};

export const normalizeIDSRequirement = (
  idsRequirement: IDSRequirement,
  // eslint-disable-next-line
  _options?: NormalizeIDSOptions
): IDSRequirement => {
  return {
    $_description: idsRequirement?.$_description,
    entity: idsRequirement?.entity.map(normalizeIDSRestrictionEntity) as IDSRestrictionEntity[],
    partOf: idsRequirement?.partOf.map(normalizeIDSRestrictionPartOf) as IDSRestrictionPartOf[],
    classification: idsRequirement?.classification.map(
      normalizeIDSRestrictionClassification
    ) as IDSRestrictionClassification[],
    attribute: idsRequirement.attribute.map(normalizeIDSRestrictionAttribute),
    material: idsRequirement?.material.map(
      normalizeIDSRestrictionMaterial
    ) as IDSRestrictionMaterial[],
    property: idsRequirement?.property.map(normalizeIDSRestrictionProperty),
  };
};

export type IDSIFCVersion = "IFC2X3" | "IFC4" | "IFC4X3_ADD2";
export type IDSIFCVersions = Partial<Record<IDSIFCVersion, boolean>>;

export type IDSValidationReport = {
  applicableItemsCount: number;
  applicableItemsPass: number;
  applicableItemsFail: number;
};

export type IDSValidationResult = {
  requirementDescription: string;
  reason: string;
};

export type IDSValidationEntity = {
  expressID: number;
  name: string;
  failedRequirements: IDSValidationResult[];
};

export const ifcVersionsToString = (ifcVersions?: IDSIFCVersions) => {
  if (ifcVersions == null) return undefined;

  const str = Object.entries(ifcVersions).reduce(
    (acc, [ifcVersion, value]) =>
      value == true ? (acc ? `${acc} ${ifcVersion}` : ifcVersion) : acc,
    undefined as string | undefined
  );
  if (str == null || str.length == 0) return undefined;
  return str;
};

export const stringToifcVersions = (ifcVersion?: string): IDSIFCVersions | undefined => {
  const defaultValue = {
    IFC2X3: false,
    IFC4: false,
    IFC4X3_ADD2: false,
  } as Record<IDSIFCVersion, boolean>;

  if (ifcVersion == null || ifcVersion == "") return defaultValue;

  return (
    ifcVersion
      .split(" ")
      .reduce((acc, option) => ((acc[option as IDSIFCVersion] = true), acc), defaultValue) ??
    defaultValue
  );
};

export type IDSSpecification = {
  $_name: string; // spec mandatory
  $_ifcVersion?: string; // spec mandatory, defeined as xs:list with minLength=1
  $_identifier?: string;
  $_description?: string;
  $_instructions?: string;
  applicability: IDSApplicability;
  requirements: IDSRequirement;
  _id?: string;
  _validationReport?: IDSValidationReport;
  _validationResults?: IDSValidationEntity[];
};

export const normalizeIDSSpecification = (
  idsSpecification: IDSSpecification,
  options?: NormalizeIDSOptions
): IDSSpecification => {
  const keepPrivateState = options?.keepPrivateState ?? defaultNormalizeIDSOptions.keepPrivateState;

  return {
    $_name: idsSpecification?.$_name,
    $_ifcVersion: idsSpecification?.$_ifcVersion,
    $_identifier: idsSpecification?.$_identifier,
    $_description: idsSpecification?.$_description,
    $_instructions: idsSpecification?.$_instructions,
    applicability: normalizeIDSApplicability(idsSpecification?.applicability, options),
    requirements: normalizeIDSRequirement(idsSpecification?.requirements, options),
    ...(keepPrivateState && {
      _id: idsSpecification._id,
      _validationReport: idsSpecification._validationReport,
      _validationResults: idsSpecification._validationResults,
    }),
  };
};

export type IDSInfo = {
  title?: string; // spec mandatory
  copyright?: string;
  version?: string;
  description?: string;
  author?: string; // spec also demands [^@]+@[^\.]+\..+
  date?: string;
  purpose?: string;
  milestone?: string;
};

export const normalizeIDSInfo = (idsInfo: IDSInfo): IDSInfo => {
  return {
    title: idsInfo?.title,
    copyright: idsInfo?.copyright,
    version: idsInfo?.version,
    description: idsInfo?.description,
    author: idsInfo?.author,
    date: idsInfo?.date,
    purpose: idsInfo?.purpose,
    milestone: idsInfo?.milestone,
  };
};

export type IDSTree = {
  info: IDSInfo;
  specifications: IDSSpecification[];
};

/*
  The role of normalize* is to force the ids to match the backend requirements:
   - no extra fields (both private like _validationReport, also out-of-spec e.g. ids from a previous version) 
   - mandatory fields filled in with default value
   - consistent fields order -- the xsd defines fields as *xs:sequences*
*/
export const normalizeIDSTree = (idsTree: IDSTree): IDSTree => {
  return {
    info: normalizeIDSInfo(idsTree.info),
    specifications: idsTree.specifications.map(x => normalizeIDSSpecification(x)),
  };
};

/*
  note: XMLBuilder doesn't support transformTagName => 
    * tags are exported as in the state tree (which matches the descriptos below)
    * also nsInternalDescriptors are manually set to undefined in _generateXML
*/
const nsDescriptor = (
  name: string,
  isImported: boolean,
  isExported: boolean,
  isInternalState: boolean
) => ({ name, isImported, isExported, isInternalState });

const nsIDSDescriptor = nsDescriptor("ids", false, false, false);
const nsInternalDescriptor = nsDescriptor("_", false, false, true);
const nsXSDescriptor = nsDescriptor("xs", true, true, false);

export const defaultXMLNamespaces: Record<
  string,
  {
    name: string;
    isImported: boolean;
    isExported: boolean;
    isInternalState: boolean;
  }
> = {
  // ids tags
  ids: nsIDSDescriptor,

  info: nsIDSDescriptor,
  title: nsIDSDescriptor,
  copyright: nsIDSDescriptor,
  version: nsIDSDescriptor,
  description: nsIDSDescriptor,
  author: nsIDSDescriptor,
  date: nsIDSDescriptor,
  purpose: nsIDSDescriptor,
  milestone: nsIDSDescriptor,

  specifications: nsIDSDescriptor,
  specification: nsIDSDescriptor,
  applicability: nsIDSDescriptor,
  requirements: nsIDSDescriptor,
  _id: nsInternalDescriptor,
  _validationReport: nsInternalDescriptor,
  _validationResults: nsInternalDescriptor,

  entity: nsIDSDescriptor,
  name: nsIDSDescriptor,
  predifinedType: nsIDSDescriptor,

  partOf: nsIDSDescriptor,
  relation: nsIDSDescriptor,

  classification: nsIDSDescriptor,
  value: nsIDSDescriptor,
  system: nsIDSDescriptor,

  attribute: nsIDSDescriptor,

  property: nsIDSDescriptor,
  propertySet: nsIDSDescriptor,
  baseName: nsIDSDescriptor,
  dataType: nsIDSDescriptor,

  material: nsIDSDescriptor,

  simpleValue: nsIDSDescriptor,

  // xs tags
  restriction: nsXSDescriptor,
  annotation: nsXSDescriptor,
  documentation: nsXSDescriptor,
  Label: nsXSDescriptor,
  pattern: nsXSDescriptor,
  minInclusive: nsXSDescriptor,
  maxInclusive: nsXSDescriptor,
  minExclusive: nsXSDescriptor,
  maxExclusive: nsXSDescriptor,
  length: nsXSDescriptor,
  minLength: nsXSDescriptor,
  maxLength: nsXSDescriptor,
  enumeration: nsXSDescriptor,

  fractionDigits: nsXSDescriptor,
  totalDigits: nsXSDescriptor,
  whiteSpace: nsXSDescriptor,
};

// https://ifc43-docs.standards.buildingsmart.org/IFC/RELEASE/IFC4x3/HTML/annex-b1.html
// JSON.stringify([...document.getElementById("themainlist").children].map(x => x.innerText.split("\n")[1].toUpperCase()))
export const IFCEntities = [
  "IFCACTIONREQUEST",
  "IFCACTOR",
  "IFCACTORROLE",
  "IFCACTUATOR",
  "IFCACTUATORTYPE",
  "IFCADDRESS",
  "IFCADVANCEDBREP",
  "IFCADVANCEDBREPWITHVOIDS",
  "IFCADVANCEDFACE",
  "IFCAIRTERMINAL",
  "IFCAIRTERMINALBOX",
  "IFCAIRTERMINALBOXTYPE",
  "IFCAIRTERMINALTYPE",
  "IFCAIRTOAIRHEATRECOVERY",
  "IFCAIRTOAIRHEATRECOVERYTYPE",
  "IFCALARM",
  "IFCALARMTYPE",
  "IFCALIGNMENT",
  "IFCALIGNMENTCANT",
  "IFCALIGNMENTCANTSEGMENT",
  "IFCALIGNMENTHORIZONTAL",
  "IFCALIGNMENTHORIZONTALSEGMENT",
  "IFCALIGNMENTPARAMETERSEGMENT",
  "IFCALIGNMENTSEGMENT",
  "IFCALIGNMENTVERTICAL",
  "IFCALIGNMENTVERTICALSEGMENT",
  "IFCANNOTATION",
  "IFCANNOTATIONFILLAREA",
  "IFCAPPLICATION",
  "IFCAPPLIEDVALUE",
  "IFCAPPROVAL",
  "IFCAPPROVALRELATIONSHIP",
  "IFCARBITRARYCLOSEDPROFILEDEF",
  "IFCARBITRARYOPENPROFILEDEF",
  "IFCARBITRARYPROFILEDEFWITHVOIDS",
  "IFCASSET",
  "IFCASYMMETRICISHAPEPROFILEDEF",
  "IFCAUDIOVISUALAPPLIANCE",
  "IFCAUDIOVISUALAPPLIANCETYPE",
  "IFCAXIS1PLACEMENT",
  "IFCAXIS2PLACEMENT2D",
  "IFCAXIS2PLACEMENT3D",
  "IFCAXIS2PLACEMENTLINEAR",
  "IFCBSPLINECURVE",
  "IFCBSPLINECURVEWITHKNOTS",
  "IFCBSPLINESURFACE",
  "IFCBSPLINESURFACEWITHKNOTS",
  "IFCBEAM",
  "IFCBEAMTYPE",
  "IFCBEARING",
  "IFCBEARINGTYPE",
  "IFCBLOBTEXTURE",
  "IFCBLOCK",
  "IFCBOILER",
  "IFCBOILERTYPE",
  "IFCBOOLEANCLIPPINGRESULT",
  "IFCBOOLEANRESULT",
  "IFCBOREHOLE",
  "IFCBOUNDARYCONDITION",
  "IFCBOUNDARYCURVE",
  "IFCBOUNDARYEDGECONDITION",
  "IFCBOUNDARYFACECONDITION",
  "IFCBOUNDARYNODECONDITION",
  "IFCBOUNDARYNODECONDITIONWARPING",
  "IFCBOUNDEDCURVE",
  "IFCBOUNDEDSURFACE",
  "IFCBOUNDINGBOX",
  "IFCBOXEDHALFSPACE",
  "IFCBRIDGE",
  "IFCBRIDGEPART",
  "IFCBUILDING",
  "IFCBUILDINGELEMENTPART",
  "IFCBUILDINGELEMENTPARTTYPE",
  "IFCBUILDINGELEMENTPROXY",
  "IFCBUILDINGELEMENTPROXYTYPE",
  "IFCBUILDINGSTOREY",
  "IFCBUILDINGSYSTEM",
  "IFCBUILTELEMENT",
  "IFCBUILTELEMENTTYPE",
  "IFCBUILTSYSTEM",
  "IFCBURNER",
  "IFCBURNERTYPE",
  "IFCCSHAPEPROFILEDEF",
  "IFCCABLECARRIERFITTING",
  "IFCCABLECARRIERFITTINGTYPE",
  "IFCCABLECARRIERSEGMENT",
  "IFCCABLECARRIERSEGMENTTYPE",
  "IFCCABLEFITTING",
  "IFCCABLEFITTINGTYPE",
  "IFCCABLESEGMENT",
  "IFCCABLESEGMENTTYPE",
  "IFCCAISSONFOUNDATION",
  "IFCCAISSONFOUNDATIONTYPE",
  "IFCCARTESIANPOINT",
  "IFCCARTESIANPOINTLIST",
  "IFCCARTESIANPOINTLIST2D",
  "IFCCARTESIANPOINTLIST3D",
  "IFCCARTESIANTRANSFORMATIONOPERATOR",
  "IFCCARTESIANTRANSFORMATIONOPERATOR2D",
  "IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM",
  "IFCCARTESIANTRANSFORMATIONOPERATOR3D",
  "IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM",
  "IFCCENTERLINEPROFILEDEF",
  "IFCCHILLER",
  "IFCCHILLERTYPE",
  "IFCCHIMNEY",
  "IFCCHIMNEYTYPE",
  "IFCCIRCLE",
  "IFCCIRCLEHOLLOWPROFILEDEF",
  "IFCCIRCLEPROFILEDEF",
  "IFCCIVILELEMENT",
  "IFCCIVILELEMENTTYPE",
  "IFCCLASSIFICATION",
  "IFCCLASSIFICATIONREFERENCE",
  "IFCCLOSEDSHELL",
  "IFCCLOTHOID",
  "IFCCOIL",
  "IFCCOILTYPE",
  "IFCCOLOURRGB",
  "IFCCOLOURRGBLIST",
  "IFCCOLOURSPECIFICATION",
  "IFCCOLUMN",
  "IFCCOLUMNTYPE",
  "IFCCOMMUNICATIONSAPPLIANCE",
  "IFCCOMMUNICATIONSAPPLIANCETYPE",
  "IFCCOMPLEXPROPERTY",
  "IFCCOMPLEXPROPERTYTEMPLATE",
  "IFCCOMPOSITECURVE",
  "IFCCOMPOSITECURVEONSURFACE",
  "IFCCOMPOSITECURVESEGMENT",
  "IFCCOMPOSITEPROFILEDEF",
  "IFCCOMPRESSOR",
  "IFCCOMPRESSORTYPE",
  "IFCCONDENSER",
  "IFCCONDENSERTYPE",
  "IFCCONIC",
  "IFCCONNECTEDFACESET",
  "IFCCONNECTIONCURVEGEOMETRY",
  "IFCCONNECTIONGEOMETRY",
  "IFCCONNECTIONPOINTECCENTRICITY",
  "IFCCONNECTIONPOINTGEOMETRY",
  "IFCCONNECTIONSURFACEGEOMETRY",
  "IFCCONNECTIONVOLUMEGEOMETRY",
  "IFCCONSTRAINT",
  "IFCCONSTRUCTIONEQUIPMENTRESOURCE",
  "IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE",
  "IFCCONSTRUCTIONMATERIALRESOURCE",
  "IFCCONSTRUCTIONMATERIALRESOURCETYPE",
  "IFCCONSTRUCTIONPRODUCTRESOURCE",
  "IFCCONSTRUCTIONPRODUCTRESOURCETYPE",
  "IFCCONSTRUCTIONRESOURCE",
  "IFCCONSTRUCTIONRESOURCETYPE",
  "IFCCONTEXT",
  "IFCCONTEXTDEPENDENTUNIT",
  "IFCCONTROL",
  "IFCCONTROLLER",
  "IFCCONTROLLERTYPE",
  "IFCCONVERSIONBASEDUNIT",
  "IFCCONVERSIONBASEDUNITWITHOFFSET",
  "IFCCONVEYORSEGMENT",
  "IFCCONVEYORSEGMENTTYPE",
  "IFCCOOLEDBEAM",
  "IFCCOOLEDBEAMTYPE",
  "IFCCOOLINGTOWER",
  "IFCCOOLINGTOWERTYPE",
  "IFCCOORDINATEOPERATION",
  "IFCCOORDINATEREFERENCESYSTEM",
  "IFCCOSINESPIRAL",
  "IFCCOSTITEM",
  "IFCCOSTSCHEDULE",
  "IFCCOSTVALUE",
  "IFCCOURSE",
  "IFCCOURSETYPE",
  "IFCCOVERING",
  "IFCCOVERINGTYPE",
  "IFCCREWRESOURCE",
  "IFCCREWRESOURCETYPE",
  "IFCCSGPRIMITIVE3D",
  "IFCCSGSOLID",
  "IFCCURRENCYRELATIONSHIP",
  "IFCCURTAINWALL",
  "IFCCURTAINWALLTYPE",
  "IFCCURVE",
  "IFCCURVEBOUNDEDPLANE",
  "IFCCURVEBOUNDEDSURFACE",
  "IFCCURVESEGMENT",
  "IFCCURVESTYLE",
  "IFCCURVESTYLEFONT",
  "IFCCURVESTYLEFONTANDSCALING",
  "IFCCURVESTYLEFONTPATTERN",
  "IFCCYLINDRICALSURFACE",
  "IFCDAMPER",
  "IFCDAMPERTYPE",
  "IFCDEEPFOUNDATION",
  "IFCDEEPFOUNDATIONTYPE",
  "IFCDERIVEDPROFILEDEF",
  "IFCDERIVEDUNIT",
  "IFCDERIVEDUNITELEMENT",
  "IFCDIMENSIONALEXPONENTS",
  "IFCDIRECTION",
  "IFCDIRECTRIXCURVESWEPTAREASOLID",
  "IFCDIRECTRIXDERIVEDREFERENCESWEPTAREASOLID",
  "IFCDISCRETEACCESSORY",
  "IFCDISCRETEACCESSORYTYPE",
  "IFCDISTRIBUTIONBOARD",
  "IFCDISTRIBUTIONBOARDTYPE",
  "IFCDISTRIBUTIONCHAMBERELEMENT",
  "IFCDISTRIBUTIONCHAMBERELEMENTTYPE",
  "IFCDISTRIBUTIONCIRCUIT",
  "IFCDISTRIBUTIONCONTROLELEMENT",
  "IFCDISTRIBUTIONCONTROLELEMENTTYPE",
  "IFCDISTRIBUTIONELEMENT",
  "IFCDISTRIBUTIONELEMENTTYPE",
  "IFCDISTRIBUTIONFLOWELEMENT",
  "IFCDISTRIBUTIONFLOWELEMENTTYPE",
  "IFCDISTRIBUTIONPORT",
  "IFCDISTRIBUTIONSYSTEM",
  "IFCDOCUMENTINFORMATION",
  "IFCDOCUMENTINFORMATIONRELATIONSHIP",
  "IFCDOCUMENTREFERENCE",
  "IFCDOOR",
  "IFCDOORLININGPROPERTIES",
  "IFCDOORPANELPROPERTIES",
  "IFCDOORTYPE",
  "IFCDRAUGHTINGPREDEFINEDCOLOUR",
  "IFCDRAUGHTINGPREDEFINEDCURVEFONT",
  "IFCDUCTFITTING",
  "IFCDUCTFITTINGTYPE",
  "IFCDUCTSEGMENT",
  "IFCDUCTSEGMENTTYPE",
  "IFCDUCTSILENCER",
  "IFCDUCTSILENCERTYPE",
  "IFCEARTHWORKSCUT",
  "IFCEARTHWORKSELEMENT",
  "IFCEARTHWORKSFILL",
  "IFCEDGE",
  "IFCEDGECURVE",
  "IFCEDGELOOP",
  "IFCELECTRICAPPLIANCE",
  "IFCELECTRICAPPLIANCETYPE",
  "IFCELECTRICDISTRIBUTIONBOARD",
  "IFCELECTRICDISTRIBUTIONBOARDTYPE",
  "IFCELECTRICFLOWSTORAGEDEVICE",
  "IFCELECTRICFLOWSTORAGEDEVICETYPE",
  "IFCELECTRICFLOWTREATMENTDEVICE",
  "IFCELECTRICFLOWTREATMENTDEVICETYPE",
  "IFCELECTRICGENERATOR",
  "IFCELECTRICGENERATORTYPE",
  "IFCELECTRICMOTOR",
  "IFCELECTRICMOTORTYPE",
  "IFCELECTRICTIMECONTROL",
  "IFCELECTRICTIMECONTROLTYPE",
  "IFCELEMENT",
  "IFCELEMENTASSEMBLY",
  "IFCELEMENTASSEMBLYTYPE",
  "IFCELEMENTCOMPONENT",
  "IFCELEMENTCOMPONENTTYPE",
  "IFCELEMENTQUANTITY",
  "IFCELEMENTTYPE",
  "IFCELEMENTARYSURFACE",
  "IFCELLIPSE",
  "IFCELLIPSEPROFILEDEF",
  "IFCENERGYCONVERSIONDEVICE",
  "IFCENERGYCONVERSIONDEVICETYPE",
  "IFCENGINE",
  "IFCENGINETYPE",
  "IFCEVAPORATIVECOOLER",
  "IFCEVAPORATIVECOOLERTYPE",
  "IFCEVAPORATOR",
  "IFCEVAPORATORTYPE",
  "IFCEVENT",
  "IFCEVENTTIME",
  "IFCEVENTTYPE",
  "IFCEXTENDEDPROPERTIES",
  "IFCEXTERNALINFORMATION",
  "IFCEXTERNALREFERENCE",
  "IFCEXTERNALREFERENCERELATIONSHIP",
  "IFCEXTERNALSPATIALELEMENT",
  "IFCEXTERNALSPATIALSTRUCTUREELEMENT",
  "IFCEXTERNALLYDEFINEDHATCHSTYLE",
  "IFCEXTERNALLYDEFINEDSURFACESTYLE",
  "IFCEXTERNALLYDEFINEDTEXTFONT",
  "IFCEXTRUDEDAREASOLID",
  "IFCEXTRUDEDAREASOLIDTAPERED",
  "IFCFACE",
  "IFCFACEBASEDSURFACEMODEL",
  "IFCFACEBOUND",
  "IFCFACEOUTERBOUND",
  "IFCFACESURFACE",
  "IFCFACETEDBREP",
  "IFCFACETEDBREPWITHVOIDS",
  "IFCFACILITY",
  "IFCFACILITYPART",
  "IFCFACILITYPARTCOMMON",
  "IFCFAILURECONNECTIONCONDITION",
  "IFCFAN",
  "IFCFANTYPE",
  "IFCFASTENER",
  "IFCFASTENERTYPE",
  "IFCFEATUREELEMENT",
  "IFCFEATUREELEMENTADDITION",
  "IFCFEATUREELEMENTSUBTRACTION",
  "IFCFILLAREASTYLE",
  "IFCFILLAREASTYLEHATCHING",
  "IFCFILLAREASTYLETILES",
  "IFCFILTER",
  "IFCFILTERTYPE",
  "IFCFIRESUPPRESSIONTERMINAL",
  "IFCFIRESUPPRESSIONTERMINALTYPE",
  "IFCFIXEDREFERENCESWEPTAREASOLID",
  "IFCFLOWCONTROLLER",
  "IFCFLOWCONTROLLERTYPE",
  "IFCFLOWFITTING",
  "IFCFLOWFITTINGTYPE",
  "IFCFLOWINSTRUMENT",
  "IFCFLOWINSTRUMENTTYPE",
  "IFCFLOWMETER",
  "IFCFLOWMETERTYPE",
  "IFCFLOWMOVINGDEVICE",
  "IFCFLOWMOVINGDEVICETYPE",
  "IFCFLOWSEGMENT",
  "IFCFLOWSEGMENTTYPE",
  "IFCFLOWSTORAGEDEVICE",
  "IFCFLOWSTORAGEDEVICETYPE",
  "IFCFLOWTERMINAL",
  "IFCFLOWTERMINALTYPE",
  "IFCFLOWTREATMENTDEVICE",
  "IFCFLOWTREATMENTDEVICETYPE",
  "IFCFOOTING",
  "IFCFOOTINGTYPE",
  "IFCFURNISHINGELEMENT",
  "IFCFURNISHINGELEMENTTYPE",
  "IFCFURNITURE",
  "IFCFURNITURETYPE",
  "IFCGEOGRAPHICCRS",
  "IFCGEOGRAPHICELEMENT",
  "IFCGEOGRAPHICELEMENTTYPE",
  "IFCGEOMETRICCURVESET",
  "IFCGEOMETRICREPRESENTATIONCONTEXT",
  "IFCGEOMETRICREPRESENTATIONITEM",
  "IFCGEOMETRICREPRESENTATIONSUBCONTEXT",
  "IFCGEOMETRICSET",
  "IFCGEOMODEL",
  "IFCGEOSLICE",
  "IFCGEOTECHNICALASSEMBLY",
  "IFCGEOTECHNICALELEMENT",
  "IFCGEOTECHNICALSTRATUM",
  "IFCGRADIENTCURVE",
  "IFCGRID",
  "IFCGRIDAXIS",
  "IFCGRIDPLACEMENT",
  "IFCGROUP",
  "IFCHALFSPACESOLID",
  "IFCHEATEXCHANGER",
  "IFCHEATEXCHANGERTYPE",
  "IFCHUMIDIFIER",
  "IFCHUMIDIFIERTYPE",
  "IFCISHAPEPROFILEDEF",
  "IFCIMAGETEXTURE",
  "IFCIMPACTPROTECTIONDEVICE",
  "IFCIMPACTPROTECTIONDEVICETYPE",
  "IFCINDEXEDCOLOURMAP",
  "IFCINDEXEDPOLYCURVE",
  "IFCINDEXEDPOLYGONALFACE",
  "IFCINDEXEDPOLYGONALFACEWITHVOIDS",
  "IFCINDEXEDPOLYGONALTEXTUREMAP",
  "IFCINDEXEDTEXTUREMAP",
  "IFCINDEXEDTRIANGLETEXTUREMAP",
  "IFCINTERCEPTOR",
  "IFCINTERCEPTORTYPE",
  "IFCINTERSECTIONCURVE",
  "IFCINVENTORY",
  "IFCIRREGULARTIMESERIES",
  "IFCIRREGULARTIMESERIESVALUE",
  "IFCJUNCTIONBOX",
  "IFCJUNCTIONBOXTYPE",
  "IFCKERB",
  "IFCKERBTYPE",
  "IFCLSHAPEPROFILEDEF",
  "IFCLABORRESOURCE",
  "IFCLABORRESOURCETYPE",
  "IFCLAGTIME",
  "IFCLAMP",
  "IFCLAMPTYPE",
  "IFCLIBRARYINFORMATION",
  "IFCLIBRARYREFERENCE",
  "IFCLIGHTDISTRIBUTIONDATA",
  "IFCLIGHTFIXTURE",
  "IFCLIGHTFIXTURETYPE",
  "IFCLIGHTINTENSITYDISTRIBUTION",
  "IFCLIGHTSOURCE",
  "IFCLIGHTSOURCEAMBIENT",
  "IFCLIGHTSOURCEDIRECTIONAL",
  "IFCLIGHTSOURCEGONIOMETRIC",
  "IFCLIGHTSOURCEPOSITIONAL",
  "IFCLIGHTSOURCESPOT",
  "IFCLINE",
  "IFCLINEARELEMENT",
  "IFCLINEARPLACEMENT",
  "IFCLINEARPOSITIONINGELEMENT",
  "IFCLIQUIDTERMINAL",
  "IFCLIQUIDTERMINALTYPE",
  "IFCLOCALPLACEMENT",
  "IFCLOOP",
  "IFCMANIFOLDSOLIDBREP",
  "IFCMAPCONVERSION",
  "IFCMAPCONVERSIONSCALED",
  "IFCMAPPEDITEM",
  "IFCMARINEFACILITY",
  "IFCMARINEPART",
  "IFCMATERIAL",
  "IFCMATERIALCLASSIFICATIONRELATIONSHIP",
  "IFCMATERIALCONSTITUENT",
  "IFCMATERIALCONSTITUENTSET",
  "IFCMATERIALDEFINITION",
  "IFCMATERIALDEFINITIONREPRESENTATION",
  "IFCMATERIALLAYER",
  "IFCMATERIALLAYERSET",
  "IFCMATERIALLAYERSETUSAGE",
  "IFCMATERIALLAYERWITHOFFSETS",
  "IFCMATERIALLIST",
  "IFCMATERIALPROFILE",
  "IFCMATERIALPROFILESET",
  "IFCMATERIALPROFILESETUSAGE",
  "IFCMATERIALPROFILESETUSAGETAPERING",
  "IFCMATERIALPROFILEWITHOFFSETS",
  "IFCMATERIALPROPERTIES",
  "IFCMATERIALRELATIONSHIP",
  "IFCMATERIALUSAGEDEFINITION",
  "IFCMEASUREWITHUNIT",
  "IFCMECHANICALFASTENER",
  "IFCMECHANICALFASTENERTYPE",
  "IFCMEDICALDEVICE",
  "IFCMEDICALDEVICETYPE",
  "IFCMEMBER",
  "IFCMEMBERTYPE",
  "IFCMETRIC",
  "IFCMIRROREDPROFILEDEF",
  "IFCMOBILETELECOMMUNICATIONSAPPLIANCE",
  "IFCMOBILETELECOMMUNICATIONSAPPLIANCETYPE",
  "IFCMONETARYUNIT",
  "IFCMOORINGDEVICE",
  "IFCMOORINGDEVICETYPE",
  "IFCMOTORCONNECTION",
  "IFCMOTORCONNECTIONTYPE",
  "IFCNAMEDUNIT",
  "IFCNAVIGATIONELEMENT",
  "IFCNAVIGATIONELEMENTTYPE",
  "IFCOBJECT",
  "IFCOBJECTDEFINITION",
  "IFCOBJECTPLACEMENT",
  "IFCOBJECTIVE",
  "IFCOCCUPANT",
  "IFCOFFSETCURVE",
  "IFCOFFSETCURVE2D",
  "IFCOFFSETCURVE3D",
  "IFCOFFSETCURVEBYDISTANCES",
  "IFCOPENCROSSPROFILEDEF",
  "IFCOPENSHELL",
  "IFCOPENINGELEMENT",
  "IFCORGANIZATION",
  "IFCORGANIZATIONRELATIONSHIP",
  "IFCORIENTEDEDGE",
  "IFCOUTERBOUNDARYCURVE",
  "IFCOUTLET",
  "IFCOUTLETTYPE",
  "IFCOWNERHISTORY",
  "IFCPARAMETERIZEDPROFILEDEF",
  "IFCPATH",
  "IFCPAVEMENT",
  "IFCPAVEMENTTYPE",
  "IFCPCURVE",
  "IFCPERFORMANCEHISTORY",
  "IFCPERMEABLECOVERINGPROPERTIES",
  "IFCPERMIT",
  "IFCPERSON",
  "IFCPERSONANDORGANIZATION",
  "IFCPHYSICALCOMPLEXQUANTITY",
  "IFCPHYSICALQUANTITY",
  "IFCPHYSICALSIMPLEQUANTITY",
  "IFCPILE",
  "IFCPILETYPE",
  "IFCPIPEFITTING",
  "IFCPIPEFITTINGTYPE",
  "IFCPIPESEGMENT",
  "IFCPIPESEGMENTTYPE",
  "IFCPIXELTEXTURE",
  "IFCPLACEMENT",
  "IFCPLANARBOX",
  "IFCPLANAREXTENT",
  "IFCPLANE",
  "IFCPLATE",
  "IFCPLATETYPE",
  "IFCPOINT",
  "IFCPOINTBYDISTANCEEXPRESSION",
  "IFCPOINTONCURVE",
  "IFCPOINTONSURFACE",
  "IFCPOLYLOOP",
  "IFCPOLYGONALBOUNDEDHALFSPACE",
  "IFCPOLYGONALFACESET",
  "IFCPOLYLINE",
  "IFCPOLYNOMIALCURVE",
  "IFCPORT",
  "IFCPOSITIONINGELEMENT",
  "IFCPOSTALADDRESS",
  "IFCPREDEFINEDCOLOUR",
  "IFCPREDEFINEDCURVEFONT",
  "IFCPREDEFINEDITEM",
  "IFCPREDEFINEDPROPERTIES",
  "IFCPREDEFINEDPROPERTYSET",
  "IFCPREDEFINEDTEXTFONT",
  "IFCPRESENTATIONITEM",
  "IFCPRESENTATIONLAYERASSIGNMENT",
  "IFCPRESENTATIONLAYERWITHSTYLE",
  "IFCPRESENTATIONSTYLE",
  "IFCPROCEDURE",
  "IFCPROCEDURETYPE",
  "IFCPROCESS",
  "IFCPRODUCT",
  "IFCPRODUCTDEFINITIONSHAPE",
  "IFCPRODUCTREPRESENTATION",
  "IFCPROFILEDEF",
  "IFCPROFILEPROPERTIES",
  "IFCPROJECT",
  "IFCPROJECTLIBRARY",
  "IFCPROJECTORDER",
  "IFCPROJECTEDCRS",
  "IFCPROJECTIONELEMENT",
  "IFCPROPERTY",
  "IFCPROPERTYABSTRACTION",
  "IFCPROPERTYBOUNDEDVALUE",
  "IFCPROPERTYDEFINITION",
  "IFCPROPERTYDEPENDENCYRELATIONSHIP",
  "IFCPROPERTYENUMERATEDVALUE",
  "IFCPROPERTYENUMERATION",
  "IFCPROPERTYLISTVALUE",
  "IFCPROPERTYREFERENCEVALUE",
  "IFCPROPERTYSET",
  "IFCPROPERTYSETDEFINITION",
  "IFCPROPERTYSETTEMPLATE",
  "IFCPROPERTYSINGLEVALUE",
  "IFCPROPERTYTABLEVALUE",
  "IFCPROPERTYTEMPLATE",
  "IFCPROPERTYTEMPLATEDEFINITION",
  "IFCPROTECTIVEDEVICE",
  "IFCPROTECTIVEDEVICETRIPPINGUNIT",
  "IFCPROTECTIVEDEVICETRIPPINGUNITTYPE",
  "IFCPROTECTIVEDEVICETYPE",
  "IFCPUMP",
  "IFCPUMPTYPE",
  "IFCQUANTITYAREA",
  "IFCQUANTITYCOUNT",
  "IFCQUANTITYLENGTH",
  "IFCQUANTITYNUMBER",
  "IFCQUANTITYSET",
  "IFCQUANTITYTIME",
  "IFCQUANTITYVOLUME",
  "IFCQUANTITYWEIGHT",
  "IFCRAIL",
  "IFCRAILTYPE",
  "IFCRAILING",
  "IFCRAILINGTYPE",
  "IFCRAILWAY",
  "IFCRAILWAYPART",
  "IFCRAMP",
  "IFCRAMPFLIGHT",
  "IFCRAMPFLIGHTTYPE",
  "IFCRAMPTYPE",
  "IFCRATIONALBSPLINECURVEWITHKNOTS",
  "IFCRATIONALBSPLINESURFACEWITHKNOTS",
  "IFCRECTANGLEHOLLOWPROFILEDEF",
  "IFCRECTANGLEPROFILEDEF",
  "IFCRECTANGULARPYRAMID",
  "IFCRECTANGULARTRIMMEDSURFACE",
  "IFCRECURRENCEPATTERN",
  "IFCREFERENCE",
  "IFCREFERENT",
  "IFCREGULARTIMESERIES",
  "IFCREINFORCEDSOIL",
  "IFCREINFORCEMENTBARPROPERTIES",
  "IFCREINFORCEMENTDEFINITIONPROPERTIES",
  "IFCREINFORCINGBAR",
  "IFCREINFORCINGBARTYPE",
  "IFCREINFORCINGELEMENT",
  "IFCREINFORCINGELEMENTTYPE",
  "IFCREINFORCINGMESH",
  "IFCREINFORCINGMESHTYPE",
  "IFCRELADHERESTOELEMENT",
  "IFCRELAGGREGATES",
  "IFCRELASSIGNS",
  "IFCRELASSIGNSTOACTOR",
  "IFCRELASSIGNSTOCONTROL",
  "IFCRELASSIGNSTOGROUP",
  "IFCRELASSIGNSTOGROUPBYFACTOR",
  "IFCRELASSIGNSTOPROCESS",
  "IFCRELASSIGNSTOPRODUCT",
  "IFCRELASSIGNSTORESOURCE",
  "IFCRELASSOCIATES",
  "IFCRELASSOCIATESAPPROVAL",
  "IFCRELASSOCIATESCLASSIFICATION",
  "IFCRELASSOCIATESCONSTRAINT",
  "IFCRELASSOCIATESDOCUMENT",
  "IFCRELASSOCIATESLIBRARY",
  "IFCRELASSOCIATESMATERIAL",
  "IFCRELASSOCIATESPROFILEDEF",
  "IFCRELCONNECTS",
  "IFCRELCONNECTSELEMENTS",
  "IFCRELCONNECTSPATHELEMENTS",
  "IFCRELCONNECTSPORTTOELEMENT",
  "IFCRELCONNECTSPORTS",
  "IFCRELCONNECTSSTRUCTURALACTIVITY",
  "IFCRELCONNECTSSTRUCTURALMEMBER",
  "IFCRELCONNECTSWITHECCENTRICITY",
  "IFCRELCONNECTSWITHREALIZINGELEMENTS",
  "IFCRELCONTAINEDINSPATIALSTRUCTURE",
  "IFCRELCOVERSBLDGELEMENTS",
  "IFCRELCOVERSSPACES",
  "IFCRELDECLARES",
  "IFCRELDECOMPOSES",
  "IFCRELDEFINES",
  "IFCRELDEFINESBYOBJECT",
  "IFCRELDEFINESBYPROPERTIES",
  "IFCRELDEFINESBYTEMPLATE",
  "IFCRELDEFINESBYTYPE",
  "IFCRELFILLSELEMENT",
  "IFCRELFLOWCONTROLELEMENTS",
  "IFCRELINTERFERESELEMENTS",
  "IFCRELNESTS",
  "IFCRELPOSITIONS",
  "IFCRELPROJECTSELEMENT",
  "IFCRELREFERENCEDINSPATIALSTRUCTURE",
  "IFCRELSEQUENCE",
  "IFCRELSERVICESBUILDINGS",
  "IFCRELSPACEBOUNDARY",
  "IFCRELSPACEBOUNDARY1STLEVEL",
  "IFCRELSPACEBOUNDARY2NDLEVEL",
  "IFCRELVOIDSELEMENT",
  "IFCRELATIONSHIP",
  "IFCREPARAMETRISEDCOMPOSITECURVESEGMENT",
  "IFCREPRESENTATION",
  "IFCREPRESENTATIONCONTEXT",
  "IFCREPRESENTATIONITEM",
  "IFCREPRESENTATIONMAP",
  "IFCRESOURCE",
  "IFCRESOURCEAPPROVALRELATIONSHIP",
  "IFCRESOURCECONSTRAINTRELATIONSHIP",
  "IFCRESOURCELEVELRELATIONSHIP",
  "IFCRESOURCETIME",
  "IFCREVOLVEDAREASOLID",
  "IFCREVOLVEDAREASOLIDTAPERED",
  "IFCRIGHTCIRCULARCONE",
  "IFCRIGHTCIRCULARCYLINDER",
  "IFCRIGIDOPERATION",
  "IFCROAD",
  "IFCROADPART",
  "IFCROOF",
  "IFCROOFTYPE",
  "IFCROOT",
  "IFCROUNDEDRECTANGLEPROFILEDEF",
  "IFCSIUNIT",
  "IFCSANITARYTERMINAL",
  "IFCSANITARYTERMINALTYPE",
  "IFCSCHEDULINGTIME",
  "IFCSEAMCURVE",
  "IFCSECONDORDERPOLYNOMIALSPIRAL",
  "IFCSECTIONPROPERTIES",
  "IFCSECTIONREINFORCEMENTPROPERTIES",
  "IFCSECTIONEDSOLID",
  "IFCSECTIONEDSOLIDHORIZONTAL",
  "IFCSECTIONEDSPINE",
  "IFCSECTIONEDSURFACE",
  "IFCSEGMENT",
  "IFCSEGMENTEDREFERENCECURVE",
  "IFCSENSOR",
  "IFCSENSORTYPE",
  "IFCSEVENTHORDERPOLYNOMIALSPIRAL",
  "IFCSHADINGDEVICE",
  "IFCSHADINGDEVICETYPE",
  "IFCSHAPEASPECT",
  "IFCSHAPEMODEL",
  "IFCSHAPEREPRESENTATION",
  "IFCSHELLBASEDSURFACEMODEL",
  "IFCSIGN",
  "IFCSIGNTYPE",
  "IFCSIGNAL",
  "IFCSIGNALTYPE",
  "IFCSIMPLEPROPERTY",
  "IFCSIMPLEPROPERTYTEMPLATE",
  "IFCSINESPIRAL",
  "IFCSITE",
  "IFCSLAB",
  "IFCSLABTYPE",
  "IFCSLIPPAGECONNECTIONCONDITION",
  "IFCSOLARDEVICE",
  "IFCSOLARDEVICETYPE",
  "IFCSOLIDMODEL",
  "IFCSPACE",
  "IFCSPACEHEATER",
  "IFCSPACEHEATERTYPE",
  "IFCSPACETYPE",
  "IFCSPATIALELEMENT",
  "IFCSPATIALELEMENTTYPE",
  "IFCSPATIALSTRUCTUREELEMENT",
  "IFCSPATIALSTRUCTUREELEMENTTYPE",
  "IFCSPATIALZONE",
  "IFCSPATIALZONETYPE",
  "IFCSPHERE",
  "IFCSPHERICALSURFACE",
  "IFCSPIRAL",
  "IFCSTACKTERMINAL",
  "IFCSTACKTERMINALTYPE",
  "IFCSTAIR",
  "IFCSTAIRFLIGHT",
  "IFCSTAIRFLIGHTTYPE",
  "IFCSTAIRTYPE",
  "IFCSTRUCTURALACTION",
  "IFCSTRUCTURALACTIVITY",
  "IFCSTRUCTURALANALYSISMODEL",
  "IFCSTRUCTURALCONNECTION",
  "IFCSTRUCTURALCONNECTIONCONDITION",
  "IFCSTRUCTURALCURVEACTION",
  "IFCSTRUCTURALCURVECONNECTION",
  "IFCSTRUCTURALCURVEMEMBER",
  "IFCSTRUCTURALCURVEMEMBERVARYING",
  "IFCSTRUCTURALCURVEREACTION",
  "IFCSTRUCTURALITEM",
  "IFCSTRUCTURALLINEARACTION",
  "IFCSTRUCTURALLOAD",
  "IFCSTRUCTURALLOADCASE",
  "IFCSTRUCTURALLOADCONFIGURATION",
  "IFCSTRUCTURALLOADGROUP",
  "IFCSTRUCTURALLOADLINEARFORCE",
  "IFCSTRUCTURALLOADORRESULT",
  "IFCSTRUCTURALLOADPLANARFORCE",
  "IFCSTRUCTURALLOADSINGLEDISPLACEMENT",
  "IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION",
  "IFCSTRUCTURALLOADSINGLEFORCE",
  "IFCSTRUCTURALLOADSINGLEFORCEWARPING",
  "IFCSTRUCTURALLOADSTATIC",
  "IFCSTRUCTURALLOADTEMPERATURE",
  "IFCSTRUCTURALMEMBER",
  "IFCSTRUCTURALPLANARACTION",
  "IFCSTRUCTURALPOINTACTION",
  "IFCSTRUCTURALPOINTCONNECTION",
  "IFCSTRUCTURALPOINTREACTION",
  "IFCSTRUCTURALREACTION",
  "IFCSTRUCTURALRESULTGROUP",
  "IFCSTRUCTURALSURFACEACTION",
  "IFCSTRUCTURALSURFACECONNECTION",
  "IFCSTRUCTURALSURFACEMEMBER",
  "IFCSTRUCTURALSURFACEMEMBERVARYING",
  "IFCSTRUCTURALSURFACEREACTION",
  "IFCSTYLEMODEL",
  "IFCSTYLEDITEM",
  "IFCSTYLEDREPRESENTATION",
  "IFCSUBCONTRACTRESOURCE",
  "IFCSUBCONTRACTRESOURCETYPE",
  "IFCSUBEDGE",
  "IFCSURFACE",
  "IFCSURFACECURVE",
  "IFCSURFACECURVESWEPTAREASOLID",
  "IFCSURFACEFEATURE",
  "IFCSURFACEOFLINEAREXTRUSION",
  "IFCSURFACEOFREVOLUTION",
  "IFCSURFACEREINFORCEMENTAREA",
  "IFCSURFACESTYLE",
  "IFCSURFACESTYLELIGHTING",
  "IFCSURFACESTYLEREFRACTION",
  "IFCSURFACESTYLERENDERING",
  "IFCSURFACESTYLESHADING",
  "IFCSURFACESTYLEWITHTEXTURES",
  "IFCSURFACETEXTURE",
  "IFCSWEPTAREASOLID",
  "IFCSWEPTDISKSOLID",
  "IFCSWEPTDISKSOLIDPOLYGONAL",
  "IFCSWEPTSURFACE",
  "IFCSWITCHINGDEVICE",
  "IFCSWITCHINGDEVICETYPE",
  "IFCSYSTEM",
  "IFCSYSTEMFURNITUREELEMENT",
  "IFCSYSTEMFURNITUREELEMENTTYPE",
  "IFCTSHAPEPROFILEDEF",
  "IFCTABLE",
  "IFCTABLECOLUMN",
  "IFCTABLEROW",
  "IFCTANK",
  "IFCTANKTYPE",
  "IFCTASK",
  "IFCTASKTIME",
  "IFCTASKTIMERECURRING",
  "IFCTASKTYPE",
  "IFCTELECOMADDRESS",
  "IFCTENDON",
  "IFCTENDONANCHOR",
  "IFCTENDONANCHORTYPE",
  "IFCTENDONCONDUIT",
  "IFCTENDONCONDUITTYPE",
  "IFCTENDONTYPE",
  "IFCTESSELLATEDFACESET",
  "IFCTESSELLATEDITEM",
  "IFCTEXTLITERAL",
  "IFCTEXTLITERALWITHEXTENT",
  "IFCTEXTSTYLE",
  "IFCTEXTSTYLEFONTMODEL",
  "IFCTEXTSTYLEFORDEFINEDFONT",
  "IFCTEXTSTYLETEXTMODEL",
  "IFCTEXTURECOORDINATE",
  "IFCTEXTURECOORDINATEGENERATOR",
  "IFCTEXTURECOORDINATEINDICES",
  "IFCTEXTURECOORDINATEINDICESWITHVOIDS",
  "IFCTEXTUREMAP",
  "IFCTEXTUREVERTEX",
  "IFCTEXTUREVERTEXLIST",
  "IFCTHIRDORDERPOLYNOMIALSPIRAL",
  "IFCTIMEPERIOD",
  "IFCTIMESERIES",
  "IFCTIMESERIESVALUE",
  "IFCTOPOLOGICALREPRESENTATIONITEM",
  "IFCTOPOLOGYREPRESENTATION",
  "IFCTOROIDALSURFACE",
  "IFCTRACKELEMENT",
  "IFCTRACKELEMENTTYPE",
  "IFCTRANSFORMER",
  "IFCTRANSFORMERTYPE",
  "IFCTRANSPORTELEMENT",
  "IFCTRANSPORTELEMENTTYPE",
  "IFCTRANSPORTATIONDEVICE",
  "IFCTRANSPORTATIONDEVICETYPE",
  "IFCTRAPEZIUMPROFILEDEF",
  "IFCTRIANGULATEDFACESET",
  "IFCTRIANGULATEDIRREGULARNETWORK",
  "IFCTRIMMEDCURVE",
  "IFCTUBEBUNDLE",
  "IFCTUBEBUNDLETYPE",
  "IFCTYPEOBJECT",
  "IFCTYPEPROCESS",
  "IFCTYPEPRODUCT",
  "IFCTYPERESOURCE",
  "IFCUSHAPEPROFILEDEF",
  "IFCUNITASSIGNMENT",
  "IFCUNITARYCONTROLELEMENT",
  "IFCUNITARYCONTROLELEMENTTYPE",
  "IFCUNITARYEQUIPMENT",
  "IFCUNITARYEQUIPMENTTYPE",
  "IFCVALVE",
  "IFCVALVETYPE",
  "IFCVECTOR",
  "IFCVEHICLE",
  "IFCVEHICLETYPE",
  "IFCVERTEX",
  "IFCVERTEXLOOP",
  "IFCVERTEXPOINT",
  "IFCVIBRATIONDAMPER",
  "IFCVIBRATIONDAMPERTYPE",
  "IFCVIBRATIONISOLATOR",
  "IFCVIBRATIONISOLATORTYPE",
  "IFCVIRTUALELEMENT",
  "IFCVIRTUALGRIDINTERSECTION",
  "IFCVOIDINGFEATURE",
  "IFCWALL",
  "IFCWALLSTANDARDCASE",
  "IFCWALLTYPE",
  "IFCWASTETERMINAL",
  "IFCWASTETERMINALTYPE",
  "IFCWELLKNOWNTEXT",
  "IFCWINDOW",
  "IFCWINDOWLININGPROPERTIES",
  "IFCWINDOWPANELPROPERTIES",
  "IFCWINDOWTYPE",
  "IFCWORKCALENDAR",
  "IFCWORKCONTROL",
  "IFCWORKPLAN",
  "IFCWORKSCHEDULE",
  "IFCWORKTIME",
  "IFCZSHAPEPROFILEDEF",
  "IFCZONE",
];

// https://ifc43-docs.standards.buildingsmart.org/IFC/RELEASE/IFC4x3/HTML/annex-b2.html
// JSON.stringify([...document.getElementById("themainlist").children].map(x => x.innerText.split("\n")[1].toUpperCase()))
export const IFCDataTypes = [
  "IFCABSORBEDDOSEMEASURE",
  "IFCACCELERATIONMEASURE",
  "IFCACTIONREQUESTTYPEENUM",
  "IFCACTIONSOURCETYPEENUM",
  "IFCACTIONTYPEENUM",
  "IFCACTORSELECT",
  "IFCACTUATORTYPEENUM",
  "IFCADDRESSTYPEENUM",
  "IFCAIRTERMINALBOXTYPEENUM",
  "IFCAIRTERMINALTYPEENUM",
  "IFCAIRTOAIRHEATRECOVERYTYPEENUM",
  "IFCALARMTYPEENUM",
  "IFCALIGNMENTCANTSEGMENTTYPEENUM",
  "IFCALIGNMENTHORIZONTALSEGMENTTYPEENUM",
  "IFCALIGNMENTTYPEENUM",
  "IFCALIGNMENTVERTICALSEGMENTTYPEENUM",
  "IFCAMOUNTOFSUBSTANCEMEASURE",
  "IFCANALYSISMODELTYPEENUM",
  "IFCANALYSISTHEORYTYPEENUM",
  "IFCANGULARVELOCITYMEASURE",
  "IFCANNOTATIONTYPEENUM",
  "IFCAPPLIEDVALUESELECT",
  "IFCARCINDEX",
  "IFCAREADENSITYMEASURE",
  "IFCAREAMEASURE",
  "IFCARITHMETICOPERATORENUM",
  "IFCASSEMBLYPLACEENUM",
  "IFCAUDIOVISUALAPPLIANCETYPEENUM",
  "IFCAXIS2PLACEMENT",
  "IFCBSPLINECURVEFORM",
  "IFCBSPLINESURFACEFORM",
  "IFCBEAMTYPEENUM",
  "IFCBEARINGTYPEENUM",
  "IFCBENCHMARKENUM",
  "IFCBENDINGPARAMETERSELECT",
  "IFCBINARY",
  "IFCBOILERTYPEENUM",
  "IFCBOOLEAN",
  "IFCBOOLEANOPERAND",
  "IFCBOOLEANOPERATOR",
  "IFCBOXALIGNMENT",
  "IFCBRIDGEPARTTYPEENUM",
  "IFCBRIDGETYPEENUM",
  "IFCBUILDINGELEMENTPARTTYPEENUM",
  "IFCBUILDINGELEMENTPROXYTYPEENUM",
  "IFCBUILDINGSYSTEMTYPEENUM",
  "IFCBUILTSYSTEMTYPEENUM",
  "IFCBURNERTYPEENUM",
  "IFCCABLECARRIERFITTINGTYPEENUM",
  "IFCCABLECARRIERSEGMENTTYPEENUM",
  "IFCCABLEFITTINGTYPEENUM",
  "IFCCABLESEGMENTTYPEENUM",
  "IFCCAISSONFOUNDATIONTYPEENUM",
  "IFCCARDINALPOINTREFERENCE",
  "IFCCHANGEACTIONENUM",
  "IFCCHILLERTYPEENUM",
  "IFCCHIMNEYTYPEENUM",
  "IFCCLASSIFICATIONREFERENCESELECT",
  "IFCCLASSIFICATIONSELECT",
  "IFCCOILTYPEENUM",
  "IFCCOLOUR",
  "IFCCOLOURORFACTOR",
  "IFCCOLUMNTYPEENUM",
  "IFCCOMMUNICATIONSAPPLIANCETYPEENUM",
  "IFCCOMPLEXNUMBER",
  "IFCCOMPLEXPROPERTYTEMPLATETYPEENUM",
  "IFCCOMPOUNDPLANEANGLEMEASURE",
  "IFCCOMPRESSORTYPEENUM",
  "IFCCONDENSERTYPEENUM",
  "IFCCONNECTIONTYPEENUM",
  "IFCCONSTRAINTENUM",
  "IFCCONSTRUCTIONEQUIPMENTRESOURCETYPEENUM",
  "IFCCONSTRUCTIONMATERIALRESOURCETYPEENUM",
  "IFCCONSTRUCTIONPRODUCTRESOURCETYPEENUM",
  "IFCCONTEXTDEPENDENTMEASURE",
  "IFCCONTROLLERTYPEENUM",
  "IFCCONVEYORSEGMENTTYPEENUM",
  "IFCCOOLEDBEAMTYPEENUM",
  "IFCCOOLINGTOWERTYPEENUM",
  "IFCCOORDINATEREFERENCESYSTEMSELECT",
  "IFCCOSTITEMTYPEENUM",
  "IFCCOSTSCHEDULETYPEENUM",
  "IFCCOUNTMEASURE",
  "IFCCOURSETYPEENUM",
  "IFCCOVERINGTYPEENUM",
  "IFCCREWRESOURCETYPEENUM",
  "IFCCSGSELECT",
  "IFCCURTAINWALLTYPEENUM",
  "IFCCURVATUREMEASURE",
  "IFCCURVEFONTORSCALEDCURVEFONTSELECT",
  "IFCCURVEINTERPOLATIONENUM",
  "IFCCURVEMEASURESELECT",
  "IFCCURVEONSURFACE",
  "IFCCURVEOREDGECURVE",
  "IFCCURVESTYLEFONTSELECT",
  "IFCDAMPERTYPEENUM",
  "IFCDATAORIGINENUM",
  "IFCDATE",
  "IFCDATETIME",
  "IFCDAYINMONTHNUMBER",
  "IFCDAYINWEEKNUMBER",
  "IFCDEFINITIONSELECT",
  "IFCDERIVEDMEASUREVALUE",
  "IFCDERIVEDUNITENUM",
  "IFCDESCRIPTIVEMEASURE",
  "IFCDIMENSIONCOUNT",
  "IFCDIRECTIONSENSEENUM",
  "IFCDISCRETEACCESSORYTYPEENUM",
  "IFCDISTRIBUTIONBOARDTYPEENUM",
  "IFCDISTRIBUTIONCHAMBERELEMENTTYPEENUM",
  "IFCDISTRIBUTIONPORTTYPEENUM",
  "IFCDISTRIBUTIONSYSTEMENUM",
  "IFCDOCUMENTCONFIDENTIALITYENUM",
  "IFCDOCUMENTSELECT",
  "IFCDOCUMENTSTATUSENUM",
  "IFCDOORPANELOPERATIONENUM",
  "IFCDOORPANELPOSITIONENUM",
  "IFCDOORTYPEENUM",
  "IFCDOORTYPEOPERATIONENUM",
  "IFCDOSEEQUIVALENTMEASURE",
  "IFCDUCTFITTINGTYPEENUM",
  "IFCDUCTSEGMENTTYPEENUM",
  "IFCDUCTSILENCERTYPEENUM",
  "IFCDURATION",
  "IFCDYNAMICVISCOSITYMEASURE",
  "IFCEARTHWORKSCUTTYPEENUM",
  "IFCEARTHWORKSFILLTYPEENUM",
  "IFCELECTRICAPPLIANCETYPEENUM",
  "IFCELECTRICCAPACITANCEMEASURE",
  "IFCELECTRICCHARGEMEASURE",
  "IFCELECTRICCONDUCTANCEMEASURE",
  "IFCELECTRICCURRENTMEASURE",
  "IFCELECTRICDISTRIBUTIONBOARDTYPEENUM",
  "IFCELECTRICFLOWSTORAGEDEVICETYPEENUM",
  "IFCELECTRICFLOWTREATMENTDEVICETYPEENUM",
  "IFCELECTRICGENERATORTYPEENUM",
  "IFCELECTRICMOTORTYPEENUM",
  "IFCELECTRICRESISTANCEMEASURE",
  "IFCELECTRICTIMECONTROLTYPEENUM",
  "IFCELECTRICVOLTAGEMEASURE",
  "IFCELEMENTASSEMBLYTYPEENUM",
  "IFCELEMENTCOMPOSITIONENUM",
  "IFCENERGYMEASURE",
  "IFCENGINETYPEENUM",
  "IFCEVAPORATIVECOOLERTYPEENUM",
  "IFCEVAPORATORTYPEENUM",
  "IFCEVENTTRIGGERTYPEENUM",
  "IFCEVENTTYPEENUM",
  "IFCEXTERNALSPATIALELEMENTTYPEENUM",
  "IFCFACILITYPARTCOMMONTYPEENUM",
  "IFCFACILITYUSAGEENUM",
  "IFCFANTYPEENUM",
  "IFCFASTENERTYPEENUM",
  "IFCFILLSTYLESELECT",
  "IFCFILTERTYPEENUM",
  "IFCFIRESUPPRESSIONTERMINALTYPEENUM",
  "IFCFLOWDIRECTIONENUM",
  "IFCFLOWINSTRUMENTTYPEENUM",
  "IFCFLOWMETERTYPEENUM",
  "IFCFONTSTYLE",
  "IFCFONTVARIANT",
  "IFCFONTWEIGHT",
  "IFCFOOTINGTYPEENUM",
  "IFCFORCEMEASURE",
  "IFCFREQUENCYMEASURE",
  "IFCFURNITURETYPEENUM",
  "IFCGEOGRAPHICELEMENTTYPEENUM",
  "IFCGEOMETRICPROJECTIONENUM",
  "IFCGEOMETRICSETSELECT",
  "IFCGEOTECHNICALSTRATUMTYPEENUM",
  "IFCGLOBALORLOCALENUM",
  "IFCGLOBALLYUNIQUEID",
  "IFCGRIDPLACEMENTDIRECTIONSELECT",
  "IFCGRIDTYPEENUM",
  "IFCHATCHLINEDISTANCESELECT",
  "IFCHEATEXCHANGERTYPEENUM",
  "IFCHEATFLUXDENSITYMEASURE",
  "IFCHEATINGVALUEMEASURE",
  "IFCHUMIDIFIERTYPEENUM",
  "IFCIDENTIFIER",
  "IFCILLUMINANCEMEASURE",
  "IFCIMPACTPROTECTIONDEVICETYPEENUM",
  "IFCINDUCTANCEMEASURE",
  "IFCINTEGER",
  "IFCINTEGERCOUNTRATEMEASURE",
  "IFCINTERCEPTORTYPEENUM",
  "IFCINTERFERENCESELECT",
  "IFCINTERNALOREXTERNALENUM",
  "IFCINVENTORYTYPEENUM",
  "IFCIONCONCENTRATIONMEASURE",
  "IFCISOTHERMALMOISTURECAPACITYMEASURE",
  "IFCJUNCTIONBOXTYPEENUM",
  "IFCKERBTYPEENUM",
  "IFCKINEMATICVISCOSITYMEASURE",
  "IFCKNOTTYPE",
  "IFCLABEL",
  "IFCLABORRESOURCETYPEENUM",
  "IFCLAMPTYPEENUM",
  "IFCLANGUAGEID",
  "IFCLAYERSETDIRECTIONENUM",
  "IFCLAYEREDITEM",
  "IFCLENGTHMEASURE",
  "IFCLIBRARYSELECT",
  "IFCLIGHTDISTRIBUTIONCURVEENUM",
  "IFCLIGHTDISTRIBUTIONDATASOURCESELECT",
  "IFCLIGHTEMISSIONSOURCEENUM",
  "IFCLIGHTFIXTURETYPEENUM",
  "IFCLINEINDEX",
  "IFCLINEARFORCEMEASURE",
  "IFCLINEARMOMENTMEASURE",
  "IFCLINEARSTIFFNESSMEASURE",
  "IFCLINEARVELOCITYMEASURE",
  "IFCLIQUIDTERMINALTYPEENUM",
  "IFCLOADGROUPTYPEENUM",
  "IFCLOGICAL",
  "IFCLOGICALOPERATORENUM",
  "IFCLUMINOUSFLUXMEASURE",
  "IFCLUMINOUSINTENSITYDISTRIBUTIONMEASURE",
  "IFCLUMINOUSINTENSITYMEASURE",
  "IFCMAGNETICFLUXDENSITYMEASURE",
  "IFCMAGNETICFLUXMEASURE",
  "IFCMARINEFACILITYTYPEENUM",
  "IFCMARINEPARTTYPEENUM",
  "IFCMASSDENSITYMEASURE",
  "IFCMASSFLOWRATEMEASURE",
  "IFCMASSMEASURE",
  "IFCMASSPERLENGTHMEASURE",
  "IFCMATERIALSELECT",
  "IFCMEASUREVALUE",
  "IFCMECHANICALFASTENERTYPEENUM",
  "IFCMEDICALDEVICETYPEENUM",
  "IFCMEMBERTYPEENUM",
  "IFCMETRICVALUESELECT",
  "IFCMOBILETELECOMMUNICATIONSAPPLIANCETYPEENUM",
  "IFCMODULUSOFELASTICITYMEASURE",
  "IFCMODULUSOFLINEARSUBGRADEREACTIONMEASURE",
  "IFCMODULUSOFROTATIONALSUBGRADEREACTIONMEASURE",
  "IFCMODULUSOFROTATIONALSUBGRADEREACTIONSELECT",
  "IFCMODULUSOFSUBGRADEREACTIONMEASURE",
  "IFCMODULUSOFSUBGRADEREACTIONSELECT",
  "IFCMODULUSOFTRANSLATIONALSUBGRADEREACTIONSELECT",
  "IFCMOISTUREDIFFUSIVITYMEASURE",
  "IFCMOLECULARWEIGHTMEASURE",
  "IFCMOMENTOFINERTIAMEASURE",
  "IFCMONETARYMEASURE",
  "IFCMONTHINYEARNUMBER",
  "IFCMOORINGDEVICETYPEENUM",
  "IFCMOTORCONNECTIONTYPEENUM",
  "IFCNAVIGATIONELEMENTTYPEENUM",
  "IFCNONNEGATIVELENGTHMEASURE",
  "IFCNORMALISEDRATIOMEASURE",
  "IFCNUMERICMEASURE",
  "IFCOBJECTREFERENCESELECT",
  "IFCOBJECTIVEENUM",
  "IFCOCCUPANTTYPEENUM",
  "IFCOPENINGELEMENTTYPEENUM",
  "IFCOUTLETTYPEENUM",
  "IFCPHMEASURE",
  "IFCPARAMETERVALUE",
  "IFCPAVEMENTTYPEENUM",
  "IFCPERFORMANCEHISTORYTYPEENUM",
  "IFCPERMEABLECOVERINGOPERATIONENUM",
  "IFCPERMITTYPEENUM",
  "IFCPHYSICALORVIRTUALENUM",
  "IFCPILECONSTRUCTIONENUM",
  "IFCPILETYPEENUM",
  "IFCPIPEFITTINGTYPEENUM",
  "IFCPIPESEGMENTTYPEENUM",
  "IFCPLANARFORCEMEASURE",
  "IFCPLANEANGLEMEASURE",
  "IFCPLATETYPEENUM",
  "IFCPOINTORVERTEXPOINT",
  "IFCPOSITIVEINTEGER",
  "IFCPOSITIVELENGTHMEASURE",
  "IFCPOSITIVEPLANEANGLEMEASURE",
  "IFCPOSITIVERATIOMEASURE",
  "IFCPOWERMEASURE",
  "IFCPREFERREDSURFACECURVEREPRESENTATION",
  "IFCPRESENTABLETEXT",
  "IFCPRESSUREMEASURE",
  "IFCPROCEDURETYPEENUM",
  "IFCPROCESSSELECT",
  "IFCPRODUCTREPRESENTATIONSELECT",
  "IFCPRODUCTSELECT",
  "IFCPROFILETYPEENUM",
  "IFCPROJECTORDERTYPEENUM",
  "IFCPROJECTEDORTRUELENGTHENUM",
  "IFCPROJECTIONELEMENTTYPEENUM",
  "IFCPROPERTYSETDEFINITIONSELECT",
  "IFCPROPERTYSETDEFINITIONSET",
  "IFCPROPERTYSETTEMPLATETYPEENUM",
  "IFCPROTECTIVEDEVICETRIPPINGUNITTYPEENUM",
  "IFCPROTECTIVEDEVICETYPEENUM",
  "IFCPUMPTYPEENUM",
  "IFCRADIOACTIVITYMEASURE",
  "IFCRAILTYPEENUM",
  "IFCRAILINGTYPEENUM",
  "IFCRAILWAYPARTTYPEENUM",
  "IFCRAILWAYTYPEENUM",
  "IFCRAMPFLIGHTTYPEENUM",
  "IFCRAMPTYPEENUM",
  "IFCRATIOMEASURE",
  "IFCREAL",
  "IFCRECURRENCETYPEENUM",
  "IFCREFERENTTYPEENUM",
  "IFCREFLECTANCEMETHODENUM",
  "IFCREINFORCEDSOILTYPEENUM",
  "IFCREINFORCINGBARROLEENUM",
  "IFCREINFORCINGBARSURFACEENUM",
  "IFCREINFORCINGBARTYPEENUM",
  "IFCREINFORCINGMESHTYPEENUM",
  "IFCRESOURCEOBJECTSELECT",
  "IFCRESOURCESELECT",
  "IFCROADPARTTYPEENUM",
  "IFCROADTYPEENUM",
  "IFCROLEENUM",
  "IFCROOFTYPEENUM",
  "IFCROTATIONALFREQUENCYMEASURE",
  "IFCROTATIONALMASSMEASURE",
  "IFCROTATIONALSTIFFNESSMEASURE",
  "IFCROTATIONALSTIFFNESSSELECT",
  "IFCSIPREFIX",
  "IFCSIUNITNAME",
  "IFCSANITARYTERMINALTYPEENUM",
  "IFCSECTIONMODULUSMEASURE",
  "IFCSECTIONTYPEENUM",
  "IFCSECTIONALAREAINTEGRALMEASURE",
  "IFCSEGMENTINDEXSELECT",
  "IFCSENSORTYPEENUM",
  "IFCSEQUENCEENUM",
  "IFCSHADINGDEVICETYPEENUM",
  "IFCSHEARMODULUSMEASURE",
  "IFCSHELL",
  "IFCSIGNTYPEENUM",
  "IFCSIGNALTYPEENUM",
  "IFCSIMPLEPROPERTYTEMPLATETYPEENUM",
  "IFCSIMPLEVALUE",
  "IFCSIZESELECT",
  "IFCSLABTYPEENUM",
  "IFCSOLARDEVICETYPEENUM",
  "IFCSOLIDANGLEMEASURE",
  "IFCSOLIDORSHELL",
  "IFCSOUNDPOWERLEVELMEASURE",
  "IFCSOUNDPOWERMEASURE",
  "IFCSOUNDPRESSURELEVELMEASURE",
  "IFCSOUNDPRESSUREMEASURE",
  "IFCSPACEBOUNDARYSELECT",
  "IFCSPACEHEATERTYPEENUM",
  "IFCSPACETYPEENUM",
  "IFCSPATIALREFERENCESELECT",
  "IFCSPATIALZONETYPEENUM",
  "IFCSPECIFICHEATCAPACITYMEASURE",
  "IFCSPECULAREXPONENT",
  "IFCSPECULARHIGHLIGHTSELECT",
  "IFCSPECULARROUGHNESS",
  "IFCSTACKTERMINALTYPEENUM",
  "IFCSTAIRFLIGHTTYPEENUM",
  "IFCSTAIRTYPEENUM",
  "IFCSTATEENUM",
  "IFCSTRIPPEDOPTIONAL",
  "IFCSTRUCTURALACTIVITYASSIGNMENTSELECT",
  "IFCSTRUCTURALCURVEACTIVITYTYPEENUM",
  "IFCSTRUCTURALCURVEMEMBERTYPEENUM",
  "IFCSTRUCTURALSURFACEACTIVITYTYPEENUM",
  "IFCSTRUCTURALSURFACEMEMBERTYPEENUM",
  "IFCSUBCONTRACTRESOURCETYPEENUM",
  "IFCSURFACEFEATURETYPEENUM",
  "IFCSURFACEORFACESURFACE",
  "IFCSURFACESIDE",
  "IFCSURFACESTYLEELEMENTSELECT",
  "IFCSWITCHINGDEVICETYPEENUM",
  "IFCSYSTEMFURNITUREELEMENTTYPEENUM",
  "IFCTANKTYPEENUM",
  "IFCTASKDURATIONENUM",
  "IFCTASKTYPEENUM",
  "IFCTEMPERATUREGRADIENTMEASURE",
  "IFCTEMPERATURERATEOFCHANGEMEASURE",
  "IFCTENDONANCHORTYPEENUM",
  "IFCTENDONCONDUITTYPEENUM",
  "IFCTENDONTYPEENUM",
  "IFCTEXT",
  "IFCTEXTALIGNMENT",
  "IFCTEXTDECORATION",
  "IFCTEXTFONTNAME",
  "IFCTEXTFONTSELECT",
  "IFCTEXTPATH",
  "IFCTEXTTRANSFORMATION",
  "IFCTHERMALADMITTANCEMEASURE",
  "IFCTHERMALCONDUCTIVITYMEASURE",
  "IFCTHERMALEXPANSIONCOEFFICIENTMEASURE",
  "IFCTHERMALRESISTANCEMEASURE",
  "IFCTHERMALTRANSMITTANCEMEASURE",
  "IFCTHERMODYNAMICTEMPERATUREMEASURE",
  "IFCTIME",
  "IFCTIMEMEASURE",
  "IFCTIMEORRATIOSELECT",
  "IFCTIMESERIESDATATYPEENUM",
  "IFCTIMESTAMP",
  "IFCTORQUEMEASURE",
  "IFCTRACKELEMENTTYPEENUM",
  "IFCTRANSFORMERTYPEENUM",
  "IFCTRANSITIONCODE",
  "IFCTRANSLATIONALSTIFFNESSSELECT",
  "IFCTRANSPORTELEMENTTYPEENUM",
  "IFCTRIMMINGPREFERENCE",
  "IFCTRIMMINGSELECT",
  "IFCTUBEBUNDLETYPEENUM",
  "IFCURIREFERENCE",
  "IFCUNIT",
  "IFCUNITENUM",
  "IFCUNITARYCONTROLELEMENTTYPEENUM",
  "IFCUNITARYEQUIPMENTTYPEENUM",
  "IFCVALUE",
  "IFCVALVETYPEENUM",
  "IFCVAPORPERMEABILITYMEASURE",
  "IFCVECTORORDIRECTION",
  "IFCVEHICLETYPEENUM",
  "IFCVIBRATIONDAMPERTYPEENUM",
  "IFCVIBRATIONISOLATORTYPEENUM",
  "IFCVIRTUALELEMENTTYPEENUM",
  "IFCVOIDINGFEATURETYPEENUM",
  "IFCVOLUMEMEASURE",
  "IFCVOLUMETRICFLOWRATEMEASURE",
  "IFCWALLTYPEENUM",
  "IFCWARPINGCONSTANTMEASURE",
  "IFCWARPINGMOMENTMEASURE",
  "IFCWARPINGSTIFFNESSSELECT",
  "IFCWASTETERMINALTYPEENUM",
  "IFCWELLKNOWNTEXTLITERAL",
  "IFCWINDOWPANELOPERATIONENUM",
  "IFCWINDOWPANELPOSITIONENUM",
  "IFCWINDOWTYPEENUM",
  "IFCWINDOWTYPEPARTITIONINGENUM",
  "IFCWORKCALENDARTYPEENUM",
  "IFCWORKPLANTYPEENUM",
  "IFCWORKSCHEDULETYPEENUM",
];
