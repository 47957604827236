import { CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedModal } from "app/state/slices/ifcManagerSlice";
import { MODALS } from "app/common/types";

import * as S from "./LoadingModal.style";

export default function LoadingModal() {
  const selectedModal = useSelector(selectSelectedModal);
  const isOpen = MODALS.LOADING === selectedModal;

  return (
    <S.StyledBackdrop open={isOpen}>
      <CircularProgress color="inherit" />
      <Typography variant="body1" sx={{ mt: 2 }}>
        Loading model, please wait...
      </Typography>
    </S.StyledBackdrop>
  );
}
