import { Box, BoxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

export function Heading({
  children,
  noBottomMargin,
  sx,
  color,
  ...otherProps
}: BoxProps & { children?: ReactNode; noBottomMargin?: boolean; color?: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        ...(!noBottomMargin && {
          marginBottom: "0.1rem",
        }),
        width: "max-content",
        ...sx,
      }}
      {...otherProps}
    >
      <Typography
        sx={{
          fontSize: "1.15rem",
          fontWeight: "500",
          lineHeight: "1.3",
        }}
      >
        {children}
      </Typography>
      <Box
        sx={{
          width: "120%",
          height: "2px",
          background: color || "rgba(13, 71, 161, 0.1)",
          borderRadius: "1px",
          ...(!noBottomMargin && {
            marginBottom: "0.8rem",
          }),
        }}
      />
    </Box>
  );
}
