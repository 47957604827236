import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Chip, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { IDSIFCVersion, IDSValidationEntity, IDSValidationReport } from "app/common/idsSpec";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxRow } from "app/components/common/BoxRow";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  duplicateIDSSpec,
  highlightByID,
  idsSpecUpdateAttr,
  moveToIDSSpec,
  selectIDSApplAttr,
  selectIDSMode,
  selectIDSPanelState,
  selectIDSSpecAttr,
  selectIDSSpecSelectedId,
  selectIDSSpecSelectedRestrictionPath,
  selectModelUUID,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { useState } from "react";
import { NodeRendererProps } from "react-arborist";
import { sentinelMap } from "app/utils/objectMeta";

import { IDSSpecTreeNode, nodeHeight } from "./common";

export const NodeSpec = ({ node, style, dragHandle }: NodeRendererProps<IDSSpecTreeNode>) => {
  const dispatch = useAppDispatch();
  const idsMode = useAppSelector(selectIDSMode);

  //todo: update for federated
  const modelUUID = useAppSelector(selectModelUUID);
  const idsSpecIfcVersion = useAppSelector(selectIDSSpecAttr(node.id, "$_ifcVersion")) as
    | string
    | undefined;
  const idsSpecName = useAppSelector(selectIDSSpecAttr(node.id, "$_name")) as string;
  const validationReport = useAppSelector(selectIDSSpecAttr(node.id, "_validationReport")) as
    | IDSValidationReport
    | undefined;

  const restrictionPath = useAppSelector(selectIDSSpecSelectedRestrictionPath);
  const isSelected =
    useAppSelector(selectIDSSpecSelectedId) == node.id && restrictionPath?.section !== "results";
  const validationResults = useAppSelector(selectIDSSpecAttr(node.id, "_validationResults")) as
    | IDSValidationEntity[]
    | undefined;

  const idsPanelState = useAppSelector(selectIDSPanelState);

  const [ifcVersionMenuAnchor, setifcVersionMenuAnchor] = useState<null | HTMLElement>(null);
  const isIfcVersionMenuOpen = Boolean(ifcVersionMenuAnchor);
  const onIfcVersionMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setifcVersionMenuAnchor(event.currentTarget);
  };
  const onIfcVersionMenuSelect = (option: IDSIFCVersion | null) => () => {
    setifcVersionMenuAnchor(null);
    if (option) {
      dispatch(
        idsSpecUpdateAttr({
          _id: node.id,
          $_ifcVersion: option,
        })
      );
    }
  };

  // Note: both are declared as <xs:attribute default="1" name="minOccurs"... => 1 if null
  //       but ifctester has [0, "unbounded"] as defaults
  const applMinOccurs = (useAppSelector(selectIDSApplAttr(node.id, "$_minOccurs")) ?? 0) as number;
  const applMaxOccurs = sentinelMap(
    useAppSelector(selectIDSApplAttr(node.id, "$_maxOccurs")) ?? "unbounded",
    "unbounded",
    Number.MAX_VALUE
  );

  const hasPassed =
    validationReport != null &&
    validationReport.applicableItemsPass == validationReport.applicableItemsCount &&
    validationReport.applicableItemsCount >= applMinOccurs &&
    validationReport.applicableItemsCount <= applMaxOccurs;

  return (
    <div
      ref={dragHandle}
      className="TreeItemHover"
      style={{
        ...style,
        height: `${nodeHeight}px`,
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderRadius: "8px",
        border: "2px solid",
        marginLeft: theme.sppx.compact2V,
        marginRight: theme.sppx.compact2V,
        borderColor: isSelected ? theme.palette.primary.main : "transparent",
        ...(isSelected && {
          backgroundColor: theme.palette.selectedBackground,
          border: "#0d47a1 2px solid",
        }),
      }}
      onPointerEnter={() => {
        if (!validationResults) return;
        dispatch(
          highlightByID({
            modelUUID: modelUUID,
            expressID: validationResults.map(x => String(x.expressID)),
            isTempHover: true,
            isSpatiallyReccursive: true,
          })
        );
      }}
      onPointerLeave={() =>
        dispatch(
          highlightByID({
            modelUUID: null,
            expressID: null,
            isTempHover: true,
            isSpatiallyReccursive: true,
          })
        )
      }
    >
      <BoxRow
        sx={{
          height: `${nodeHeight}px`,
          alignItems: "center",
          boxSizing: "border-box",
          borderRadius: "8px",
          paddingRight: theme.sppx.compactV,
        }}
        onClick={() => dispatch(moveToIDSSpec(node.id))}
      >
        {(validationReport && (
          <Tooltip placement="top" arrow title="Toggle expansion">
            <IconButton
              color={node.isOpen ? "primary" : "default"}
              sx={{
                padding: `${theme.sppx.compactH}`,
                marginLeft: theme.sppx.gapTiny,
                marginRight: theme.sppx.gapTiny,
                ...(validationReport && {
                  borderRadius: "5px",
                }),
              }}
              onClick={e => {
                if (node.isClosed && idsPanelState != "minimized") {
                  // dispatch(idsSetPanelSection("results"));
                }
                node.toggle();
                e.stopPropagation();
              }}
            >
              <BoxCol sx={{ ...(validationReport && { marginLeft: "-7px", marginRight: "-2px" }) }}>
                {validationReport &&
                  (hasPassed ? (
                    <CheckCircleOutlineIcon sx={{ fill: theme.palette.softPass }} />
                  ) : (
                    <HighlightOffIcon sx={{ fill: theme.palette.softFail }} />
                  ))}
              </BoxCol>
              {node.isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </Tooltip>
        )) || (
          <Box
            sx={{
              paddingLeft: theme.sppx.compact2H,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: theme.palette.softBulletGrey,
                padding: "3px 0px 0px 2px",
                marginLeft: theme.sppx.compactV,
                marginRight: theme.sppx.spaciousH,
                width: `${theme.sppx.compact2H}`,
                height: `${theme.sppx.compact2H}`,
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            "&:hover": {
              overflowX: "auto",
              textOverflow: "inherit",
            },
          }}
        >
          <Typography>{idsSpecName ?? <i>Unnamed Spec</i>}</Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        {idsMode == "edit" && (
          <IconButton
            color="default"
            onClick={(e: any) => {
              dispatch(duplicateIDSSpec(node.id));
              e.stopPropagation();
            }}
            sx={{
              width: theme => theme.spacing(5),
              height: theme => theme.spacing(5),
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        )}
        {/* <IconButton
          color={isNameEditable !== false ? "primary" : "default"}
          onClick={(e: any) => {
            if (isNameEditable) {
              setIsNameEditable(false);
            } else {
              setIsNameEditable(true);
            }
            e.stopPropagation();
          }}
          sx={{
            width: theme => theme.spacing(5),
            height: theme => theme.spacing(5),
          }}
        >
          <BorderColorIcon />
        </IconButton> */}
      </BoxRow>
      <BoxRow sx={{ justifyContent: "flex-end", display: "none" }}>
        <Chip
          label={idsSpecIfcVersion ?? "No version"}
          onClick={onIfcVersionMenuClick}
          sx={{
            marginLeft: theme.sppx.compactH,
            marginBottom: theme.sppx.compactV,
          }}
        />
        <Menu
          anchorEl={ifcVersionMenuAnchor}
          open={isIfcVersionMenuOpen}
          onClose={onIfcVersionMenuSelect(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={onIfcVersionMenuSelect("IFC2X3")}>IFC2X3</MenuItem>
          <MenuItem onClick={onIfcVersionMenuSelect("IFC4")}>IFC4</MenuItem>
          <MenuItem onClick={onIfcVersionMenuSelect("IFC4X3_ADD2")}>IFC4X3_ADD2</MenuItem>
        </Menu>
      </BoxRow>
      {/* <Box sx={{ height: "4px" }} /> */}
    </div>
  );
};
