import { useEffect, useState } from "react";
import { useAppSelector } from "app/state/hooks";
import Layout from "app/Layout";
import LoadingModal from "app/modals/LoadingModal";
import SnackbarModal from "app/modals/SnackbarModal";
import CloudPickerModal from "app/modals/CloudPicker";
import LoginModal from "app/modals/LoginModal";
import AccountModal from "app/modals/AccountModal";
import PolicyModal from "app/modals/policies/PolicyModal";
import OpenAgreementModal from "app/modals/OpenAgreementModal";
import PropertiesSidebar from "app/components/properties/PropertiesSidebar";
import ToolsSidebar from "app/components/ToolsSideBar";
import { selectAccountIsLoggedIn, selectIsViewerDisplayed } from "app/state/slices/ifcManagerSlice";
import QueryTable from "app/components/QueryTable";
import LandingSplash from "app/pages/viewer/LandingSplash";
import UnauthorizedDomain from "app/modals/UnauthorizedDomain";
import IDSPanel from "app/components/ToolsSideBar/idsEditor/IDSPanel";

let Viewer: any = () => <></>;

export default function ViewerPage() {
  const [isViewerLoaded, setIsViewerLoaded] = useState<boolean>(false);
  const isViewerDisplayed = useAppSelector(selectIsViewerDisplayed);
  const accountIsLoggedIn = useAppSelector(selectAccountIsLoggedIn);

  const loadViewer = () => {
    if (!isViewerLoaded) {
      import("app/pages/viewer").then(module => {
        Viewer = module.default;
        setIsViewerLoaded(true);
      });
    }
  };

  /* This is the quickpath for the signed in user, see _updateAccountReducer for more info */
  if (localStorage.getItem("isLoggedIn") == "true") {
    loadViewer();
  }

  useEffect(() => {
    if (isViewerDisplayed) {
      loadViewer();
    }
  }, [isViewerDisplayed]);

  useEffect(() => {
    if (accountIsLoggedIn) {
      loadViewer();
    }
  }, [accountIsLoggedIn]);

  return (
    <Layout>
      {/* todo: this modal list should be a single element in the layout */}
      <UnauthorizedDomain />
      <OpenAgreementModal />
      <LoginModal />
      <PolicyModal />
      <AccountModal />
      <CloudPickerModal />
      <LoadingModal />
      <SnackbarModal />
      <PropertiesSidebar />
      <ToolsSidebar />
      <Viewer />
      <LandingSplash isViewerLoaded={isViewerLoaded} />
      <QueryTable />
      <IDSPanel />
    </Layout>
  );
}
