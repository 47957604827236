import * as THREE from "three";
import { Components } from "../../Components";
import { readPixelsAsync } from "./screen-culler-helper";
import { AsyncEvent, Event, World } from "../../Types";
import { viewerAPI } from "app/common/ViewerAPI";

/**
 * Settings to configure the CullerRenderer.
 */
export interface CullerRendererSettings {
  /**
   * Interval in milliseconds at which the visibility check should be performed.
   * Default value is 1000.
   */
  updateInterval?: number;

  /**
   * Width of the render target used for visibility checks.
   * Default value is 512.
   */
  width?: number;

  /**
   * Height of the render target used for visibility checks.
   * Default value is 512.
   */
  height?: number;

  /**
   * Whether the visibility check should be performed automatically.
   * Default value is true.
   */
  autoUpdate?: boolean;
}

/**
 * A base renderer to determine visibility on screen.
 */
export class CullerRenderer {
  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  /**
   * Fires after making the visibility check to the meshes. It lists the
   * meshes that are currently visible, and the ones that were visible
   * just before but not anymore.
   */
  readonly onViewUpdated: Event<any> | AsyncEvent<any> = new AsyncEvent<any>();

  /**
   * Whether this renderer is active or not. If not, it won't render anything.
   */
  enabled = true;

  /**
   * Needs to check whether there are objects that need to be hidden or shown.
   * You can bind this to the camera movement, to a certain interval, etc.
   */
  needsUpdate = false;
  wantsUpdate = false;

  /**
   * Render the internal scene used to determine the object visibility. Used
   * for debugging purposes.
   */
  renderDebugFrame = false;

  // ms that the last render took
  lastUpdateTimeTaken = 0;

  /** The components instance to which this renderer belongs. */
  components: Components;

  /** The world instance to which this renderer belongs. */
  readonly world: World;

  /** The THREE.js renderer used to make the visibility test. */
  readonly renderer: THREE.WebGLRenderer;

  protected autoUpdate = true;

  protected updateInterval = 1000;

  // protected readonly worker: Worker;

  public readonly scene = new THREE.Scene();

  private _width = 512;

  private _height = 512;

  private _availableColor = 1;

  private readonly renderTarget: THREE.WebGLRenderTarget;

  private readonly bufferSize: number;

  private readonly _buffer: Uint8Array;

  // Prevents worker being fired multiple times
  protected _isWorkerBusy = false;

  constructor(components: Components, world: World, settings?: CullerRendererSettings) {
    if (!world.renderer) {
      throw new Error("The given world must have a renderer!");
    }

    this.components = components;
    this.applySettings(settings);

    this.world = world;
    this.renderer = new THREE.WebGLRenderer({
      precision: "lowp",
    });

    this.renderTarget = new THREE.WebGLRenderTarget(this._width, this._height, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
    });
    this.bufferSize = this._width * this._height * 4;
    this._buffer = new Uint8Array(this.bufferSize);

    this.renderer.clippingPlanes = world.renderer.clippingPlanes;
  }

  /** {@link Disposable.dispose} */
  dispose() {
    this.enabled = false;
    for (const child of this.scene.children) {
      child.removeFromParent();
    }
    this.onViewUpdated.reset();
    // this.worker.terminate();
    this.renderer.dispose();
    this.renderTarget.dispose();
    (this._buffer as any) = null;
    this.onDisposed.reset();
  }

  /**
   * The function that the culler uses to reprocess the scene. Generally it's
   * better to call needsUpdate, but you can also call this to force it.
   * @param force if true, it will refresh the scene even if needsUpdate is
   * not true.
   */
  updateVisibility = async (force?: boolean) => {
    if (!this.enabled) return;
    if (!this.wantsUpdate && !this.needsUpdate && !force) return;
    // if not necessary, make sure it hits 24fps
    if (
      !(
        force ||
        this.needsUpdate ||
        (this.wantsUpdate &&
          this.lastUpdateTimeTaken + (this.world.renderer?.lastUpdateTimeTaken ?? 15) <= 41)
      )
    )
      return;

    if (this._isWorkerBusy) return;
    this._isWorkerBusy = true;

    const timeStart = performance.now();

    const camera = this.world.camera.three;
    camera.updateMatrix();

    // const renderer = this.world.renderer?.three;
    const renderer = this.renderer;
    if (!renderer) return;

    const oldSize = new THREE.Vector2();
    let sizeChanged = false;
    renderer.getSize(oldSize);
    if (Math.abs(oldSize.x - this._width) >= 1 || Math.abs(oldSize.y - this._height) >= 1) {
      renderer.setSize(this._width, this._height);
      sizeChanged = true;
    }
    renderer.setRenderTarget(this.renderTarget);

    if (!this.renderDebugFrame) {
      renderer.render(this.scene, camera);
    }

    const context = renderer.getContext() as WebGL2RenderingContext;

    readPixelsAsync(
      context,
      0,
      0,
      this._width,
      this._height,
      context.RGBA,
      context.UNSIGNED_BYTE,
      this._buffer
    );

    renderer.setRenderTarget(null);
    // if (sizeChanged) {
    //   // renderer.setSize(oldSize.x, oldSize.y);
    // }

    if (this.renderDebugFrame) {
      // this.renderer.render(this.scene, camera);

      // const scene = ;
      const scene = this.scene;
      const camera = this.world.camera.three;
      const renderer = this.world.renderer?.three;
      if (renderer) {
        //note: for this to work, renderer. preserveDrawingBuffer: true
        // renderer.setSize(this._width, this._height);
        // renderer.setRenderTarget(this.renderTarget);
        renderer.render(scene, camera);
        // renderer.render(this.world.scene.three, camera);
      }
    }

    {
      const buffer = this._buffer;
      const colors = new Set<number>();
      for (let i = 0; i < buffer.length; i += 4) {
        const r = buffer[i];
        const g = buffer[i + 1];
        const b = buffer[i + 2];
        const code = (r << 16) | (g << 8) | b;
        colors.add(code);
      }

      const extension = this as any;
      if (extension.handleColorCodeUpdate) {
        await extension.handleColorCodeUpdate(colors);
      }
    }

    const timeEnd = performance.now();
    this.lastUpdateTimeTaken = timeEnd - timeStart;

    if (this.world.renderer) {
      this.world.renderer.needsUpdate = true;
      const viewer = viewerAPI();
      if (viewer?._navGizmo) {
        viewer._navGizmo.needsUpdate = true;
      }
    }
    this.needsUpdate = false;
    this.wantsUpdate = false;
  };

  protected getAvailableColor() {
    const code = this._availableColor;
    const b = this._availableColor & 255;
    const g = (this._availableColor >> 8) & 255;
    const r = (this._availableColor >> 16) & 255;

    return { r, g, b, code };
  }

  protected increaseColor() {
    if (this._availableColor === 256 * 256 * 256) {
      console.warn("Color can't be increased over 256 x 256 x 256!");
      return;
    }
    this._availableColor++;
  }

  protected decreaseColor() {
    if (this._availableColor === 1) {
      console.warn("Color can't be decreased under 0!");
      return;
    }
    this._availableColor--;
  }

  private applySettings(settings?: CullerRendererSettings) {
    if (settings) {
      if (settings.updateInterval !== undefined) {
        this.updateInterval = settings.updateInterval;
      }
      if (settings.height !== undefined) {
        this._height = settings.height;
      }
      if (settings.width !== undefined) {
        this._width = settings.width;
      }
      if (settings.autoUpdate !== undefined) {
        this.autoUpdate = settings.autoUpdate;
      }
    }
  }
}
