import { Box, BoxProps, Link, LinkProps, Typography } from "@mui/material";
import { MODALS } from "app/common/types";
import { BoxRow } from "app/components/common/BoxRow";
import { setSelectedModal } from "app/state/slices/ifcManagerSlice";
import LinkedINIcon from "app/static/images/LI-Logo-small.png";
import theme from "app/theme";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const FooterLink = (props: LinkProps) => {
  const { sx, ...otherProps } = props;
  return (
    <Link
      underline="none"
      sx={{
        fontWeight: 500,
        marginLeft: "0.33em",
        marginRight: "0.33em",
        textUnderlineOffset: "0.2em",
        ...sx,
      }}
      {...otherProps}
    ></Link>
  );
};

const LinkedINLink = (props: BoxProps) => {
  const linkedinRef = useRef(null as HTMLElement | null);
  const [showPlainLink, setShowPlainLink] = useState(true);
  useEffect(() => {
    if (!linkedinRef.current) return;
    const observer = new MutationObserver(() => {
      setShowPlainLink(false);
    });

    observer.observe(linkedinRef.current, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [linkedinRef]);

  return (
    <BoxRow ref={linkedinRef} sx={{ alignItems: "center" }} {...props}>
      <script type="IN/FollowCompany" data-id="70916412" data-counter="side"></script>
      <Link
        target="_blank"
        underline="none"
        href="https://www.linkedin.com/company/sortdesk/"
        sx={{ fontWeight: 500, ...(!showPlainLink && { display: "none" }) }}
      >
        <img src={LinkedINIcon} style={{ display: "block", height: "1.2em", margin: "auto" }} />
      </Link>
    </BoxRow>
  );
};

const Footer = () => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100vw",
        backgroundColor: theme.palette.secondary.main,
        height: "max-content",
        zIndex: theme.zIndex.drawer + 1,
        overflowY: "hidden",
      }}
    >
      <Typography
        variant="body1"
        component="div"
        align="center"
        sx={{
          display: "flex",
          width: "100%",
          minHeight: theme.sppx.footerHeight,
          height: "max-content",
          alignItems: "center",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            justifyContent: "space-around",
          },
          fontSize: "0.875rem",
          flexWrap: "wrap",
          paddingLeft: theme.sppx.compactH,
          paddingRight: theme.sppx.compactH,
          boxSizing: "border-box",
          gap: theme.sppx.compactV,
        }}
      >
        <FooterLink target="_blank" href="mailto:support@sortdesk.com">
          support@sortdesk.com
        </FooterLink>

        <BoxRow
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <span>Made by </span>
          <FooterLink
            target="_blank"
            href="https://sortdesk.com/?utm_source=viewer.sortdesk.com&utm_medium=referral&utm_campaign=viewer_traffic"
          >
            Sortdesk.com
          </FooterLink>
          <Box sx={{ display: "flex", lineHeight: "2", marginLeft: "0.33em" }}>
            <LinkedINLink />
          </Box>
        </BoxRow>

        <Box sx={{ display: "flex" }}>
          <FooterLink onClick={() => dispatch(setSelectedModal(MODALS.OPENAGREEMENT))}>
            Terms of service
          </FooterLink>
        </Box>
      </Typography>
    </Box>
  );
};

export default Footer;
