import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/state/store";
import { MODALS, POLICIES, SEVERITIES } from "app/common/types";

import { IIfcMangerState } from "../ifcManagerSlice";

export interface IPopupsState {
  // full screen modal
  selectedModal: null | MODALS;
  selectedPolicy: null | POLICIES;
  /* for the back button in Terms from Account modal */
  previousModal: null | MODALS;
  // bottom right small popup
  snackbarMessage: string | null;
  snackbarSeverity: SEVERITIES;
  // top of LoginModal
  loginMessage: string | null;
  loginSeverity: SEVERITIES;
}

export const initialPopupsState: IPopupsState = {
  selectedModal: null,
  selectedPolicy: null,
  previousModal: null,
  snackbarMessage: null,
  snackbarSeverity: SEVERITIES.SUCCESS,
  loginMessage: null,
  loginSeverity: SEVERITIES.SUCCESS,
};

export const setSelectedModalReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<MODALS | null>
) => {
  state.previousModal = state.selectedModal;
  state.selectedModal = payload;
};

export const setSelectedPolicyModalReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<POLICIES | null>
) => {
  state.previousModal = state.selectedModal;
  state.selectedModal = MODALS.POLICY;
  state.selectedPolicy = payload;
};

export const dismissModalReducer = (state: IIfcMangerState) => {
  state.selectedModal = null;
};

export const dismissSnackbarReducer = (state: IIfcMangerState) => {
  state.snackbarMessage = null;
};

export const dismissLoginAlertReducer = (state: IIfcMangerState) => {
  state.loginMessage = null;
};

export const popupsSelectors = {
  selectSelectedPolicy: (state: RootState) => state.ifcManager.selectedPolicy,
  selectSelectedModal: (state: RootState) => state.ifcManager.selectedModal,
  selectPreviousModal: (state: RootState) => state.ifcManager.previousModal,
  selectSnackbarMessage: (state: RootState) => state.ifcManager.snackbarMessage,
  selectSnackbarSeverity: (state: RootState) => state.ifcManager.snackbarSeverity,
  selectLoginMessage: (state: RootState) => state.ifcManager.loginMessage,
  selectLoginSeverity: (state: RootState) => state.ifcManager.loginSeverity,
};

export const popupsReducers = {
  setSelectedModal: setSelectedModalReducer,
  setSelectedPolicyModal: setSelectedPolicyModalReducer,
  dismissModal: dismissModalReducer,
  dismissSnackbar: dismissSnackbarReducer,
  dismissLoginAlert: dismissLoginAlertReducer,
};
