import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MODALS, TOOLS } from "app/common/types";
import { BoxColE2E } from "app/components/common/BoxColE2E";
import { Heading } from "app/components/common/Heading";
import { Heavy } from "app/components/common/Heavy";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  exportModelQuery,
  hideTool,
  highlightByID,
  queryFile,
  queryFileSchema,
  selectAccountIsLoggedIn,
  selectIsLeftPanelOpen,
  selectIsModelReadyToQuery,
  selectIsRightPanelOpen,
  selectIsToolActive,
  selectIsWaitingForModelQuery,
  selectModelQueryExportedAttributes,
  selectModelQueryResult,
  selectModelQuerySchema,
  selectModelQuerySelectedTypes,
  selectModelQueryTypes,
  selectModelsDetails,
  selectPropertiesExpressID,
  selectQueryModelCloudId,
  selectQueryPollRetriesRemaining,
  setModelQueryExportedAttributes,
  setModelQuerySelectedTypes,
  setQueryModelCloudId,
  setSelectedModal,
  setlastSignUpEventSource,
} from "app/state/slices/ifcManagerSlice";
import theme, { styled } from "app/theme";
import { objectRecAccess } from "app/utils/objectMeta";
import { memo, useEffect, useMemo, useState } from "react";

import { ArrowButton } from "./ArrowButton";
import StyledDrawer from "./StyledDrawer";
import { BoxRowE2E } from "./common/BoxRowE2E";
import { RichSpan } from "./common/RichSpan";
import { BoxRow } from "./common/BoxRow";

const OverflowableTableCell = styled(TableCell)({
  overflowX: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    overflowX: "auto",
    textOverflow: "clip",
  },
  whiteSpace: "nowrap",
});

function ResultsTableBody({
  modelQueryResult,
  exportedAttributesKeys,
  exportedAttributes,
  onClick,
}: {
  modelQueryResult: any[];
  exportedAttributesKeys: string[];
  exportedAttributes: Record<string, any>;
  onClick?: (row: any) => void;
}) {
  const propertiesId = useAppSelector(selectPropertiesExpressID);

  return (
    <TableBody>
      {modelQueryResult.map((row: any) => (
        <TableRow
          key={row.id}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            cursor: "pointer",
            background: "" + propertiesId == "" + row.id ? theme.palette.hoverBackground : "white",
            "&:hover": {
              backgroundColor: theme.palette.hoverGreyBackground,
            },
          }}
          onClick={() => onClick && onClick(row)}
        >
          {exportedAttributesKeys.map(key => (
            <OverflowableTableCell key={key}>
              {objectRecAccess(row, exportedAttributes[key])}
            </OverflowableTableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

function Content({
  isExpanded,
  onExpand,
  onCollapse,
}: {
  isExpanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
}) {
  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [autosuggestOptions, setAutosuggestOptions] = useState([] as string[]);
  const [autosuggestQuery, setAutosuggestQuery] = useState("");
  const [queryModelUUID, setQueryModelUUID] = useState("");

  const queryModelCloudId = useAppSelector(selectQueryModelCloudId);
  const modelsDetails = useAppSelector(selectModelsDetails);
  const isModelReadyToQuery = useAppSelector(selectIsModelReadyToQuery);
  const types = useAppSelector(selectModelQueryTypes);
  const selectedTypes = useAppSelector(selectModelQuerySelectedTypes);
  const selectedTypesSentinel = selectedTypes ?? [];

  const flatSchema = useAppSelector(selectModelQuerySchema);
  const modelQueryResult = useAppSelector(selectModelQueryResult);
  const queryPollRetriesRemaining = useAppSelector(selectQueryPollRetriesRemaining);
  const isWaitingForModelQuery = useAppSelector(selectIsWaitingForModelQuery);
  const exportedAttributes = useAppSelector(selectModelQueryExportedAttributes);
  const exportedAttributesKeys = Object.keys(exportedAttributes).filter(
    x => exportedAttributes[x] != undefined
  );
  const areAnyAttributesExported = exportedAttributesKeys.length > 0;

  const isActive = useAppSelector(selectIsToolActive(TOOLS.QUERY));
  const accountIsLoggedIn =
    useAppSelector(selectAccountIsLoggedIn) || localStorage.getItem("isLoggedIn") == "true";

  useEffect(() => {
    if (flatSchema && selectedTypes && selectedTypes.length > 0) {
      setAutosuggestOptions(
        Object.keys(Object.assign({}, ...selectedTypes.map(x => flatSchema[x])))
      );
    } else {
      setAutosuggestOptions([]);
    }
  }, [selectedTypes, flatSchema]);

  useEffect(() => {
    const keys = Object.keys(modelsDetails);
    if (keys.length > 0) {
      const key = keys[0];
      const modelCloudID = modelsDetails[key]?.modelCloudID;
      if (modelCloudID) {
        setQueryModelUUID(modelsDetails[key]?.modelUUID);
        dispatch(setQueryModelCloudId(modelCloudID));
        dispatch(queryFileSchema({ refreshPolling: true }));
      }
    }
  }, [modelsDetails]);
  useEffect(() => {
    if (isActive && !isModelReadyToQuery) {
      dispatch(queryFileSchema({ refreshPolling: true }));
    }
  }, [isActive, queryModelCloudId]);

  return (
    <BoxColE2E
      // This whole box is needed because height: 0 doesn't work with padding
      sx={{ padding: `${theme.sppx.compact2V} ${theme.sppx.compact2H}` }}
    >
      <BoxRowE2E sx={{ padding: 0, height: "max-content" }}>
        <ArrowButton hasArrowNext isSelected={currentStep == 1} onClick={() => setCurrentStep(1)}>
          1. Select
        </ArrowButton>
        <ArrowButton
          hasArrowPrev
          isSelected={currentStep == 2}
          onClick={async () => {
            setCurrentStep(2);
            dispatch(queryFile());
          }}
        >
          2. Preview & Export
        </ArrowButton>
        <Box sx={{ flexGrow: "1" }} />
        <Button
          disabled={modelQueryResult == null}
          sx={{ ...(currentStep != 2 && { display: "none" }) }}
          variant="contained"
          size="small"
          onClick={async () => {
            dispatch(exportModelQuery());
          }}
        >
          <Typography variant="button">Export as CSV</Typography>
        </Button>
        <Box /* spacer to avoid X mislicks */ sx={{ width: `${theme.sp.spaciousH}px` }} />
        <Tooltip title="Toggle Expansion">
          <IconButton
            onClick={async () => {
              isExpanded ? onCollapse() : onExpand();
            }}
          >
            <FullscreenIcon sx={{ display: isExpanded ? "none" : "block" }} />
            <FullscreenExitIcon sx={{ display: isExpanded ? "block" : "none" }} />
          </IconButton>
        </Tooltip>
        <Box /* spacer to avoid X mislicks */ sx={{ width: theme.sppx.gapTiny }} />
        <Tooltip title="Close Panel">
          <IconButton
            onClick={async () => {
              dispatch(hideTool({ tool: TOOLS.QUERY }));
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </BoxRowE2E>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexBasis: "1",
          flexGrow: "1",
          paddingTop: theme.sppx.spacious2V,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          position: "relative",
          minHeight: 0,
        }}
      >
        {!accountIsLoggedIn && (
          <Box
            sx={{
              height: "100%",
              flexBasis: "0.3",
              flexGrow: "0.2",
              maxWidth: "30rem",
              display: currentStep == 1 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 0,
              gap: theme.sppx.spacious2V,
            }}
          >
            <Typography>
              <b>Querying</b> is only available for <Heavy>cloud-synced</Heavy> models
            </Typography>
            <BoxRow
              sx={{
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "center",
                width: "100%",
                paddingBottom: theme.sppx.spacious2H,
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  dispatch(setlastSignUpEventSource("queryPanel"));
                  dispatch(setSelectedModal(MODALS.LOGIN));
                }}
              >
                <Typography variant="button" sx={{ textTransform: "none" }}>
                  Sign up
                </Typography>
              </Button>
              <Typography>
                to <Heavy>start</Heavy> using it!
              </Typography>
            </BoxRow>
          </Box>
        )}
        {accountIsLoggedIn && !queryModelCloudId && (
          <>
            <Box
              sx={{
                height: "100%",
                flexBasis: "0.3",
                flexGrow: "0.2",
                maxWidth: "30rem",
                display: currentStep == 1 ? "flex" : "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginRight: theme.sppx.spaciousH,
                minHeight: 0,
                gap: theme.sppx.spacious2V,
              }}
            >
              <Typography>Querying is only available for cloud-synced models</Typography>
              <Typography>
                <RichSpan key="qtuploadmodel">
                  <b>Upload</b> a model to your <Heavy>cloud</Heavy> get started!
                </RichSpan>
              </Typography>
            </Box>
          </>
        )}
        {accountIsLoggedIn &&
          queryModelCloudId != null &&
          !isModelReadyToQuery &&
          queryPollRetriesRemaining > 0 && (
            <>
              <Box
                sx={{
                  height: "100%",
                  flexBasis: "0.3",
                  flexGrow: "0.2",
                  maxWidth: "30rem",
                  display: currentStep == 1 ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: theme.sppx.spaciousH,
                  minHeight: 0,
                  gap: theme.sppx.spacious2V,
                }}
              >
                <CircularProgress />
                <Typography>We are crunching your data to make it easily queryable</Typography>
                <Typography>This may take up to a minute</Typography>
              </Box>
            </>
          )}
        {accountIsLoggedIn &&
          queryModelCloudId != null &&
          !isModelReadyToQuery &&
          queryPollRetriesRemaining <= 0 && (
            <>
              <Box
                sx={{
                  height: "100%",
                  flexBasis: "0.3",
                  flexGrow: "0.2",
                  maxWidth: "30rem",
                  display: currentStep == 1 ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: theme.sppx.spaciousH,
                  minHeight: 0,
                  gap: theme.sppx.spacious2V,
                }}
              >
                <Typography>
                  This model&apos;s attributes couldn&apos;t be processed for querying.
                </Typography>
              </Box>
            </>
          )}
        {isModelReadyToQuery && (
          <>
            <Box
              sx={{
                height: "100%",
                flexBasis: "0.3",
                flexGrow: "0.2",
                maxWidth: "30rem",
                display: currentStep == 1 ? "flex" : "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                marginRight: theme.sppx.spaciousH,
                minHeight: 0,
              }}
            >
              <Heading>Element Types</Heading>

              <List sx={{ height: "100%", width: "100%", overflow: "auto", minHeight: 0 }}>
                {types &&
                  types.map((t: any) => (
                    <ListItem disablePadding key={t.name}>
                      <ListItemButton
                        role={undefined}
                        onClick={() => {
                          dispatch(
                            setModelQuerySelectedTypes(
                              selectedTypesSentinel.includes(t.name)
                                ? [...selectedTypesSentinel.filter((x: any) => x != t.name)]
                                : [...selectedTypesSentinel.filter((x: any) => x != t.name), t.name]
                            )
                          );
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            variant="inline"
                            checked={selectedTypesSentinel.includes(t.name)}
                            inputProps={{ "aria-labelledby": t.name }}
                          />
                        </ListItemIcon>
                        <ListItemText id={t.name} primary={`${t.name}`} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Box>
            <Box
              sx={{
                display: currentStep == 1 && selectedTypesSentinel.length == 0 ? "flex" : "none",
                flexBasis: "0.8",
                flexGrow: "2",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                height: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                padding: "2px", // this is for the MUI Shadow
                gap: theme.sppx.spacious2V,
              }}
            >
              <Typography>
                <RichSpan key="qtselectet">
                  <b>Select</b> some <Heavy>element types</Heavy> to start your query
                </RichSpan>
              </Typography>
            </Box>
            <Box
              sx={{
                display: currentStep == 1 && selectedTypesSentinel.length > 0 ? "flex" : "none",
                flexBasis: "0.8",
                flexGrow: "2",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                height: "100%",
                overflow: "hidden",
                padding: "2px", // this is for the MUI Shadow
              }}
            >
              <Heading>Element Attributes</Heading>

              <TableContainer
                component={Paper}
                sx={{
                  "&&.MuiPaper-root": {
                    overflowX: "auto",
                    overflowY: "auto",
                  },
                }}
              >
                <Table
                  stickyHeader
                  sx={{ width: "100%", tableLayout: "fixed" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {/* {flatSchema && Object.keys(flatSchema).map(x => (<TableCell>{x}</TableCell>))} */}
                      <TableCell sx={{ width: "80%" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ fontWeight: 500 }}>Attribute Name</Typography>
                          <Autocomplete
                            freeSolo
                            size="small"
                            onChange={(e, option) => setAutosuggestQuery(option ?? "")}
                            //@ts-ignore
                            onInputChange={e =>
                              //@ts-ignore
                              typeof e?.target?.value === "string" &&
                              //@ts-ignore
                              setAutosuggestQuery(e?.target?.value ?? "")
                            }
                            value={autosuggestQuery}
                            // placeholder="Type to search.."
                            options={autosuggestOptions}
                            sx={{ padding: "0em 1em", flexGrow: 1 }}
                            renderInput={params => <TextField {...params} />}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography sx={{ fontWeight: 500 }}>Example Value</Typography>
                      </TableCell>
                      <TableCell sx={{ paddingRight: "3em", width: "5%" }}>
                        <Typography sx={{ fontWeight: 500 }}>Export</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ width: "100%" }}>
                    {flatSchema &&
                      selectedTypes &&
                      selectedTypes.length > 0 &&
                      Object.entries(
                        Object.assign({}, ...selectedTypes.map(x => flatSchema[x])) as {
                          [key: string]: {
                            queryPath: any;
                            value: string;
                          };
                        }
                      ).map(([key, attribute]) => (
                        <TableRow
                          key={key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "0.2em",
                            display: (key + "")
                              .toLowerCase()
                              .includes(autosuggestQuery.toLowerCase())
                              ? "table-row"
                              : "none",
                            background:
                              exportedAttributes[key] != undefined
                                ? theme.palette.selectedBackground
                                : "white",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                            overflow: "hidden",
                          }}
                        >
                          <OverflowableTableCell>{key}</OverflowableTableCell>
                          <OverflowableTableCell>{attribute.value}</OverflowableTableCell>
                          <OverflowableTableCell
                            sx={{}}
                            onClick={() =>
                              dispatch(
                                setModelQueryExportedAttributes({
                                  ...exportedAttributes,
                                  [key]:
                                    exportedAttributes[key] == undefined
                                      ? attribute.queryPath
                                      : undefined,
                                })
                              )
                            }
                          >
                            <BoxColE2E sx={{ padding: 0 }}>
                              <Checkbox
                                edge="start"
                                checked={exportedAttributes[key] != undefined}
                                tabIndex={-1}
                                disableRipple
                                sx={{ padding: 0 }}
                              />
                            </BoxColE2E>
                          </OverflowableTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
        {!accountIsLoggedIn && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: currentStep == 2 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.sppx.spacious2V,
            }}
          >
            <Typography>
              <b>Querying</b> is only available for <Heavy>cloud-synced</Heavy> models
            </Typography>
            <BoxRow
              sx={{
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "center",
                width: "100%",
                paddingBottom: theme.sppx.spacious2H,
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  dispatch(setlastSignUpEventSource("queryPanel"));
                  dispatch(setSelectedModal(MODALS.LOGIN));
                }}
              >
                <Typography variant="button" sx={{ textTransform: "none" }}>
                  Sign up
                </Typography>
              </Button>
              <Typography>
                to <Heavy>start</Heavy> using it!
              </Typography>
            </BoxRow>
          </Box>
        )}
        {accountIsLoggedIn && !queryModelCloudId && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: currentStep == 2 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.sppx.spacious2V,
            }}
          >
            <Typography>Querying is only available for cloud-synced models</Typography>
            <Typography>
              <RichSpan key="qtuploadmodel2">
                <b>Upload</b> a model to your <Heavy>cloud</Heavy> get started!
              </RichSpan>
            </Typography>
          </Box>
        )}
        {accountIsLoggedIn && queryModelCloudId != null && !areAnyAttributesExported && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: currentStep == 2 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography>
              <RichSpan key="qtgoback">
                Go to <b>1. SELECT</b> and tick some properties to be exported
              </RichSpan>
            </Typography>
          </Box>
        )}
        {areAnyAttributesExported && isWaitingForModelQuery && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: currentStep == 2 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.sppx.spacious2V,
            }}
          >
            <CircularProgress />
            <Typography>Collecting your results..</Typography>
          </Box>
        )}
        {areAnyAttributesExported && !isWaitingForModelQuery && (
          <>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: currentStep == 2 ? "flex" : "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  height: "100%",
                  "&&.MuiPaper-root": {
                    overflowX: "auto",
                    overflowY: "auto",
                  },
                }}
              >
                <Table
                  stickyHeader
                  sx={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                  aria-label="exports table"
                >
                  <TableHead>
                    <TableRow>
                      {exportedAttributesKeys.map(key => (
                        <TableCell key={key}>{key}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {(modelQueryResult && (
                    <ResultsTableBody
                      modelQueryResult={modelQueryResult}
                      exportedAttributesKeys={exportedAttributesKeys}
                      exportedAttributes={exportedAttributes}
                      onClick={row => {
                        dispatch(
                          highlightByID({
                            modelUUID: queryModelUUID,
                            expressID: row.id,
                          })
                        );
                        onCollapse();
                      }}
                    />
                  )) || <TableBody />}
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </BoxColE2E>
  );
}
const MemoContent = memo(Content);

export default function QueryTable() {
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const isActive = useAppSelector(selectIsToolActive(TOOLS.QUERY));

  const isLeftPanelOpen = useAppSelector(selectIsLeftPanelOpen);
  const isRightPanelOpen = useAppSelector(selectIsRightPanelOpen);

  const onCollapse = useMemo(() => () => setIsExpanded(false), [setIsExpanded]);
  const onExpand = useMemo(() => () => setIsExpanded(true), [setIsExpanded]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    setPageHeight(window.innerHeight);
    window.addEventListener(
      "resize",
      () => {
        setPageWidth(window.innerWidth);
        setPageHeight(window.innerHeight);
      },
      true
    );
  }, []);

  return (
    <StyledDrawer
      variant="permanent"
      open={isActive}
      anchor="bottom"
      PaperProps={{
        sx: {
          mb: `${theme.sp.footerHeight}px`,
          ml: isLeftPanelOpen
            ? `${theme.sp.sidepanelWidth + theme.sp.sidebarWidth}px`
            : `${theme.sp.sidebarWidth}px`,
          width:
            isLeftPanelOpen && isRightPanelOpen
              ? `${pageWidth - 2 * theme.sp.sidepanelWidth - theme.sp.sidebarWidth - 2}px`
              : (isRightPanelOpen &&
                  `${pageWidth - theme.sp.sidepanelWidth - theme.sp.sidebarWidth - 2}px`) ||
                (isLeftPanelOpen &&
                  `${pageWidth - theme.sp.sidepanelWidth - 2 * theme.sp.sidebarWidth - 2}px`) ||
                `${pageWidth - 2 * theme.sp.sidebarWidth - 2}px`,

          height: isActive
            ? isExpanded
              ? `${pageHeight - theme.sp.headerHeight - theme.sp.footerHeight + 1}px` // the 1 is to collapse borders
              : "30vh"
            : 0,
          border: isActive ? "1px solid " + theme.palette.subtleGreyBorder : "none",
        },
      }}
    >
      <MemoContent isExpanded={isExpanded} onExpand={onExpand} onCollapse={onCollapse} />
    </StyledDrawer>
  );
}
