import { Box, Tooltip } from "@mui/material";
import theme, { styled } from "app/theme";

export const ToolsContainer = styled(Box)(
  ({
    theme: {
      zIndex: { drawer },
      palette: {
        common: { white },
      },
    },
  }) => ({
    boxSizing: "border-box",
    paddingTop: `${theme.sp.headerHeight + theme.sp.compact2V}px`,
    backgroundColor: white,
    height: "100vh",
    width: "56px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: drawer + 1,
  })
);

export const StyledTooltip = styled(Tooltip)({
  margin: "0 auto",
});
