import { PayloadAction } from "@reduxjs/toolkit";
import { viewerAPI } from "app/common/ViewerAPI";
import { RootState } from "app/state/store";

import { IIfcMangerState } from "../ifcManagerSlice";

export interface ICameraState {
  isOrthoEnabled: boolean;
}

export const initialCameraState: ICameraState = {
  isOrthoEnabled: false,
};

export const setIsOrthoEnabledReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<boolean>
) => {
  state.isOrthoEnabled = payload;
  viewerAPI()?.setIsOrthoEnabled(payload);
};

export const toggleOrthoReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();
  // predictive rather than reactive to make it snappy, camera movement takes a while
  state.isOrthoEnabled = !viewer.isOrthoEnabled();
  viewer.toggleOrtho();
};

export const cameraSelectors = {
  selectIsOrthoEnabled: (state: RootState) => state.ifcManager.isOrthoEnabled,
};

export const cameraReducers = {
  toggleOrtho: toggleOrthoReducer,
};
