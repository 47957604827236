import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Divider, Link, Typography } from "@mui/material";
import NoModelLoaded from "app/components/NoModelLoaded";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxColE2E } from "app/components/common/BoxColE2E";
import { BoxRow } from "app/components/common/BoxRow";
import { Heavy } from "app/components/common/Heavy";
import InlineIcon from "app/components/common/InlineIcon";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  idsSpecSetSelectedRestrictionXPath,
  selectIDSParsingError,
  selectIDSResultItem,
  selectIDSSpecAttr,
  selectIDSSpecSelectedId,
  selectIDSSpecSelectedRestrictionPath,
  selectModelId,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";

import { UsageHint } from "../common";
import { EditableField } from "./EditableField";

export function IDSSectionResultItem() {
  const dispatch = useAppDispatch();

  const restrictionPath = useAppSelector(selectIDSSpecSelectedRestrictionPath);
  const modelId = useAppSelector(selectModelId);
  const specId = useAppSelector(selectIDSSpecSelectedId);

  const idsParsingError = useAppSelector(selectIDSParsingError);
  const resultItem = useAppSelector(selectIDSResultItem(restrictionPath ?? undefined));
  const _validationResults = useAppSelector(
    selectIDSSpecAttr(specId ?? null, "_validationResults")
  );

  console.log("modelId:", modelId);

  if (idsParsingError) {
    return (
      <BoxRow
        sx={{
          paddingTop: theme.sppx.spaciousV,
          paddingLeft: theme.sppx.spaciousH,
          paddingRight: theme.sppx.spaciousH,
          gap: 0,
          height: "100%",
          width: "100%",
          overflowY: "hidden",
          alignItems: "flex-start",
          boxSizing: "border-box",
        }}
      >
        <BoxCol
          sx={{
            gap: theme.sppx.compactV,
            height: "100%",
            flexGrow: 1,
            overflow: "hidden",
            alignItems: "flex-start",
            display: "flex",
          }}
        >
          <BoxRow sx={{ alignItems: "center", width: "100%" }}>
            <HighlightOffIcon sx={{ fill: theme.palette.softFail }} />
            <Typography
              sx={{
                marginLeft: theme.sppx.compactV,
                fontWeight: 500,
                color: "#444",
                width: "10rem",
              }}
            >
              Invalid IDS
            </Typography>
            <Typography>Note: IFC Viewer supports only v1.0.0 of IDS</Typography>
            <Box sx={{ flexGrow: 1 }} />
          </BoxRow>
          <Divider
            orientation="horizontal"
            sx={{ width: "100%", marginBottom: theme.sppx.spaciousV }}
          />
          <BoxCol
            sx={{
              overflowY: "auto",
              width: "100%",
              paddingRight: theme.sppx.spaciousH,
              boxSizing: "border-box",
            }}
          >
            <Typography>
              <b>Error</b>: {idsParsingError.message}
            </Typography>
            <Typography>
              <b>Reason</b>: {idsParsingError.reason}
            </Typography>
            <Typography>
              <b>XPath</b>:{" "}
              <Link
                href="#"
                onClick={() => dispatch(idsSpecSetSelectedRestrictionXPath(idsParsingError.path))}
              >
                {idsParsingError.path}
              </Link>
            </Typography>

            <Typography>
              <b>Affected element</b>:{" "}
            </Typography>

            <BoxRow sx={{ alignItems: "flex-start", display: "flex", width: "100%" }}>
              <EditableField flexGrow label={null} value={idsParsingError.elem ?? ""} />
            </BoxRow>
          </BoxCol>
        </BoxCol>
      </BoxRow>
    );
  }

  if (!_validationResults) {
    return (
      <BoxColE2E sx={{ justifyContent: "center" }}>
        {!modelId ? (
          <NoModelLoaded sx={{ justifyContent: "center" }} />
        ) : (
          <UsageHint>
            <b>Click</b> <InlineIcon icon={PlayArrowIcon} sx={{ color: "#56a642" }}></InlineIcon> to{" "}
            <Heavy>validate</Heavy> specifications against the loaded <Heavy>model</Heavy>
          </UsageHint>
        )}
      </BoxColE2E>
    );
  }
  if (!resultItem) {
    return (
      <BoxColE2E sx={{ justifyContent: "center" }}>
        <UsageHint>
          <b>Click</b> a <Heavy>result item</Heavy> in the sidebar
        </UsageHint>
      </BoxColE2E>
    );
  }

  return (
    <BoxCol
      sx={{
        paddingTop: theme.sppx.spaciousV,
        paddingLeft: theme.sppx.spaciousH,
        paddingRight: theme.sppx.spaciousH,
        gap: 0,
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        alignItems: "flex-start",
      }}
    >
      <BoxRow
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          marginBottom: theme.sppx.compactV,
          marginTop: theme.sppx.compactV,
        }}
      >
        {resultItem &&
          (resultItem?.failedRequirements?.length == 0 ? (
            <BoxRow sx={{ alignItems: "center" }}>
              <CheckCircleOutlineIcon sx={{ fill: theme.palette.softPass }} />
              <Typography
                sx={{
                  marginLeft: theme.sppx.compactV,
                  fontWeight: 500,
                  color: "#444",
                  width: "4rem",
                }}
              >
                Pass
              </Typography>
            </BoxRow>
          ) : (
            <BoxRow sx={{ alignItems: "center" }}>
              <HighlightOffIcon sx={{ fill: theme.palette.softFail }} />
              <Typography
                sx={{
                  marginLeft: theme.sppx.compactV,
                  fontWeight: 500,
                  color: "#444",
                  width: "4rem",
                }}
              >
                Fail
              </Typography>
            </BoxRow>
          ))}
        <Typography sx={{ fontWeight: 600, fontSize: "1.05rem" }}>{resultItem.name}</Typography>
      </BoxRow>
      <Divider
        orientation="horizontal"
        sx={{ width: "100%", marginBottom: theme.sppx.spaciousV }}
      />
      <BoxCol
        sx={{
          overflowY: "auto",
          width: "100%",
          gap: theme.sppx.spaciousV,
          alignItems: "flex-start",
          paddingBottom: theme.sppx.spaciousH,
        }}
      >
        {resultItem.failedRequirements.map((failedRequirement, index) => (
          <BoxCol
            key={index}
            sx={{
              // borderLeft: "2px solid #ddd",
              paddingLeft: "4px",
              gap: theme.sppx.compactV,
            }}
          >
            <BoxRow sx={{ alignItems: "center", gap: theme.sppx.compactH }}>
              <InlineIcon
                icon={FiberManualRecordIcon}
                sx={{
                  fill: theme.palette.softFail,
                  width: `${theme.sppx.compact2H}`,
                  height: `${theme.sppx.compact2H}`,
                }}
              />
              <Typography sx={{ fontWeight: 500 }}>
                {failedRequirement.requirementDescription}
              </Typography>
            </BoxRow>
            <Typography sx={{ marginLeft: `${theme.sp.spaciousH + theme.sp.compactH}px` }}>
              {failedRequirement.reason}
            </Typography>
          </BoxCol>
        ))}
      </BoxCol>
    </BoxCol>
  );
}
