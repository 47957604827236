import { PostproductionRenderer } from "app/components/ifcjs/front";
import { ViewerSingleton } from "app/common/ViewerAPI";

import { _fitModelToFrame } from "./camera";

export const _goToPlan = async (planId: string) => {
  const viewer = ViewerSingleton.getInstance();

  if (viewer._renderer instanceof PostproductionRenderer) {
    // outline edges, thicken walls
    viewer._renderer.postproduction.enabled = true;
  }
  viewer._plans.enabled = true;
  // viewer._plans.goTo(planId, true);

  // their goTo camera implementation has bad hardcoded coordinates
  // /* sane reimplementation of viewer.plans.goTo(planId, true): */
  if (viewer._plans.currentPlan?.id !== planId) {
    // viewer._plans.objects.visible = false;
    //@ts-ignore
    const is_first_time = !viewer._plans._floorPlanViewCached;
    if (viewer._arePlansActive) {
      // first time will be centered below
      if (!is_first_time) {
        // remember 2d camera position for consistency
        //@ts-ignore
        viewer._plans.cacheFloorplanView();
      }
    } else {
      // when exiting go back to this
      //@ts-ignore
      viewer._plans.store3dCameraPosition();
    }
    await viewer._plans.onNavigated.trigger({ id: planId });
    // todo: fork the component to avoid accessing private members
    //@ts-ignore
    await viewer._plans.hidePreviousClippingPlane();
    //@ts-ignore
    viewer._plans.updateCurrentPlan(planId);
    //@ts-ignore
    await viewer._plans.activateCurrentPlan();

    // prevent other clicks from entering this
    if (!viewer._arePlansActive) {
      viewer._arePlansActive = true;
      /*
       *  On camera problems:
       *   - multiple quick clicks can trigger storing weird cameras
       *     => prevent clicks from enterting this scope
       *     !! but chaging viewer.plans.enabled here breaks stuff
       *       => change it at the end and use custom variable here
       *   - async camera motions sometimes trigger very late
       *      => slugish feeling => no animations
       */

      //@ts-ignore
      if (!is_first_time) {
        viewer._camera.controls.reset(false);
      } else {
        viewer._camera.controls.setLookAt(0, viewer._boundingSphereRadius * 1.2, 0, 0, 0, 0, false);
        _fitModelToFrame();
        //@ts-ignore
        viewer._plans.cacheFloorplanView();
      }
    }
  }
  // this updates all plans, but just activateCurrentPlan seems to be enough
  // viewer._edges.update(true);

  viewer._ifcStreamer.culler.needsUpdate = true;
  viewer._renderer.needsUpdate = true;
  viewer._navGizmo.needsUpdate = true;
  // const found = await viewer._classifier?.find({ storeys: [planId] });
  // if (found) {
  //   viewer._hider.set(true, found);
  // }
};

export const _exitPlanView = async () => {
  // problem: the async stuff sometimes takes time,
  // but enabled state is changed at start => weird half states with camera
  /* reimplementation of viewer.plans.exitPlanView(true);: */
  const viewer = ViewerSingleton.getInstance();
  if (!viewer._arePlansActive) return;
  viewer._arePlansActive = false;

  viewer._plans.exitPlanView(true);
  if (viewer._renderer instanceof PostproductionRenderer) {
    viewer._renderer.postproduction.enabled = false;
  }

  // await viewer._plans.onExited.trigger();

  // todo: fork the plans to avoid the ignores
  // @ts-ignore
  // viewer._plans.cacheFloorplanView();

  // viewer._camera.setNavigationMode("Orbit");
  // // @ts-ignore
  // viewer._camera.setProjection(viewer._plans._previousProjection);
  viewer._camera.set("Orbit");
  if (viewer._plans.currentPlan && viewer._plans.currentPlan.plane) {
    viewer._plans.currentPlan.plane.enabled = false;
    viewer._plans.currentPlan.plane.edges.visible = false;
  }
  viewer._plans.currentPlan = null;
  // viewer._camera.controls.setLookAt(
  //   //@ts-ignore
  //   viewer._plans._previousCamera.x,
  //   //@ts-ignore
  //   viewer._plans._previousCamera.y,
  //   //@ts-ignore
  //   viewer._plans._previousCamera.z,
  //   //@ts-ignore
  //   viewer._plans._previousTarget.x,
  //   //@ts-ignore
  //   viewer._plans._previousTarget.y,
  //   //@ts-ignore
  //   viewer._plans._previousTarget.z,
  //   true
  // );
  viewer._plans.enabled = false;
  viewer._ifcStreamer.culler.needsUpdate = true;
};
