export const perfMeasure = (name?: string) => {
  const state = {
    startTime: performance.now(),
    lastTime: performance.now(),
    enabled: true,
  };
  return {
    stop: () => {
      if (!state.enabled) return;
      const endTime = performance.now();
      console.log(`${name} took ${endTime - state.startTime} ms`);
    },
    lap: (lapName?: string) => {
      if (!state.enabled) return;
      const endTime = performance.now();
      console.log(`${lapName ?? `lap::${name}`} took ${endTime - state.lastTime} ms`);
      state.lastTime = endTime;
    },
    disable: () => {
      state.enabled = false;
    },
  };
};
