// import { EdgesPlane } from "openbim-components";
import { SimplePlane } from "app/components/ifcjs/core";
import { ViewerSingleton } from "app/common/ViewerAPI";
import { Section, SectionId } from "app/common/types";
import { invalidateAutosave } from "app/utils/autoSaveTrigger";
import { appendRandomUniqueName, resetRandomUniqueNames } from "app/utils/randomUniqueName";
import { Vector3 } from "three";

export const _turnOnSectionTool = () => {
  const viewer = ViewerSingleton.getInstance();

  //@ts-ignore manually enable, builtin one is broken
  viewer._clipper._enabled = true;
  // viewer._clipper.updateEdges();
};

export const _turnOffSectionTool = () => {
  const viewer = ViewerSingleton.getInstance();

  //@ts-ignore manual enabled, builtin one is broken
  viewer._clipper._enabled = false;
  viewer._clipper.visible = false;
};

export const _isSectionToolEnabled = () => {
  const viewer = ViewerSingleton.getInstance();

  return viewer._clipper.enabled;
};

const _listenForChanges = (createdPlane: SimplePlane, sectionID: SectionId) => {
  // const viewer = ViewerSingleton.getInstance();

  //@ts-ignore
  console.log("onCreated:", createdPlane._plane);
  createdPlane.onDraggingEnded.add(async () => {
    //@ts-ignore
    console.log("onDraggingEnded:", createdPlane._plane, sectionID);
    invalidateAutosave();
    /* warning: reverse updates disabled in favour of on-demand access 
       due to Sentry issue "s._dispatch is not a function" */
    // viewer._dispatch(
    //   updateSectionPosition({
    //     id: sectionID,
    //     origin: _getSectionOrigin(sectionID),
    //     normal: _getSectionNormal(sectionID),
    //   })
    // );
  });
};

export const _createSection = (): SectionId => {
  const viewer = ViewerSingleton.getInstance();
  viewer._clipper.create(viewer._world);

  const sectionID = viewer._customClippingPlanesNextID;
  viewer._customClippingPlanesNextID += 1;

  const createdPlane = viewer._clipper.list.at(-1);
  if (!createdPlane) {
    console.error("unexpected empty created clipping Plane");
    return 0;
  }

  viewer._customClippingPlanes[sectionID] = createdPlane;
  _listenForChanges(createdPlane, sectionID);
  viewer._ifcStreamer.culler.needsUpdate = true;

  return sectionID;
};

export const _getSectionOrigin = (sectionId: SectionId) => {
  const viewer = ViewerSingleton.getInstance();
  const edgesPlane = viewer._customClippingPlanes[sectionId];

  //@ts-ignore
  const plane = edgesPlane.three as THREE.Plane;
  // const point = plane.normal.clone().multiplyScalar(-plane.constant);
  const point = new Vector3();
  plane.projectPoint(edgesPlane.origin, point);
  return [point.x, point.y, point.z];
};

export const _getSectionNormal = (sectionId: SectionId) => {
  const viewer = ViewerSingleton.getInstance();
  const edgesPlane = viewer._customClippingPlanes[sectionId];
  const point = edgesPlane.three.normal;
  return [point.x, point.y, point.z];
};

export const _getLastSection = () => {
  const viewer = ViewerSingleton.getInstance();
  return viewer._clipper.list.at(-1);
};

export const _deleteSection = (section: SectionId) => {
  const viewer = ViewerSingleton.getInstance();

  //@ts-ignore
  const prevEnabled = viewer._clipper._enabled;
  //@ts-ignore deleted sections don't update if not enabled
  viewer._clipper._enabled = true;
  viewer._clipper.delete(viewer._world, viewer._customClippingPlanes[section]);
  // viewer._clipper.updateEdges();
  viewer._ifcStreamer.culler.needsUpdate = true;

  //@ts-ignore
  viewer._clipper._enabled = prevEnabled;
};

export const _setSectionVisibility = (section: SectionId, value: boolean) => {
  const viewer = ViewerSingleton.getInstance();
  if (!viewer._customClippingPlanes[section]) return;
  if (viewer._customClippingPlanes[section].visible != value) {
    viewer._customClippingPlanes[section].visible = value;
    // viewer._clipper.updateEdges();
    viewer._ifcStreamer.culler.needsUpdate = true;
  }
};

export const _setSectionEnabled = (section: SectionId, value = true) => {
  const viewer = ViewerSingleton.getInstance();
  if (!viewer._customClippingPlanes[section]) return;

  if (viewer._customClippingPlanes[section].enabled != value) {
    viewer._customClippingPlanes[section].enabled = value;
    // viewer._clipper.updateEdges();
    viewer._ifcStreamer.culler.needsUpdate = true;
  }
};

export function _deleteCustomSection(plane: SimplePlane) {
  const viewer = ViewerSingleton.getInstance();
  viewer._clipper.delete(viewer._world, plane);
}

export function _restoreSections(sections: Section[]) {
  const viewer = ViewerSingleton.getInstance();

  // delete all current sections
  //@ts-ignore
  const prevEnabled = viewer._clipper._enabled;
  //@ts-ignore deleted sections don't update if not enabled
  viewer._clipper._enabled = true;
  for (const sectionId in viewer._customClippingPlanes) {
    viewer._clipper.delete(viewer._world, viewer._customClippingPlanes[sectionId]);
  }

  viewer._customClippingPlanes = {};
  resetRandomUniqueNames();

  sections.forEach(section => {
    viewer._customClippingPlanesNextID = Math.max(
      viewer._customClippingPlanesNextID,
      section.id + 1
    );

    const createdSection = viewer._clipper.createFromNormalAndCoplanarPoint(
      viewer._world,
      new Vector3(section.normal[0], section.normal[1], section.normal[2]),
      new Vector3(section.origin[0], section.origin[1], section.origin[2])
    );

    viewer._customClippingPlanes[section.id] = createdSection;
    _listenForChanges(createdSection, section.id);
    createdSection.update();

    appendRandomUniqueName(section.name);

    _setSectionVisibility(section.id, section.isEditable);
    _setSectionEnabled(section.id, section.isEditable || section.isVisibilityLocked);
  });

  // viewer._clipper.updateEdges();
  viewer._edges.update(true);
  //@ts-ignore
  viewer._clipper._enabled = prevEnabled;
}

// // needed because object tree visibility affects the planes/edges
// export async function _updateClippingPlanes() {
//   const { _clipper: clipper } = ViewerSingleton.getInstance();

//   console.log("_updateClippingPlanes");
//   const start = Date.now();
//   await clipper.updateEdges(true);
//   const end = Date.now();
//   console.log(`_updateClippingPlanes execution time: ${end - start} ms`);
// }
