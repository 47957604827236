import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { Box, IconButton, List, ListItemText, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  selectDimensions,
  selectIsMeasurementCreationActive,
  startMeasurementCreation,
  cancelMeasurementCreation,
  setIsDimensionHighlighted,
  removeDimension,
  setDimensionVisibility,
  unhighlightAllDimensions,
  setIsDimensionTempHighlighted,
} from "app/state/slices/ifcManagerSlice";
import { Dimension } from "app/common/types";
import {
  AnimatedAddIcon,
  AddIconInline,
  CancelIconInline,
  VisibilityIconInline,
  ToolsSidebarHeader,
  ToolsSidebarBody,
  ToolsListItem,
  UsageHint,
} from "app/components/ToolsSideBar/common";
import { Heavy } from "app/components/common/Heavy";
import { RichSpan } from "app/components/common/RichSpan";
import { ReactMouseEvent } from "app/common/types";
import theme from "app/theme";

import { DIMENSION_TOOL_HELP_CONTENT } from "../../help/HelpTexts";

export default function DimensionToolSidebar() {
  const dispatch = useAppDispatch();

  const isMeasurementCreationActive = useAppSelector(selectIsMeasurementCreationActive);
  const savedDimensions = useAppSelector(selectDimensions);

  function toggleMeasurementCreationHandler() {
    if (isMeasurementCreationActive) {
      dispatch(cancelMeasurementCreation());
    } else {
      dispatch(startMeasurementCreation());
    }
  }

  const toggleHighlightHandler = (dimension: Dimension) => {
    dispatch(setIsDimensionHighlighted({ id: dimension.id, value: !dimension.isHighlighted }));
  };

  const tempHighlightHandler = (dimension: Dimension, value: boolean) => {
    dispatch(setIsDimensionTempHighlighted({ id: dimension.id, value }));
  };

  const toogleVisibilityHandler = (e: ReactMouseEvent, dimension: Dimension) => {
    dispatch(setDimensionVisibility({ id: dimension.id, value: !dimension.isVisible }));
    e.stopPropagation();
  };

  const removeHandler = (e: ReactMouseEvent, dimension: Dimension) => {
    dispatch(removeDimension({ id: dimension.id }));
    e.stopPropagation();
  };

  const usageHint = () =>
    (isMeasurementCreationActive && (
      <>
        <RichSpan key="dsisMeasurementCreationActiveuh1">
          <b>Double click</b> on any <Heavy>point</Heavy> to <b>create</b> one.{" "}
        </RichSpan>
        <br />
        <RichSpan key="dsisMeasurementCreationActiveuh2">
          <b>Click</b> the <CancelIconInline /> button to <b>cancel</b>.
        </RichSpan>
      </>
    )) ||
    (savedDimensions.length == 0 && (
      <>
        <RichSpan key="dsnodimsuh1">No measurements yet. </RichSpan>
        <br />
        <RichSpan key="dsnodimsuh2">
          <b>Click</b> the <AddIconInline /> button to <Heavy>create</Heavy> one.
        </RichSpan>
      </>
    )) ||
    (savedDimensions.length > 0 && (
      <>
        <RichSpan key="dsaresaveduh1">
          <b>Click</b> on any item to <Heavy>highlight</Heavy> it.
        </RichSpan>{" "}
        <br />
        <RichSpan key="dsaresaveduh2">
          <b>Click</b> the <VisibilityIconInline /> to toggle <Heavy>visibility</Heavy>.
        </RichSpan>
      </>
    ));

  return (
    <Box
      sx={{ minHeight: "100%", overflowY: "hidden", display: "flex", flexDirection: "column" }} // provide clicking space for hide all
    >
      <ToolsSidebarHeader title="Measurements" HelpContent={DIMENSION_TOOL_HELP_CONTENT}>
        <Tooltip title="Toggle Measurement Creation" placement="right">
          <IconButton onClick={() => toggleMeasurementCreationHandler()} sx={{ padding: "4px" }}>
            <AnimatedAddIcon className={isMeasurementCreationActive ? "CreationEnabled" : ""} />
          </IconButton>
        </Tooltip>
      </ToolsSidebarHeader>

      <ToolsSidebarBody
        onClick={e => e.target === e.currentTarget && dispatch(unhighlightAllDimensions())}
      >
        <UsageHint>{usageHint()}</UsageHint>

        <List component="nav" aria-label="main measurements" sx={{ ...theme.vPad.compact2 }}>
          {savedDimensions.map(dimension => (
            <ToolsListItem
              key={dimension.id}
              selected={dimension.isHighlighted}
              onClick={() => toggleHighlightHandler(dimension)}
              onPointerEnter={() => tempHighlightHandler(dimension, true)}
              onPointerLeave={() => tempHighlightHandler(dimension, false)}
            >
              <Tooltip placement="top" arrow title="Toggle visibility">
                <IconButton
                  color={dimension.isVisible ? "primary" : "default"}
                  onClick={e => toogleVisibilityHandler(e, dimension)}
                  variant="inline"
                >
                  {dimension.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Tooltip>
              <ListItemText
                sx={{ paddingLeft: "12px" }} // decrowd the eye icon
                primary={<>{dimension.length}m</>}
              />
              <Tooltip placement="top" arrow title="Delete">
                <IconButton variant="inline" onClick={e => removeHandler(e, dimension)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </ToolsListItem>
          ))}
        </List>
      </ToolsSidebarBody>
    </Box>
  );
}
