import React from "react";
import { Button } from "@mui/material";

const FeedbackButton = () => (
  <Button
    variant="contained"
    size="small"
    onClick={() => {
      //@ts-ignore
      window.appzi && window.appzi.openWidget(import.meta.env.REACT_APP_SURVEY_ID);
    }}
  >
    Give feedback
  </Button>
);

export default FeedbackButton;
