import { IDSRestrictionPath, IDSValue } from "app/common/idsSpec";
import theme from "app/theme";

export const nodeHeight = 6 * theme.sp.compactH;

export type IDSSpecTreeNode = {
  name: string;
  id: string;
  children?: IDSSpecTreeNode[];
  metadata: {
    type: "specification" | "restriction" | "resultItem";
    restrictionPath?: IDSRestrictionPath;
    hasPassedValidation?: boolean;
    resultsLength?: number;
  };
};

export type IDSRestrictionListItem = {
  name: string;
  cardinality?: string;
  restrictionPath?: IDSRestrictionPath;
  id: number;
};

export const presentIDSValue = (value: IDSValue | undefined) => {
  if (!value) return undefined;
  if (typeof value == "string") return value;
  if (value.simpleValue) return String(value.simpleValue);
  if (!value["xs:restriction"]) return undefined;
  const restriction = value["xs:restriction"];
  if (restriction["xs:pattern"]) return String(restriction["xs:pattern"].$_value);
  if (restriction["xs:length"]) return String(restriction["xs:length"].$_value);
  if (restriction["xs:enumeration"]) {
    let merged = restriction["xs:enumeration"]
      .slice(0, 10)
      .map(x => x?.$_value)
      .join(", ");
    if (merged.length > 25) {
      merged = merged.slice(0, 25) + "...";
    }
    return `{${merged}}`;
  }

  let stringAcc = "";
  if (restriction["xs:minLength"]) {
    stringAcc += `[${restriction["xs:minLength"].$_value}`;
  }
  if (restriction["xs:minInclusive"]) {
    stringAcc += `[${restriction["xs:minInclusive"].$_value}`;
  }
  if (restriction["xs:minExclusive"]) {
    stringAcc += `(${restriction["xs:minExclusive"].$_value}`;
  }
  stringAcc += ", ";
  if (restriction["xs:maxLength"]) {
    stringAcc += `${restriction["xs:maxLength"].$_value}]`;
  }
  if (restriction["xs:maxInclusive"]) {
    stringAcc += `${restriction["xs:maxInclusive"].$_value}]`;
  }
  if (restriction["xs:maxExclusive"]) {
    stringAcc += `${restriction["xs:maxExclusive"].$_value})`;
  }

  return stringAcc;
};
