import { useDebouncedValue } from "app/utils/useDebouncedValue";
import { ReactElement } from "react";
import useResizeObserver from "use-resize-observer";

const style = {
  flex: 1,
  width: "100%",
  height: "100%",
  minHeight: 0,
  minWidth: 0,
};

export const FillFlexParent = (props: {
  children: (dimensions: { width: number; height: number }) => ReactElement;
}) => {
  const { ref, width, height } = useResizeObserver();

  // defer changes to a future frame to not trigger ResizeObserver errors
  const debouncedWidth = useDebouncedValue(width, 100) ?? 0;
  const debouncedHeight = useDebouncedValue(height, 100) ?? 0;

  return (
    <div style={style} ref={ref}>
      {props.children({ width: debouncedWidth, height: debouncedHeight })}
    </div>
  );
};

export default FillFlexParent;
