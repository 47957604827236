import { useAppDispatch, useAppSelector } from "app/state/hooks";
import BorderAll from "@mui/icons-material/BorderAll";
import CenterFocusStrong from "@mui/icons-material/CenterFocusStrong";
import Feedback from "@mui/icons-material/Feedback";
import Home from "@mui/icons-material/Home";
import { Button } from "@mui/material";
import {
  toggleTool,
  toggleOrtho,
  selectIsOrthoEnabled,
  selectIsNavGizmoEnabled,
  selectFileName,
  selectIsFirstLoad,
  selectAccountIsLoggedIn,
} from "app/state/slices/ifcManagerSlice";
import { viewerAPI } from "app/common/ViewerAPI";
import { TOOLS } from "app/common/types";

import * as S from "./PropertiesSidebar.style";

export const PropertiesSidebarAnchor = () => {
  const dispatch = useAppDispatch();
  const viewer = viewerAPI();
  const isNavGizmoEnabled = useAppSelector(selectIsNavGizmoEnabled);
  const isOrthoEnabled = useAppSelector(selectIsOrthoEnabled);

  const fileName = useAppSelector(selectFileName);
  const isFirstLoad = useAppSelector(selectIsFirstLoad);
  const accountIsLoggedIn =
    useAppSelector(selectAccountIsLoggedIn) || localStorage.getItem("isLoggedIn") == "true";

  return (
    <>
      {(accountIsLoggedIn || fileName || !isFirstLoad) && (
        <>
          <S.GizmoButton
            Icon={Home}
            tootltipText="Fully Reset View"
            disabled={!isNavGizmoEnabled}
            action={() => {
              viewer?.cameraGoHome();
              viewer?.fitModelToFrame();
            }}
            sx={{
              top: "168px",
              left: "-70px",
            }}
            isHighlighted={false}
          />
          <S.GizmoButton
            Icon={CenterFocusStrong}
            tootltipText="Recenter Object"
            disabled={false}
            action={() => viewer?.fitModelToFrame()}
            sx={{
              top: "178px",
              left: "-119px",
            }}
            isHighlighted={false}
          />
          <S.GizmoButton
            Icon={BorderAll}
            tootltipText="Toggle Orthographic Mode"
            disabled={!isNavGizmoEnabled}
            action={() => dispatch(toggleOrtho())}
            sx={{
              top: "168px",
              left: "-168px",
            }}
            isHighlighted={isOrthoEnabled}
          />
        </>
      )}

      <Button
        color="primary"
        aria-label="feedback"
        sx={{
          // this is a one-of-a-kind snowflake customization
          // at least it scales correctly
          borderRadius: "25px",
          border: "3px solid #0d47a1",
          background: "#ffffff", // f69f01 fea61a
          position: "absolute",
          bottom: "21px",
          left: "-158px",
          color: "#013788", // 003d96
          padding: "8px 12px 8px 15px",
          textTransform: "none",
          fontSize: "18px",
          fontWeight: "600",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;",
          "&:hover": {
            background: "#eff5ff",
            color: "#013788",
          },
        }}
        onClick={() => {
          dispatch(toggleTool({ selectedTool: TOOLS.HELP }));
          //@ts-ignore
          window.appzi && window.appzi.openWidget(import.meta.env.REACT_APP_SURVEY_ID);
        }}
      >
        Feedback <Feedback sx={{ fontSize: "24px", color: "#fea61a", paddingLeft: "5px" }} />
      </Button>

      {/* <Fab
        color="primary"
        aria-label="help"
        component="label"
        sx={{
          position: "absolute",
          bottom: "182px",
          left: "-70px",
        }}
      >
        <LocalIFCPicker onChange={onFileLoad} />
        <FileOpenOutlined
          color="secondary"
          sx={{
            // make it look as big as help
            width: "45%",
            height: "45%",
          }}
        />
      </Fab> */}
    </>
  );
};
