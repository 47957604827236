import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormGroupProps,
  FormLabel,
} from "@mui/material";
import theme from "app/theme";

export function CleanMultiChoice({
  label,
  choices,
  onChange,
  disabled,
  ...props
}: Omit<FormGroupProps, "onChange"> & {
  disabled?: boolean;
  label: string;
  options?: string[];
  choices?: { [label: string]: boolean };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <FormControl
      sx={{
        padding: theme.sppx.gapTiny,
      }}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row {...props}>
        {(choices &&
          Object.entries(choices).map(([label, isChecked]) => (
            <FormControlLabel
              key={label}
              control={
                <Checkbox
                  disabled={disabled}
                  name={label}
                  checked={isChecked}
                  onChange={e => {
                    if (onChange) {
                      const ev = {
                        ...e,
                        target: {
                          ...e?.target,
                          name: label,
                          value: e.target.checked,
                        },
                      };
                      //@ts-ignore
                      onChange(ev as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />
              }
              label={label}
            />
          ))) ||
          "No options"}
      </FormGroup>
    </FormControl>
  );
}
