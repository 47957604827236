import { PayloadAction } from "@reduxjs/toolkit";
import { viewerAPI } from "app/common/ViewerAPI";
import { Section, SectionId } from "app/common/types";
import { IIfcMangerState } from "app/state/slices/ifcManagerSlice";
import { randomUniqueName } from "app/utils/randomUniqueName";
import { RootState } from "app/state/store";

import { invalidateCloudStateReducer } from "./common";

export interface ISectionsState {
  isSectionCreationActive: boolean;
  customSections: Array<Section>;
  nextSectionNumber: number;
}

export const initialSectionsState = {
  isSectionCreationActive: false,
  customSections: [],
  nextSectionNumber: 1,
};

export const createSectionReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();

  if (!viewer.isSectionToolEnabled()) {
    viewer.turnOnSectionTool();
  }

  const sectionId = viewer.createSection();
  const createdSection = viewer.getLastSection();
  if (createdSection) {
    state.isSectionCreationActive = false;
  }

  viewer.pickHoveredItemProps(false);

  state.customSections.push({
    id: sectionId,
    isEditable: true,
    isVisibilityLocked: true,
    // note: left this here in case animal names don't work out
    // name: `Section ${state.nextSectionNumber}`,
    name: randomUniqueName(),
    origin: viewer.getSectionOrigin(sectionId),
    normal: viewer.getSectionNormal(sectionId),
  });

  state.nextSectionNumber += 1;
  state.isSectionCreationActive = false;
  viewer.turnOffTargetCursor();
  setIsSectionEditableReducer(state, {
    type: "createSectionReducer/setIsSectionEditableReducer",
    payload: {
      id: sectionId,
      value: true,
    },
  });
  invalidateCloudStateReducer(state);
};

export const updateSectionPositionReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    id: SectionId;
    origin: number[];
    normal: number[];
  }>
) => {
  for (const section of state.customSections) {
    if (section.id == payload.id) {
      section.origin = payload.origin;
      section.normal = payload.normal;
    }
  }
  invalidateCloudStateReducer(state);
};

export const deleteSectionReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    id: SectionId;
  }>
) => {
  const viewer = viewerAPI();
  viewer.deleteSection(payload.id);
  state.customSections = state.customSections.filter(x => x.id != payload.id);
  invalidateCloudStateReducer(state);
};

export const startSectionCreationReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();
  viewer.turnOnSectionTool();
  viewer.turnOnTargetCursor();

  state.isSectionCreationActive = true;
  hideAllSectionsReducer(state);
};

export const cancelSectionCreationReducer = (state: IIfcMangerState) => {
  const viewer = viewerAPI();
  viewer.turnOffSectionTool();
  viewer.turnOffTargetCursor();

  state.isSectionCreationActive = false;
};

export const setIsSectionEditableReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    id: SectionId;
    value: boolean;
  }>
): void => {
  const viewer = viewerAPI();

  // make it behave as a mutex with only one editable section
  for (const section of state.customSections) {
    if (section.id == payload.id) {
      section.isEditable = payload.value;
      // set editor gizmo
      viewer.setSectionVisibility(section.id, payload.value);
      // auto visible
      viewer.setSectionEnabled(section.id, section.isVisibilityLocked || payload.value);
    } else {
      section.isEditable = false;
      // disable editor gizmo
      viewer.setSectionVisibility(section.id, false);
      // leave the other sections visibile of locked
      viewer.setSectionEnabled(section.id, section.isVisibilityLocked);
    }
  }
  invalidateCloudStateReducer(state);
};
export const setSectionNameReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    id: SectionId;
    value: string;
  }>
): void => {
  for (const section of state.customSections) {
    if (section.id == payload.id) {
      section.name = payload.value;
    }
  }
};

export const hideAllSectionsReducer = (state: IIfcMangerState): void => {
  const viewer = viewerAPI();

  for (const section of state.customSections) {
    section.isEditable = false;
    viewer.setSectionVisibility(section.id, false);
    viewer.setSectionEnabled(section.id, section.isVisibilityLocked);
  }
  invalidateCloudStateReducer(state);
};

export const setIsSectionVisibilityLockedReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    id: SectionId;
    value: boolean;
  }>
) => {
  const viewer = viewerAPI();

  for (const section of state.customSections) {
    if (section.id == payload.id) {
      section.isVisibilityLocked = payload.value;

      // special case for auto off
      if (!payload.value && section.isEditable) {
        section.isEditable = false;
      }
    }

    viewer.setSectionEnabled(section.id, section.isEditable || section.isVisibilityLocked);
    viewer.setSectionVisibility(section.id, section.isEditable);
  }
  invalidateCloudStateReducer(state);
};

export const sectionsSelectors = {
  selectIsSectionCreationActive: (state: RootState) => state.ifcManager.isSectionCreationActive,
  selectCustomSections: (state: RootState) => state.ifcManager.customSections,
};

export const sectionsReducers = {
  startSectionCreation: startSectionCreationReducer,
  createSection: createSectionReducer,
  cancelSectionCreation: cancelSectionCreationReducer,
  deleteSection: deleteSectionReducer,
  setSectionName: setSectionNameReducer,
  setIsSectionVisibilityLocked: setIsSectionVisibilityLockedReducer,
  setIsSectionEditable: setIsSectionEditableReducer,
  hideAllSections: hideAllSectionsReducer,
  updateSectionPosition: updateSectionPositionReducer,
};
