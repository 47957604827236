import dayjs from "dayjs";
import { Box, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { BoxCol } from "app/components/common/BoxCol";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { idsSetInfo, selectIDSInfo } from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { DatePicker } from "@mui/x-date-pickers";
import { BoxRow } from "app/components/common/BoxRow";
import { emptyStringTo } from "app/utils/objectMeta";

import { EditableField } from "./EditableField";

export function IDSSectionFileInfo() {
  const dispatch = useAppDispatch();

  const idsInfo = useAppSelector(selectIDSInfo);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "1",
        flexGrow: "1",
        padding: theme.pad.spacious,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        position: "relative",
        minHeight: 0,
        overflow: "hidden",
        gap: theme.sppx.spaciousH,
      }}
      onClick={(e: any) => {
        // unhighlight EditableField
        if (!e?.target?.className?.includes?.("nput")) {
          //@ts-ignore
          document?.activeElement?.blur?.();
        }
      }}
    >
      <BoxCol sx={{ gap: theme.sppx.compactV, height: "100%", flexGrow: 1, overflow: "auto" }}>
        <EditableField
          isRequired
          label="Title"
          value={idsInfo?.title ?? ""}
          onChange={e =>
            dispatch(idsSetInfo({ ...idsInfo, title: emptyStringTo(e.target.value, undefined) }))
          }
        />
        <EditableField
          label="Author Email"
          validate={value => /^[^@]+@[^.]+\..+$/.test(value ?? "")}
          value={idsInfo?.author ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, author: e.target.value }))}
        />
        <EditableField
          label="Copyright"
          value={idsInfo?.copyright ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, copyright: e.target.value }))}
        />
        <EditableField
          label="Version"
          value={idsInfo?.version ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, version: e.target.value }))}
        />
      </BoxCol>
      <BoxCol sx={{ gap: theme.sppx.compactV, height: "100%", flexGrow: 1, overflow: "auto" }}>
        <BoxRow sx={{ width: "100%" }}>
          <EditableField
            flexGrow
            label="Date"
            value={idsInfo?.date ?? ""}
            onChange={e => dispatch(idsSetInfo({ ...idsInfo, date: e.target.value }))}
          />
          <BoxCol sx={{ justifyContent: "center" }}>
            <DatePicker
              sx={{
                marginRight: theme.sppx.compactH,
                "& .MuiInputBase-root": {
                  height: "44px",
                  width: "44px",
                },
                "& .MuiInputBase-input": {
                  display: "none",
                },
                "& fieldset": {
                  display: "none",
                },
              }}
              //@ts-ignore
              value={dayjs(idsInfo?.date ?? "")}
              onChange={newValue =>
                dispatch(
                  idsSetInfo({
                    ...idsInfo,
                    //@ts-ignore
                    date: (newValue && newValue.format("YYYY-MM-DD")) || "",
                  })
                )
              }
            />
          </BoxCol>
          <BoxCol sx={{ justifyContent: "center" }}>
            <IconButton
              onClick={() =>
                dispatch(
                  idsSetInfo({ ...idsInfo, date: new Date().toISOString().substring(0, 10) })
                )
              }
            >
              <RefreshIcon />
            </IconButton>
          </BoxCol>
        </BoxRow>
        <EditableField
          label="Milestone"
          value={idsInfo?.milestone ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, milestone: e.target.value }))}
        />
        <EditableField
          label="Purpose"
          value={idsInfo?.milestone ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, purpose: e.target.value }))}
        />
        <EditableField
          label="Description"
          value={idsInfo?.description ?? ""}
          onChange={e => dispatch(idsSetInfo({ ...idsInfo, description: e.target.value }))}
        />
      </BoxCol>
    </Box>
  );
}
// const MemoIDSSectionFileInfo = memo(IDSSectionFileInfo);

export default IDSSectionFileInfo;
