import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IDSFacet, IFCDataTypes, IFCEntities } from "app/common/idsSpec";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxColE2E } from "app/components/common/BoxColE2E";
import { BoxRow } from "app/components/common/BoxRow";
import { CleanTextField } from "app/components/common/CleanTextField";
import { Heavy } from "app/components/common/Heavy";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  idsReqsUpdateAttr,
  idsSpecChangeRestrictionFacet,
  selectIDSReqsAttr,
  selectIDSSpecSelectedRestrictionPath,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { emptyStringTo } from "app/utils/objectMeta";

import { UsageHint } from "../common";
import { RestrictionField } from "./RestrictionField";

export function IDSSectionRequirement() {
  const dispatch = useAppDispatch();

  const restrictionPath = useAppSelector(selectIDSSpecSelectedRestrictionPath);
  const reqsDescription = useAppSelector(
    selectIDSReqsAttr(restrictionPath?.specId ?? null, "$_description")
  );

  if (!restrictionPath) {
    return (
      <BoxColE2E>
        <UsageHint>
          <b>Click</b> a restriction to <Heavy>edit</Heavy> it
        </UsageHint>
      </BoxColE2E>
    );
  }

  const onResctrictionFacetChange = (value: IDSFacet) => {
    dispatch(idsSpecChangeRestrictionFacet({ restrictionPath, newFacet: value as IDSFacet }));
  };

  return (
    <BoxCol
      sx={{
        gap: theme.sppx.spaciousV,
        height: "100%",
        flexGrow: 1,
      }}
    >
      {restrictionPath?.section == "requirements" && (
        <BoxRow sx={{ alignItems: "center" }}>
          <CleanTextField
            label="Requirements Description"
            value={reqsDescription}
            sx={{ minWidth: "20rem" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                idsReqsUpdateAttr({
                  _id: restrictionPath.specId,
                  $_description: emptyStringTo(event.target.value, null),
                })
              );
            }}
          />
        </BoxRow>
      )}
      {restrictionPath?.section == "requirements" && <Divider />}
      <BoxRow sx={{ alignItems: "center" }}>
        <FormControl>
          <FormLabel>Restriction Type:</FormLabel>
          <RadioGroup
            row
            value={restrictionPath.facet ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onResctrictionFacetChange((event.target as HTMLInputElement).value as IDSFacet)
            }
          >
            <FormControlLabel value="entity" control={<Radio />} label="is Entity" />
            <FormControlLabel value="partOf" control={<Radio />} label="part Of" />
            <FormControlLabel
              value="classification"
              control={<Radio />}
              label="has Classification"
            />
            <FormControlLabel value="attribute" control={<Radio />} label="has Attribute" />
            <FormControlLabel value="property" control={<Radio />} label="has Property" />
            <FormControlLabel value="material" control={<Radio />} label="has Material" />
          </RadioGroup>
        </FormControl>
      </BoxRow>
      <BoxCol
        sx={{ overflowY: "auto", gap: theme.sppx.spaciousV, paddingTop: theme.sppx.compactV }}
      >
        {restrictionPath?.section == "requirements" &&
          restrictionPath?.facet &&
          ["attribute", "property", "material", "classification"].includes(
            restrictionPath?.facet
          ) && (
            <RestrictionField
              label="Cardinality"
              variant="dropdown"
              enumValues={[
                ["", "empty"],
                ["required", "Required"],
                ["prohibited", "Prohibited"],
                ["optional", "Optional"],
              ]}
              restrictionPath={{
                ...restrictionPath,
                attribute: "$_cardinality",
                valueType: "plain",
              }}
            />
          )}
        {restrictionPath?.section == "requirements" &&
          restrictionPath?.facet &&
          ["partOf"].includes(restrictionPath?.facet) && (
            <RestrictionField
              label="Cardinality"
              variant="dropdown"
              enumValues={[
                ["", "empty"],
                ["required", "Required"],
                ["prohibited", "Prohibited"],
              ]}
              restrictionPath={{
                ...restrictionPath,
                attribute: "$_cardinality",
                valueType: "plain",
              }}
            />
          )}
        {restrictionPath?.facet == "property" && (
          <RestrictionField
            label="Property Set"
            restrictionPath={{ ...restrictionPath, attribute: "propertySet" }}
          />
        )}
        {restrictionPath?.facet == "property" && (
          <RestrictionField
            isRequired
            label="Base Name"
            restrictionPath={{ ...restrictionPath, attribute: "baseName" }}
          />
        )}
        {restrictionPath?.facet && ["entity", "attribute"].includes(restrictionPath?.facet) && (
          <RestrictionField
            isRequired
            label="Name"
            restrictionPath={{ ...restrictionPath, attribute: "name" }}
            options={
              restrictionPath?.facet == "entity" ? IFCEntities.map(x => ({ label: x })) : undefined
            }
          />
        )}
        {restrictionPath?.facet == "property" && (
          <RestrictionField
            label="Data Type"
            restrictionPath={{ ...restrictionPath, attribute: "$_dataType", valueType: "plain" }}
            options={IFCDataTypes.map(x => ({ label: x }))}
          />
        )}
        {restrictionPath?.facet &&
          ["attribute", "classification", "property", "material"].includes(
            restrictionPath?.facet
          ) && (
            <RestrictionField
              label="Value"
              restrictionPath={{ ...restrictionPath, attribute: "value" }}
            />
          )}
        {restrictionPath?.facet && ["classification"].includes(restrictionPath?.facet) && (
          <RestrictionField
            label="System"
            restrictionPath={{ ...restrictionPath, attribute: "system" }}
          />
        )}
        {restrictionPath?.facet == "entity" && (
          <RestrictionField
            label="Predifined Type"
            restrictionPath={{ ...restrictionPath, attribute: "predefinedType" }}
          />
        )}
        {restrictionPath?.facet == "partOf" && (
          <RestrictionField
            isRequired
            label="Entity Name"
            restrictionPath={{ ...restrictionPath, attributeWrapper: "entity", attribute: "name" }}
            options={IFCEntities.map(x => ({ label: x }))}
          />
        )}
        {restrictionPath?.facet == "partOf" && (
          <RestrictionField
            label="Entity Predifined Type"
            restrictionPath={{
              ...restrictionPath,
              attributeWrapper: "entity",
              attribute: "predefinedType",
            }}
          />
        )}
        {restrictionPath?.facet == "partOf" && (
          <RestrictionField
            label="Relation"
            restrictionPath={{ ...restrictionPath, attribute: "$_relation", valueType: "plain" }}
            options={[
              "IFCRELAGGREGATES",
              "IFCRELASSIGNSTOGROUP",
              "IFCRELCONTAINEDINSPATIALSTRUCTURE",
              "IFCRELNESTS",
              "IFCRELVOIDSELEMENT IFCRELFILLSELEMENT",
            ].map(x => ({ label: x }))}
          />
        )}
        {restrictionPath?.section == "requirements" &&
          restrictionPath?.facet &&
          ["property", "classification", "material"].includes(restrictionPath?.facet) && (
            <RestrictionField
              label="URI"
              restrictionPath={{ ...restrictionPath, attribute: "$_uri", valueType: "plain" }}
            />
          )}
        {restrictionPath?.section == "requirements" && (
          <RestrictionField
            label="Instructions"
            restrictionPath={{
              ...restrictionPath,
              attribute: "$_instructions",
              valueType: "plain",
            }}
          />
        )}
      </BoxCol>
    </BoxCol>
  );
}
