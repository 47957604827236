// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from "flatbuffers";

export class CivilCurve {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): CivilCurve {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsCivilCurve(bb: flatbuffers.ByteBuffer, obj?: CivilCurve): CivilCurve {
    return (obj || new CivilCurve()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsCivilCurve(bb: flatbuffers.ByteBuffer, obj?: CivilCurve): CivilCurve {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new CivilCurve()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  points(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
  }

  pointsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  pointsArray(): Float32Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Float32Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset)
        )
      : null;
  }

  data(): string | null;
  data(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  data(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  static startCivilCurve(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addPoints(builder: flatbuffers.Builder, pointsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, pointsOffset, 0);
  }

  static createPointsVector(
    builder: flatbuffers.Builder,
    data: number[] | Float32Array
  ): flatbuffers.Offset;
  /**
   * @deprecated This Uint8Array overload will be removed in the future.
   */
  static createPointsVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array
  ): flatbuffers.Offset;
  static createPointsVector(
    builder: flatbuffers.Builder,
    data: number[] | Float32Array | Uint8Array
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addFloat32(data[i]!);
    }
    return builder.endVector();
  }

  static startPointsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addData(builder: flatbuffers.Builder, dataOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, dataOffset, 0);
  }

  static endCivilCurve(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createCivilCurve(
    builder: flatbuffers.Builder,
    pointsOffset: flatbuffers.Offset,
    dataOffset: flatbuffers.Offset
  ): flatbuffers.Offset {
    CivilCurve.startCivilCurve(builder);
    CivilCurve.addPoints(builder, pointsOffset);
    CivilCurve.addData(builder, dataOffset);
    return CivilCurve.endCivilCurve(builder);
  }
}
