import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

export function CleanDropdown({
  label,
  sx,
  values,
  ...props
}: SelectProps & { values?: string[][] }) {
  return (
    <FormControl sx={{ boxSizing: "border-box", minWidth: "max-content", ...sx }}>
      <InputLabel>{label}</InputLabel>
      <Select
        sx={{
          minWidth: "8.5rem",
        }}
        {...props}
        label={label}
        autoWidth
      >
        {values &&
          values.map(([value, name], index) => (
            <MenuItem key={index} value={value}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
