import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InlineIcon from "app/components/common/InlineIcon";
import theme from "app/theme";

export function LinkArrow({ active }: { active?: boolean }) {
  return (
    <InlineIcon
      active={active ?? true}
      icon={OpenInNewIcon}
      sx={{
        marginLeft: theme.sppx.compactH,
        verticalAlign: "middle",
        height: "0.85em",
      }}
    />
  );
}
