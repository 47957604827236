import React from "react";
import { List, Box, Typography } from "@mui/material";
import { PropertyValue, QuantityValue } from "app/common/ViewerAPI/properties";
import theme from "app/theme/theme";
import { RichSpan } from "app/components/common/RichSpan";

import Properties from "./Properties";
import BaseQuantities from "./BaseQuantities";

interface Props {
  propertyValues?: PropertyValue[];
  quantityValues?: QuantityValue[];
}

const PropertiesTabPanel: React.FC<Props> = ({ propertyValues, quantityValues }) => {
  return (
    <Box sx={{ flexShrink: 1, flexGrow: 1, overflowY: "auto" }}>
      <List sx={{ overflowX: "auto" }}>
        {(propertyValues && <Properties pset={propertyValues} />) ||
          (quantityValues && <BaseQuantities qset={quantityValues} />) || (
            <Typography variant="body1" sx={{ ...theme.pad.spacious2, textAlign: "center" }}>
              <RichSpan key="nopropsorqts">
                No properties or quantities <br /> in the currently selected set.
              </RichSpan>
            </Typography>
          )}
      </List>
    </Box>
  );
};

export default PropertiesTabPanel;
