// Thanks to the advice here https://github.com/zalo/TetSim/commit/9696c2e1cd6354fb9bd40dbd299c58f4de0341dd

function clientWaitAsync(
  gl: WebGL2RenderingContext,
  sync: WebGLSync,
  flags: any,
  intervalMilliseconds: number
) {
  return new Promise<void>((resolve, reject) => {
    function test() {
      const res = gl.clientWaitSync(sync, flags, 1);
      if (res === gl.WAIT_FAILED) {
        reject();
        return;
      }
      if (res === gl.TIMEOUT_EXPIRED) {
        setTimeout(test, intervalMilliseconds);
        return;
      }
      resolve();
    }

    test();
  });
}

function getBufferSubDataAsync(
  gl: WebGL2RenderingContext,
  target: number,
  buffer: WebGLBuffer,
  srcByteOffset: number,
  dstBuffer: ArrayBufferView,
  dstOffset?: number,
  length?: number
) {
  const sync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0)!;
  gl.flush();
  gl.clientWaitSync(sync, 0, 0);
  // await clientWaitAsync(gl, sync, 0, 10);
  gl.deleteSync(sync);
  gl.bindBuffer(target, buffer);
  gl.getBufferSubData(target, srcByteOffset, dstBuffer, dstOffset, length);
  gl.bindBuffer(target, null);
}

export function readPixelsAsync(
  gl: WebGL2RenderingContext,
  x: number,
  y: number,
  w: number,
  h: number,
  format: any,
  type: any,
  dest: ArrayBufferView
) {
  // gl.bindBuffer(gl.PIXEL_PACK_BUFFER, glBuffer);
  // gl.bufferData(gl.PIXEL_PACK_BUFFER, dest.byteLength, gl.STREAM_READ);
  gl.readPixels(x, y, w, h, format, type, dest, 0);
  // gl.bindBuffer(gl.PIXEL_PACK_BUFFER, null);

  // getBufferSubDataAsync(gl, gl.PIXEL_PACK_BUFFER, glBuffer, 0, dest);
  return dest;
}
