import { Typography, TypographyProps } from "@mui/material";

/*
  Allows combinging raw text with other elements such that they don't break translation extentions
  all raw text children will be auto-wrapped in spans

  Note: Translators will move stuff out of order (particularly inline icons to the end)
  Can be mitigared by more wrapping, but settting a unique <RichSpan key="uniquename"> works quite well

  Bibliography:
  * https://github.com/facebook/react/issues/17256
  * https://github.com/facebook/react/issues/11538
  * https://github.com/solidjs/solid/issues/1451
  * https://martijnhols.nl/gists/everything-about-google-translate-crashing-react#surrounding-textnodes-with-spans
  * https://issues.chromium.org/issues/41407169
  * https://codesandbox.io/p/sandbox/distracted-framework-dh2yyg
*/
export const RichSpan = (props: TypographyProps) => {
  const { children, sx, ...otherProps } = props;

  let wrappedChildren = children;

  if (Array.isArray(children)) {
    wrappedChildren = children.map((child, i) =>
      typeof child == "string" ? <span key={i}>{child}</span> : child
    );
  } else if (typeof children == "string") {
    wrappedChildren = <span>{children}</span>;
  }

  return (
    <Typography sx={{ ...sx }} component="span" {...otherProps}>
      {wrappedChildren}
    </Typography>
  );
};
