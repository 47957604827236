import { Box, InputBase, InputBaseProps } from "@mui/material";

export const LocalIDSPicker = (props: InputBaseProps) => (
  <Box sx={{ display: "none" }}>
    <InputBase
      id="fileInput"
      inputProps={{ accept: ".ids,.IDS,.xml,.XML", multiple: false }}
      type="file"
      // this forces onChange to trigger on every file selection to cover same name reup:
      value={""}
      {...props}
    />
  </Box>
);
