import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from "@mui/material";
import {
  dismissModal,
  selectSelectedModal,
  setSelectedModal,
  selectPreviousModal,
  selectSelectedPolicy,
} from "app/state/slices/ifcManagerSlice";
import { MODALS } from "app/common/types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "app/state/hooks";

import { policies } from "./generator/results";

export default function PolicyModal() {
  const selectedModal = useAppSelector(selectSelectedModal);
  const previousModal = useAppSelector(selectPreviousModal);
  const selectedPolicy = useAppSelector(selectSelectedPolicy);
  if (selectedPolicy == null) return <></>;

  const policy = policies[selectedPolicy];
  const isOpen = MODALS.POLICY === selectedModal;
  const dispatch = useAppDispatch();

  return (
    <Dialog sx={{ p: 2 }} onClose={() => dispatch(dismissModal())} open={isOpen}>
      <DialogTitle>
        <b>{policy.title}</b>
        <IconButton
          onClick={() => dispatch(setSelectedModal(previousModal))}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ textAlign: "justify" }}>
        <Typography component="div">{policy.content}</Typography>
      </DialogContent>
    </Dialog>
  );
}
