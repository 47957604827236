import { useEffect, useMemo, useState } from "react";
import { Box, Autocomplete, TextField, Divider, Typography } from "@mui/material";
import { IProperty } from "app/common/types";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  selectLastSelectedPropertyName,
  selectPropertiesExpressID,
  selectpropertiesModelUUID,
  setLastSelectedPropertyName,
  setPropertiesItemName,
  setPropertiesSelectedSetName,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { viewerAPI } from "app/common/ViewerAPI";
import { ifcTypeNames } from "app/common/webIfcReverseSchema";

import PropertiesTabPanel from "./PropertiesTabPanel";
import { BoxDynCol } from "../common/BoxDynCol";

function PropertiesTabs() {
  const dispatch = useAppDispatch();
  const propertiesExpressID = useAppSelector(selectPropertiesExpressID);
  const propertiesModelUUID = useAppSelector(selectpropertiesModelUUID);
  const [propertyValues, setPropertyValues] = useState<IProperty[]>([]);
  const [quantityValues, setQuantityValues] = useState<IProperty[]>([]);
  const [typeName, setTypeName] = useState(undefined as string | undefined);
  const [noGeometry, setNoGeometry] = useState(false);

  const options = useMemo(
    () =>
      [...propertyValues, ...quantityValues].map((prop, index) => ({
        label: prop?.Name?.value ?? "Unnamed",
        key: prop.expressID,
        value: index,
        propertyValues: prop.propertyValues,
        quantityValues: prop.quantityValues,
      })),
    [propertyValues, quantityValues]
  );

  const lastSelectedPropertyName = useAppSelector(selectLastSelectedPropertyName);
  // prefer to keep last selected PSET, to easily compare properties of similar items
  const [tabSelected, setTabSelected] = useState<number>(
    options.find(x => x.label == lastSelectedPropertyName)?.value ?? 0
  );

  async function loadProperties() {
    if (propertiesModelUUID === null || propertiesExpressID === null) {
      setTabSelected(0);
      setPropertyValues([]);
      setQuantityValues([]);
      return;
    }

    const { type, noGeometry, name, quantityValues, propertyValues } =
      await viewerAPI().getIfcProperties();

    setPropertyValues(propertyValues);
    setQuantityValues(quantityValues);

    let currentPSETindex = 0;
    let newPSETindex = 0;
    for (const prop of propertyValues) {
      if ((prop?.Name?.value ?? "Unnamed") == lastSelectedPropertyName)
        newPSETindex = currentPSETindex;
      currentPSETindex++;
    }
    for (const prop of quantityValues) {
      if ((prop?.Name?.value ?? "Unnamed") == lastSelectedPropertyName)
        newPSETindex = currentPSETindex;
      currentPSETindex++;
    }
    setTabSelected(newPSETindex);
    if (newPSETindex == 0) {
      const newLabel = propertyValues?.[0]?.Name?.value ?? "Unnamed";
      dispatch(setPropertiesSelectedSetName(String(newLabel)));
      dispatch(setLastSelectedPropertyName(String(newLabel)));
    }

    dispatch(setPropertiesItemName(name));
    setTypeName(type != null ? ifcTypeNames[type] : undefined);
    setNoGeometry(noGeometry ?? false);
  }

  useEffect(() => {
    loadProperties();

    return () => {
      setPropertyValues([]);
      setQuantityValues([]);
      dispatch(setPropertiesItemName(null));
    };
  }, [propertiesModelUUID, propertiesExpressID]);

  const selectedOption = options.find(x => x.value == tabSelected);

  if (!options || options.length == 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          overflowX: "hidden",
          overflowY: "auto",
          textWrap: "wrap",
          textAlign: "center",
        }}
      >
        {propertiesExpressID != null && (
          <Typography variant="body1">
            No properties <span>{noGeometry && "and geometry"}</span> for the selected{" "}
            <b>{typeName || "Unknown IFC type"}</b>
          </Typography>
        )}
        {propertiesExpressID == null && <Typography variant="body1">No item selected</Typography>}
      </Box>
    );
  }

  return (
    <BoxDynCol>
      <Autocomplete
        onChange={(_, option) => {
          if (option === null) return;
          setTabSelected(option.value);
          dispatch(setPropertiesSelectedSetName(String(option.label)));
          dispatch(setLastSelectedPropertyName(String(option.label)));
        }}
        value={options[tabSelected]}
        // placeholder="Type to search.."
        options={options}
        sx={{ padding: `0 ${theme.sppx.compact2V}` }}
        renderInput={params => <TextField {...params} />}
      />
      <Divider sx={{ marginTop: theme.sppx.spaciousV }} />
      <PropertiesTabPanel
        propertyValues={selectedOption?.propertyValues}
        quantityValues={selectedOption?.quantityValues}
      />
    </BoxDynCol>
  );
}

export default PropertiesTabs;
