import { PreloadedState, combineReducers, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import checkboxesSlice from "./slices/checkboxesSlice";
import ifcManagerSlice from "./slices/ifcManagerSlice";
import telemetrySlice from "./slices/telemetrySlice";

const reducers = {
  checkboxes: checkboxesSlice,
  ifcManager: ifcManagerSlice,
  telemetry: telemetrySlice,
};

// sentry: do not log anything
const ignoredActions: { [key: string]: boolean } = {
  "ifcManager/updateLoginDetails": true,
};

// sentry: fully log it
const whitelistedActions: { [key: string]: boolean } = {
  "ifcManager/setTool": true,
  "ifcManager/toggleTool": true,
  "ifcManager/setSelectedModal": true,
};

const sentryReduxEnhancer = () =>
  Sentry.createReduxEnhancer({
    stateTransformer: state => {
      // Transform the state to remove sensitive information
      const transformedState = {
        ...state,
        ifcManager: {
          ...state.ifcManager,
          loginName: null,
          loginEmail: null,
          loginCode: null,
          loginPassword: null,
          loginPasswordConfirmation: null,
          loginNewPassword: null,
          loginNewPasswordConfirmation: null,
          name: null,
          firstName: null,
          lastName: null,
          customSections: null,
          plans: null,
          selectedPlan: null,
          dimensions: null,
          savedModels: null,
          fileName: null,
          lastSelectedPropertyName: null,
          modelQueryTypes: null,
          modelQuerySelectedTypes: null,
          modelQuerySchema: null,
          modelQueryExportedAttributes: null,
          modelQueryResult: null,
          /* useful for debugging, don't directly leak personal data */
          // modelID: null,
          // propertiesID: null,
          // authUserID: null,
        },
      };

      return transformedState;
    },
    actionTransformer: action => {
      if (!action.type) return null;
      if (ignoredActions[action.type] === true) return null;
      if (whitelistedActions[action.type] === true) return action;

      // default: remove all payload data, capture just the name
      return {
        ...action,
        payload: null,
      };
    },
  });

export const store = configureStore({
  reducer: reducers,
  enhancers: defaultEnhancers =>
    // todo: on acceptedTelemetry reset store to load it without refresh?
    localStorage.getItem("acceptedTelemetry") === "true"
      ? defaultEnhancers.concat(sentryReduxEnhancer())
      : defaultEnhancers,
});

const rootReducer = combineReducers(reducers);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
