export type NullOptional<T> = undefined extends T ? T | null : T;

export type NullOptionals<T> = {
  [K in keyof T]: NullOptional<T[K]>;
};

export const emptyStringTo = (x: any, value?: any) => {
  return typeof x == "string" && x.length == 0 ? value : x;
};

export const sentinelMap = (x: any, value_from: any, value_to: any) => {
  return x === value_from ? value_to : x;
};

/*
 *  Reccursively access obj via the fields of struct path
 *  e.g. objectRecAccess(
 *    {a: {b: {c: "hello"}}},
 *    {a: {b: {c: 1      }}}
 *  ) == "hello"
 */
export function objectRecAccess(obj: any, structPath: any): any {
  const keys = Object.keys(structPath ?? {});
  if (keys.length == 0) return obj;
  if (!obj || !Object.keys(obj).includes(keys[0])) return undefined;

  return objectRecAccess(obj[keys[0]], structPath[keys[0]]);
}

export const objectHas = (obj: object, target: object) => {
  for (const key in target) {
    //@ts-ignore
    if (target[key] !== undefined) {
      //@ts-ignore
      if (obj[key] !== target[key]) return false;
    }
  }
  return true;
};

export const objectMergeInto = (obj: object, objInto: object) => {
  for (const key in obj) {
    //@ts-ignore
    if (obj[key] !== undefined) {
      //@ts-ignore
      objInto[key] = obj[key];
    }
  }
};

export const objectSetKeyPath = (obj: any, keys: string[], value: any) => {
  let ref = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i == keys.length - 1) {
      ref[key] = value;
      return obj;
    }

    if (ref[key] === undefined || Object.keys(ref[key]).length == 0) {
      ref[key] = {};
    }

    ref = ref[key];
  }

  return obj;
};

/*
  takes a nested strucutre and returns a flat list of values
  alongside their structured path + a flat string path
  {a: {b: {c: "hello"}}} => [{pathString: "a/b/c", pathStruct: {a: {b: {c: 1}}}, value: "hello"}] 
*/
export type ExtractedValue = {
  pathString: string;
  pathStruct: any;
  value: string | number | boolean | null | undefined;
};
export const objectExtractValues = (
  obj: object,
  pathReducer?: (acc: string, keyName: string) => string
) => {
  const values: ExtractedValue[] = [];
  _objectExtractValues(obj, values, [], pathReducer ?? ((acc, keyName) => `${acc}/${keyName}`));
  return values;
};

const _objectExtractValues = (
  obj: any,
  resultAcc: ExtractedValue[],
  keysAcc: string[],
  pathReducer: (acc: string, keyName: string) => string
) => {
  if (obj === undefined) {
    return;
  }

  if (obj === null) {
    resultAcc.push({
      pathString: keysAcc.reduce(pathReducer),
      pathStruct: objectSetKeyPath({}, keysAcc, 1),
      value: null,
    });
    return;
  }

  if (["string", "number", "boolean"].includes(typeof obj)) {
    resultAcc.push({
      pathString: keysAcc.reduce(pathReducer),
      pathStruct: objectSetKeyPath({}, keysAcc, 1),
      value: obj,
    });
    return;
  }

  const keys = Object.keys(obj);
  if (keys.length > 0) {
    for (const key of keys) {
      _objectExtractValues(obj[key], resultAcc, [...keysAcc, key], pathReducer);
    }
    return;
  }

  resultAcc.push({
    pathString: keysAcc.reduce(pathReducer),
    pathStruct: objectSetKeyPath({}, keysAcc, 1),
    value: undefined,
  });
};

export const objectDeepMerge = (initial: object, objs: object[]) => {
  for (const obj of objs) {
    for (const key in obj) {
      //@ts-ignore
      if (obj[key] === undefined) continue;

      //@ts-ignore
      if (obj[key] === null) {
        //@ts-ignore
        if (initial[key] === undefined) initial[key] = null;
        continue;
      }

      //@ts-ignore
      if (Object.keys(obj[key]).length > 0) {
        //@ts-ignore
        if (initial[key] == null || initial[key] == undefined) {
          //@ts-ignore
          initial[key] = {};
        }
        //@ts-ignore
        objectDeepMerge(
          //@ts-ignore
          initial[key],
          //@ts-ignore
          Object.keys(obj[key]).map(x => ({ [x]: obj[key][x] }))
        );
      } else {
        //@ts-ignore
        initial[key] = obj[key];
      }
    }
  }
  return initial;
};
