export default function convertIfcString(ifcString: string | null | undefined) {
  /**
   * Function which converts IFC strings to unicode
   * IfcStrings contain backslashes, so it is important
   * to pass String.raw`your_word` input (in console context)
   */

  if (ifcString == null) return null;

  const ifcUnicodeRegEx = /\\X2\\(.*?)\\X0\\/giu;
  let resultString = ifcString;
  let match = ifcUnicodeRegEx.exec(ifcString);

  while (match) {
    const utf16Number = parseInt(match[1], 16);
    const unicodeChar = String.fromCharCode(utf16Number);
    resultString = resultString.replace(match[0], unicodeChar);
    match = ifcUnicodeRegEx.exec(ifcString);
  }

  // note: these weird strings come from web-ifc from "$" values in .ifc file
  // which mean "undefined"
  if (resultString.includes("\b")) {
    //\b\u0006\b\t\u0005
    return "undefined";
  }

  return resultString;
}
