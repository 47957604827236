/* this file has been generated based on web-ifc 0.0.46 schema */
export const ifcTypeNames: Record<string, string> = {
  "950732822": "IFCURIREFERENCE",
  "4075327185": "IFCTIME",
  "1209108979": "IFCTEMPERATURERATEOFCHANGEMEASURE",
  "3457685358": "IFCSOUNDPRESSURELEVELMEASURE",
  "4157543285": "IFCSOUNDPOWERLEVELMEASURE",
  "2798247006": "IFCPROPERTYSETDEFINITIONSET",
  "1790229001": "IFCPOSITIVEINTEGER",
  "525895558": "IFCNONNEGATIVELENGTHMEASURE",
  "1774176899": "IFCLINEINDEX",
  "1275358634": "IFCLANGUAGEID",
  "2541165894": "IFCDURATION",
  "3701338814": "IFCDAYINWEEKNUMBER",
  "2195413836": "IFCDATETIME",
  "937566702": "IFCDATE",
  "1683019596": "IFCCARDINALPOINTREFERENCE",
  "2314439260": "IFCBINARY",
  "1500781891": "IFCAREADENSITYMEASURE",
  "3683503648": "IFCARCINDEX",
  "4065007721": "IFCYEARNUMBER",
  "1718600412": "IFCWARPINGMOMENTMEASURE",
  "51269191": "IFCWARPINGCONSTANTMEASURE",
  "2593997549": "IFCVOLUMETRICFLOWRATEMEASURE",
  "3458127941": "IFCVOLUMEMEASURE",
  "3345633955": "IFCVAPORPERMEABILITYMEASURE",
  "1278329552": "IFCTORQUEMEASURE",
  "2591213694": "IFCTIMESTAMP",
  "2726807636": "IFCTIMEMEASURE",
  "743184107": "IFCTHERMODYNAMICTEMPERATUREMEASURE",
  "2016195849": "IFCTHERMALTRANSMITTANCEMEASURE",
  "857959152": "IFCTHERMALRESISTANCEMEASURE",
  "2281867870": "IFCTHERMALEXPANSIONCOEFFICIENTMEASURE",
  "2645777649": "IFCTHERMALCONDUCTIVITYMEASURE",
  "232962298": "IFCTHERMALADMITTANCEMEASURE",
  "296282323": "IFCTEXTTRANSFORMATION",
  "603696268": "IFCTEXTFONTNAME",
  "3490877962": "IFCTEXTDECORATION",
  "1460886941": "IFCTEXTALIGNMENT",
  "2801250643": "IFCTEXT",
  "58845555": "IFCTEMPERATUREGRADIENTMEASURE",
  "361837227": "IFCSPECULARROUGHNESS",
  "2757832317": "IFCSPECULAREXPONENT",
  "3477203348": "IFCSPECIFICHEATCAPACITYMEASURE",
  "993287707": "IFCSOUNDPRESSUREMEASURE",
  "846465480": "IFCSOUNDPOWERMEASURE",
  "3471399674": "IFCSOLIDANGLEMEASURE",
  "408310005": "IFCSHEARMODULUSMEASURE",
  "2190458107": "IFCSECTIONALAREAINTEGRALMEASURE",
  "3467162246": "IFCSECTIONMODULUSMEASURE",
  "2766185779": "IFCSECONDINMINUTE",
  "3211557302": "IFCROTATIONALSTIFFNESSMEASURE",
  "1755127002": "IFCROTATIONALMASSMEASURE",
  "2133746277": "IFCROTATIONALFREQUENCYMEASURE",
  "200335297": "IFCREAL",
  "96294661": "IFCRATIOMEASURE",
  "3972513137": "IFCRADIOACTIVITYMEASURE",
  "3665567075": "IFCPRESSUREMEASURE",
  "2169031380": "IFCPRESENTABLETEXT",
  "1364037233": "IFCPOWERMEASURE",
  "1245737093": "IFCPOSITIVERATIOMEASURE",
  "3054510233": "IFCPOSITIVEPLANEANGLEMEASURE",
  "2815919920": "IFCPOSITIVELENGTHMEASURE",
  "4042175685": "IFCPLANEANGLEMEASURE",
  "2642773653": "IFCPLANARFORCEMEASURE",
  "2260317790": "IFCPARAMETERVALUE",
  "929793134": "IFCPHMEASURE",
  "2395907400": "IFCNUMERICMEASURE",
  "2095195183": "IFCNORMALISEDRATIOMEASURE",
  "765770214": "IFCMONTHINYEARNUMBER",
  "2615040989": "IFCMONETARYMEASURE",
  "3114022597": "IFCMOMENTOFINERTIAMEASURE",
  "1648970520": "IFCMOLECULARWEIGHTMEASURE",
  "3177669450": "IFCMOISTUREDIFFUSIVITYMEASURE",
  "1753493141": "IFCMODULUSOFSUBGRADEREACTIONMEASURE",
  "1052454078": "IFCMODULUSOFROTATIONALSUBGRADEREACTIONMEASURE",
  "2173214787": "IFCMODULUSOFLINEARSUBGRADEREACTIONMEASURE",
  "3341486342": "IFCMODULUSOFELASTICITYMEASURE",
  "102610177": "IFCMINUTEINHOUR",
  "3531705166": "IFCMASSPERLENGTHMEASURE",
  "3124614049": "IFCMASSMEASURE",
  "4017473158": "IFCMASSFLOWRATEMEASURE",
  "1477762836": "IFCMASSDENSITYMEASURE",
  "2486716878": "IFCMAGNETICFLUXMEASURE",
  "286949696": "IFCMAGNETICFLUXDENSITYMEASURE",
  "151039812": "IFCLUMINOUSINTENSITYMEASURE",
  "2755797622": "IFCLUMINOUSINTENSITYDISTRIBUTIONMEASURE",
  "2095003142": "IFCLUMINOUSFLUXMEASURE",
  "503418787": "IFCLOGICAL",
  "3086160713": "IFCLINEARVELOCITYMEASURE",
  "1307019551": "IFCLINEARSTIFFNESSMEASURE",
  "2128979029": "IFCLINEARMOMENTMEASURE",
  "191860431": "IFCLINEARFORCEMEASURE",
  "1243674935": "IFCLENGTHMEASURE",
  "3258342251": "IFCLABEL",
  "2054016361": "IFCKINEMATICVISCOSITYMEASURE",
  "3192672207": "IFCISOTHERMALMOISTURECAPACITYMEASURE",
  "3686016028": "IFCIONCONCENTRATIONMEASURE",
  "3809634241": "IFCINTEGERCOUNTRATEMEASURE",
  "1939436016": "IFCINTEGER",
  "2679005408": "IFCINDUCTANCEMEASURE",
  "3358199106": "IFCILLUMINANCEMEASURE",
  "983778844": "IFCIDENTIFIER",
  "2589826445": "IFCHOURINDAY",
  "1158859006": "IFCHEATINGVALUEMEASURE",
  "3113092358": "IFCHEATFLUXDENSITYMEASURE",
  "3064340077": "IFCGLOBALLYUNIQUEID",
  "3044325142": "IFCFREQUENCYMEASURE",
  "1361398929": "IFCFORCEMEASURE",
  "2590844177": "IFCFONTWEIGHT",
  "2715512545": "IFCFONTVARIANT",
  "1102727119": "IFCFONTSTYLE",
  "2078135608": "IFCENERGYMEASURE",
  "2506197118": "IFCELECTRICVOLTAGEMEASURE",
  "2951915441": "IFCELECTRICRESISTANCEMEASURE",
  "3790457270": "IFCELECTRICCURRENTMEASURE",
  "2093906313": "IFCELECTRICCONDUCTANCEMEASURE",
  "3818826038": "IFCELECTRICCHARGEMEASURE",
  "1827137117": "IFCELECTRICCAPACITANCEMEASURE",
  "69416015": "IFCDYNAMICVISCOSITYMEASURE",
  "524656162": "IFCDOSEEQUIVALENTMEASURE",
  "4134073009": "IFCDIMENSIONCOUNT",
  "1514641115": "IFCDESCRIPTIVEMEASURE",
  "300323983": "IFCDAYLIGHTSAVINGHOUR",
  "86635668": "IFCDAYINMONTHNUMBER",
  "94842927": "IFCCURVATUREMEASURE",
  "1778710042": "IFCCOUNTMEASURE",
  "3238673880": "IFCCONTEXTDEPENDENTMEASURE",
  "3812528620": "IFCCOMPOUNDPLANEANGLEMEASURE",
  "2991860651": "IFCCOMPLEXNUMBER",
  "1867003952": "IFCBOXALIGNMENT",
  "2735952531": "IFCBOOLEAN",
  "2650437152": "IFCAREAMEASURE",
  "632304761": "IFCANGULARVELOCITYMEASURE",
  "360377573": "IFCAMOUNTOFSUBSTANCEMEASURE",
  "4182062534": "IFCACCELERATIONMEASURE",
  "3699917729": "IFCABSORBEDDOSEMEASURE",
  "1971632696": "IFCGEOSLICE",
  "2680139844": "IFCGEOMODEL",
  "24726584": "IFCELECTRICFLOWTREATMENTDEVICE",
  "3693000487": "IFCDISTRIBUTIONBOARD",
  "3460952963": "IFCCONVEYORSEGMENT",
  "3999819293": "IFCCAISSONFOUNDATION",
  "3314249567": "IFCBOREHOLE",
  "4196446775": "IFCBEARING",
  "325726236": "IFCALIGNMENT",
  "3425753595": "IFCTRACKELEMENT",
  "991950508": "IFCSIGNAL",
  "3798194928": "IFCREINFORCEDSOIL",
  "3290496277": "IFCRAIL",
  "1383356374": "IFCPAVEMENT",
  "2182337498": "IFCNAVIGATIONELEMENT",
  "234836483": "IFCMOORINGDEVICE",
  "2078563270": "IFCMOBILETELECOMMUNICATIONSAPPLIANCE",
  "1638804497": "IFCLIQUIDTERMINAL",
  "1154579445": "IFCLINEARPOSITIONINGELEMENT",
  "2696325953": "IFCKERB",
  "2713699986": "IFCGEOTECHNICALASSEMBLY",
  "2142170206": "IFCELECTRICFLOWTREATMENTDEVICETYPE",
  "3376911765": "IFCEARTHWORKSFILL",
  "1077100507": "IFCEARTHWORKSELEMENT",
  "3071239417": "IFCEARTHWORKSCUT",
  "479945903": "IFCDISTRIBUTIONBOARDTYPE",
  "3426335179": "IFCDEEPFOUNDATION",
  "1502416096": "IFCCOURSE",
  "2940368186": "IFCCONVEYORSEGMENTTYPE",
  "3203706013": "IFCCAISSONFOUNDATIONTYPE",
  "3862327254": "IFCBUILTSYSTEM",
  "1876633798": "IFCBUILTELEMENT",
  "963979645": "IFCBRIDGEPART",
  "644574406": "IFCBRIDGE",
  "3649138523": "IFCBEARINGTYPE",
  "1662888072": "IFCALIGNMENTVERTICAL",
  "317615605": "IFCALIGNMENTSEGMENT",
  "1545765605": "IFCALIGNMENTHORIZONTAL",
  "4266260250": "IFCALIGNMENTCANT",
  "3956297820": "IFCVIBRATIONDAMPERTYPE",
  "1530820697": "IFCVIBRATIONDAMPER",
  "840318589": "IFCVEHICLE",
  "1953115116": "IFCTRANSPORTATIONDEVICE",
  "618700268": "IFCTRACKELEMENTTYPE",
  "2281632017": "IFCTENDONCONDUITTYPE",
  "3663046924": "IFCTENDONCONDUIT",
  "42703149": "IFCSINESPIRAL",
  "1894708472": "IFCSIGNALTYPE",
  "3599934289": "IFCSIGNTYPE",
  "33720170": "IFCSIGN",
  "1027922057": "IFCSEVENTHORDERPOLYNOMIALSPIRAL",
  "544395925": "IFCSEGMENTEDREFERENCECURVE",
  "3649235739": "IFCSECONDORDERPOLYNOMIALSPIRAL",
  "550521510": "IFCROADPART",
  "146592293": "IFCROAD",
  "3818125796": "IFCRELADHERESTOELEMENT",
  "4021432810": "IFCREFERENT",
  "1891881377": "IFCRAILWAYPART",
  "3992365140": "IFCRAILWAY",
  "1763565496": "IFCRAILTYPE",
  "1946335990": "IFCPOSITIONINGELEMENT",
  "514975943": "IFCPAVEMENTTYPE",
  "506776471": "IFCNAVIGATIONELEMENTTYPE",
  "710110818": "IFCMOORINGDEVICETYPE",
  "1950438474": "IFCMOBILETELECOMMUNICATIONSAPPLIANCETYPE",
  "976884017": "IFCMARINEPART",
  "525669439": "IFCMARINEFACILITY",
  "1770583370": "IFCLIQUIDTERMINALTYPE",
  "2176059722": "IFCLINEARELEMENT",
  "679976338": "IFCKERBTYPE",
  "3948183225": "IFCIMPACTPROTECTIONDEVICETYPE",
  "2568555532": "IFCIMPACTPROTECTIONDEVICE",
  "2898700619": "IFCGRADIENTCURVE",
  "1594536857": "IFCGEOTECHNICALSTRATUM",
  "4230923436": "IFCGEOTECHNICALELEMENT",
  "4228831410": "IFCFACILITYPARTCOMMON",
  "1310830890": "IFCFACILITYPART",
  "24185140": "IFCFACILITY",
  "4234616927": "IFCDIRECTRIXDERIVEDREFERENCESWEPTAREASOLID",
  "1306400036": "IFCDEEPFOUNDATIONTYPE",
  "4189326743": "IFCCOURSETYPE",
  "2000195564": "IFCCOSINESPIRAL",
  "3497074424": "IFCCLOTHOID",
  "1626504194": "IFCBUILTELEMENTTYPE",
  "3651464721": "IFCVEHICLETYPE",
  "1229763772": "IFCTRIANGULATEDIRREGULARNETWORK",
  "3665877780": "IFCTRANSPORTATIONDEVICETYPE",
  "782932809": "IFCTHIRDORDERPOLYNOMIALSPIRAL",
  "2735484536": "IFCSPIRAL",
  "1356537516": "IFCSECTIONEDSURFACE",
  "1290935644": "IFCSECTIONEDSOLIDHORIZONTAL",
  "1862484736": "IFCSECTIONEDSOLID",
  "1441486842": "IFCRELPOSITIONS",
  "1033248425": "IFCRELASSOCIATESPROFILEDEF",
  "3381221214": "IFCPOLYNOMIALCURVE",
  "2485787929": "IFCOFFSETCURVEBYDISTANCES",
  "590820931": "IFCOFFSETCURVE",
  "3465909080": "IFCINDEXEDPOLYGONALTEXTUREMAP",
  "593015953": "IFCDIRECTRIXCURVESWEPTAREASOLID",
  "4212018352": "IFCCURVESEGMENT",
  "3425423356": "IFCAXIS2PLACEMENTLINEAR",
  "823603102": "IFCSEGMENT",
  "2165702409": "IFCPOINTBYDISTANCEEXPRESSION",
  "182550632": "IFCOPENCROSSPROFILEDEF",
  "388784114": "IFCLINEARPLACEMENT",
  "536804194": "IFCALIGNMENTHORIZONTALSEGMENT",
  "3752311538": "IFCALIGNMENTCANTSEGMENT",
  "1010789467": "IFCTEXTURECOORDINATEINDICESWITHVOIDS",
  "222769930": "IFCTEXTURECOORDINATEINDICES",
  "2691318326": "IFCQUANTITYNUMBER",
  "3633395639": "IFCALIGNMENTVERTICALSEGMENT",
  "2879124712": "IFCALIGNMENTPARAMETERSEGMENT",
  "25142252": "IFCCONTROLLER",
  "3087945054": "IFCALARM",
  "4288193352": "IFCACTUATOR",
  "630975310": "IFCUNITARYCONTROLELEMENT",
  "4086658281": "IFCSENSOR",
  "2295281155": "IFCPROTECTIVEDEVICETRIPPINGUNIT",
  "182646315": "IFCFLOWINSTRUMENT",
  "1426591983": "IFCFIRESUPPRESSIONTERMINAL",
  "819412036": "IFCFILTER",
  "3415622556": "IFCFAN",
  "1003880860": "IFCELECTRICTIMECONTROL",
  "402227799": "IFCELECTRICMOTOR",
  "264262732": "IFCELECTRICGENERATOR",
  "3310460725": "IFCELECTRICFLOWSTORAGEDEVICE",
  "862014818": "IFCELECTRICDISTRIBUTIONBOARD",
  "1904799276": "IFCELECTRICAPPLIANCE",
  "1360408905": "IFCDUCTSILENCER",
  "3518393246": "IFCDUCTSEGMENT",
  "342316401": "IFCDUCTFITTING",
  "562808652": "IFCDISTRIBUTIONCIRCUIT",
  "4074379575": "IFCDAMPER",
  "3640358203": "IFCCOOLINGTOWER",
  "4136498852": "IFCCOOLEDBEAM",
  "2272882330": "IFCCONDENSER",
  "3571504051": "IFCCOMPRESSOR",
  "3221913625": "IFCCOMMUNICATIONSAPPLIANCE",
  "639361253": "IFCCOIL",
  "3902619387": "IFCCHILLER",
  "4217484030": "IFCCABLESEGMENT",
  "1051757585": "IFCCABLEFITTING",
  "3758799889": "IFCCABLECARRIERSEGMENT",
  "635142910": "IFCCABLECARRIERFITTING",
  "2938176219": "IFCBURNER",
  "32344328": "IFCBOILER",
  "2906023776": "IFCBEAMSTANDARDCASE",
  "277319702": "IFCAUDIOVISUALAPPLIANCE",
  "2056796094": "IFCAIRTOAIRHEATRECOVERY",
  "177149247": "IFCAIRTERMINALBOX",
  "1634111441": "IFCAIRTERMINAL",
  "486154966": "IFCWINDOWSTANDARDCASE",
  "4237592921": "IFCWASTETERMINAL",
  "4156078855": "IFCWALLELEMENTEDCASE",
  "4207607924": "IFCVALVE",
  "4292641817": "IFCUNITARYEQUIPMENT",
  "3179687236": "IFCUNITARYCONTROLELEMENTTYPE",
  "3026737570": "IFCTUBEBUNDLE",
  "3825984169": "IFCTRANSFORMER",
  "812556717": "IFCTANK",
  "1162798199": "IFCSWITCHINGDEVICE",
  "385403989": "IFCSTRUCTURALLOADCASE",
  "1404847402": "IFCSTACKTERMINAL",
  "1999602285": "IFCSPACEHEATER",
  "3420628829": "IFCSOLARDEVICE",
  "3027962421": "IFCSLABSTANDARDCASE",
  "3127900445": "IFCSLABELEMENTEDCASE",
  "1329646415": "IFCSHADINGDEVICE",
  "3053780830": "IFCSANITARYTERMINAL",
  "2572171363": "IFCREINFORCINGBARTYPE",
  "1232101972": "IFCRATIONALBSPLINECURVEWITHKNOTS",
  "90941305": "IFCPUMP",
  "655969474": "IFCPROTECTIVEDEVICETRIPPINGUNITTYPE",
  "738039164": "IFCPROTECTIVEDEVICE",
  "1156407060": "IFCPLATESTANDARDCASE",
  "3612865200": "IFCPIPESEGMENT",
  "310824031": "IFCPIPEFITTING",
  "3694346114": "IFCOUTLET",
  "144952367": "IFCOUTERBOUNDARYCURVE",
  "2474470126": "IFCMOTORCONNECTION",
  "1911478936": "IFCMEMBERSTANDARDCASE",
  "1437502449": "IFCMEDICALDEVICE",
  "629592764": "IFCLIGHTFIXTURE",
  "76236018": "IFCLAMP",
  "2176052936": "IFCJUNCTIONBOX",
  "4175244083": "IFCINTERCEPTOR",
  "2068733104": "IFCHUMIDIFIER",
  "3319311131": "IFCHEATEXCHANGER",
  "2188021234": "IFCFLOWMETER",
  "1209101575": "IFCEXTERNALSPATIALELEMENT",
  "484807127": "IFCEVAPORATOR",
  "3747195512": "IFCEVAPORATIVECOOLER",
  "2814081492": "IFCENGINE",
  "2417008758": "IFCELECTRICDISTRIBUTIONBOARDTYPE",
  "3242481149": "IFCDOORSTANDARDCASE",
  "3205830791": "IFCDISTRIBUTIONSYSTEM",
  "400855858": "IFCCOMMUNICATIONSAPPLIANCETYPE",
  "905975707": "IFCCOLUMNSTANDARDCASE",
  "1677625105": "IFCCIVILELEMENT",
  "3296154744": "IFCCHIMNEY",
  "2674252688": "IFCCABLEFITTINGTYPE",
  "2188180465": "IFCBURNERTYPE",
  "1177604601": "IFCBUILDINGSYSTEM",
  "39481116": "IFCBUILDINGELEMENTPARTTYPE",
  "1136057603": "IFCBOUNDARYCURVE",
  "2461110595": "IFCBSPLINECURVEWITHKNOTS",
  "1532957894": "IFCAUDIOVISUALAPPLIANCETYPE",
  "4088093105": "IFCWORKCALENDAR",
  "4009809668": "IFCWINDOWTYPE",
  "926996030": "IFCVOIDINGFEATURE",
  "2391383451": "IFCVIBRATIONISOLATOR",
  "2415094496": "IFCTENDONTYPE",
  "3081323446": "IFCTENDONANCHORTYPE",
  "413509423": "IFCSYSTEMFURNITUREELEMENT",
  "3101698114": "IFCSURFACEFEATURE",
  "3657597509": "IFCSTRUCTURALSURFACEACTION",
  "2757150158": "IFCSTRUCTURALCURVEREACTION",
  "1004757350": "IFCSTRUCTURALCURVEACTION",
  "338393293": "IFCSTAIRTYPE",
  "1072016465": "IFCSOLARDEVICETYPE",
  "4074543187": "IFCSHADINGDEVICETYPE",
  "2157484638": "IFCSEAMCURVE",
  "2781568857": "IFCROOFTYPE",
  "2310774935": "IFCREINFORCINGMESHTYPE",
  "964333572": "IFCREINFORCINGELEMENTTYPE",
  "683857671": "IFCRATIONALBSPLINESURFACEWITHKNOTS",
  "1469900589": "IFCRAMPTYPE",
  "2839578677": "IFCPOLYGONALFACESET",
  "1158309216": "IFCPILETYPE",
  "3079942009": "IFCOPENINGSTANDARDCASE",
  "1114901282": "IFCMEDICALDEVICETYPE",
  "3113134337": "IFCINTERSECTIONCURVE",
  "3946677679": "IFCINTERCEPTORTYPE",
  "2571569899": "IFCINDEXEDPOLYCURVE",
  "3493046030": "IFCGEOGRAPHICELEMENT",
  "1509553395": "IFCFURNITURE",
  "1893162501": "IFCFOOTINGTYPE",
  "2853485674": "IFCEXTERNALSPATIALSTRUCTUREELEMENT",
  "4148101412": "IFCEVENT",
  "132023988": "IFCENGINETYPE",
  "2397081782": "IFCELEMENTASSEMBLYTYPE",
  "2323601079": "IFCDOORTYPE",
  "1213902940": "IFCCYLINDRICALSURFACE",
  "1525564444": "IFCCONSTRUCTIONPRODUCTRESOURCETYPE",
  "4105962743": "IFCCONSTRUCTIONMATERIALRESOURCETYPE",
  "2185764099": "IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE",
  "15328376": "IFCCOMPOSITECURVEONSURFACE",
  "3875453745": "IFCCOMPLEXPROPERTYTEMPLATE",
  "3893394355": "IFCCIVILELEMENTTYPE",
  "2197970202": "IFCCHIMNEYTYPE",
  "167062518": "IFCBSPLINESURFACEWITHKNOTS",
  "2887950389": "IFCBSPLINESURFACE",
  "2603310189": "IFCADVANCEDBREPWITHVOIDS",
  "1635779807": "IFCADVANCEDBREP",
  "2916149573": "IFCTRIANGULATEDFACESET",
  "1935646853": "IFCTOROIDALSURFACE",
  "2387106220": "IFCTESSELLATEDFACESET",
  "3206491090": "IFCTASKTYPE",
  "699246055": "IFCSURFACECURVE",
  "4095615324": "IFCSUBCONTRACTRESOURCETYPE",
  "603775116": "IFCSTRUCTURALSURFACEREACTION",
  "4015995234": "IFCSPHERICALSURFACE",
  "2481509218": "IFCSPATIALZONETYPE",
  "463610769": "IFCSPATIALZONE",
  "710998568": "IFCSPATIALELEMENTTYPE",
  "1412071761": "IFCSPATIALELEMENT",
  "3663146110": "IFCSIMPLEPROPERTYTEMPLATE",
  "3243963512": "IFCREVOLVEDAREASOLIDTAPERED",
  "816062949": "IFCREPARAMETRISEDCOMPOSITECURVESEGMENT",
  "1521410863": "IFCRELSPACEBOUNDARY2NDLEVEL",
  "3523091289": "IFCRELSPACEBOUNDARY1STLEVEL",
  "427948657": "IFCRELINTERFERESELEMENTS",
  "307848117": "IFCRELDEFINESBYTEMPLATE",
  "1462361463": "IFCRELDEFINESBYOBJECT",
  "2565941209": "IFCRELDECLARES",
  "1027710054": "IFCRELASSIGNSTOGROUPBYFACTOR",
  "3521284610": "IFCPROPERTYTEMPLATE",
  "492091185": "IFCPROPERTYSETTEMPLATE",
  "653396225": "IFCPROJECTLIBRARY",
  "569719735": "IFCPROCEDURETYPE",
  "3967405729": "IFCPREDEFINEDPROPERTYSET",
  "1682466193": "IFCPCURVE",
  "428585644": "IFCLABORRESOURCETYPE",
  "2294589976": "IFCINDEXEDPOLYGONALFACEWITHVOIDS",
  "178912537": "IFCINDEXEDPOLYGONALFACE",
  "4095422895": "IFCGEOGRAPHICELEMENTTYPE",
  "2652556860": "IFCFIXEDREFERENCESWEPTAREASOLID",
  "2804161546": "IFCEXTRUDEDAREASOLIDTAPERED",
  "4024345920": "IFCEVENTTYPE",
  "2629017746": "IFCCURVEBOUNDEDSURFACE",
  "1815067380": "IFCCREWRESOURCETYPE",
  "3419103109": "IFCCONTEXT",
  "2574617495": "IFCCONSTRUCTIONRESOURCETYPE",
  "2059837836": "IFCCARTESIANPOINTLIST3D",
  "1675464909": "IFCCARTESIANPOINTLIST2D",
  "574549367": "IFCCARTESIANPOINTLIST",
  "3406155212": "IFCADVANCEDFACE",
  "3698973494": "IFCTYPERESOURCE",
  "3736923433": "IFCTYPEPROCESS",
  "901063453": "IFCTESSELLATEDITEM",
  "1096409881": "IFCSWEPTDISKSOLIDPOLYGONAL",
  "1042787934": "IFCRESOURCETIME",
  "1608871552": "IFCRESOURCECONSTRAINTRELATIONSHIP",
  "2943643501": "IFCRESOURCEAPPROVALRELATIONSHIP",
  "2090586900": "IFCQUANTITYSET",
  "1482703590": "IFCPROPERTYTEMPLATEDEFINITION",
  "3778827333": "IFCPREDEFINEDPROPERTIES",
  "2998442950": "IFCMIRROREDPROFILEDEF",
  "853536259": "IFCMATERIALRELATIONSHIP",
  "3404854881": "IFCMATERIALPROFILESETUSAGETAPERING",
  "3079605661": "IFCMATERIALPROFILESETUSAGE",
  "2852063980": "IFCMATERIALCONSTITUENTSET",
  "3708119e3": "IFCMATERIALCONSTITUENT",
  "1585845231": "IFCLAGTIME",
  "2133299955": "IFCINDEXEDTRIANGLETEXTUREMAP",
  "1437953363": "IFCINDEXEDTEXTUREMAP",
  "3570813810": "IFCINDEXEDCOLOURMAP",
  "1437805879": "IFCEXTERNALREFERENCERELATIONSHIP",
  "297599258": "IFCEXTENDEDPROPERTIES",
  "211053100": "IFCEVENTTIME",
  "2713554722": "IFCCONVERSIONBASEDUNITWITHOFFSET",
  "3285139300": "IFCCOLOURRGBLIST",
  "1236880293": "IFCWORKTIME",
  "1199560280": "IFCTIMEPERIOD",
  "3611470254": "IFCTEXTUREVERTEXLIST",
  "2771591690": "IFCTASKTIMERECURRING",
  "1549132990": "IFCTASKTIME",
  "2043862942": "IFCTABLECOLUMN",
  "2934153892": "IFCSURFACEREINFORCEMENTAREA",
  "609421318": "IFCSTRUCTURALLOADORRESULT",
  "3478079324": "IFCSTRUCTURALLOADCONFIGURATION",
  "1054537805": "IFCSCHEDULINGTIME",
  "2439245199": "IFCRESOURCELEVELRELATIONSHIP",
  "2433181523": "IFCREFERENCE",
  "3915482550": "IFCRECURRENCEPATTERN",
  "986844984": "IFCPROPERTYABSTRACTION",
  "3843373140": "IFCPROJECTEDCRS",
  "677532197": "IFCPRESENTATIONITEM",
  "1507914824": "IFCMATERIALUSAGEDEFINITION",
  "552965576": "IFCMATERIALPROFILEWITHOFFSETS",
  "164193824": "IFCMATERIALPROFILESET",
  "2235152071": "IFCMATERIALPROFILE",
  "1847252529": "IFCMATERIALLAYERWITHOFFSETS",
  "760658860": "IFCMATERIALDEFINITION",
  "3057273783": "IFCMAPCONVERSION",
  "4294318154": "IFCEXTERNALINFORMATION",
  "1466758467": "IFCCOORDINATEREFERENCESYSTEM",
  "1785450214": "IFCCOORDINATEOPERATION",
  "775493141": "IFCCONNECTIONVOLUMEGEOMETRY",
  "979691226": "IFCREINFORCINGBAR",
  "3700593921": "IFCELECTRICDISTRIBUTIONPOINT",
  "1062813311": "IFCDISTRIBUTIONCONTROLELEMENT",
  "1052013943": "IFCDISTRIBUTIONCHAMBERELEMENT",
  "578613899": "IFCCONTROLLERTYPE",
  "2454782716": "IFCCHAMFEREDGEFEATURE",
  "753842376": "IFCBEAM",
  "3001207471": "IFCALARMTYPE",
  "2874132201": "IFCACTUATORTYPE",
  "3304561284": "IFCWINDOW",
  "3512223829": "IFCWALLSTANDARDCASE",
  "2391406946": "IFCWALL",
  "3313531582": "IFCVIBRATIONISOLATORTYPE",
  "2347447852": "IFCTENDONANCHOR",
  "3824725483": "IFCTENDON",
  "2515109513": "IFCSTRUCTURALANALYSISMODEL",
  "4252922144": "IFCSTAIRFLIGHT",
  "331165859": "IFCSTAIR",
  "1529196076": "IFCSLAB",
  "1783015770": "IFCSENSORTYPE",
  "1376911519": "IFCROUNDEDEDGEFEATURE",
  "2016517767": "IFCROOF",
  "2320036040": "IFCREINFORCINGMESH",
  "3027567501": "IFCREINFORCINGELEMENT",
  "3055160366": "IFCRATIONALBEZIERCURVE",
  "3283111854": "IFCRAMPFLIGHT",
  "3024970846": "IFCRAMP",
  "2262370178": "IFCRAILING",
  "3171933400": "IFCPLATE",
  "1687234759": "IFCPILE",
  "1073191201": "IFCMEMBER",
  "900683007": "IFCFOOTING",
  "3508470533": "IFCFLOWTREATMENTDEVICE",
  "2223149337": "IFCFLOWTERMINAL",
  "707683696": "IFCFLOWSTORAGEDEVICE",
  "987401354": "IFCFLOWSEGMENT",
  "3132237377": "IFCFLOWMOVINGDEVICE",
  "4037862832": "IFCFLOWINSTRUMENTTYPE",
  "4278956645": "IFCFLOWFITTING",
  "2058353004": "IFCFLOWCONTROLLER",
  "4222183408": "IFCFIRESUPPRESSIONTERMINALTYPE",
  "1810631287": "IFCFILTERTYPE",
  "346874300": "IFCFANTYPE",
  "1658829314": "IFCENERGYCONVERSIONDEVICE",
  "857184966": "IFCELECTRICALELEMENT",
  "1634875225": "IFCELECTRICALCIRCUIT",
  "712377611": "IFCELECTRICTIMECONTROLTYPE",
  "1217240411": "IFCELECTRICMOTORTYPE",
  "1365060375": "IFCELECTRICHEATERTYPE",
  "1534661035": "IFCELECTRICGENERATORTYPE",
  "3277789161": "IFCELECTRICFLOWSTORAGEDEVICETYPE",
  "663422040": "IFCELECTRICAPPLIANCETYPE",
  "855621170": "IFCEDGEFEATURE",
  "2030761528": "IFCDUCTSILENCERTYPE",
  "3760055223": "IFCDUCTSEGMENTTYPE",
  "869906466": "IFCDUCTFITTINGTYPE",
  "395920057": "IFCDOOR",
  "3041715199": "IFCDISTRIBUTIONPORT",
  "3040386961": "IFCDISTRIBUTIONFLOWELEMENT",
  "1945004755": "IFCDISTRIBUTIONELEMENT",
  "2063403501": "IFCDISTRIBUTIONCONTROLELEMENTTYPE",
  "1599208980": "IFCDISTRIBUTIONCHAMBERELEMENTTYPE",
  "2635815018": "IFCDISCRETEACCESSORYTYPE",
  "1335981549": "IFCDISCRETEACCESSORY",
  "4147604152": "IFCDIAMETERDIMENSION",
  "3961806047": "IFCDAMPERTYPE",
  "3495092785": "IFCCURTAINWALL",
  "1973544240": "IFCCOVERING",
  "2954562838": "IFCCOOLINGTOWERTYPE",
  "335055490": "IFCCOOLEDBEAMTYPE",
  "488727124": "IFCCONSTRUCTIONPRODUCTRESOURCE",
  "1060000209": "IFCCONSTRUCTIONMATERIALRESOURCE",
  "3898045240": "IFCCONSTRUCTIONEQUIPMENTRESOURCE",
  "1163958913": "IFCCONDITIONCRITERION",
  "2188551683": "IFCCONDITION",
  "2816379211": "IFCCONDENSERTYPE",
  "3850581409": "IFCCOMPRESSORTYPE",
  "843113511": "IFCCOLUMN",
  "2301859152": "IFCCOILTYPE",
  "2611217952": "IFCCIRCLE",
  "2951183804": "IFCCHILLERTYPE",
  "1285652485": "IFCCABLESEGMENTTYPE",
  "3293546465": "IFCCABLECARRIERSEGMENTTYPE",
  "395041908": "IFCCABLECARRIERFITTINGTYPE",
  "1909888760": "IFCBUILDINGELEMENTPROXYTYPE",
  "1095909175": "IFCBUILDINGELEMENTPROXY",
  "2979338954": "IFCBUILDINGELEMENTPART",
  "52481810": "IFCBUILDINGELEMENTCOMPONENT",
  "3299480353": "IFCBUILDINGELEMENT",
  "231477066": "IFCBOILERTYPE",
  "1916977116": "IFCBEZIERCURVE",
  "819618141": "IFCBEAMTYPE",
  "1967976161": "IFCBSPLINECURVE",
  "3460190687": "IFCASSET",
  "2470393545": "IFCANGULARDIMENSION",
  "1871374353": "IFCAIRTOAIRHEATRECOVERYTYPE",
  "3352864051": "IFCAIRTERMINALTYPE",
  "1411407467": "IFCAIRTERMINALBOXTYPE",
  "3821786052": "IFCACTIONREQUEST",
  "1213861670": "IFC2DCOMPOSITECURVE",
  "1033361043": "IFCZONE",
  "3342526732": "IFCWORKSCHEDULE",
  "4218914973": "IFCWORKPLAN",
  "1028945134": "IFCWORKCONTROL",
  "1133259667": "IFCWASTETERMINALTYPE",
  "1898987631": "IFCWALLTYPE",
  "2769231204": "IFCVIRTUALELEMENT",
  "728799441": "IFCVALVETYPE",
  "1911125066": "IFCUNITARYEQUIPMENTTYPE",
  "1600972822": "IFCTUBEBUNDLETYPE",
  "3593883385": "IFCTRIMMEDCURVE",
  "1620046519": "IFCTRANSPORTELEMENT",
  "1692211062": "IFCTRANSFORMERTYPE",
  "1637806684": "IFCTIMESERIESSCHEDULE",
  "5716631": "IFCTANKTYPE",
  "2254336722": "IFCSYSTEM",
  "2315554128": "IFCSWITCHINGDEVICETYPE",
  "148013059": "IFCSUBCONTRACTRESOURCE",
  "1975003073": "IFCSTRUCTURALSURFACECONNECTION",
  "2986769608": "IFCSTRUCTURALRESULTGROUP",
  "1235345126": "IFCSTRUCTURALPOINTREACTION",
  "734778138": "IFCSTRUCTURALPOINTCONNECTION",
  "2082059205": "IFCSTRUCTURALPOINTACTION",
  "3987759626": "IFCSTRUCTURALPLANARACTIONVARYING",
  "1621171031": "IFCSTRUCTURALPLANARACTION",
  "1252848954": "IFCSTRUCTURALLOADGROUP",
  "1721250024": "IFCSTRUCTURALLINEARACTIONVARYING",
  "1807405624": "IFCSTRUCTURALLINEARACTION",
  "2445595289": "IFCSTRUCTURALCURVEMEMBERVARYING",
  "214636428": "IFCSTRUCTURALCURVEMEMBER",
  "4243806635": "IFCSTRUCTURALCURVECONNECTION",
  "1179482911": "IFCSTRUCTURALCONNECTION",
  "682877961": "IFCSTRUCTURALACTION",
  "1039846685": "IFCSTAIRFLIGHTTYPE",
  "3112655638": "IFCSTACKTERMINALTYPE",
  "3812236995": "IFCSPACETYPE",
  "652456506": "IFCSPACEPROGRAM",
  "1305183839": "IFCSPACEHEATERTYPE",
  "3856911033": "IFCSPACE",
  "2533589738": "IFCSLABTYPE",
  "4097777520": "IFCSITE",
  "4105383287": "IFCSERVICELIFE",
  "3517283431": "IFCSCHEDULETIMECONTROL",
  "1768891740": "IFCSANITARYTERMINALTYPE",
  "2863920197": "IFCRELASSIGNSTASKS",
  "160246688": "IFCRELAGGREGATES",
  "2324767716": "IFCRAMPFLIGHTTYPE",
  "2893384427": "IFCRAILINGTYPE",
  "3248260540": "IFCRADIUSDIMENSION",
  "2250791053": "IFCPUMPTYPE",
  "1842657554": "IFCPROTECTIVEDEVICETYPE",
  "3651124850": "IFCPROJECTIONELEMENT",
  "3642467123": "IFCPROJECTORDERRECORD",
  "2904328755": "IFCPROJECTORDER",
  "2744685151": "IFCPROCEDURE",
  "3740093272": "IFCPORT",
  "3724593414": "IFCPOLYLINE",
  "4017108033": "IFCPLATETYPE",
  "4231323485": "IFCPIPESEGMENTTYPE",
  "804291784": "IFCPIPEFITTINGTYPE",
  "3327091369": "IFCPERMIT",
  "2382730787": "IFCPERFORMANCEHISTORY",
  "2837617999": "IFCOUTLETTYPE",
  "3425660407": "IFCORDERACTION",
  "3588315303": "IFCOPENINGELEMENT",
  "4143007308": "IFCOCCUPANT",
  "1916936684": "IFCMOVE",
  "977012517": "IFCMOTORCONNECTIONTYPE",
  "3181161470": "IFCMEMBERTYPE",
  "2108223431": "IFCMECHANICALFASTENERTYPE",
  "377706215": "IFCMECHANICALFASTENER",
  "2506943328": "IFCLINEARDIMENSION",
  "1161773419": "IFCLIGHTFIXTURETYPE",
  "1051575348": "IFCLAMPTYPE",
  "3827777499": "IFCLABORRESOURCE",
  "4288270099": "IFCJUNCTIONBOXTYPE",
  "2391368822": "IFCINVENTORY",
  "1806887404": "IFCHUMIDIFIERTYPE",
  "1251058090": "IFCHEATEXCHANGERTYPE",
  "2706460486": "IFCGROUP",
  "3009204131": "IFCGRID",
  "200128114": "IFCGASTERMINALTYPE",
  "814719939": "IFCFURNITURESTANDARD",
  "263784265": "IFCFURNISHINGELEMENT",
  "3009222698": "IFCFLOWTREATMENTDEVICETYPE",
  "2297155007": "IFCFLOWTERMINALTYPE",
  "1339347760": "IFCFLOWSTORAGEDEVICETYPE",
  "1834744321": "IFCFLOWSEGMENTTYPE",
  "1482959167": "IFCFLOWMOVINGDEVICETYPE",
  "3815607619": "IFCFLOWMETERTYPE",
  "3198132628": "IFCFLOWFITTINGTYPE",
  "3907093117": "IFCFLOWCONTROLLERTYPE",
  "1287392070": "IFCFEATUREELEMENTSUBTRACTION",
  "2143335405": "IFCFEATUREELEMENTADDITION",
  "2827207264": "IFCFEATUREELEMENT",
  "2489546625": "IFCFASTENERTYPE",
  "647756555": "IFCFASTENER",
  "3737207727": "IFCFACETEDBREPWITHVOIDS",
  "807026263": "IFCFACETEDBREP",
  "3390157468": "IFCEVAPORATORTYPE",
  "3174744832": "IFCEVAPORATIVECOOLERTYPE",
  "3272907226": "IFCEQUIPMENTSTANDARD",
  "1962604670": "IFCEQUIPMENTELEMENT",
  "2107101300": "IFCENERGYCONVERSIONDEVICETYPE",
  "1704287377": "IFCELLIPSE",
  "2590856083": "IFCELEMENTCOMPONENTTYPE",
  "1623761950": "IFCELEMENTCOMPONENT",
  "4123344466": "IFCELEMENTASSEMBLY",
  "1758889154": "IFCELEMENT",
  "360485395": "IFCELECTRICALBASEPROPERTIES",
  "3849074793": "IFCDISTRIBUTIONFLOWELEMENTTYPE",
  "3256556792": "IFCDISTRIBUTIONELEMENTTYPE",
  "681481545": "IFCDIMENSIONCURVEDIRECTEDCALLOUT",
  "1457835157": "IFCCURTAINWALLTYPE",
  "3295246426": "IFCCREWRESOURCE",
  "1916426348": "IFCCOVERINGTYPE",
  "1419761937": "IFCCOSTSCHEDULE",
  "3895139033": "IFCCOSTITEM",
  "3293443760": "IFCCONTROL",
  "2559216714": "IFCCONSTRUCTIONRESOURCE",
  "2510884976": "IFCCONIC",
  "3732776249": "IFCCOMPOSITECURVE",
  "300633059": "IFCCOLUMNTYPE",
  "2937912522": "IFCCIRCLEHOLLOWPROFILEDEF",
  "3124254112": "IFCBUILDINGSTOREY",
  "1950629157": "IFCBUILDINGELEMENTTYPE",
  "4031249490": "IFCBUILDING",
  "1260505505": "IFCBOUNDEDCURVE",
  "3649129432": "IFCBOOLEANCLIPPINGRESULT",
  "1334484129": "IFCBLOCK",
  "3207858831": "IFCASYMMETRICISHAPEPROFILEDEF",
  "1674181508": "IFCANNOTATION",
  "2296667514": "IFCACTOR",
  "2097647324": "IFCTRANSPORTELEMENTTYPE",
  "3473067441": "IFCTASK",
  "1580310250": "IFCSYSTEMFURNITUREELEMENTTYPE",
  "4124788165": "IFCSURFACEOFREVOLUTION",
  "2809605785": "IFCSURFACEOFLINEAREXTRUSION",
  "2028607225": "IFCSURFACECURVESWEPTAREASOLID",
  "4070609034": "IFCSTRUCTUREDDIMENSIONCALLOUT",
  "2218152070": "IFCSTRUCTURALSURFACEMEMBERVARYING",
  "3979015343": "IFCSTRUCTURALSURFACEMEMBER",
  "3689010777": "IFCSTRUCTURALREACTION",
  "530289379": "IFCSTRUCTURALMEMBER",
  "3136571912": "IFCSTRUCTURALITEM",
  "3544373492": "IFCSTRUCTURALACTIVITY",
  "451544542": "IFCSPHERE",
  "3893378262": "IFCSPATIALSTRUCTUREELEMENTTYPE",
  "2706606064": "IFCSPATIALSTRUCTUREELEMENT",
  "3626867408": "IFCRIGHTCIRCULARCYLINDER",
  "4158566097": "IFCRIGHTCIRCULARCONE",
  "1856042241": "IFCREVOLVEDAREASOLID",
  "2914609552": "IFCRESOURCE",
  "1401173127": "IFCRELVOIDSELEMENT",
  "3451746338": "IFCRELSPACEBOUNDARY",
  "366585022": "IFCRELSERVICESBUILDINGS",
  "4122056220": "IFCRELSEQUENCE",
  "1058617721": "IFCRELSCHEDULESCOSTITEMS",
  "1245217292": "IFCRELREFERENCEDINSPATIALSTRUCTURE",
  "750771296": "IFCRELPROJECTSELEMENT",
  "202636808": "IFCRELOVERRIDESPROPERTIES",
  "2051452291": "IFCRELOCCUPIESSPACES",
  "3268803585": "IFCRELNESTS",
  "4189434867": "IFCRELINTERACTIONREQUIREMENTS",
  "279856033": "IFCRELFLOWCONTROLELEMENTS",
  "3940055652": "IFCRELFILLSELEMENT",
  "781010003": "IFCRELDEFINESBYTYPE",
  "4186316022": "IFCRELDEFINESBYPROPERTIES",
  "693640335": "IFCRELDEFINES",
  "2551354335": "IFCRELDECOMPOSES",
  "2802773753": "IFCRELCOVERSSPACES",
  "886880790": "IFCRELCOVERSBLDGELEMENTS",
  "3242617779": "IFCRELCONTAINEDINSPATIALSTRUCTURE",
  "3678494232": "IFCRELCONNECTSWITHREALIZINGELEMENTS",
  "504942748": "IFCRELCONNECTSWITHECCENTRICITY",
  "1638771189": "IFCRELCONNECTSSTRUCTURALMEMBER",
  "3912681535": "IFCRELCONNECTSSTRUCTURALELEMENT",
  "2127690289": "IFCRELCONNECTSSTRUCTURALACTIVITY",
  "3190031847": "IFCRELCONNECTSPORTS",
  "4201705270": "IFCRELCONNECTSPORTTOELEMENT",
  "3945020480": "IFCRELCONNECTSPATHELEMENTS",
  "1204542856": "IFCRELCONNECTSELEMENTS",
  "826625072": "IFCRELCONNECTS",
  "2851387026": "IFCRELASSOCIATESPROFILEPROPERTIES",
  "2655215786": "IFCRELASSOCIATESMATERIAL",
  "3840914261": "IFCRELASSOCIATESLIBRARY",
  "982818633": "IFCRELASSOCIATESDOCUMENT",
  "2728634034": "IFCRELASSOCIATESCONSTRAINT",
  "919958153": "IFCRELASSOCIATESCLASSIFICATION",
  "4095574036": "IFCRELASSOCIATESAPPROVAL",
  "1327628568": "IFCRELASSOCIATESAPPLIEDVALUE",
  "1865459582": "IFCRELASSOCIATES",
  "205026976": "IFCRELASSIGNSTORESOURCE",
  "3372526763": "IFCRELASSIGNSTOPROJECTORDER",
  "2857406711": "IFCRELASSIGNSTOPRODUCT",
  "4278684876": "IFCRELASSIGNSTOPROCESS",
  "1307041759": "IFCRELASSIGNSTOGROUP",
  "2495723537": "IFCRELASSIGNSTOCONTROL",
  "1683148259": "IFCRELASSIGNSTOACTOR",
  "3939117080": "IFCRELASSIGNS",
  "3454111270": "IFCRECTANGULARTRIMMEDSURFACE",
  "2798486643": "IFCRECTANGULARPYRAMID",
  "2770003689": "IFCRECTANGLEHOLLOWPROFILEDEF",
  "3219374653": "IFCPROXY",
  "1451395588": "IFCPROPERTYSET",
  "4194566429": "IFCPROJECTIONCURVE",
  "103090709": "IFCPROJECT",
  "4208778838": "IFCPRODUCT",
  "2945172077": "IFCPROCESS",
  "220341763": "IFCPLANE",
  "603570806": "IFCPLANARBOX",
  "3566463478": "IFCPERMEABLECOVERINGPROPERTIES",
  "3505215534": "IFCOFFSETCURVE3D",
  "3388369263": "IFCOFFSETCURVE2D",
  "3888040117": "IFCOBJECT",
  "1425443689": "IFCMANIFOLDSOLIDBREP",
  "1281925730": "IFCLINE",
  "572779678": "IFCLSHAPEPROFILEDEF",
  "1484403080": "IFCISHAPEPROFILEDEF",
  "987898635": "IFCGEOMETRICCURVESET",
  "1268542332": "IFCFURNITURETYPE",
  "4238390223": "IFCFURNISHINGELEMENTTYPE",
  "3455213021": "IFCFLUIDFLOWPROPERTIES",
  "315944413": "IFCFILLAREASTYLETILES",
  "4203026998": "IFCFILLAREASTYLETILESYMBOLWITHSTYLE",
  "374418227": "IFCFILLAREASTYLEHATCHING",
  "2047409740": "IFCFACEBASEDSURFACEMODEL",
  "477187591": "IFCEXTRUDEDAREASOLID",
  "80994333": "IFCENERGYPROPERTIES",
  "2835456948": "IFCELLIPSEPROFILEDEF",
  "2777663545": "IFCELEMENTARYSURFACE",
  "339256511": "IFCELEMENTTYPE",
  "1883228015": "IFCELEMENTQUANTITY",
  "1472233963": "IFCEDGELOOP",
  "4006246654": "IFCDRAUGHTINGPREDEFINEDCURVEFONT",
  "445594917": "IFCDRAUGHTINGPREDEFINEDCOLOUR",
  "3073041342": "IFCDRAUGHTINGCALLOUT",
  "526551008": "IFCDOORSTYLE",
  "1714330368": "IFCDOORPANELPROPERTIES",
  "2963535650": "IFCDOORLININGPROPERTIES",
  "32440307": "IFCDIRECTION",
  "4054601972": "IFCDIMENSIONCURVETERMINATOR",
  "606661476": "IFCDIMENSIONCURVE",
  "693772133": "IFCDEFINEDSYMBOL",
  "2827736869": "IFCCURVEBOUNDEDPLANE",
  "2601014836": "IFCCURVE",
  "2147822146": "IFCCSGSOLID",
  "2506170314": "IFCCSGPRIMITIVE3D",
  "194851669": "IFCCRANERAILFSHAPEPROFILEDEF",
  "4133800736": "IFCCRANERAILASHAPEPROFILEDEF",
  "2485617015": "IFCCOMPOSITECURVESEGMENT",
  "2205249479": "IFCCLOSEDSHELL",
  "1383045692": "IFCCIRCLEPROFILEDEF",
  "1416205885": "IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM",
  "3331915920": "IFCCARTESIANTRANSFORMATIONOPERATOR3D",
  "3486308946": "IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM",
  "3749851601": "IFCCARTESIANTRANSFORMATIONOPERATOR2D",
  "59481748": "IFCCARTESIANTRANSFORMATIONOPERATOR",
  "1123145078": "IFCCARTESIANPOINT",
  "2898889636": "IFCCSHAPEPROFILEDEF",
  "2713105998": "IFCBOXEDHALFSPACE",
  "2581212453": "IFCBOUNDINGBOX",
  "4182860854": "IFCBOUNDEDSURFACE",
  "2736907675": "IFCBOOLEANRESULT",
  "2740243338": "IFCAXIS2PLACEMENT3D",
  "3125803723": "IFCAXIS2PLACEMENT2D",
  "4261334040": "IFCAXIS1PLACEMENT",
  "1302238472": "IFCANNOTATIONSURFACE",
  "2265737646": "IFCANNOTATIONFILLAREAOCCURRENCE",
  "669184980": "IFCANNOTATIONFILLAREA",
  "3288037868": "IFCANNOTATIONCURVEOCCURRENCE",
  "2543172580": "IFCZSHAPEPROFILEDEF",
  "1299126871": "IFCWINDOWSTYLE",
  "512836454": "IFCWINDOWPANELPROPERTIES",
  "336235671": "IFCWINDOWLININGPROPERTIES",
  "2759199220": "IFCVERTEXLOOP",
  "1417489154": "IFCVECTOR",
  "427810014": "IFCUSHAPEPROFILEDEF",
  "2347495698": "IFCTYPEPRODUCT",
  "1628702193": "IFCTYPEOBJECT",
  "1345879162": "IFCTWODIRECTIONREPEATFACTOR",
  "2715220739": "IFCTRAPEZIUMPROFILEDEF",
  "3124975700": "IFCTEXTLITERALWITHEXTENT",
  "4282788508": "IFCTEXTLITERAL",
  "3028897424": "IFCTERMINATORSYMBOL",
  "3071757647": "IFCTSHAPEPROFILEDEF",
  "230924584": "IFCSWEPTSURFACE",
  "1260650574": "IFCSWEPTDISKSOLID",
  "2247615214": "IFCSWEPTAREASOLID",
  "1878645084": "IFCSURFACESTYLERENDERING",
  "2513912981": "IFCSURFACE",
  "2233826070": "IFCSUBEDGE",
  "3653947884": "IFCSTRUCTURALSTEELPROFILEPROPERTIES",
  "3843319758": "IFCSTRUCTURALPROFILEPROPERTIES",
  "1190533807": "IFCSTRUCTURALLOADSINGLEFORCEWARPING",
  "1597423693": "IFCSTRUCTURALLOADSINGLEFORCE",
  "1973038258": "IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION",
  "2473145415": "IFCSTRUCTURALLOADSINGLEDISPLACEMENT",
  "2668620305": "IFCSTRUCTURALLOADPLANARFORCE",
  "1595516126": "IFCSTRUCTURALLOADLINEARFORCE",
  "390701378": "IFCSPACETHERMALLOADPROPERTIES",
  "1202362311": "IFCSOUNDVALUE",
  "2485662743": "IFCSOUNDPROPERTIES",
  "723233188": "IFCSOLIDMODEL",
  "2609359061": "IFCSLIPPAGECONNECTIONCONDITION",
  "4124623270": "IFCSHELLBASEDSURFACEMODEL",
  "2411513650": "IFCSERVICELIFEFACTOR",
  "1509187699": "IFCSECTIONEDSPINE",
  "2778083089": "IFCROUNDEDRECTANGLEPROFILEDEF",
  "478536968": "IFCRELATIONSHIP",
  "3765753017": "IFCREINFORCEMENTDEFINITIONPROPERTIES",
  "3413951693": "IFCREGULARTIMESERIES",
  "3615266464": "IFCRECTANGLEPROFILEDEF",
  "110355661": "IFCPROPERTYTABLEVALUE",
  "3650150729": "IFCPROPERTYSINGLEVALUE",
  "3357820518": "IFCPROPERTYSETDEFINITION",
  "941946838": "IFCPROPERTYREFERENCEVALUE",
  "2752243245": "IFCPROPERTYLISTVALUE",
  "4166981789": "IFCPROPERTYENUMERATEDVALUE",
  "1680319473": "IFCPROPERTYDEFINITION",
  "871118103": "IFCPROPERTYBOUNDEDVALUE",
  "673634403": "IFCPRODUCTDEFINITIONSHAPE",
  "179317114": "IFCPREDEFINEDPOINTMARKERSYMBOL",
  "433424934": "IFCPREDEFINEDDIMENSIONSYMBOL",
  "2559016684": "IFCPREDEFINEDCURVEFONT",
  "759155922": "IFCPREDEFINEDCOLOUR",
  "2775532180": "IFCPOLYGONALBOUNDEDHALFSPACE",
  "2924175390": "IFCPOLYLOOP",
  "1423911732": "IFCPOINTONSURFACE",
  "4022376103": "IFCPOINTONCURVE",
  "2067069095": "IFCPOINT",
  "1663979128": "IFCPLANAREXTENT",
  "2004835150": "IFCPLACEMENT",
  "597895409": "IFCPIXELTEXTURE",
  "3021840470": "IFCPHYSICALCOMPLEXQUANTITY",
  "2519244187": "IFCPATH",
  "2529465313": "IFCPARAMETERIZEDPROFILEDEF",
  "1029017970": "IFCORIENTEDEDGE",
  "2665983363": "IFCOPENSHELL",
  "2833995503": "IFCONEDIRECTIONREPEATFACTOR",
  "219451334": "IFCOBJECTDEFINITION",
  "1430189142": "IFCMECHANICALCONCRETEMATERIALPROPERTIES",
  "2022407955": "IFCMATERIALDEFINITIONREPRESENTATION",
  "2347385850": "IFCMAPPEDITEM",
  "1008929658": "IFCLOOP",
  "2624227202": "IFCLOCALPLACEMENT",
  "3422422726": "IFCLIGHTSOURCESPOT",
  "1520743889": "IFCLIGHTSOURCEPOSITIONAL",
  "4266656042": "IFCLIGHTSOURCEGONIOMETRIC",
  "2604431987": "IFCLIGHTSOURCEDIRECTIONAL",
  "125510826": "IFCLIGHTSOURCEAMBIENT",
  "1402838566": "IFCLIGHTSOURCE",
  "3741457305": "IFCIRREGULARTIMESERIES",
  "3905492369": "IFCIMAGETEXTURE",
  "2445078500": "IFCHYGROSCOPICMATERIALPROPERTIES",
  "812098782": "IFCHALFSPACESOLID",
  "178086475": "IFCGRIDPLACEMENT",
  "3590301190": "IFCGEOMETRICSET",
  "4142052618": "IFCGEOMETRICREPRESENTATIONSUBCONTEXT",
  "2453401579": "IFCGEOMETRICREPRESENTATIONITEM",
  "3448662350": "IFCGEOMETRICREPRESENTATIONCONTEXT",
  "1446786286": "IFCGENERALPROFILEPROPERTIES",
  "803998398": "IFCGENERALMATERIALPROPERTIES",
  "3857492461": "IFCFUELPROPERTIES",
  "738692330": "IFCFILLAREASTYLE",
  "4219587988": "IFCFAILURECONNECTIONCONDITION",
  "3008276851": "IFCFACESURFACE",
  "803316827": "IFCFACEOUTERBOUND",
  "1809719519": "IFCFACEBOUND",
  "2556980723": "IFCFACE",
  "1860660968": "IFCEXTENDEDMATERIALPROPERTIES",
  "476780140": "IFCEDGECURVE",
  "3900360178": "IFCEDGE",
  "4170525392": "IFCDRAUGHTINGPREDEFINEDTEXTFONT",
  "3732053477": "IFCDOCUMENTREFERENCE",
  "1694125774": "IFCDIMENSIONPAIR",
  "2273265877": "IFCDIMENSIONCALLOUTRELATIONSHIP",
  "3632507154": "IFCDERIVEDPROFILEDEF",
  "3800577675": "IFCCURVESTYLE",
  "2889183280": "IFCCONVERSIONBASEDUNIT",
  "3050246964": "IFCCONTEXTDEPENDENTUNIT",
  "45288368": "IFCCONNECTIONPOINTECCENTRICITY",
  "1981873012": "IFCCONNECTIONCURVEGEOMETRY",
  "370225590": "IFCCONNECTEDFACESET",
  "1485152156": "IFCCOMPOSITEPROFILEDEF",
  "2542286263": "IFCCOMPLEXPROPERTY",
  "776857604": "IFCCOLOURRGB",
  "647927063": "IFCCLASSIFICATIONREFERENCE",
  "3150382593": "IFCCENTERLINEPROFILEDEF",
  "616511568": "IFCBLOBTEXTURE",
  "2705031697": "IFCARBITRARYPROFILEDEFWITHVOIDS",
  "1310608509": "IFCARBITRARYOPENPROFILEDEF",
  "3798115385": "IFCARBITRARYCLOSEDPROFILEDEF",
  "2297822566": "IFCANNOTATIONTEXTOCCURRENCE",
  "3612888222": "IFCANNOTATIONSYMBOLOCCURRENCE",
  "962685235": "IFCANNOTATIONSURFACEOCCURRENCE",
  "2442683028": "IFCANNOTATIONOCCURRENCE",
  "1065908215": "IFCWATERPROPERTIES",
  "891718957": "IFCVIRTUALGRIDINTERSECTION",
  "1907098498": "IFCVERTEXPOINT",
  "3304826586": "IFCVERTEXBASEDTEXTUREMAP",
  "2799835756": "IFCVERTEX",
  "180925521": "IFCUNITASSIGNMENT",
  "1735638870": "IFCTOPOLOGYREPRESENTATION",
  "1377556343": "IFCTOPOLOGICALREPRESENTATIONITEM",
  "581633288": "IFCTIMESERIESVALUE",
  "1718945513": "IFCTIMESERIESREFERENCERELATIONSHIP",
  "3101149627": "IFCTIMESERIES",
  "3317419933": "IFCTHERMALMATERIALPROPERTIES",
  "1210645708": "IFCTEXTUREVERTEX",
  "2552916305": "IFCTEXTUREMAP",
  "1742049831": "IFCTEXTURECOORDINATEGENERATOR",
  "280115917": "IFCTEXTURECOORDINATE",
  "1484833681": "IFCTEXTSTYLEWITHBOXCHARACTERISTICS",
  "1640371178": "IFCTEXTSTYLETEXTMODEL",
  "2636378356": "IFCTEXTSTYLEFORDEFINEDFONT",
  "1983826977": "IFCTEXTSTYLEFONTMODEL",
  "1447204868": "IFCTEXTSTYLE",
  "912023232": "IFCTELECOMADDRESS",
  "531007025": "IFCTABLEROW",
  "985171141": "IFCTABLE",
  "1290481447": "IFCSYMBOLSTYLE",
  "626085974": "IFCSURFACETEXTURE",
  "1351298697": "IFCSURFACESTYLEWITHTEXTURES",
  "846575682": "IFCSURFACESTYLESHADING",
  "1607154358": "IFCSURFACESTYLEREFRACTION",
  "3303107099": "IFCSURFACESTYLELIGHTING",
  "1300840506": "IFCSURFACESTYLE",
  "3049322572": "IFCSTYLEDREPRESENTATION",
  "3958052878": "IFCSTYLEDITEM",
  "2830218821": "IFCSTYLEMODEL",
  "3408363356": "IFCSTRUCTURALLOADTEMPERATURE",
  "2525727697": "IFCSTRUCTURALLOADSTATIC",
  "2162789131": "IFCSTRUCTURALLOAD",
  "2273995522": "IFCSTRUCTURALCONNECTIONCONDITION",
  "3692461612": "IFCSIMPLEPROPERTY",
  "4240577450": "IFCSHAPEREPRESENTATION",
  "3982875396": "IFCSHAPEMODEL",
  "867548509": "IFCSHAPEASPECT",
  "4165799628": "IFCSECTIONREINFORCEMENTPROPERTIES",
  "2042790032": "IFCSECTIONPROPERTIES",
  "448429030": "IFCSIUNIT",
  "2341007311": "IFCROOT",
  "3679540991": "IFCRIBPLATEPROFILEPROPERTIES",
  "1660063152": "IFCREPRESENTATIONMAP",
  "3008791417": "IFCREPRESENTATIONITEM",
  "3377609919": "IFCREPRESENTATIONCONTEXT",
  "1076942058": "IFCREPRESENTATION",
  "1222501353": "IFCRELAXATION",
  "1580146022": "IFCREINFORCEMENTBARPROPERTIES",
  "2692823254": "IFCREFERENCESVALUEDOCUMENT",
  "825690147": "IFCQUANTITYWEIGHT",
  "2405470396": "IFCQUANTITYVOLUME",
  "3252649465": "IFCQUANTITYTIME",
  "931644368": "IFCQUANTITYLENGTH",
  "2093928680": "IFCQUANTITYCOUNT",
  "2044713172": "IFCQUANTITYAREA",
  "3710013099": "IFCPROPERTYENUMERATION",
  "148025276": "IFCPROPERTYDEPENDENCYRELATIONSHIP",
  "3896028662": "IFCPROPERTYCONSTRAINTRELATIONSHIP",
  "2598011224": "IFCPROPERTY",
  "2802850158": "IFCPROFILEPROPERTIES",
  "3958567839": "IFCPROFILEDEF",
  "2267347899": "IFCPRODUCTSOFCOMBUSTIONPROPERTIES",
  "2095639259": "IFCPRODUCTREPRESENTATION",
  "2417041796": "IFCPRESENTATIONSTYLEASSIGNMENT",
  "3119450353": "IFCPRESENTATIONSTYLE",
  "1304840413": "IFCPRESENTATIONLAYERWITHSTYLE",
  "2022622350": "IFCPRESENTATIONLAYERASSIGNMENT",
  "1775413392": "IFCPREDEFINEDTEXTFONT",
  "3213052703": "IFCPREDEFINEDTERMINATORSYMBOL",
  "990879717": "IFCPREDEFINEDSYMBOL",
  "3727388367": "IFCPREDEFINEDITEM",
  "3355820592": "IFCPOSTALADDRESS",
  "2226359599": "IFCPHYSICALSIMPLEQUANTITY",
  "2483315170": "IFCPHYSICALQUANTITY",
  "101040310": "IFCPERSONANDORGANIZATION",
  "2077209135": "IFCPERSON",
  "1207048766": "IFCOWNERHISTORY",
  "1411181986": "IFCORGANIZATIONRELATIONSHIP",
  "4251960020": "IFCORGANIZATION",
  "1227763645": "IFCOPTICALMATERIALPROPERTIES",
  "2251480897": "IFCOBJECTIVE",
  "3701648758": "IFCOBJECTPLACEMENT",
  "1918398963": "IFCNAMEDUNIT",
  "2706619895": "IFCMONETARYUNIT",
  "3368373690": "IFCMETRIC",
  "677618848": "IFCMECHANICALSTEELMATERIALPROPERTIES",
  "4256014907": "IFCMECHANICALMATERIALPROPERTIES",
  "2597039031": "IFCMEASUREWITHUNIT",
  "3265635763": "IFCMATERIALPROPERTIES",
  "2199411900": "IFCMATERIALLIST",
  "1303795690": "IFCMATERIALLAYERSETUSAGE",
  "3303938423": "IFCMATERIALLAYERSET",
  "248100487": "IFCMATERIALLAYER",
  "1847130766": "IFCMATERIALCLASSIFICATIONRELATIONSHIP",
  "1838606355": "IFCMATERIAL",
  "30780891": "IFCLOCALTIME",
  "1566485204": "IFCLIGHTINTENSITYDISTRIBUTION",
  "4162380809": "IFCLIGHTDISTRIBUTIONDATA",
  "3452421091": "IFCLIBRARYREFERENCE",
  "2655187982": "IFCLIBRARYINFORMATION",
  "3020489413": "IFCIRREGULARTIMESERIESVALUE",
  "852622518": "IFCGRIDAXIS",
  "3548104201": "IFCEXTERNALLYDEFINEDTEXTFONT",
  "3207319532": "IFCEXTERNALLYDEFINEDSYMBOL",
  "1040185647": "IFCEXTERNALLYDEFINEDSURFACESTYLE",
  "2242383968": "IFCEXTERNALLYDEFINEDHATCHSTYLE",
  "3200245327": "IFCEXTERNALREFERENCE",
  "1648886627": "IFCENVIRONMENTALIMPACTVALUE",
  "3796139169": "IFCDRAUGHTINGCALLOUTRELATIONSHIP",
  "770865208": "IFCDOCUMENTINFORMATIONRELATIONSHIP",
  "1154170062": "IFCDOCUMENTINFORMATION",
  "1376555844": "IFCDOCUMENTELECTRONICFORMAT",
  "2949456006": "IFCDIMENSIONALEXPONENTS",
  "1045800335": "IFCDERIVEDUNITELEMENT",
  "1765591967": "IFCDERIVEDUNIT",
  "1072939445": "IFCDATEANDTIME",
  "3510044353": "IFCCURVESTYLEFONTPATTERN",
  "2367409068": "IFCCURVESTYLEFONTANDSCALING",
  "1105321065": "IFCCURVESTYLEFONT",
  "539742890": "IFCCURRENCYRELATIONSHIP",
  "602808272": "IFCCOSTVALUE",
  "1065062679": "IFCCOORDINATEDUNIVERSALTIMEOFFSET",
  "347226245": "IFCCONSTRAINTRELATIONSHIP",
  "613356794": "IFCCONSTRAINTCLASSIFICATIONRELATIONSHIP",
  "1658513725": "IFCCONSTRAINTAGGREGATIONRELATIONSHIP",
  "1959218052": "IFCCONSTRAINT",
  "2732653382": "IFCCONNECTIONSURFACEGEOMETRY",
  "4257277454": "IFCCONNECTIONPORTGEOMETRY",
  "2614616156": "IFCCONNECTIONPOINTGEOMETRY",
  "2859738748": "IFCCONNECTIONGEOMETRY",
  "3264961684": "IFCCOLOURSPECIFICATION",
  "3639012971": "IFCCLASSIFICATIONNOTATIONFACET",
  "938368621": "IFCCLASSIFICATIONNOTATION",
  "1098599126": "IFCCLASSIFICATIONITEMRELATIONSHIP",
  "1767535486": "IFCCLASSIFICATIONITEM",
  "747523909": "IFCCLASSIFICATION",
  "622194075": "IFCCALENDARDATE",
  "2069777674": "IFCBOUNDARYNODECONDITIONWARPING",
  "1387855156": "IFCBOUNDARYNODECONDITION",
  "3367102660": "IFCBOUNDARYFACECONDITION",
  "1560379544": "IFCBOUNDARYEDGECONDITION",
  "4037036970": "IFCBOUNDARYCONDITION",
  "3869604511": "IFCAPPROVALRELATIONSHIP",
  "390851274": "IFCAPPROVALPROPERTYRELATIONSHIP",
  "2080292479": "IFCAPPROVALACTORRELATIONSHIP",
  "130549933": "IFCAPPROVAL",
  "1110488051": "IFCAPPLIEDVALUERELATIONSHIP",
  "411424972": "IFCAPPLIEDVALUE",
  "639542469": "IFCAPPLICATION",
  "618182010": "IFCADDRESS",
  "3630933823": "IFCACTORROLE",
  "599546466": "FILE_DESCRIPTION",
  "1390159747": "FILE_NAME",
  "1109904537": "FILE_SCHEMA",
};
