import {
  Camera,
  Color,
  Vector3,
  Vector2,
  WebGLRenderer,
  Scene,
  PerspectiveCamera,
  Vector4,
  MeshBasicMaterial,
  Mesh,
  CylinderGeometry,
  Quaternion,
  CanvasTexture,
  Sprite,
  SpriteMaterial,
  SphereGeometry,
  BackSide,
  AmbientLight,
  MeshToonMaterial,
  PointLight,
  Raycaster,
  Object3D,
  Group,
} from "three";
import { Component, Updateable, Disposable, Event } from "app/components/ifcjs/core";
import { ViewerAPI, viewerAPI } from "app/common/ViewerAPI";
/*
 *
 *  Why? to make the gizmo reuse
 *       the graphics context and camera controller from ifc viewer,
 *       also easier to make it look integrated rather than a square patched on
 *
 *  The logic flow is as follows:
 *
 *    - A render loop running on EVERY animation frame in IfcContext calls
 *       context.items.components[*].update(delta)
 *
 *    - the IfcNavCube is sneaked in there via IfcContext.addComponent
 *
 *    - if redraw is needed render a new scene on top in the corner
 *        (new scene to avoid lighting, clipping and camera conflicts,
 *        but reuse the canvas & renderer)
 *
 *    - navGizmoSlice is used to delegate interactions with the rest of the app
 *
 *    - communication with navGizmoSlice is through the UI-related variables
 *
 */

export class NavGizmo extends Component implements Updateable, Disposable {
  // OBC requirements
  enabled = true;
  static readonly uuid = "b2dd0184-36bb-4d9b-a6ab-537f6944806e";
  readonly onAfterUpdate = new Event();
  readonly onBeforeUpdate = new Event();
  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  renderer: WebGLRenderer;
  needsUpdate: boolean = true;
  camera: Camera;
  viewport: Vector4;
  viewportSide: number;
  uiCamera: Camera;
  uiScene: Scene;
  interactiveObjects: Object3D[];
  bgSphere: Object3D;
  radius: number;
  mousePos: Vector2;

  // React UI related
  viewportOffsets: Vector4;
  isRightPanelOpen: boolean;
  isHovering: boolean;
  isAxisSelected: boolean;
  selectedAzimuthAngle: number; // horizontal: 0rad front, Math.PI / 2 left
  selectedPolarAngle: number; // vertical: Math.PI / 2 front, 0rad down

  private viewer: ViewerAPI;

  constructor(viewer: ViewerAPI) {
    super(viewer._components);
    this.viewer = viewer;
    viewer._components.add(NavGizmo.uuid, this);

    this.viewport = new Vector4();
    this.viewportSide = 0; // dynamic based on zoom level and em size, see updateCamera
    this.camera = viewer._camera.three;
    this.renderer = viewer._renderer.three;
    this.interactiveObjects = [];
    this.viewportOffsets = new Vector4();
    // note: this is just in-scene radius, change viewportSide for size, see updateCamera
    this.radius = 1.2;
    // middle of screen => not selected
    this.mousePos = new Vector2(0, 0);

    const dom = viewer._renderer.container;

    const updateMousePos = (posX: number, posY: number) => {
      this.mousePos.set(
        (posX / dom.clientWidth) * 2.0 - 1,
        (1 - posY / dom.clientHeight) * 2.0 - 1
      );
    };
    // for touch and mouse events
    dom.addEventListener("pointermove", e => updateMousePos(e.clientX, e.clientY));
    // for backwards compatibility
    dom.addEventListener("mousemove", e => updateMousePos(e.clientX, e.clientY));
    // for touch taps
    dom.addEventListener("pointerdown", e => updateMousePos(e.clientX, e.clientY));

    this.isRightPanelOpen = false;
    this.isHovering = false;
    this.isAxisSelected = false;
    this.selectedAzimuthAngle = 0;
    this.selectedPolarAngle = 0;

    const axes = [
      { color: 0xa32b24, name: "X", direction: new Vector3(1, 0, 0) },
      { color: 0x07710c, name: "Y", direction: new Vector3(0, 1, 0) },
      { color: 0x2058b3, name: "Z", direction: new Vector3(0, 0, 1) },
    ];
    // the color every axis becomes when hovered over
    const globalHighlightColor = 0x2b3b55;
    this.uiScene = new Scene();
    {
      /* Camera */
      // have some perspective, but not too much
      this.uiCamera = new PerspectiveCamera(40, 1, 0.1, 10);
      // must be added to scene for Raycaster to work
      this.uiScene.add(this.uiCamera);
      this.updateCamera();
    }
    {
      /* Background Sphere */
      // visual + hitbox for when the gizmo is active
      const geometry = new SphereGeometry(this.radius);
      const sphere = new Mesh(
        geometry,
        new MeshBasicMaterial({
          side: BackSide,
          transparent: true,
          opacity: 0.1,
          depthTest: false,
        })
      );

      sphere.userData = {
        isSelectable: true,
        colorDefault: new Color(0xcccccc),
        // slight blue highlight
        colorHighlight: new Color(0xafcdfe),
      };
      this.bgSphere = sphere;
      this.uiScene.add(sphere);
      this.interactiveObjects.push(sphere);
    }

    {
      /* Axes */

      const makeThickLine = (
        color: number,
        direction: Vector3,
        offset: number,
        length: number,
        thickness: number
      ) => {
        /*
          "Due to limitations of the OpenGL Core Profile with the WebGL renderer 
            on most platforms linewidth will always be 1 regardless of the set value"
          Cylinders they shall be!
        */
        const geometry = new CylinderGeometry(
          thickness * 0.1,
          thickness,
          length,
          4,
          1,
          false,
          Math.PI / 2
        );

        const material = new MeshToonMaterial({});
        const cylinder = new Mesh(geometry, material);
        cylinder.position.copy(direction).multiplyScalar(offset);

        // apply local rotation to align with axis
        const cylinderUpAxis = new Vector3(0, 1, 0);
        const quaternion = new Quaternion().setFromUnitVectors(cylinderUpAxis, direction);
        cylinder.applyQuaternion(quaternion);

        cylinder.userData = {
          isSelectable: true,
          colorDefault: new Color(color),
          colorHighlight: new Color(globalHighlightColor),
        };

        return cylinder;
      };

      const makeHandleSprite = (color: Color, text: string) => {
        const canvas = document.createElement("canvas");
        canvas.width = 64;
        canvas.height = 64;

        const graphicsContext = canvas.getContext("2d");
        if (graphicsContext === null) return null;

        graphicsContext.font = "bold 24px Arial"; // non-Serif like the main font
        graphicsContext.textAlign = "center";
        graphicsContext.fillStyle = "#FFFFFF";
        graphicsContext.fillText(text, 32, 41);

        const texture = new CanvasTexture(canvas);

        const obj = new Sprite(
          new SpriteMaterial({
            map: texture,
            toneMapped: false,
          })
        );

        obj.userData = {
          isSelectable: true,
          colorDefault: new Color(0xffffff),
          colorHighlight: new Color(0xffffff),
        };
        return obj;
      };

      axes.forEach(ax => {
        const createSubGroup = (subAxis: Vector3, subAxisName: string | null) => {
          const subGroup = new Group();

          {
            /* Interactive handle behind the text */
            const thickLine = makeThickLine(ax.color, subAxis, 0.43, 0.78, 0.16);

            const sphereGeometry = new SphereGeometry(0.2);
            const sphere = new Mesh(
              sphereGeometry,
              new MeshBasicMaterial({
                side: BackSide,
                opacity: 1.0,
              })
            );
            sphere.position.copy(subAxis);
            sphere.userData = {
              isSelectable: true,
              // color match the after-illumination color of the thickLine
              colorDefault: new Color(ax.color).multiplyScalar(1.2),
              colorHighlight: new Color(globalHighlightColor),
            };

            subGroup.add(sphere);
            subGroup.add(thickLine);

            subGroup.userData = {
              isSelectable: true,
              isAxis: true,
            };

            this.interactiveObjects.push(sphere, thickLine);
            [thickLine, sphere].forEach(
              x =>
                (x.userData = {
                  ...x.userData,
                  isAxisHitbox: true,
                  axisGroup: subGroup,
                  axisVector: subAxis,
                })
            );
          }

          {
            /* Text Sprite, always faces the camera */
            let sprite = null;
            if (subAxisName != null) {
              sprite = makeHandleSprite(new Color(ax.color), subAxisName);
              if (sprite === null) {
                console.log("Failed to create axis sprites");
                return subGroup;
              }
              sprite.position.copy(subAxis);
              sprite.scale.setScalar(0.75);
              subGroup.add(sprite);
            }
          }

          this.uiScene.add(subGroup);
        };

        // create positive and negative half axis
        createSubGroup(new Vector3().copy(ax.direction), ax.name);
        createSubGroup(new Vector3().copy(ax.direction).multiplyScalar(-1), null);
      });
    }

    {
      /* Lighting */

      // medium shadows
      const ambientLight1 = new AmbientLight(0x7a7a7a, 5);
      ambientLight1.position.set(-10, -10, -10);
      this.uiScene.add(ambientLight1);

      // golden sun
      const directionalLight = new PointLight(0xffcba5, 40, 100, 1.5);
      directionalLight.position.set(2, 5, 2);
      this.uiScene.add(directionalLight);
    }
  }
  async dispose() {
    this.enabled = false;
    this.onBeforeUpdate.reset();
    this.onAfterUpdate.reset();

    // todo: if required for reseting state:
    // const disposer = await this.components.tool.get(OBC.Disposer);
    // disposer.destroy(this.<all meshes>);
    // window.removeEventListener("mousemove", this.logMessage);
  }

  get() {
    return {};
  }

  updateCamera() {
    this.camera = this.viewer._camera.three;
    this.renderer.getViewport(this.viewport); // (x,y,z,w) <-> (x,y,width,height)

    /* side panels have weird css, use the their static pixel values:
        left+right = 56px + 56px = 112px
        top+bottom = 64px + 30px = 94px
    */

    // (left, bottom, right, top)
    this.viewportOffsets.set(56, 30, this.isRightPanelOpen ? 400 : 56, 64);
    this.viewportSide = 140; // logical pixels(they scale with zoom)

    const camForward = new Vector3();
    this.camera.getWorldDirection(camForward); // forward vector, -Z
    camForward.normalize();

    this.uiCamera.up.copy(this.camera.up);
    this.uiCamera.position.copy(camForward).multiplyScalar(-3.5);
    this.uiCamera.lookAt(new Vector3(0, 0, 0));

    this.renderer.setViewport(
      this.viewport.z - this.viewportOffsets.z - this.radius * this.viewportSide,
      this.viewport.w - this.viewportOffsets.w - this.radius * this.viewportSide,
      this.viewportSide,
      this.viewportSide
    );
  }

  interact() {
    const raycaster = new Raycaster();
    const mousePos = new Vector2().copy(this.mousePos);

    /* offset viewport's center in raycaster's Normalized Device Coordinates */
    mousePos.x -=
      (this.viewport.z * 0.5 - this.viewportOffsets.z - (this.radius - 0.5) * this.viewportSide) /
      (0.5 * this.viewport.z);
    mousePos.y -=
      (this.viewport.w * 0.5 - this.viewportOffsets.w - (this.radius - 0.5) * this.viewportSide) /
      (0.5 * this.viewport.w);

    // rescale to map the coordinates to gizmo viewport's [-1,1]
    mousePos.x /= this.viewportSide / this.viewport.z;
    mousePos.y /= this.viewportSide / this.viewport.w;

    this.uiScene.children.forEach(child => {
      if (child.userData.isSelectable && child.userData.isAxis) {
        child.children.forEach(axisChild => {
          //@ts-ignore
          axisChild.material.color.set(axisChild.userData.colorDefault);
        });
      }
    });

    // sphere always reflects hover state, reset it here, update on intersection
    //@ts-ignore
    this.bgSphere.material.color.set(this.bgSphere.userData.colorDefault);
    this.isAxisSelected = false;

    // if outside viewport, no use doind the intersections
    if (Math.abs(mousePos.x) >= 1 || Math.abs(mousePos.y) >= 1) {
      this.isHovering = false;
      return;
    }

    raycaster.setFromCamera(mousePos, this.uiCamera);
    const intersects = raycaster.intersectObjects(this.interactiveObjects);

    if (intersects.length > 0) {
      const object = intersects[0].object;

      if (object.userData.isAxisHitbox) {
        object.userData.axisGroup.children.forEach((axisChild: any) => {
          //@ts-ignore
          axisChild.material.color.set(axisChild.userData.colorHighlight);
        });
        this.isAxisSelected = true;

        // get coses of angles to transform axis vector to pitch and yaw
        const prFront = new Vector3(0, 0, 1).dot(object.userData.axisVector);
        const prLeft = new Vector3(1, 0, 0).dot(object.userData.axisVector);
        const prUp = new Vector3(0, 1, 0).dot(object.userData.axisVector);
        this.selectedAzimuthAngle = Math.atan2(prLeft, prFront);
        this.selectedPolarAngle = Math.acos(prUp);
      }

      //@ts-ignore
      this.bgSphere.material.color.set(this.bgSphere.userData.colorHighlight);
      this.isHovering = true;

      return true;
    } else {
      this.isHovering = false;
      return false;
    }
  }

  rotateToAxis() {
    if (!this.isAxisSelected) return;

    // todo: camera-controls picks the long path for some angles
    this.viewer.rotateToAngle({
      azimuthAngle: this.selectedAzimuthAngle,
      // azimuthAngle: Math.PI / 4,
      polarAngle: this.selectedPolarAngle,
      // polarAngle: Math.PI / 4,
      enableTransition: true,
    });
  }

  async update() {
    if (!this.needsUpdate) return;

    if (viewerAPI()) {
      this.viewer = viewerAPI();
    }

    this.updateCamera();
    this.interact();

    // redraw on top of other scene
    const autoClear = this.renderer.autoClear;
    this.renderer.autoClear = false;
    this.renderer.clearDepth();
    // plan clipping interferes
    const clippingPlanes = this.renderer.clippingPlanes;
    this.renderer.clippingPlanes = [];

    this.renderer.render(this.uiScene, this.uiCamera);

    this.renderer.autoClear = autoClear;
    this.renderer.setViewport(this.viewport);
    this.renderer.clippingPlanes = clippingPlanes;
    // this.context.renderer.get().localClippingEnabled = true;
    // this.context.renderer.postproduction.enabled = hasClipping;
    this.needsUpdate = false;
  }
}
