import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuItemProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { IDSValidationEntity } from "app/common/idsSpec";
import { TOOLS } from "app/common/types";
import FillFlexParent from "app/components/FillFlexParent";
import {
  AddIconInline,
  AnimatedAddIcon,
  ToolsSidebarBody,
  ToolsSidebarHeader,
  UsageHint,
} from "app/components/ToolsSideBar/common";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxRow } from "app/components/common/BoxRow";
import { Heavy } from "app/components/common/Heavy";
import InlineIcon, { inlineIconStyle } from "app/components/common/InlineIcon";
import { LinkArrow } from "app/components/common/LinkArrow";
import { LocalIDSPicker } from "app/components/common/LocalIDSPicker";
import { RichSpan } from "app/components/common/RichSpan";
import { useIDSFileLoad } from "app/components/common/useFileLoad";
import { DIMENSION_TOOL_HELP_CONTENT } from "app/components/help/HelpTexts";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { idsSpecDownloadResultsCSVFile } from "app/state/slices/ifcManager/idsEditor";
import {
  createIDSSpec,
  createNewIDSFile,
  idsSetMode,
  idsSpecDownloadIDSFile,
  idsSpecDownloadSpecsCSVFile,
  idsSpecRunValidation,
  moveToIDSSection,
  moveToIDSSpec,
  selectIDSFileName,
  selectIDSMode,
  selectIDSSpecSelectedId,
  selectIDSSpecs,
  selectIDSSpecsOrder,
  selectIsIDSValidationRunning,
  selectIsToolActive,
  selectIsWaitingForUpload,
  selectModelCloudId,
  uploadModel,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { useCallback, useMemo, useRef, useState } from "react";
import { NodeRendererProps, Tree, TreeApi } from "react-arborist";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

import { NodeResultItem } from "./NodeResultItem";
import { NodeSpec } from "./NodeSpec";
import { IDSSpecTreeNode, nodeHeight } from "./common";

const presentResultsAsTree = (
  specId: string,
  idPrefix: string,
  results?: IDSValidationEntity[]
): IDSSpecTreeNode[] => {
  const nodes: IDSSpecTreeNode[] = [];
  let id = 0;
  console.log("results:", specId, results);

  if (results) {
    for (let index = 0; index < results.length; index++) {
      nodes.push({
        name: idPrefix + String(id),
        id: idPrefix + String(id),
        metadata: {
          type: "resultItem",
          restrictionPath: {
            specId,
            section: "results",
            facetIndex: index,
          },
          hasPassedValidation: results[index]?.failedRequirements?.length > 0,
        },
      });
      id += 1;
    }
    id += 1;
  }
  nodes.sort((a, b) => {
    const aPassed = a?.metadata?.hasPassedValidation == true;
    const bPassed = b?.metadata?.hasPassedValidation == true;
    if (aPassed == bPassed) return 0;
    if (!bPassed) return -1;
    if (!aPassed) return +1;
    // never reached
    return 0;
  });

  if (nodes.length == 0) {
    nodes.push({
      name: "No result items",
      id: idPrefix + String(id),
      metadata: {
        type: "resultItem",
        restrictionPath: {
          specId,
          section: "results",
          facetIndex: 0,
        },
      },
    });
  }

  return nodes;
};

const CustomNode = (props: NodeRendererProps<IDSSpecTreeNode>) => {
  const metadata = props.node.data.metadata;
  if (metadata.type == "specification") return <NodeSpec {...props} />;
  if (metadata.type == "resultItem") return <NodeResultItem {...props} />;

  return <>No matching node.</>;
};

const LabelButton = (props: ButtonProps) => {
  return <Button component="label" {...props} />;
};

const menuItemProps: MenuItemProps = {
  disableGutters: true,
  sx: {
    paddingLeft: theme.sppx.compact2H,
    paddingRight: 0,
    paddingTop: theme.sppx.compact2V,
    paddingBottom: theme.sppx.compact2V,
  },
};

const MenuItemExtension = (props: BoxProps) => {
  const { sx, ...otherProps } = props;
  return (
    <Box
      component="span"
      sx={{
        marginLeft: theme.sppx.compact2H,
        marginRight: theme.sppx.compactV,
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export function IDSEditorSidebar() {
  const treeAPI = useRef<TreeApi<IDSSpecTreeNode>>();
  const idsSpecs = useAppSelector(selectIDSSpecs);
  const idsSpecsOrder = useAppSelector(selectIDSSpecsOrder);
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectIsToolActive(TOOLS.IDSEDITOR));
  const idsSpecId = useAppSelector(selectIDSSpecSelectedId);

  const modelCloudId = useAppSelector(selectModelCloudId);
  const isWaitingForUpload = useAppSelector(selectIsWaitingForUpload);

  const idsMode = useAppSelector(selectIDSMode);
  const idsFileName = useAppSelector(selectIDSFileName);
  const onIDSFileLoad = useIDSFileLoad();

  const isIDSValidationRunning = useAppSelector(selectIsIDSValidationRunning);

  const [exportAsAnchor, setExportAsAnchor] = useState(null as null | HTMLElement);

  const onDrop = useCallback((files: File[], _rejected: FileRejection[], e: DropEvent) => {
    //@ts-ignore
    onIDSFileLoad({ ...e, target: { ...e.target, files: files } }).finally(() =>
      setExportAsAnchor(null)
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const specList = useMemo(() => {
    const roots: IDSSpecTreeNode[] = [];

    if (!idsSpecsOrder || idsSpecsOrder.length == 0) return roots;

    for (const specId of idsSpecsOrder) {
      let children: IDSSpecTreeNode[] = [];
      children =
        (idsMode == "check" &&
          presentResultsAsTree(specId, specId + "-res-", idsSpecs[specId]?._validationResults)) ||
        [];
      roots.push({
        name: idsSpecs[specId].$_name,
        id: specId,
        children,
        metadata: {
          type: "specification",
          resultsLength: idsSpecs[specId]?._validationResults?.length ?? 0,
        },
      });
    }

    return roots;
  }, [idsSpecs]);

  const usageHint = () =>
    (isDragActive && (
      <>
        <RichSpan key="idsd&duh1">
          <b>Drop</b> the file here to{" "}
          <Button component="label" sx={{ padding: "0px" }}>
            <FileUploadIcon />
            {
              //@ts-ignore
              <LocalIDSPicker
                onChange={onIDSFileLoad}
                {...{ ...getInputProps(), ref: undefined }}
              />
            }
          </Button>{" "}
          <Heavy>import</Heavy> it.
        </RichSpan>
        <br />
      </>
    )) ||
    (idsFileName == null && (
      <BoxCol
        sx={{
          gap: theme.sppx.compactH,
          alignItems: "center",
        }}
      >
        <RichSpan key="idsnofileuh1">No IDS loaded.</RichSpan>
        <RichSpan key="idsnofileuh2">
          <Heavy>Click </Heavy>
          <Button
            component="label"
            sx={{ padding: "0px" }}
            onClick={() => dispatch(createNewIDSFile())}
          >
            <NoteAddIcon />
          </Button>{" "}
          to <b>Create</b> a .ids file
        </RichSpan>
        <RichSpan key="idsnofileuh3">
          <Heavy>Click </Heavy>
          <Button component="label" sx={{ padding: "0px" }}>
            <FileUploadIcon />
            {
              //@ts-ignore
              <LocalIDSPicker
                onChange={onIDSFileLoad}
                {...{ ...getInputProps(), ref: undefined }}
              />
            }
          </Button>{" "}
          to <b>Import</b> a .ids file
        </RichSpan>
      </BoxCol>
    )) ||
    (Object.keys(idsSpecs).length == 0 && (
      <>
        <RichSpan key="idsnospecsuh1">
          <b>Click </b>{" "}
          <IconButton
            disabled={idsMode == "check"}
            onClick={() => dispatch(createIDSSpec())}
            sx={{ padding: "0px" }}
          >
            <AddIconInline />
          </IconButton>{" "}
          to create a new Specification.
        </RichSpan>
        <br />
      </>
    )) ||
    (idsMode == "edit" && Object.keys(idsSpecs).length > 0 && !idsSpecId && (
      <>
        <RichSpan key="idsnoedituh1">
          <b>Click</b> on a specification to <Heavy>toggle</Heavy> editing
        </RichSpan>
        <br />
      </>
    )) ||
    (idsMode == "check" && Object.keys(idsSpecs).length > 0 && (
      <>
        <RichSpan key="idsnocheckuh1">
          <b>Click</b>{" "}
          <CheckCircleOutlineIcon sx={{ ...inlineIconStyle, fill: theme.palette.softPass }} />/
          <HighlightOffIcon sx={{ ...inlineIconStyle, fill: theme.palette.softFail }} /> to expand{" "}
          <Heavy>results</Heavy>
        </RichSpan>
        <br />
        <RichSpan
          key="idsnocheckuh2"
          sx={{
            lineHeight: "2",
          }}
        >
          <b>Click</b>{" "}
          <InlineIcon
            icon={EditIcon}
            sx={{
              // background: theme.palette.primary.main,
              borderRadius: "4px",
              // color: "#fff",
              color: theme.palette.primary.main,
            }}
          />{" "}
          to go back to <Heavy>editing</Heavy>
        </RichSpan>
        <br />
        <RichSpan
          key="idsnocheckuh3"
          sx={{
            lineHeight: "2",
          }}
        >
          <b>Click</b>{" "}
          <InlineIcon
            icon={ImportExportIcon}
            sx={{
              borderRadius: "4px",
              color: theme.palette.primary.main,
            }}
          />{" "}
          to <Heavy>export</Heavy> results
        </RichSpan>
      </>
    )) ||
    (Object.keys(idsSpecs).length > 0 && idsSpecId && (
      <>
        <RichSpan key="idsnoconstruh1">
          Add constraints in the <br /> <Heavy>Applicability</Heavy> or <Heavy>Requirements</Heavy>{" "}
          tabs.
        </RichSpan>
        <br />
      </>
    ));

  return (
    <Box
      sx={{
        minHeight: "100%",
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        overflow: "hidden",
      }} // provide clicking space for hide all
      {...getRootProps()}
      onClick={undefined}
    >
      <ToolsSidebarHeader title={"IDS"} HelpContent={DIMENSION_TOOL_HELP_CONTENT}>
        <Box sx={{ flexGrow: 1 }} />

        {idsFileName == null && (
          <Tooltip title="Import a .ids file" placement="bottom">
            <Button component="label" sx={{ padding: "8px", marginRight: theme.sppx.compactV }}>
              <FileUploadIcon />
              {
                //@ts-ignore
                <LocalIDSPicker
                  onChange={onIDSFileLoad}
                  {...{ ...getInputProps(), ref: undefined }}
                />
              }
            </Button>
          </Tooltip>
        )}
        {idsFileName == null && (
          <Tooltip title="Create a new .ids file" placement="bottom">
            <Button
              component="label"
              sx={{ padding: "8px", marginRight: theme.sppx.compactV }}
              onClick={() => dispatch(createNewIDSFile())}
            >
              <NoteAddIcon />
            </Button>
          </Tooltip>
        )}
        {idsFileName != null && (
          <Tooltip title="Import or Export Specifications" placement="top">
            <Button
              component="label"
              onClick={e => setExportAsAnchor(e.currentTarget)}
              sx={{ padding: "8px" }}
            >
              <ImportExportIcon color="primary" />
            </Button>
          </Tooltip>
        )}
        <Menu
          anchorEl={exportAsAnchor}
          open={exportAsAnchor != null}
          onClose={() => setExportAsAnchor(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem disableGutters sx={{ ...menuItemProps.sx }} component={LabelButton}>
            Import
            <Box flexGrow={1} />
            <MenuItemExtension>.ids</MenuItemExtension>
            <ListItemIcon>
              <FileUploadIcon color="primary" fontSize="small" />
            </ListItemIcon>
            {
              //@ts-ignore
              <LocalIDSPicker {...{ ...getInputProps(), ref: undefined }} />
            }
          </MenuItem>
          <Divider sx={{ "&&&": { marginTop: 0, marginBottom: 0 } }} />
          <MenuItem
            {...menuItemProps}
            onClick={() => {
              dispatch(idsSpecDownloadIDSFile());
              setExportAsAnchor(null);
            }}
          >
            Export Specifications
            <Box flexGrow={1} />
            <MenuItemExtension>.ids</MenuItemExtension>
            <ListItemIcon>
              <FileDownloadIcon color="primary" fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            {...menuItemProps}
            onClick={() => {
              dispatch(idsSpecDownloadSpecsCSVFile());
              setExportAsAnchor(null);
            }}
          >
            Export Specifications
            <Box flexGrow={1} />
            <MenuItemExtension>.csv</MenuItemExtension>
            <ListItemIcon>
              <FileDownloadIcon color="primary" fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            {...menuItemProps}
            onClick={() => {
              dispatch(idsSpecDownloadResultsCSVFile());
              setExportAsAnchor(null);
            }}
            disabled={idsMode == "edit"}
          >
            Export Results
            <Box flexGrow={1} />
            <MenuItemExtension>.csv</MenuItemExtension>
            <ListItemIcon>
              <FileDownloadIcon color="primary" fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
        {idsFileName != null && (
          <Tooltip title="Create a Specification" placement="right">
            <IconButton
              disabled={idsMode == "check"}
              onClick={() => dispatch(createIDSSpec())}
              sx={{ padding: "4px" }}
            >
              <AnimatedAddIcon
                sx={{ ...(idsMode == "check" && { color: theme.palette.inactiveGrey }) }}
              />
            </IconButton>
          </Tooltip>
        )}
      </ToolsSidebarHeader>

      <ToolsSidebarBody
        onClick={(e: any) => {
          if ((e.target as HTMLDivElement)?.className?.includes?.("objectTreeContainer")) {
            dispatch(moveToIDSSpec(null));
          }
        }}
        sx={{ padding: "0" }}
      >
        {idsFileName != null && (
          <BoxRow
            sx={{
              width: "100%",
              boxSizing: "border-box",
              overflow: "hidden",
              padding: theme.pad.compact,
              borderBottom: `1px solid ${theme.palette.softBulletGrey}`,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                overflowX: "hidden",
                overflowY: "auto",
                wordBreak: "break-all",
                textWrap: "pretty",
                maxHeight: "8rem",
              }}
            >
              <span> {idsFileName ? idsFileName : "No file name"}</span>
            </Typography>
            <Tooltip title="Edit IDS Information" placement="top">
              <Button
                component="label"
                sx={{
                  padding: theme.sppx.compactH,
                  width: "40px",
                  marginLeft: theme.sppx.compactV,
                  flexShrink: 0,
                }}
                onClick={() => dispatch(moveToIDSSection("fileInfo"))}
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </BoxRow>
        )}
        <UsageHint
          height={idsMode == "check" || idsFileName == null ? "7.5rem" : "4.5rem"}
          sx={{ marginBottom: theme.sppx.compactV }}
        >
          {usageHint()}
        </UsageHint>
        {idsFileName && (
          <FillFlexParent>
            {({ width, height }) => (
              <Tree
                ref={treeAPI}
                disableEdit
                disableDrag
                disableDrop
                indent={theme.sp.compact2H}
                data={specList}
                width={width}
                height={height}
                openByDefault={false}
                rowHeight={nodeHeight + 5}
                className="objectTreeContainer"
              >
                {CustomNode}
              </Tree>
            )}
          </FillFlexParent>
        )}

        {!idsFileName && (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
              overflowX: "hidden",
              padding: theme.pad.spacious,
            }}
          >
            <Box flexGrow={0.3} flexShrink={1} />
            <Divider sx={{ width: "100%", marginBottom: theme.sppx.spacious2H }} />
            <Typography sx={{ width: "100%", textWrap: "wrap" }} component="p">
              <Typography sx={{ fontSize: "1.2em", fontWeight: "500" }} component="span">
                {" "}
                Looking for Ready-Made IDS Files?
              </Typography>
              <br />
              <br />
              Visit our{" "}
              <Link href="https://hub.sortdesk.com" target="_blank">
                Model Quality Hub
              </Link>{" "}
              to access and share IDS files with the community.
              <br />
              <br />
              <Heavy>Stop reinventing the wheel</Heavy>—find what you need, and contribute your own!
            </Typography>
            <BoxRow
              sx={{
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "center",
                width: "100%",
                paddingTop: theme.sppx.spaciousV,
                paddingBottom: theme.sppx.spacious2H,
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                component="a"
                href="https://hub.sortdesk.com"
                target="_blank"
                sx={{ flexGrow: 1 }}
              >
                <Typography variant="button" sx={{ textTransform: "none" }}>
                  Model Quality Hub <LinkArrow active={false} />
                </Typography>
              </Button>
            </BoxRow>
            <Divider sx={{ width: "100%" }} />
            <Box flexGrow={0.7} flexShrink={1} />
          </Box>
        )}

        {idsFileName && (
          <BoxRow
            sx={{
              height: "66px",
              width: "100%",
              borderTop: `1px solid ${theme.palette.softBulletGrey}`,
              paddingTop: theme.sppx.compact2V,
              paddingBottom: theme.sppx.compact2V,
              paddingLeft: theme.sppx.compactV,
              paddingRight: theme.sppx.compactV,
              boxSizing: "border-box",
            }}
          >
            <Tooltip title="Run all specifications" placement="top">
              <Button
                disabled={isIDSValidationRunning || isWaitingForUpload}
                variant="contained"
                //@ts-ignore
                color={idsMode == "edit" ? "runGreen" : "primary"}
                sx={{
                  flexGrow: 1,
                  padding: "8px",
                }}
                onClick={() =>
                  (idsMode == "edit" &&
                    ((!modelCloudId && dispatch(uploadModel())) ||
                      dispatch(idsSpecRunValidation()))) ||
                  dispatch(idsSetMode("edit"))
                }
              >
                {idsMode == "edit" && (
                  <Typography variant="button" sx={{ textTransform: "none", lineHeight: "1" }}>
                    {(!modelCloudId &&
                      (isWaitingForUpload ? "Uploading File..." : "Upload Loaded File to Check")) ||
                      (isIDSValidationRunning ? "Checking Loaded Model.." : "Check Loaded Model")}
                  </Typography>
                )}
                {idsMode == "check" && (
                  <Typography variant="button" sx={{ textTransform: "none", lineHeight: "1" }}>
                    Edit Specifications
                  </Typography>
                )}
                <BoxCol
                  sx={{
                    color: "#fff",
                    alignItems: "center",
                  }}
                >
                  {idsMode == "edit" &&
                    (isIDSValidationRunning ? (
                      <CircularProgress
                        size="1.5rem"
                        sx={{
                          marginLeft: "0.5rem",
                        }}
                      />
                    ) : (
                      (isWaitingForUpload && <CircularProgress size="1em" />) ||
                      (!modelCloudId && (
                        <CloudUploadIcon
                          sx={{
                            color: "#fff",
                            width: "1.2em",
                            height: "1.2em",
                            marginLeft: "10px",
                          }}
                        />
                      )) || <PlayArrowIcon />
                    ))}
                  {idsMode == "check" && <EditIcon sx={{ marginLeft: "5px" }} />}
                </BoxCol>
              </Button>
            </Tooltip>
          </BoxRow>
        )}
      </ToolsSidebarBody>
    </Box>
  );
}
// const MemoIDSEditorSidebar = memo(IDSEditorSidebar);

export default IDSEditorSidebar;
