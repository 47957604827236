import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_dsn,
    beforeSend: event => {
      // ignore gtag/js blocked errors
      if (
        event.exception?.values?.some?.(
          ex =>
            ex?.type == "TypeError" &&
            ex?.value == "Failed to fetch" &&
            ex?.stacktrace?.frames?.some?.(
              frame => frame?.module == "gtag/js" && frame?.filename == "/gtag/js"
            )
        )
      )
        return null;

      return event;
    },
    // can cause big perfomance issues on staging, prod doesn't have console logs
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === "console" ? null : breadcrumb;
    },
    integrations: [
      // the Browser Tracing integration is for tracking requests through frontent <-> backend, not applicable
      new Sentry.Replay(),
    ],
    debug: false, // do not capture console.*(...) calls
    attachStacktrace: true, // show stacktraces for Sentry.captureMessage
    // Performance Monitoring
    tracesSampleRate: 0.05, //  Capture 5% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
