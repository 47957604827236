import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Drawer } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const openedMixin = (theme: Theme, _anchor: string): CSSObject => ({
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const closedMixin = (theme: Theme, _anchor: string): CSSObject => ({
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== "open" })(
  ({ theme, open, anchor }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    overflow: "hidden",
    "& .MuiPaper-root": {
      overflow: "hidden",
      minWidth: 0,
      minHeight: 0,
    },
    "& .MuiDrawer-root": {
      overflow: "visible",
      overflowX: "visible",
    },
    ...(open &&
      anchor && {
        ...openedMixin(theme, anchor),
        "& .MuiDrawer-paper": openedMixin(theme, anchor),
      }),
    ...(!open &&
      anchor && {
        ...closedMixin(theme, anchor),
        "& .MuiDrawer-paper": closedMixin(theme, anchor),
      }),
  })
);

export default StyledDrawer;
