import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { CheckboxStateType } from "app/common/types";

export interface CheckboxesState {
  values: CheckboxStateType;
}

const initialState: CheckboxesState = {
  values: {} as CheckboxStateType,
};

export const checkboxesSlice = createSlice({
  name: "checkboxes",
  initialState,
  reducers: {
    setCheckboxState: (state, action: PayloadAction<CheckboxStateType>) => {
      const [id, value] = Object.entries(action.payload)[0];
      state.values[id] = value;
    },
    setMultipleCheckboxesState: (state, action: PayloadAction<CheckboxStateType>) => {
      state.values = { ...state.values, ...action.payload };
    },
  },
});

export const { setCheckboxState, setMultipleCheckboxesState } = checkboxesSlice.actions;

export default checkboxesSlice.reducer;
