import HelpOutlineRounded from "@mui/icons-material/HelpOutlineRounded";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import HelpDialog from "app/components/help/HelpDialog";
import theme from "app/theme";
import React, { ReactNode, useState } from "react";

interface Props {
  title: string;
  hasHelpIcon?: boolean;
  HelpContent?: React.FC;
  children?: ReactNode;
}

/* this a fork of SidebarHeader for the new Tool UX
   todo: delete one of them when all tools are done */
export const ToolsSidebarHeader: React.FC<Props> = ({
  title,
  HelpContent,
  hasHelpIcon = false,
  children,
}) => {
  const [helpOpen, setHelpOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `${theme.sppx.compact2V} ${theme.sppx.compact2H}`,
          minHeight: `${theme.sppx.iUnit2}`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">{title}</Typography>
          {hasHelpIcon && HelpContent && (
            <IconButton
              onClick={() => {
                setHelpOpen(!helpOpen);
              }}
            >
              <HelpOutlineRounded />
            </IconButton>
          )}
        </Box>
        {children}
      </Box>
      <Divider />

      {hasHelpIcon && HelpContent && (
        <HelpDialog open={helpOpen} setDialogOpen={setHelpOpen} HelpContent={HelpContent} />
      )}
    </>
  );
};

export default ToolsSidebarHeader;
