import { TextField, TextFieldProps } from "@mui/material";
import { BoxRow } from "app/components/common/BoxRow";
import theme from "app/theme";

export function EditableField({
  label,
  onFocus,
  onBlur,
  sx,
  value,
  flexGrow,
  isRequired,
  validate,
  ...props
}: TextFieldProps & {
  label: string | null;
  flexGrow?: boolean;
  isRequired?: boolean;
  validate?: (value?: string) => boolean;
}) {
  //@ts-ignore
  const hasError =
    (validate != null &&
      (isRequired || (value != null && value != "")) &&
      validate(value as string) == false) ||
    (isRequired && (value == null || (value as string)?.length == 0));

  return (
    <BoxRow
      sx={{ alignItems: "flex-start", gap: theme.sppx.compactV, ...(flexGrow && { flexGrow: 1 }) }}
    >
      <TextField
        label={label}
        error={hasError}
        multiline
        sx={{
          flexGrow: "1",
          margin: theme.sppx.compactV,
          // this fixes the textarea hitbox to behave like a input
          // the only drawback is label overlap on scroll but isn't maxRows capped
          "& .MuiInputBase-root": {
            padding: "0px",
          },
          "& textarea": {
            padding: "16.5px 14px",
          },
          ...sx,
        }}
        onFocus={e => {
          onFocus && onFocus(e);
        }}
        onBlur={e => {
          onBlur && onBlur(e);
        }}
        value={value}
        {...props}
      />
    </BoxRow>
  );
}
