import { styled } from "app/theme";
import InlineIcon from "app/components/common/InlineIcon";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { default as VisibilityIcon } from "@mui/icons-material/Visibility";

export { default as VisibilityIcon } from "@mui/icons-material/Visibility";
export { default as VisibilityOffIcon } from "@mui/icons-material/VisibilityOff";
export { default as CloseIcon } from "@mui/icons-material/Close";

export const AnimatedAddIcon = styled(AddCircleOutline)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: `${theme.sp.iconSize + 9}px`, // the svg size is abnormal, compensate it
  height: `${theme.sp.iconSize + 9}px`,
  transform: "rotate(0deg)",
  transition: "transform 90ms cubic-bezier(0, 0.66, 1, 0.81) 0ms",
  "&.CreationEnabled": {
    transform: "rotate(45deg)",
  },
}));

export const AddIconInline = () => <InlineIcon icon={AddCircleOutline} active={true} />;
export const CancelIconInline = () => (
  <InlineIcon
    icon={AddCircleOutline}
    active={true}
    sx={{
      transform: "rotate(45deg)",
    }}
  />
);

export const VisibilityIconInline = () => <InlineIcon icon={VisibilityIcon} active={true} />;
