// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from "flatbuffers";

import { CivilCurve } from "./civil-curve";

export class Alignment {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): Alignment {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsAlignment(bb: flatbuffers.ByteBuffer, obj?: Alignment): Alignment {
    return (obj || new Alignment()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsAlignment(bb: flatbuffers.ByteBuffer, obj?: Alignment): Alignment {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new Alignment()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  vertical(index: number, obj?: CivilCurve): CivilCurve | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new CivilCurve()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!
        )
      : null;
  }

  verticalLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  horizontal(index: number, obj?: CivilCurve): CivilCurve | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new CivilCurve()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!
        )
      : null;
  }

  horizontalLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  absolute(index: number, obj?: CivilCurve): CivilCurve | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? (obj || new CivilCurve()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!
        )
      : null;
  }

  absoluteLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  initialPk(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
  }

  static startAlignment(builder: flatbuffers.Builder) {
    builder.startObject(4);
  }

  static addVertical(builder: flatbuffers.Builder, verticalOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, verticalOffset, 0);
  }

  static createVerticalVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[]
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startVerticalVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addHorizontal(builder: flatbuffers.Builder, horizontalOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, horizontalOffset, 0);
  }

  static createHorizontalVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[]
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startHorizontalVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addAbsolute(builder: flatbuffers.Builder, absoluteOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, absoluteOffset, 0);
  }

  static createAbsoluteVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[]
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startAbsoluteVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addInitialPk(builder: flatbuffers.Builder, initialPk: number) {
    builder.addFieldFloat32(3, initialPk, 0.0);
  }

  static endAlignment(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createAlignment(
    builder: flatbuffers.Builder,
    verticalOffset: flatbuffers.Offset,
    horizontalOffset: flatbuffers.Offset,
    absoluteOffset: flatbuffers.Offset,
    initialPk: number
  ): flatbuffers.Offset {
    Alignment.startAlignment(builder);
    Alignment.addVertical(builder, verticalOffset);
    Alignment.addHorizontal(builder, horizontalOffset);
    Alignment.addAbsolute(builder, absoluteOffset);
    Alignment.addInitialPk(builder, initialPk);
    return Alignment.endAlignment(builder);
  }
}
