import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  selectPlans,
  selectSelectedPlan,
  goToPlan,
  deselectPlan,
  selectModelsDetails,
  selectIsToolActive,
  selectModelId,
} from "app/state/slices/ifcManagerSlice";
import {
  VisibilityIcon,
  VisibilityOffIcon,
  ToolsSidebarHeader,
  ToolsSidebarBody,
  UsageHint,
} from "app/components/ToolsSideBar/common";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RichSpan } from "app/components/common/RichSpan";
import { PLAN_MANAGER_HELP_CONTENT } from "app/components/help/HelpTexts";
import theme from "app/theme";
import { memo, useMemo, useRef } from "react";
import FillFlexParent from "app/components/FillFlexParent";
import { NodeApi, NodeRendererProps, Tree, TreeApi } from "react-arborist";
import { TOOLS } from "app/common/types";
import NoModelLoaded from "app/components/NoModelLoaded";

// todo: abstract me
const nodeHeight = 6 * theme.sp.compactH;

type PlanTreeNode = {
  name: string;
  id: string;
  children?: PlanTreeNode[];
  metadata: {
    planGUID: string | null;
  };
};

const CustomNode = ({ node, style, dragHandle }: NodeRendererProps<PlanTreeNode>) => {
  const selectedPlan = useAppSelector(selectSelectedPlan);
  // note: could be set to node.isSelected to disable multiselect
  const isSelected = selectedPlan === node.data.metadata.planGUID;

  // todo: abstract (part of) this as TreeItem
  return (
    <div
      ref={dragHandle}
      className="TreeItemHover"
      style={{
        ...style,
        height: `${nodeHeight}px`,
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderRadius: "8px",
        ...(node.isLeaf &&
          isSelected && {
            backgroundColor: theme.palette.selectedBackground,
            border: "#0d47a1 2px solid",
          }),
      }}
    >
      {node.isLeaf ? (
        <Tooltip placement="top" arrow title="Toggle visibility">
          <IconButton
            color={isSelected ? "primary" : "default"}
            variant="inline"
            sx={{
              padding: `${theme.sp.compactH}px`,
              margin: "0 5px",
            }}
          >
            {isSelected ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip placement="top" arrow title="Toggle expansion">
          <IconButton
            color={node.isOpen ? "primary" : "default"}
            variant="inline"
            sx={{
              padding: `${theme.sppx.compactH}`,
              marginLeft: theme.sppx.gapTiny,
              marginRight: theme.sppx.gapTiny,
            }}
          >
            {node.isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Tooltip>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            overflowX: "auto",
            textOverflow: "inherit",
          },
        }}
      >
        <Typography>{node.data.name || "Unnamed plan"}</Typography>
      </Box>
    </div>
  );
};

export function PlanManagerSidebar() {
  const treeAPI = useRef<TreeApi<PlanTreeNode>>();
  const modelsDetails = useAppSelector(selectModelsDetails);
  const plans = useAppSelector(selectPlans);
  const dispatch = useAppDispatch();
  const selectedPlan = useAppSelector(selectSelectedPlan);
  const isVisible = useAppSelector(selectIsToolActive(TOOLS.PLAN_MANAGER));
  const modelId = useAppSelector(selectModelId);

  const toggleHighlightHandler = (node: NodeApi<PlanTreeNode>, id: string) => {
    if (id != selectedPlan) {
      dispatch(goToPlan({ planId: id }));
    } else {
      dispatch(deselectPlan());
    }
  };

  const planTrees = useMemo(() => {
    const roots: PlanTreeNode[] = [];

    if (!plans || plans.length == 0) return roots;

    const nodeMap: Record<string, PlanTreeNode> = {};

    for (const [k, v] of Object.entries(modelsDetails)) {
      const node = {
        name: v.fileName,
        id: k,
        children: [],
        metadata: {
          planGUID: null,
        },
      };
      nodeMap[k] = node;
      roots.push(node);
    }

    for (const plan of plans) {
      nodeMap[plan.modelID]?.children?.push({
        name: plan.name,
        id: `${plan.modelID}/${plan.id}`,
        metadata: {
          planGUID: plan.id,
        },
      });
    }

    return roots;
  }, [plans]);

  const usageHint = () =>
    (plans.length == 0 && <RichSpan>No plans defined.</RichSpan>) ||
    (plans.length > 0 && (
      <>
        <RichSpan key="pmnoselecteduh1">
          <b>Click</b> on any item to <b>toggle</b> it.{" "}
        </RichSpan>
        <br />
      </>
    ));

  if (!modelId) {
    return isVisible ? <NoModelLoaded /> : <></>;
  }

  return (
    <Box
      sx={{
        minHeight: "100%",
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        overflow: "hidden",
      }} // provide clicking space for hide all
      onClick={e => {
        if ((e.target as HTMLDivElement)?.className?.includes?.("objectTreeContainer")) {
          dispatch(deselectPlan());
        }
      }}
    >
      <ToolsSidebarHeader title="Plans" HelpContent={PLAN_MANAGER_HELP_CONTENT} />

      <ToolsSidebarBody>
        <UsageHint height="2.5em">{usageHint()}</UsageHint>
        <FillFlexParent>
          {({ width, height }) => (
            <Tree
              ref={treeAPI}
              disableEdit
              disableDrag
              disableDrop
              indent={theme.sp.compact2H}
              data={planTrees}
              width={width}
              height={height}
              openByDefault={true}
              rowHeight={nodeHeight}
              className="objectTreeContainer"
              onSelect={(nodes: NodeApi<PlanTreeNode>[]) => {
                const node = nodes?.[0];
                if (!node) return;
                if (node.isLeaf) {
                  if (node.data.metadata.planGUID) {
                    toggleHighlightHandler(node, node.data.metadata.planGUID);
                  }
                } else {
                  // toogle expansion instead of selection for top-level nodes
                  node.toggle();
                }
              }}
            >
              {CustomNode}
            </Tree>
          )}
        </FillFlexParent>
      </ToolsSidebarBody>
    </Box>
  );
}
const MemoPlanManagerSidebar = memo(PlanManagerSidebar);

export default MemoPlanManagerSidebar;
