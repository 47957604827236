import { Typography, Button, BoxProps } from "@mui/material";
import theme from "app/theme";

import { useFileLoad } from "./common/useFileLoad";
import { BoxColE2E } from "./common/BoxColE2E";
import { LocalIFCPicker } from "./common/LocalIFCPicker";

export default function NoModelLoaded({ sx, ...props }: BoxProps) {
  const onFileLoad = useFileLoad();

  return (
    <BoxColE2E
      sx={{
        justifyContent: "flex-start",
        p: theme.sppx.iUnit2,
        ...sx,
      }}
      {...props}
    >
      <Typography>No model loaded</Typography>
      <Button variant="outlined" size="small" component="label" sx={{ mt: theme.sppx.spaciousV }}>
        OPEN AN IFC FILE
        <LocalIFCPicker onChange={onFileLoad} />
      </Button>
    </BoxColE2E>
  );
}
