import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { viewerAPI } from "app/common/ViewerAPI";
import {
  IDSApplicability,
  IDSFacet,
  IDSInfo,
  IDSRequirement,
  IDSRestrictionAttribute,
  IDSRestrictionClassification,
  IDSRestrictionEntity,
  IDSRestrictionMaterial,
  IDSRestrictionPartOf,
  IDSRestrictionPath,
  IDSRestrictionProperty,
  IDSSpecification,
  IDSSpecificationSections,
  IDSValidationEntity,
  IDSValue,
  IDSValueObj,
  IDSValueRestrictionType,
  IDSValueType,
  NormalizeIDSOptions,
  XSRestrictionType,
  arePathsEqual,
  defaultXMLNamespaces,
  normalizeIDSInfo,
  normalizeIDSSpecification,
  readableIDSRestrictionAttribute,
  readableIDSRestrictionClassification,
  readableIDSRestrictionEntity,
  readableIDSRestrictionMaterial,
  readableIDSRestrictionPartOf,
  readableIDSRestrictionProperty,
  xsDetectBaseType,
} from "app/common/idsSpec";
import { SEVERITIES } from "app/common/types";
import { ifcTypeNames } from "app/common/webIfcReverseSchema";
import { RootState } from "app/state/store";
import { FexCodes, FirebaseFuncError, RunIDSReportArgs, firebase } from "app/utils/firebase";
import { NullOptionals } from "app/utils/objectMeta";
import download from "downloadjs";
import { CsvOutput, asString, generateCsv, mkConfig } from "export-to-csv";
import { XMLBuilder, XMLParser } from "fast-xml-parser";

import { IIfcMangerState, highlightByID, idsResetParsingError } from "../ifcManagerSlice";

export type IDSPanelState = "expanded" | "normal" | "minimized" | null;
export type IDSValidationError = {
  elem: string; // xml
  message: string; // short error
  msg: string; // full error
  path: string; // XPath for affected element e.g. "/ids/specifications/specification[1]"
  reason: string; // error cause
};

export interface IIDSEditorState {
  idsSpecs: Record<string, IDSSpecification>;
  // Note: this specId list is needed to duplicate specs right below
  // and preserve the order (through export) for the validation error XPaths
  idsSpecsOrder: string[];
  idsInfo: IDSInfo;
  idsParsingError: IDSValidationError | null;
  selectedSpecId: string | null;
  idsSpecSelectedRestrictionPath: IDSRestrictionPath | null;
  idsPanelState: IDSPanelState;
  idsPanelSection: IDSSpecificationSections | null;
  idsFileName: string | null;
  idsMode: "edit" | "check";
  isIDSValidationRunning: boolean;
}

export const initialIDSEditorState: IIDSEditorState = {
  idsSpecs: {},
  idsSpecsOrder: [],
  idsInfo: {},
  idsParsingError: null,
  selectedSpecId: null,
  idsSpecSelectedRestrictionPath: null,
  idsPanelState: "minimized",
  idsPanelSection: null,
  idsFileName: null,
  idsMode: "edit",
  isIDSValidationRunning: false,
};

const idsErrors: { [key: string]: Partial<IIfcMangerState> } = {
  /* snackbar */
  failedGeneration: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Failed to generate file",
  },
  failedDownload: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Failed to download file",
  },
};

export type IDSUIError = {
  type: string;
  affectedEnumIndexes?: boolean[];
};

const idsUIErrors: { [key: string]: IDSUIError } = {
  booleanMismatch: {
    type: "booleanMismatch",
  },
  isNotUppercase: {
    type: "isNotUppercase",
  },
};

export const createIDSSpecReducer = (state: IIfcMangerState) => {
  const _id = crypto.randomUUID();
  state.idsSpecs[_id] = {
    $_name: "New Specification",
    applicability: {
      entity: [],
      partOf: [],
      classification: [],
      attribute: [],
      property: [],
      material: [],
    },

    requirements: {
      entity: [],
      partOf: [],
      classification: [],
      attribute: [],
      property: [],
      material: [],
    },
    _id,
  };

  moveToIDSSpecReducer(state, {
    type: "createIDSSpecReducer/moveToIDSSpecReducer",
    payload: _id,
  });

  state.idsSpecsOrder.splice(0, 0, _id);
};

export const createNewIDSFileReducer = (state: IIfcMangerState) => {
  state.idsFileName = "New IDS File";
  // moveToIDSSectionReducer(state, { type: "createNewIDSFileReducer/moveToIDSSectionReducer", payload: "fileInfo" });
};

export const idsSetFileNameReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  state.idsFileName = payload;
};

export const idsSetModeReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<"edit" | "check">
) => {
  state.idsMode = payload;

  // auto invalidate(clear) all validation reports
  for (const specId in state.idsSpecs) {
    state.idsSpecs[specId]._validationReport = undefined;
    state.idsSpecs[specId]._validationResults = undefined;
  }
};

export const deleteIDSSpecReducer = (
  state: IIfcMangerState,
  action?: PayloadAction<string | void>
) => {
  const id = action?.payload || state.selectedSpecId;
  if (!id) return;

  const index = state.idsSpecsOrder.indexOf(id);
  state.idsSpecsOrder.splice(index, 1);

  const nextIndex = Math.min(Math.max(index, 0), state.idsSpecsOrder.length - 1);
  const nextId = state.idsSpecsOrder[nextIndex] ?? null;
  delete state.idsSpecs[id];

  if (state.selectedSpecId == id) state.selectedSpecId = nextId ?? null;
  if (nextId) {
    state.idsPanelSection = "specification";
  }
};

export const duplicateIDSSpecReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string>
) => {
  const id = payload;

  const _id = crypto.randomUUID();
  state.idsSpecs[_id] = {
    ...state.idsSpecs[id],
    _id,
    $_name: `Copy of ${state.idsSpecs[id].$_name}`,
  };
  state.idsPanelSection ??= "specification";
  if (state.idsPanelState != "expanded") state.idsPanelState = "normal";
  state.idsSpecSelectedRestrictionPath = null;
  state.selectedSpecId = _id;

  const prevIndex = state.idsSpecsOrder.indexOf(id);
  state.idsSpecsOrder.splice(prevIndex + 1, 0, _id);
};

const _autoSelectFirstRequirement = (
  state: IIfcMangerState,
  payload: IDSSpecificationSections | null
) => {
  if (payload == "applicability" && state.selectedSpecId) {
    if (!state.selectedSpecId) return;
    const appl = state.idsSpecs[state.selectedSpecId].applicability;

    const facet = (() => {
      if (appl?.entity && appl.entity.length > 0) return "entity";
      if (appl?.partOf && appl.partOf.length > 0) return "partOf";
      if (appl?.classification && appl.classification.length > 0) return "classification";
      if (appl?.attribute && appl.attribute.length > 0) return "attribute";
      if (appl?.property && appl.property.length > 0) return "property";
      if (appl?.material && appl.material.length > 0) return "material";
      return null;
    })();
    state.idsSpecSelectedRestrictionPath = facet
      ? {
          specId: state.selectedSpecId,
          section: "applicability",
          facet,
          facetIndex: 0,
        }
      : null;
  } else if (payload == "requirements" && state.selectedSpecId) {
    const reqs = state.idsSpecs[state.selectedSpecId].requirements;

    const facet = (() => {
      if (reqs?.entity && reqs.entity.length > 0) return "entity";
      if (reqs?.partOf && reqs.partOf.length > 0) return "partOf";
      if (reqs?.classification && reqs.classification.length > 0) return "classification";
      if (reqs?.attribute && reqs.attribute.length > 0) return "attribute";
      if (reqs?.property && reqs.property.length > 0) return "property";
      if (reqs?.material && reqs.material.length > 0) return "material";
      return null;
    })();

    state.idsSpecSelectedRestrictionPath = facet
      ? {
          specId: state.selectedSpecId,
          section: "requirements",
          facet,
          facetIndex: 0,
        }
      : null;
  }
};

export const moveToIDSSpecReducer = (
  state: IIfcMangerState,
  action: PayloadAction<string | null>
) => {
  const oldId = state.selectedSpecId;
  const { payload } = action;
  const newId = payload;

  state.idsPanelSection ??= "specification";

  // always ovverride fileInfo as it doesn't show the tabs
  if (state.idsPanelSection == "fileInfo") {
    state.idsPanelSection = "specification";
  }

  if (newId == null) {
    state.idsPanelSection = null;
    state.idsPanelState = "minimized";
    state.selectedSpecId = null;
    state.idsSpecSelectedRestrictionPath = null;
  }
  // clicking self toggles state
  else if (oldId == newId) {
    const oldSection = state.idsPanelSection;

    if (oldSection == null || oldSection != "specification") {
      moveToIDSSectionReducer(state, {
        ...action,
        payload: "specification",
      });
      state.selectedSpecId = newId;
    } else {
      moveToIDSSectionReducer(state, {
        ...action,
        payload: null,
      });
      state.idsPanelState = "minimized";
      state.selectedSpecId = null;
    }
    state.idsSpecSelectedRestrictionPath = null;
  }
  // general case
  else {
    if (state.idsPanelState == "minimized") {
      state.idsPanelState = "normal";
    }
    state.selectedSpecId = newId;
    _autoSelectFirstRequirement(state, state.idsPanelSection);
  }
};

export const moveToIDSResultReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSRestrictionPath | null>
) => {
  if (payload) {
    if (!arePathsEqual(payload, state.idsSpecSelectedRestrictionPath)) {
      state.selectedSpecId = payload.specId;
      state.idsPanelSection = "results";
      if (state.idsPanelState == "minimized") {
        state.idsPanelState = "normal";
      }
      state.idsSpecSelectedRestrictionPath = payload;
    } else {
      state.idsPanelSection = null;
      state.idsPanelState = "minimized";
      state.selectedSpecId = null;
      state.idsSpecSelectedRestrictionPath = null;
    }
  } else {
    state.selectedSpecId = null;
    state.idsPanelState == "minimized";
    state.idsSpecSelectedRestrictionPath = payload;
  }
};

export const moveToIDSSectionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSSpecificationSections | null>
) => {
  const oldSection = state.idsPanelSection;

  _autoSelectFirstRequirement(state, payload);

  if (oldSection == null || oldSection != payload) {
    if (state.idsPanelState == "minimized") {
      state.idsPanelState = "normal";
    }
    state.idsPanelSection = payload;
  }
  if (payload == null) {
    state.idsPanelState = "minimized";
  }
  // auto deselect any selected spec as the specs panel group dissapears
  if (payload == "fileInfo") {
    state.idsSpecSelectedRestrictionPath = null;
    state.selectedSpecId = null;
  }
};

export const moveToIDSRestrictionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSRestrictionPath | null>
) => {
  if (payload) {
    if (!arePathsEqual(state.idsSpecSelectedRestrictionPath, payload)) {
      state.selectedSpecId = payload.specId;
      state.idsPanelSection = payload.section;
      if (state.idsPanelState == "minimized") {
        state.idsPanelState = "normal";
      }
      state.idsSpecSelectedRestrictionPath = payload;
    } else {
      state.idsSpecSelectedRestrictionPath = null;
    }
  } else {
    state.idsSpecSelectedRestrictionPath = null;
  }
};

export const idsSpecUpdateAttrReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<Partial<NullOptionals<IDSSpecification>>>
) => {
  if (!payload._id) return;
  if (payload.$_ifcVersion !== undefined) {
    state.idsSpecs[payload._id].$_ifcVersion = payload.$_ifcVersion ?? undefined;
  }
  if (payload.$_name !== undefined) {
    state.idsSpecs[payload._id].$_name = payload.$_name ?? undefined;
  }
  if (payload.$_description !== undefined) {
    state.idsSpecs[payload._id].$_description = payload.$_description ?? undefined;
  }
  if (payload.$_instructions !== undefined) {
    state.idsSpecs[payload._id].$_instructions = payload.$_instructions ?? undefined;
  }
  if (payload.$_identifier !== undefined) {
    state.idsSpecs[payload._id].$_identifier = payload.$_identifier ?? undefined;
  }
};

export const idsApplUpdateAttrReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<Partial<NullOptionals<IDSApplicability> & { _id: string }>>
) => {
  if (!payload._id) return;
  if (!state.idsSpecs[payload._id].applicability) {
    state.idsSpecs[payload._id].applicability = {
      entity: [],
      partOf: [],
      classification: [],
      attribute: [],
      property: [],
      material: [],
    };
  }

  if (payload.$_minOccurs !== undefined) {
    state.idsSpecs[payload._id].applicability.$_minOccurs = payload.$_minOccurs ?? undefined;
  }
  if (payload.$_maxOccurs !== undefined) {
    state.idsSpecs[payload._id].applicability.$_maxOccurs = payload.$_maxOccurs ?? undefined;
  }
};

export const idsReqsUpdateAttrReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<Partial<NullOptionals<IDSRequirement> & { _id: string }>>
) => {
  if (!payload._id) return;
  if (!state.idsSpecs[payload._id].requirements) {
    state.idsSpecs[payload._id].requirements = {
      entity: [],
      partOf: [],
      classification: [],
      attribute: [],
      property: [],
      material: [],
    };
  }

  if (payload.$_description !== undefined) {
    state.idsSpecs[payload._id].requirements.$_description = payload.$_description ?? undefined;
  }
};

export const idsSpecAddApplicabilityRestrictionReducer = (state: IIfcMangerState) => {
  if (!state.selectedSpecId) return;
  state.idsSpecs[state.selectedSpecId].applicability.entity.unshift({
    name: { simpleValue: "" },
    predefinedType: { simpleValue: "" },
  });

  state.idsSpecSelectedRestrictionPath = {
    specId: state.selectedSpecId,
    section: "applicability",
    facet: "entity",
    facetIndex: 0,
    valueType: "simpleValue",
  };
};

export const idsSpecAddRequirementsRestrictionReducer = (state: IIfcMangerState) => {
  if (!state.selectedSpecId) return;

  state.idsSpecs[state.selectedSpecId].requirements.entity.unshift({
    name: { simpleValue: "" },
    predefinedType: { simpleValue: "" },
  });

  state.idsSpecSelectedRestrictionPath = {
    specId: state.selectedSpecId,
    section: "requirements",
    facet: "entity",
    facetIndex: 0,
    valueType: "simpleValue",
  };
};

export const idsSpecDeleteRestrictionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSRestrictionPath | null>
) => {
  const restrictionPath = payload;
  if (!restrictionPath) return;

  if (!(restrictionPath.section == "applicability" || restrictionPath.section == "requirements"))
    return;
  if (!restrictionPath.facet) return;

  const restrictions =
    state.idsSpecs?.[restrictionPath.specId]?.[restrictionPath.section]?.[restrictionPath.facet];

  restrictions.splice(restrictionPath.facetIndex, 1);

  const currentSelectedIndex = state.idsSpecSelectedRestrictionPath?.facetIndex;
  const newSelectedIndex = (() => {
    // if none selected leave unselected
    if (currentSelectedIndex == null) return null;
    // bumb down if is affected by splice
    let newCandidate: number | null =
      currentSelectedIndex > restrictionPath.facetIndex
        ? currentSelectedIndex - 1
        : currentSelectedIndex;
    // cap to last element
    newCandidate = Math.min(newCandidate, restrictions.length - 1);
    // unselect if none available
    newCandidate = restrictions.length > 0 ? newCandidate : null;
    return newCandidate;
  })();

  console.log("appricot newSelectedIndex:", newSelectedIndex);
  // auto select next available
  if (newSelectedIndex != null) {
    if (state.idsSpecSelectedRestrictionPath) {
      state.idsSpecSelectedRestrictionPath.facetIndex = newSelectedIndex;
      console.log(
        "appricot state.idsSpecSelectedRestrictionPath.facetIndex:",
        state.idsSpecSelectedRestrictionPath.facetIndex
      );
    }
  } // or deselect if none available
  else {
    state.idsSpecSelectedRestrictionPath = null;
  }
};

export const idsResetParsingErrorReducer = (state: IIfcMangerState) => {
  state.idsParsingError = null;
  // auto hide error panel if it was previously shown
  if (state.idsPanelSection == "results") {
    state.idsPanelState = "minimized";
  }

  state.isIDSValidationRunning = true;
};

export const idsSpecChangeRestrictionFacetReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ restrictionPath: IDSRestrictionPath; newFacet: IDSFacet }>
) => {
  const { restrictionPath, newFacet } = payload;
  if (!(restrictionPath.section == "applicability" || restrictionPath.section == "requirements"))
    return;
  if (!restrictionPath.facet) return;

  state.idsSpecs?.[restrictionPath.specId]?.[restrictionPath.section]?.[
    restrictionPath.facet
  ].splice(restrictionPath.facetIndex, 1);

  state.idsSpecs?.[restrictionPath.specId]?.[restrictionPath.section]?.[newFacet].unshift(
    (newFacet == "attribute" && {}) ||
      (newFacet == "property" && {}) ||
      (newFacet == "partOf" && {}) ||
      (newFacet == "classification" && {}) ||
      (newFacet == "material" && {}) ||
      {}
  );

  state.idsSpecSelectedRestrictionPath = {
    ...restrictionPath,
    facet: newFacet,
    facetIndex: 0,
  };
};

export const idsSpecChangeRestrictionTypeReducer = (
  state: IIfcMangerState,
  {
    payload,
  }: PayloadAction<{
    restrictionPath: IDSRestrictionPath;
    type?: IDSValueType | IDSValueRestrictionType;
  }>
) => {
  const { restrictionPath, type } = payload;

  if (!restrictionPath.attribute) return;

  if (!(restrictionPath.section == "applicability" || restrictionPath.section == "requirements"))
    return;
  if (!restrictionPath.facet) return;

  // TODO: store existingRestriction & use it when switching back

  let newValue = undefined;

  if (type == "plain") {
    newValue = "";
  } else if (type == "simpleValue") {
    newValue = {
      simpleValue: "",
    };
  } else if (type == "xs:pattern") {
    newValue = {
      "xs:restriction": {
        "xs:pattern": { $_value: "" },
      },
    };
  } else if (type == "xs:length") {
    newValue = {
      "xs:restriction": {
        "xs:length": { $_value: "0" },
      },
    };
  } else if (type == "range") {
    newValue = {
      "xs:restriction": {
        "xs:minInclusive": { $_value: "0" },
        "xs:maxInclusive": { $_value: "0" },
      },
    };
  } else if (type == "lengthRange") {
    newValue = {
      "xs:restriction": {
        "xs:minLength": { $_value: "0" },
        "xs:maxLength": { $_value: "0" },
      },
    };
  } else if (type == "xs:enumeration") {
    newValue = {
      "xs:restriction": {
        "xs:enumeration": [{ $_value: "" }],
      },
    };
  }

  const parent =
    state.idsSpecs[restrictionPath.specId][restrictionPath.section][restrictionPath.facet][
      restrictionPath.facetIndex
    ];
  if (restrictionPath.attributeWrapper) {
    //@ts-ignore
    const old_value = parent[restrictionPath.attributeWrapper]?.[0];
    //@ts-ignore
    parent[restrictionPath.attributeWrapper] = [
      { ...old_value, [restrictionPath.attribute]: newValue },
    ];
  } else {
    //@ts-ignore
    parent[restrictionPath.attribute] = newValue;
  }
};

export const idsSpecUpdateRestrictionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ restrictionPath: IDSRestrictionPath; value?: IDSValue }>
) => {
  const { restrictionPath } = payload;
  const argValue = payload.value;

  if (!restrictionPath.attribute) return;

  if (!(restrictionPath.section == "applicability" || restrictionPath.section == "requirements"))
    return;
  if (!restrictionPath.facet) return;

  const existingRestriction = restrictionPath?.attributeWrapper
    ? //@ts-ignore
      (state.idsSpecs[restrictionPath.specId]?.[restrictionPath.section][restrictionPath.facet]?.[
        restrictionPath.facetIndex
        //Note: there's a 0 here because idsAlwaysArray["entity"] == true and entity is the only attributeWrapper
      ]?.[restrictionPath.attributeWrapper]?.[0]?.[restrictionPath.attribute] as
        | IDSValue
        | undefined)
    : //@ts-ignore
      (state.idsSpecs[restrictionPath.specId]?.[restrictionPath.section][restrictionPath.facet]?.[
        restrictionPath.facetIndex
      ]?.[restrictionPath.attribute] as IDSValue | undefined);

  let newValue = undefined;
  if (restrictionPath.valueType == "plain") {
    newValue = argValue;
  } else if (restrictionPath.valueType == "simpleValue") {
    if (argValue === undefined) {
      // erase the parent field from the xml
      newValue = undefined;
    } else {
      newValue = {
        simpleValue: argValue,
      };
    }
  } else if (restrictionPath.valueType == "xs:restriction") {
    const restrictionValue = (argValue as IDSValueObj)?.["xs:restriction"];

    if (restrictionPath.xsRestrictionType == "xs:pattern") {
      const value = restrictionValue?.["xs:pattern"]?.$_value;
      newValue = {
        "xs:restriction": {
          $_base: xsDetectBaseType(value),
          "xs:pattern": { $_value: value },
        },
      };
    } else if (restrictionPath.xsRestrictionType == "xs:length") {
      const value = restrictionValue?.["xs:length"]?.$_value;
      newValue = {
        "xs:restriction": {
          $_base: xsDetectBaseType(value),
          "xs:length": { $_value: value },
        },
      };
    } else if (restrictionPath.xsRestrictionType == "range") {
      const affectedKey = restrictionValue
        ? (Object.keys(restrictionValue)[0] as XSRestrictionType)
        : undefined;
      if (affectedKey && affectedKey != "xs:enumeration") {
        const value = restrictionValue?.[affectedKey]?.$_value;
        const oldValue = (existingRestriction as IDSValueObj)?.["xs:restriction"];

        newValue = {
          "xs:restriction": {
            ...oldValue,
            [affectedKey]: { $_value: value },
            // Inclusive | Exclusive mutex
            ...(affectedKey == "xs:minInclusive" && { "xs:minExclusive": undefined }),
            ...(affectedKey == "xs:minExclusive" && { "xs:minInclusive": undefined }),
            ...(affectedKey == "xs:maxInclusive" && { "xs:maxExclusive": undefined }),
            ...(affectedKey == "xs:maxExclusive" && { "xs:maxInclusive": undefined }),
            // leave empty to auto detect largest type
            $_base: undefined as string | undefined,
          },
        };

        newValue["xs:restriction"].$_base = xsDetectBaseType(
          Object.values(newValue["xs:restriction"]).map(
            (x: any) => x?.$_value as string | undefined
          )
        );
      }
    } else if (restrictionPath.xsRestrictionType == "lengthRange") {
      const affectedKey = restrictionValue
        ? (Object.keys(restrictionValue)[0] as XSRestrictionType)
        : undefined;
      if (affectedKey && affectedKey != "xs:enumeration") {
        const value = restrictionValue?.[affectedKey]?.$_value;
        const oldValue = (existingRestriction as IDSValueObj)?.["xs:restriction"];

        newValue = {
          "xs:restriction": {
            ...oldValue,
            [affectedKey]: { $_value: value },
            // leave empty to auto detect largest type
            $_base: undefined as string | undefined,
          },
        };

        newValue["xs:restriction"].$_base = xsDetectBaseType(
          Object.values(newValue["xs:restriction"]).map(
            (x: any) => x?.$_value as string | undefined
          )
        );
      }
    } else if (restrictionPath.xsRestrictionType == "xs:enumeration") {
      const index = restrictionValue?.["xs:enumeration"]?.[0] as number | undefined;
      const value = restrictionValue?.["xs:enumeration"]?.[1]?.$_value as string | undefined;

      const oldValues = (existingRestriction as IDSValueObj)?.["xs:restriction"]?.[
        "xs:enumeration"
      ];

      if (index == undefined) {
        oldValues?.push({ $_value: value });
      } else if (value == undefined) {
        oldValues?.splice(index, 1);
      } else if (oldValues) {
        oldValues[index] = { $_value: value };
      }
      /* avoid overriding possibly long list via newValue */
      return;
    }
  }

  const facet =
    state.idsSpecs[restrictionPath.specId][restrictionPath.section][restrictionPath.facet][
      restrictionPath.facetIndex
    ];
  if (restrictionPath?.attributeWrapper) {
    //@ts-ignore
    const parent = facet[restrictionPath.attributeWrapper];
    //@ts-ignore
    facet[restrictionPath.attributeWrapper] = [
      { ...parent?.[0], [restrictionPath.attribute]: newValue },
    ];
  } else {
    //@ts-ignore
    facet[restrictionPath.attribute] = newValue;
  }
};

export const idsSpecSetSelectedIdReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  state.selectedSpecId = payload;
};

export const idsSpecSetSelectedRestrictionPathReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSRestrictionPath | null>
) => {
  state.idsSpecSelectedRestrictionPath = payload;
  if (payload) {
    state.selectedSpecId = payload.specId;
  }
};

export const idsSpecSetSelectedRestrictionXPathReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  // example: /ids/specifications/specification[1]/requirements/attribute[1]
  // only 1 child example: /ids/specifications/specification/applicability/entity
  if (!payload) {
    state.idsSpecSelectedRestrictionPath = null;
    return;
  }

  const parts = payload.split("/");
  if (state.idsPanelState == "minimized") {
    state.idsPanelState = "normal";
  }

  if (parts.length == 2 || parts[2] == "info") {
    state.idsPanelSection = "fileInfo";
    return;
  }

  if (parts.length == 3) {
    state.snackbarMessage = "Couldn't go to specification";
    state.snackbarSeverity = SEVERITIES.WARNING;
    return;
  }
  const specIndex = parseInt(parts[3].split(/[[\]]/)[1] ?? 1) - 1; // xml is 1-based, convert to 0-based
  const selectedId = state.idsSpecsOrder[specIndex] as string;

  if (parts.length == 4) {
    state.selectedSpecId = selectedId;
    state.idsSpecSelectedRestrictionPath = {
      specId: selectedId,
      section: "specification",
      facetIndex: 0,
    };
    state.idsPanelSection = "specification";
    return;
  }

  const section = parts[4] as IDSSpecificationSections;
  if (!["applicability", "requirements"].includes(section)) {
    state.snackbarMessage = "Unsupported section";
    state.snackbarSeverity = SEVERITIES.WARNING;
    return;
  }

  if (parts.length == 5) {
    state.selectedSpecId = selectedId;
    state.idsSpecSelectedRestrictionPath = {
      specId: selectedId,
      section,
      facetIndex: 0,
    };
    state.idsPanelSection = section;
    return;
  }

  const facetDetails = parts[5].split(/[[\]]/);
  const facet = facetDetails[0] as IDSFacet;
  const facetIndex = parseInt(facetDetails[1] ?? 1) - 1; // xml is 1-based, convert to 0-based

  if (
    !["entity", "attribute", "property", "partOf", "classification", "material"].includes(facet)
  ) {
    state.snackbarMessage = "Unsupported path facet";
    state.snackbarSeverity = SEVERITIES.WARNING;
    return;
  }

  if (parts.length >= 6) {
    state.selectedSpecId = selectedId;
    state.idsSpecSelectedRestrictionPath = {
      specId: selectedId,
      section,
      facet,
      facetIndex,
    };
    state.idsPanelSection = section;
    return;
  }
};

export const idsSetPanelStateReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSPanelState>
) => {
  state.idsPanelState = payload;
};

export const idsSetPanelSectionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<IDSSpecificationSections | null>
) => {
  state.idsPanelSection = payload;
};

export const idsSetInfoReducer = (state: IIfcMangerState, { payload }: PayloadAction<IDSInfo>) => {
  state.idsInfo = payload;
};

export const idsSpecHighlightResultItem = createAsyncThunk(
  "ifcManager/idsSpecHighlightResultItem",
  async ({ restrictionPath }: { restrictionPath: IDSRestrictionPath }, { getState, dispatch }) => {
    const state = (getState() as RootState).ifcManager;

    if (!state.modelUUID) return;

    const spec = state.idsSpecs[restrictionPath.specId];
    if (!spec._validationResults) return;

    const expressID = spec._validationResults[restrictionPath.facetIndex].expressID;

    dispatch(
      highlightByID({
        modelUUID: String(state.modelUUID),
        expressID: String(expressID),
      })
    );
  }
);

export const idsForceFullNormalisationReducer = (state: IIfcMangerState) => {
  for (const idsSpecId in state.idsSpecs) {
    state.idsSpecs[idsSpecId] = normalizeIDSSpecification(state.idsSpecs[idsSpecId], {
      keepPrivateState: true,
    });
  }
};

const idsAlwaysArray: Record<string, boolean> = {
  entity: true,
  attribute: true,
  property: true,
  partOf: true,
  classification: true,
  material: true,
  specification: true,
  "xs:enumeration": true,
};

export const idsSpecLoadIDSFile = createAsyncThunk(
  "ifcManager/idsSpecLoadIDSFile",
  async ({ file }: { file: File }) => {
    const data = await file.text();

    const idsSpecs: Record<string, IDSSpecification> = {};
    let idsInfo: IDSInfo = {};
    try {
      const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: "$_",
        isArray: (name: string) => {
          return idsAlwaysArray[name] == true;
        },
        transformTagName: tagName => {
          const groups = /(?:(?<ns>[^:]*):)?(?<tag>.*)/.exec(tagName)?.groups;
          // const ns = groups?.['ns'];
          const tag = groups?.["tag"];
          if (tag != null) {
            const descriptor = defaultXMLNamespaces[tag];
            if (descriptor?.isImported) return `${descriptor?.name}:${tag}`;
            else return tag;
          }
          return tagName;
        },
      });
      const idsTree = parser.parse(data);
      // const idsTree = await xml2js.parseStringPromise(data);
      console.log("idsTree:", idsTree);

      const checkIsNonNullObject = (obj: any) => typeof obj === "object" && obj;
      const withDefaultArray = (obj: any) => (Array.isArray(obj) ? obj : []);

      if (idsTree?.ids?.specifications?.specification) {
        const specifications = idsTree.ids.specifications.specification as IDSSpecification[];
        for (const possiblyBrokenSpecification of specifications) {
          const _id = crypto.randomUUID();

          const specification = checkIsNonNullObject(possiblyBrokenSpecification)
            ? possiblyBrokenSpecification
            : ({} as IDSSpecification);

          // ensure the sentinel is empty array instead of undefined
          specification.applicability = checkIsNonNullObject(specification.applicability)
            ? specification.applicability
            : {
                entity: [],
                partOf: [],
                classification: [],
                attribute: [],
                property: [],
                material: [],
              };
          specification.requirements = checkIsNonNullObject(specification.requirements)
            ? specification.requirements
            : {
                entity: [],
                partOf: [],
                classification: [],
                attribute: [],
                property: [],
                material: [],
              };
          specification.applicability.entity = withDefaultArray(specification.applicability.entity);
          specification.applicability.partOf = withDefaultArray(specification.applicability.partOf);
          specification.applicability.classification = withDefaultArray(
            specification.applicability.classification
          );
          specification.applicability.attribute = withDefaultArray(
            specification.applicability.attribute
          );
          specification.applicability.property = withDefaultArray(
            specification.applicability.property
          );
          specification.applicability.material = withDefaultArray(
            specification.applicability.material
          );

          specification.requirements.entity = withDefaultArray(specification.requirements.entity);
          specification.requirements.partOf = withDefaultArray(specification.requirements.partOf);
          specification.requirements.classification = withDefaultArray(
            specification.requirements.classification
          );
          specification.requirements.attribute = withDefaultArray(
            specification.requirements.attribute
          );
          specification.requirements.property = withDefaultArray(
            specification.requirements.property
          );
          specification.requirements.material = withDefaultArray(
            specification.requirements.material
          );

          idsSpecs[_id] = normalizeIDSSpecification(specification);
          idsSpecs[_id]._id = _id;
        }
      }
      idsInfo = idsTree?.ids?.info;
    } catch (ex) {
      console.error("failed to parse .ids:", ex);
    }
    console.log("loaded idsSpecs:", idsSpecs);
    return {
      ...initialIDSEditorState,
      idsSpecs,
      idsSpecsOrder: Object.keys(idsSpecs),
      idsInfo,
      idsFileName: file.name ?? "No Name",
    };
  }
);

const _generateXML = (state: IIfcMangerState, options?: NormalizeIDSOptions) => {
  const exportedObject = {
    "?xml": {
      $_version: "1.0",
      $_encoding: "utf-8",
      $_standalone: "yes",
    },
    ids: {
      "$_xmlns:xs": "http://www.w3.org/2001/XMLSchema",
      $_xmlns: "http://standards.buildingsmart.org/IDS",
      "$_xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
      "$_xsi:schemaLocation":
        "http://standards.buildingsmart.org/IDS http://standards.buildingsmart.org/IDS/1.0/ids.xsd",
      info: normalizeIDSInfo(state.idsInfo),
      specifications: {
        specification: state.idsSpecsOrder.map(x =>
          normalizeIDSSpecification(state.idsSpecs[x], options)
        ),
      },
    },
  };

  const builder = new XMLBuilder({
    ignoreAttributes: false,
    attributeNamePrefix: "$_",
    format: true,
  });

  return builder.build(exportedObject);
};

export const idsSpecDownloadIDSFile = createAsyncThunk(
  "ifcManager/idsSpecDownloadIDSFile",
  async (_payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;
    let xmlContent: any = null;
    try {
      xmlContent = _generateXML(state);
    } catch (ex) {
      return idsErrors.failedGeneration;
    }
    try {
      const name = state.idsFileName ?? "Specifications";
      const nameExt = name.endsWith(".ids") ? name : `${name}.ids`;
      download(xmlContent, nameExt, "text/xml");
    } catch (ex) {
      return idsErrors.failedDownload;
    }
  }
);

const _applyReadableFuncToList = (readableFunc: any, listOfObjects: any[]) => {
  if (listOfObjects.length == 0) return "";

  const values = [];
  for (const obj of listOfObjects) {
    for (const [key, value] of Object.entries(obj)) {
      if (value == undefined) continue;
      if (key.startsWith("$_")) continue;
      values.push(readableFunc(obj));
    }
  }

  return values.join("\n");
};

const _generateSpecsCSV = (state: IIfcMangerState, options?: NormalizeIDSOptions) => {
  const rows: any[] = [];
  for (const key of state.idsSpecsOrder) {
    const idsSpec = normalizeIDSSpecification(state.idsSpecs[key], options);

    const row = {
      Name: idsSpec.$_name,
      Description: idsSpec.$_description,
      "IFC Version": idsSpec.$_ifcVersion,
      Instructions: idsSpec.$_instructions,
      "Applicability Max Occurs": idsSpec.applicability.$_maxOccurs,
      "Applicability Min Occurs": idsSpec.applicability.$_minOccurs,
      "Applicability Attributes": _applyReadableFuncToList(
        readableIDSRestrictionAttribute,
        idsSpec.applicability.attribute
      ),
      "Applicability Classification": _applyReadableFuncToList(
        readableIDSRestrictionClassification,
        idsSpec.applicability.classification
      ),
      "Applicability Entity": _applyReadableFuncToList(
        readableIDSRestrictionEntity,
        idsSpec.applicability.entity
      ),
      "Applicability Material": _applyReadableFuncToList(
        readableIDSRestrictionMaterial,
        idsSpec.applicability.material
      ),
      "Applicability Part Of": _applyReadableFuncToList(
        readableIDSRestrictionPartOf,
        idsSpec.applicability.partOf
      ),
      "Applicability Property": _applyReadableFuncToList(
        readableIDSRestrictionProperty,
        idsSpec.applicability.property
      ),
      "Requirements Description": idsSpec.requirements.$_description,
      "Requirements Attributes": _applyReadableFuncToList(
        readableIDSRestrictionProperty,
        idsSpec.requirements.attribute
      ),
      "Requirements Classification": _applyReadableFuncToList(
        readableIDSRestrictionClassification,
        idsSpec.requirements.classification
      ),
      "Requirements Entity": _applyReadableFuncToList(
        readableIDSRestrictionEntity,
        idsSpec.requirements.entity
      ),
      "Requirements Material": _applyReadableFuncToList(
        readableIDSRestrictionMaterial,
        idsSpec.requirements.material
      ),
      "Requirements Part Of": _applyReadableFuncToList(
        readableIDSRestrictionPartOf,
        idsSpec.requirements.partOf
      ),
      "Requirements Property": _applyReadableFuncToList(
        readableIDSRestrictionProperty,
        idsSpec.requirements.property
      ),
    };

    rows.push(row);
  }

  const csvConfig = mkConfig({ useKeysAsHeaders: true, useBom: false });
  const csv = generateCsv(csvConfig)(rows);
  return csv;
};

export const idsSpecDownloadSpecsCSVFile = createAsyncThunk(
  "ifcManager/idsSpecDownloadSpecsCSVFile",
  async (_payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;
    let csvContent = null as null | CsvOutput;
    try {
      csvContent = _generateSpecsCSV(state);
    } catch (ex) {
      return idsErrors.failedGeneration;
    }

    try {
      const nameExt = state.idsFileName ?? "Specifications";
      const name = nameExt.endsWith(".ids") ? nameExt.substring(0, nameExt.length - 4) : nameExt;
      const exportName = `${name}.csv`;
      download(asString(csvContent), exportName, "text/csv");
    } catch (ex) {
      return idsErrors.failedDownload;
    }
  }
);

const _generateResultsCSV = (state: IIfcMangerState) => {
  const rows: any[] = [];
  const viewer = viewerAPI();

  for (const key of state.idsSpecsOrder) {
    const idsSpec = state.idsSpecs[key];

    if (idsSpec._validationResults == null) continue;
    for (const validationEntity of idsSpec._validationResults) {
      const reasons = validationEntity.failedRequirements.map(
        x => `${x.requirementDescription}: ${x.reason}`
      );

      const attributes = viewer.getIfcAttributes(validationEntity.expressID);
      const row = {
        "Global ID": attributes?.GlobalId?.value ?? "",
        "Express ID": validationEntity.expressID,
        "IFC Entity": attributes?.type ? ifcTypeNames[attributes.type] : "",
        Name: attributes?.Name?.value ?? "",
        "Specification Name": idsSpec.$_name,
        "Validation Result": reasons.length > 0 ? "FAIL" : "PASS",
        "Failure Reasons": reasons.join("\n"),
        Instructions: idsSpec.$_instructions ?? "",
      };

      rows.push(row);
    }
  }

  const csvConfig = mkConfig({ useKeysAsHeaders: true, useBom: false });
  const csv = generateCsv(csvConfig)(rows);
  return csv;
};

export const idsSpecDownloadResultsCSVFile = createAsyncThunk(
  "ifcManager/idsSpecDownloadResultsCSVFile",
  async (_payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;
    let csvContent = null as null | CsvOutput;

    try {
      csvContent = _generateResultsCSV(state);
    } catch (ex) {
      return idsErrors.failedGeneration;
    }

    try {
      const nameExt = state.fileName ?? "Verification";
      const name = nameExt.endsWith(".ifc") ? nameExt.substring(0, nameExt.length - 4) : nameExt;
      const exportName = `${name}_results.csv`;
      download(asString(csvContent), exportName, "text/csv");
    } catch (ex) {
      return idsErrors.failedDownload;
    }
  }
);

const displayIDSResultsError = (state: IIfcMangerState, error: IDSValidationError) => {
  state.idsParsingError = error;
  console.log("state.idsParsingError = fex.details:", error);

  state.idsMode = "edit";
  if (state.idsPanelState == "minimized") {
    state.idsPanelState = "normal";
  }
  state.idsPanelSection = "results";
};

export const idsSpecRunValidation = createAsyncThunk(
  "ifcManager/idsSpecRunValidation",
  async (_, { getState, dispatch }) => {
    console.log("idsSpecRunValidation:");
    dispatch(idsResetParsingError());
    try {
      const state = (getState() as RootState).ifcManager;
      const viewer = viewerAPI();
      const generatedXML = _generateXML(state);
      if (!viewer.modelSrc && !state.modelCloudId)
        return (state: IIfcMangerState) => {
          state.snackbarSeverity = SEVERITIES.WARNING;
          state.snackbarMessage = "No model is loaded";
          state.idsMode = "edit";
          state.isIDSValidationRunning = false;
        };
      if (!generatedXML)
        return (state: IIfcMangerState) => {
          state.snackbarSeverity = SEVERITIES.ERROR;
          state.snackbarMessage = "Couldn't generate the .ids file";
          state.idsMode = "edit";
          state.isIDSValidationRunning = false;
        };

      const runIDSReportArgs: RunIDSReportArgs = { idsSource: generatedXML };

      if (!state.modelCloudId) {
        return (state: IIfcMangerState) => {
          state.snackbarSeverity = SEVERITIES.ERROR;
          state.snackbarMessage = "The model must be cloud synced to run IDS validation";
          state.idsMode = "edit";
          state.isIDSValidationRunning = false;
        };
        // runIDSReportArgs.ifcSource = new TextDecoder().decode(viewer.modelSrc);
      } else {
        runIDSReportArgs.ifcModelCloudId = state.modelCloudId;
      }

      const response = await firebase.runIDSReport(runIDSReportArgs);

      // const results = mockResults;
      const results = response.data as any;
      console.log("results:", results);
      return (state: IIfcMangerState) => {
        for (const specResults of results["specifications"]) {
          const collectedFailures: Record<number, IDSValidationEntity> = {};
          for (const entity of specResults["applicable_entities"]) {
            if (!collectedFailures[entity.id]) {
              collectedFailures[entity.id] = {
                expressID: entity.id,
                name: entity["name"],
                failedRequirements: [],
              };
            }
          }
          for (const requirement of specResults["requirements"]) {
            for (const entity of requirement["failed_entities"]) {
              if (!collectedFailures[entity.id]) {
                collectedFailures[entity.id] = {
                  expressID: entity.id,
                  name: entity["name"],
                  failedRequirements: [],
                };
              }
              collectedFailures[entity.id].failedRequirements.push({
                requirementDescription: requirement["description"],
                reason: entity["reason"],
              });
            }
          }

          const matchingSpecInState = Object.values(state.idsSpecs).find(
            spec => spec.$_name == specResults["name"] // && spec.$_description == specResults["description"]
          )?._id;

          if (matchingSpecInState) {
            state.idsSpecs[matchingSpecInState]._validationReport = {
              applicableItemsCount: specResults["total_applicable"],
              applicableItemsPass: specResults["total_applicable_pass"],
              applicableItemsFail: specResults["total_applicable_fail"],
            };

            state.idsSpecs[matchingSpecInState]._validationResults =
              Object.values(collectedFailures);
          } else {
            console.log("specification result mismatch, couldn't find:", specResults);
          }
        }
        state.idsMode = "check";
        state.isIDSValidationRunning = false;
      };
    } catch (ex: any) {
      if (ex?.name == "FirebaseError") {
        const fex = ex as FirebaseFuncError;
        if (fex?.code == FexCodes.permissionDenied) {
          return (state: IIfcMangerState) => {
            state.snackbarSeverity = SEVERITIES.ERROR;
            state.snackbarMessage = "Failed to generate IDS report: Unauthorized";
            state.idsMode = "edit";
            state.isIDSValidationRunning = false;
          };
        } else if (fex?.code == FexCodes.internal) {
          if (fex?.message == "Failed to download modelCloudId")
            return (state: IIfcMangerState) => {
              state.snackbarSeverity = SEVERITIES.ERROR;
              state.snackbarMessage = "Failed to generate IDS report: Storage error";
              state.idsMode = "edit";
              state.isIDSValidationRunning = false;
            };
          else if (fex?.message == "Failed to parse IDS")
            return (state: IIfcMangerState) => {
              state.snackbarSeverity = SEVERITIES.ERROR;
              state.snackbarMessage = "Failed to generate IDS report: invalid IDS";
              state.idsMode = "edit";

              displayIDSResultsError(state, fex.details);
              state.isIDSValidationRunning = false;
            };
          else if (fex?.message == "Failed to parse IFC")
            return (state: IIfcMangerState) => {
              state.snackbarSeverity = SEVERITIES.ERROR;
              state.snackbarMessage = "Failed to generate IDS report: invalid IFC";
              state.idsMode = "edit";
              state.isIDSValidationRunning = false;
            };
          else
            return (state: IIfcMangerState) => {
              state.snackbarSeverity = SEVERITIES.ERROR;
              state.snackbarMessage = "Failed to generate IDS report";
              state.idsMode = "edit";

              if (fex?.details?.message) {
                console.log(
                  "idsSpecRunValidation:fex:",
                  fex?.code,
                  fex?.details,
                  fex?.message,
                  fex?.name
                );
                displayIDSResultsError(state, fex.details);
              } else {
                console.log("idsSpecRunValidation: function out of memory:");
                displayIDSResultsError(state, {
                  elem: "",
                  message: "The IFC file contains too many items to be validated",
                  msg: "",
                  path: "",
                  reason: "",
                });
              }
              state.isIDSValidationRunning = false;
            };
        }
      }

      console.error("idsSpecRunValidation failed:", ex);
      Sentry.captureException(ex, scope => scope.setLevel("log"));

      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.ERROR;
        state.snackbarMessage = "Failed to generate IDS report";
        state.idsMode = "edit";
        state.isIDSValidationRunning = false;
      };
    }
  }
);

const restrictionPathHasErrorSelector =
  (restrictionPath?: IDSRestrictionPath) => (state: RootState) => {
    if (
      !restrictionPath ||
      !restrictionPath.section ||
      !restrictionPath.facet ||
      !restrictionPath.specId
    )
      return null;

    const specs = state.ifcManager.idsSpecs;
    if (!specs) return;

    const spec = specs[restrictionPath.specId];
    if (!spec) return null;

    const section = spec[restrictionPath.section as "applicability" | "requirements"] as
      | IDSApplicability
      | IDSRequirement;
    if (!section) return null;

    const facet = section[restrictionPath.facet];
    if (!facet) return null;

    const anyRestiction = restrictionPath.attributeWrapper
      ? //@ts-ignore
        facet[restrictionPath.facetIndex]?.[restrictionPath.attributeWrapper]?.[0]
      : (facet[restrictionPath.facetIndex] as
          | IDSRestrictionEntity
          | IDSRestrictionPartOf
          | IDSRestrictionClassification
          | IDSRestrictionAttribute
          | IDSRestrictionProperty
          | IDSRestrictionMaterial);

    if (!anyRestiction) return;

    if (restrictionPath.facet == "property") {
      const restriction = anyRestiction as IDSRestrictionProperty;

      if (restrictionPath.attribute == "value") {
        if (restriction.$_dataType == "IFCBOOLEAN") {
          if (!restriction.value) return null;

          const testRegex = /^(?:true|false|0|1|)$/;

          // todo: extract this pattern of string | simpleValue | enumvalue
          if (typeof restriction.value == "string") {
            return testRegex.test(restriction.value ?? "") ? null : idsUIErrors.booleanMismatch;
            //
          } else if (restriction.value.simpleValue != null) {
            return testRegex.test(`${restriction.value.simpleValue}` ?? "")
              ? null
              : idsUIErrors.booleanMismatch;
            //
          } else if (restriction.value["xs:restriction"] != null) {
            const enumeration = restriction.value["xs:restriction"]["xs:enumeration"];
            if (!enumeration) return null;

            let hasFailures = false;
            const affectedEnumIndexes: boolean[] = [];
            for (let index = 0; index < enumeration.length; index++) {
              const value = enumeration[index]?.$_value;
              const isFailure = !testRegex.test(`${value}`);

              affectedEnumIndexes.push(isFailure);
              hasFailures = hasFailures || isFailure;
            }

            return hasFailures
              ? {
                  ...idsUIErrors.booleanMismatch,
                  affectedEnumIndexes,
                }
              : null;
          }
        }
      } else if (restrictionPath.attribute == "$_dataType") {
        if (!restriction.$_dataType) return null;
        return /^[A-Z]+$/.test(restriction.$_dataType) ? null : idsUIErrors.isNotUppercase;
      }
    }

    return null;
  };

const idsFixUppercaseBooleansReducer = (state: IIfcMangerState) => {
  const correction = (value: any) => {
    return `${value}`.toLowerCase();
  };

  for (const specId of state.idsSpecsOrder) {
    const spec = state.idsSpecs[specId];

    const reqsProperties = spec?.requirements?.property ?? [];
    const applProperties = spec?.applicability?.property ?? [];
    const properties = [...reqsProperties, ...applProperties];

    for (let index = 0; index < properties.length; index++) {
      const restriction = properties[index];

      if (restriction.$_dataType == "IFCBOOLEAN" && restriction.value) {
        if (typeof restriction.value == "string") {
          restriction.value = correction(restriction.value);
          //
        } else if (restriction.value.simpleValue != null) {
          restriction.value.simpleValue = correction(restriction.value.simpleValue);
          //
        } else if (restriction.value["xs:restriction"]?.["xs:enumeration"] != null) {
          const enumeration = restriction.value["xs:restriction"]["xs:enumeration"];

          for (let index = 0; index < enumeration.length; index++) {
            const value = enumeration[index]?.$_value;
            if (value) {
              enumeration[index].$_value = correction(enumeration[index]?.$_value);
            }
          }
        }
      }
    }
  }
};

export const idsEditorSelectors = {
  selectIDSRestriction: (restrictionPath?: IDSRestrictionPath) => (state: RootState) =>
    restrictionPath &&
    restrictionPath.facet &&
    (restrictionPath.section == "applicability" || restrictionPath.section == "requirements")
      ? restrictionPath.attributeWrapper
        ? // @ts-ignore
          state.ifcManager.idsSpecs?.[restrictionPath.specId]?.[restrictionPath.section]?.[
            restrictionPath.facet
            //Note: there's a 0 here because idsAlwaysArray["entity"] == true and entity is the only attributeWrapper
          ]?.[restrictionPath.facetIndex]?.[restrictionPath.attributeWrapper]?.[0]
        : state.ifcManager.idsSpecs?.[restrictionPath.specId]?.[restrictionPath.section]?.[
            restrictionPath.facet
          ]?.[restrictionPath.facetIndex]
      : undefined,
  selectIDSResultItem: (restrictionPath?: IDSRestrictionPath) => (state: RootState) =>
    restrictionPath && restrictionPath.section == "results"
      ? state.ifcManager.idsSpecs?.[restrictionPath.specId]?._validationResults?.[
          restrictionPath.facetIndex
        ]
      : undefined,
  selectIDSSpecSelectedId: (state: RootState) => state.ifcManager.selectedSpecId,
  selectIDSSpecs: (state: RootState) => state.ifcManager.idsSpecs,
  selectIDSSpecsOrder: (state: RootState) => state.ifcManager.idsSpecsOrder,
  selectIDSSpecSelectedRestrictionPath: (state: RootState) =>
    state.ifcManager.idsSpecSelectedRestrictionPath,
  selectIDSSpecAttr:
    (idsSpecId: string | null, attrName: keyof IDSSpecification) => (state: RootState) =>
      idsSpecId ? state.ifcManager.idsSpecs[idsSpecId][attrName] : null,
  selectIDSApplAttr:
    (idsSpecId: string | null, attrName: keyof IDSApplicability) => (state: RootState) =>
      idsSpecId ? state.ifcManager.idsSpecs[idsSpecId].applicability[attrName] : null,
  selectIDSReqsAttr:
    (idsSpecId: string | null, attrName: keyof IDSRequirement) => (state: RootState) =>
      idsSpecId ? state.ifcManager.idsSpecs[idsSpecId].requirements[attrName] : null,
  selectIDSSpec: (idsSpecId: string | null) => (state: RootState) =>
    idsSpecId ? state.ifcManager.idsSpecs[idsSpecId] : null,
  selectIDSPanelState: (state: RootState) => state.ifcManager.idsPanelState,
  selectIDSPanelSection: (state: RootState) => state.ifcManager.idsPanelSection,
  selectIDSInfo: (state: RootState) => state.ifcManager.idsInfo,
  selectIDSParsingError: (state: RootState) => state.ifcManager.idsParsingError,
  selectIDSFileName: (state: RootState) => state.ifcManager.idsFileName,
  selectIDSMode: (state: RootState) => state.ifcManager.idsMode,
  selectIsIDSValidationRunning: (state: RootState) => state.ifcManager.isIDSValidationRunning,
  selectRestrictionPathHasError: restrictionPathHasErrorSelector,
};

export const idsEditorReducers = {
  idsSpecSetSelectedId: idsSpecSetSelectedIdReducer,
  createIDSSpec: createIDSSpecReducer,
  deleteIDSSpec: deleteIDSSpecReducer,
  duplicateIDSSpec: duplicateIDSSpecReducer,
  moveToIDSSpec: moveToIDSSpecReducer,
  moveToIDSResult: moveToIDSResultReducer,
  moveToIDSRestriction: moveToIDSRestrictionReducer,
  moveToIDSSection: moveToIDSSectionReducer,
  idsSpecUpdateAttr: idsSpecUpdateAttrReducer,
  idsApplUpdateAttr: idsApplUpdateAttrReducer,
  idsReqsUpdateAttr: idsReqsUpdateAttrReducer,
  idsSpecAddApplicabilityRestriction: idsSpecAddApplicabilityRestrictionReducer,
  idsSpecAddRequirementsRestriction: idsSpecAddRequirementsRestrictionReducer,
  idsSpecSetSelectedRestrictionPath: idsSpecSetSelectedRestrictionPathReducer,
  idsSpecDeleteRestriction: idsSpecDeleteRestrictionReducer,
  idsSpecChangeRestrictionFacet: idsSpecChangeRestrictionFacetReducer,
  idsSpecUpdateRestriction: idsSpecUpdateRestrictionReducer,
  idsSpecChangeRestrictionType: idsSpecChangeRestrictionTypeReducer,
  idsSetPanelState: idsSetPanelStateReducer,
  idsSetPanelSection: idsSetPanelSectionReducer,
  idsSetInfo: idsSetInfoReducer,
  idsResetParsingError: idsResetParsingErrorReducer,
  idsSpecSetSelectedRestrictionXPath: idsSpecSetSelectedRestrictionXPathReducer,
  idsForceFullNormalisation: idsForceFullNormalisationReducer,
  createNewIDSFile: createNewIDSFileReducer,
  idsSetFileName: idsSetFileNameReducer,
  idsSetMode: idsSetModeReducer,
  idsFixUppercaseBooleans: idsFixUppercaseBooleansReducer,
};

export const idsEditorThunks = {
  idsSpecLoadIDSFile,
  idsSpecDownloadIDSFile,
  idsSpecDownloadSpecsCSVFile,
  idsSpecHighlightResultItem,
};

export const idsEditorMutationThunks = {
  idsSpecRunValidation,
};
