import { useAppDispatch, useAppSelector } from "app/state/hooks";
import theme, { styled } from "app/theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Link,
  List,
  ListItem,
} from "@mui/material";
import { ReactNode } from "react";
import { initializeTelemetry, deinitializeTelemetry } from "app/state/slices/telemetrySlice";
import { dismissModal, selectSelectedModal } from "app/state/slices/ifcManagerSlice";
import { MODALS } from "app/common/types";
import { useParamURLFileLoad } from "app/components/common/useFileLoad";

const ToSCompactList = styled(List)({
  padding: 0,
  marginTop: "0.25em",
  marginBottom: "0.5em",
  marginLeft: "1.5em",
  "& .MuiListItem-root": {
    paddingLeft: "0em",
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
});

const ToSItem = ({ children }: { children: ReactNode }) => {
  return (
    <ListItem
      sx={{
        display: "list-item",
        textAlign: "justify",
        [theme.breakpoints.down("sm")]: {
          textAlign: "left",
        },
      }}
    >
      <Typography sx={{ ml: "0em" }}>{children}</Typography>
    </ListItem>
  );
};

const ToSSectionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Typography sx={{ fontSize: "1.17em", marginTop: "0.5em" }} component="h3">
      {children}
    </Typography>
  );
};

export default function OpenAgreementModal() {
  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.OPENAGREEMENT === selectedModal;
  const dispatch = useAppDispatch();
  const paramUrlFileLoad = useParamURLFileLoad();

  const handleAcceptAll = () => {
    localStorage.setItem("acceptedTelemetry", "true");
    dispatch(initializeTelemetry());
    localStorage.setItem("acceptedToS", "true");
    dispatch(dismissModal());

    paramUrlFileLoad();
  };
  const handleAcceptEssentials = () => {
    dispatch(deinitializeTelemetry());
    localStorage.setItem("acceptedTelemetry", "false");
    localStorage.setItem("acceptedToS", "true");
    dispatch(dismissModal());

    paramUrlFileLoad();
  };

  return (
    <Dialog sx={{ p: 2 }} open={isOpen}>
      <DialogTitle>
        <b>Terms & Conditions</b> (for use without an account)
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          maxHeight: 400,
          textAlign: "justify",
          [theme.breakpoints.down("sm")]: {
            textAlign: "left",
          },
        }}
      >
        <Typography component="div">
          <ToSSectionHeader>
            <b>Essential</b>
          </ToSSectionHeader>

          <Typography component="div" sx={{ pl: "15px" }}>
            You accept the{" "}
            <Link href="/full-terms-open/#Terms-of-Service" target="_blank">
              Terms of Service
            </Link>
            ,{" "}
            <Link href="/full-terms-open/#Acceptable-Use" target="_blank">
              Acceptable Use
            </Link>
            ,{" "}
            <Link href="/full-terms-open/#Privacy-Policy" target="_blank">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link href="/full-terms-open/#Cookie-Policy" target="_blank">
              Cookie Policy
            </Link>{" "}
            in the{" "}
            <Link href="/full-terms-open/" target="_blank">
              Terms & Conditions
            </Link>
            , but choose to <b>opt out</b> of:
            <ToSCompactList sx={{ listStyleType: "disc", pl: "15px" }}>
              <ToSItem>
                <b>Analytics</b>: We collect data on page visits, outbound clicks, approximate
                location, language, and browser details to better understand our audience.
              </ToSItem>
              <ToSItem>
                <b>Telemetry & Crash Reporting</b>: We collect data on page state, actions and
                performance to fix errors and optimize your workflows.
              </ToSItem>
            </ToSCompactList>
          </Typography>
          <ToSSectionHeader>
            <b>All</b>
          </ToSSectionHeader>
          <Typography component="div" sx={{ pl: "15px" }}>
            You accept the{" "}
            <Link href="/full-terms-open/#Terms-of-Service" target="_blank">
              Terms of Service
            </Link>
            ,{" "}
            <Link href="/full-terms-open/#Acceptable-Use" target="_blank">
              Acceptable Use
            </Link>
            ,{" "}
            <Link href="/full-terms-open/#Privacy-Policy" target="_blank">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link href="/full-terms-open/#Cookie-Policy" target="_blank">
              Cookie Policy
            </Link>{" "}
            in the{" "}
            <Link href="/full-terms-open/" target="_blank">
              Terms & Conditions
            </Link>
          </Typography>
        </Typography>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleAcceptEssentials}
          sx={{ height: "max-content" }}
        >
          ACCEPT ESSENTIALS
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={handleAcceptAll}
          sx={{ height: "max-content" }}
        >
          ACCEPT ALL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
