import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  SelectChangeEvent,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import {
  IDSRestrictionPath,
  IDSValue,
  IDSValueRestrictionType,
  IDSValueType,
  XSEnumeration,
  XSRestriction,
  XSRestrictionType,
} from "app/common/idsSpec";
import { BoxCol } from "app/components/common/BoxCol";
import { BoxRow } from "app/components/common/BoxRow";
import { CleanDropdown } from "app/components/common/CleanDropdown";
import { CleanTextField } from "app/components/common/CleanTextField";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  idsSpecChangeRestrictionType,
  idsSpecUpdateRestriction,
  selectIDSRestriction,
  selectRestrictionPathHasError,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { emptyStringTo } from "app/utils/objectMeta";
import { useEffect, useRef, useState } from "react";

import { getIDSUIError } from "./IDSUIErrors";

const getIDSValueType = (idsValue: IDSValue): IDSValueType | null => {
  if (!idsValue) return null;
  if (typeof idsValue != "object") return "plain";
  if (idsValue["simpleValue"]) return "simpleValue";
  if (idsValue["xs:restriction"]) return "xs:restriction";
  return null;
};

const getAtributeRestrictionType = (
  xsRestriction: XSRestriction
): IDSValueRestrictionType | undefined => {
  if (!xsRestriction) return undefined;
  if (typeof xsRestriction != "object") return undefined;
  if (xsRestriction["xs:pattern"]) return "xs:pattern";
  if (xsRestriction["xs:length"]) return "xs:length";
  if (xsRestriction["xs:enumeration"]) return "xs:enumeration";
  if (
    xsRestriction["xs:minInclusive"] ||
    xsRestriction["xs:maxInclusive"] ||
    xsRestriction["xs:minExclusive"] ||
    xsRestriction["xs:maxExclusive"]
  )
    return "range";
  if (xsRestriction["xs:minLength"] || xsRestriction["xs:maxLength"]) return "lengthRange";

  return undefined;
};
const getXSRestrictionType = (xsRestriction: XSRestriction): XSRestrictionType | undefined => {
  if (!xsRestriction) return undefined;
  if (typeof xsRestriction != "object") return undefined;
  if (xsRestriction["xs:pattern"]) return "xs:pattern";
  if (xsRestriction["xs:length"]) return "xs:length";
  if (xsRestriction["xs:enumeration"]) return "xs:enumeration";
  if (xsRestriction["xs:minInclusive"]) return "xs:minInclusive";
  if (xsRestriction["xs:maxInclusive"]) return "xs:maxInclusive";
  if (xsRestriction["xs:minExclusive"]) return "xs:minExclusive";
  if (xsRestriction["xs:maxExclusive"]) return "xs:maxExclusive";
  if (xsRestriction["xs:minLength"]) return "xs:minLength";
  if (xsRestriction["xs:maxLength"]) return "xs:maxLength";

  return undefined;
};

export function RestrictionField({
  label,
  restrictionPath,
  variant,
  enumValues,
  isRequired,
  options,
  ...props
}: Omit<TextFieldProps, "variant"> & {
  label: string;
  restrictionPath: IDSRestrictionPath;
  variant?: "all" | "dropdown";
  enumValues?: string[][];
  isRequired?: boolean;
  options?: Array<{ label: string }>;
}) {
  const dispatch = useAppDispatch();
  const idsRestriction = useAppSelector(selectIDSRestriction(restrictionPath ?? undefined));

  const idsUIError = useAppSelector(selectRestrictionPathHasError(restrictionPath ?? undefined));
  const helperText = (idsUIError && getIDSUIError(idsUIError)) || undefined;

  console.log("RestrictionField: restrictionPath:", restrictionPath);
  console.log("RestrictionField: idsRestriction:", idsRestriction);

  const attribute = restrictionPath?.attribute;
  //@ts-ignore
  const attributeValue = attribute ? idsRestriction?.[attribute] : undefined;

  const attributeValueType =
    (restrictionPath?.valueType == "plain" && "plain") ||
    getIDSValueType(attributeValue) ||
    "simpleValue";
  const attributeXSRestriction =
    attributeValueType == "xs:restriction" ? attributeValue?.["xs:restriction"] : undefined;
  const attributeRestrictionType = attributeXSRestriction
    ? getAtributeRestrictionType(attributeXSRestriction)
    : undefined;
  const xsRestrictionType = attributeXSRestriction
    ? getXSRestrictionType(attributeXSRestriction)
    : undefined;
  const isMinInclusive = attributeXSRestriction?.["xs:minInclusive"];
  const isMaxInclusive = attributeXSRestriction?.["xs:maxInclusive"];

  const type = attributeRestrictionType || attributeValueType;
  const lastEnumValueRef = useRef(null as HTMLLIElement | null);
  const [lastEnumValueScrollQueued, setlastEnumValueScrollQueued] = useState(0);

  useEffect(() => {
    if (lastEnumValueRef.current) {
      lastEnumValueRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lastEnumValueScrollQueued]);

  console.log("RestrictionField: attribute:", attribute);
  console.log("RestrictionField: attributeXSRestriction:", attributeXSRestriction);
  console.log("RestrictionField: attributeRestrictionType:", attributeRestrictionType);

  if (!attribute) {
    return <>No attribute to edit</>;
  }

  const updateValue = (value: IDSValue) => {
    dispatch(
      idsSpecUpdateRestriction({
        restrictionPath: {
          ...restrictionPath,
          valueType: attributeValueType ?? undefined,
          xsRestrictionType: attributeRestrictionType,
        },
        // prevent empty simpleValues
        value: emptyStringTo(value, undefined),
      })
    );
  };

  return (
    <BoxRow sx={{ alignItems: "flex-start", gap: theme.sppx.compactV }}>
      {(variant == "dropdown" && (
        <BoxRow
          sx={{
            alignItems: "center",
            gap: theme.sppx.compactV,
            marginTop: theme.sppx.compactV,
          }}
        >
          <CleanDropdown
            label={label}
            values={enumValues}
            value={attributeValue ?? ""}
            onChange={(event: SelectChangeEvent<unknown>) =>
              updateValue(event.target.value as string)
            }
          />
        </BoxRow>
      )) || (
        <BoxRow
          sx={{
            flexGrow: 1,
            alignItems: "flex-start",
            gap: theme.sppx.compact2H,
            marginRight: theme.sppx.compactV,
            boxSizing: "border-box",
          }}
        >
          {restrictionPath?.valueType != "plain" && (
            <BoxCol sx={{ gap: theme.sppx.spaciousV }}>
              <BoxRow
                sx={{
                  alignItems: "center",
                  gap: theme.sppx.compactV,
                  marginTop: theme.sppx.compactV,
                }}
              >
                <CleanDropdown
                  label={label + " Type"}
                  values={[
                    ["simpleValue", "Simple"],
                    ["xs:enumeration", "Enumeration"],
                    ["xs:pattern", "Pattern"],
                    ["range", "Range"],
                    ["xs:length", "Length"],
                    ["lengthRange", "Length Range"],
                  ]}
                  value={type}
                  onChange={(event: SelectChangeEvent<unknown>) =>
                    dispatch(
                      idsSpecChangeRestrictionType({
                        restrictionPath,
                        type: event.target.value as IDSValueType | IDSValueRestrictionType,
                      })
                    )
                  }
                />
              </BoxRow>
            </BoxCol>
          )}
          {restrictionPath?.valueType != "plain" && (
            <Divider
              orientation="vertical"
              sx={{ height: "30px", marginTop: "15px", marginRight: "-4px" }}
            />
          )}
          {restrictionPath?.valueType != "plain" && type == "simpleValue" && (
            <CleanTextField
              label={label}
              value={attributeValue?.simpleValue ?? ""}
              options={options}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateValue(event.target.value)
              }
              isRequired={isRequired}
              hasError={idsUIError != null}
              helperText={helperText}
              {...props}
            />
          )}
          {restrictionPath?.valueType == "plain" && (
            <CleanTextField
              label={label}
              options={options}
              value={attributeValue ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateValue(event.target.value)
              }
              sx={{ marginLeft: 0 }}
              isRequired={isRequired}
              hasError={idsUIError != null}
              helperText={helperText}
              {...props}
            />
          )}
          {attributeRestrictionType &&
            xsRestrictionType &&
            ["xs:pattern", "xs:length"].includes(attributeRestrictionType) && (
              <CleanTextField
                label={label}
                value={attributeXSRestriction?.[xsRestrictionType]?.$_value ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateValue({
                    "xs:restriction": {
                      [attributeRestrictionType]: { $_value: event.target.value },
                    },
                  })
                }
                isRequired={isRequired}
                hasError={idsUIError != null}
                helperText={helperText}
                {...props}
              />
            )}

          {attributeRestrictionType &&
            ["range", "lengthRange"].includes(attributeRestrictionType) && (
              <BoxRow sx={{ alignItems: "center", flexGrow: 1 }}>
                <CleanTextField
                  label={"Min " + label}
                  value={
                    attributeXSRestriction?.[
                      (attributeRestrictionType == "lengthRange" && "xs:minLength") ||
                        (isMinInclusive ? "xs:minInclusive" : "xs:minExclusive")
                    ]?.$_value ?? ""
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateValue({
                      "xs:restriction": {
                        [(attributeRestrictionType == "lengthRange" && "xs:minLength") ||
                        (isMinInclusive ? "xs:minInclusive" : "xs:minExclusive")]: {
                          $_value: event.target.value,
                        },
                      },
                    })
                  }
                  isRequired={isRequired}
                  {...props}
                />
                {attributeRestrictionType == "range" && (
                  <CleanDropdown
                    values={[
                      ["exlusive", "Exclusive"],
                      ["inclusive", "Inclusive"],
                    ]}
                    value={isMinInclusive ? "inclusive" : "exlusive"}
                    onChange={(event: SelectChangeEvent<unknown>) =>
                      updateValue({
                        "xs:restriction": {
                          [event.target.value == "inclusive"
                            ? "xs:minInclusive"
                            : "xs:minExclusive"]: {
                            $_value:
                              attributeXSRestriction?.[
                                isMaxInclusive ? "xs:minInclusive" : "xs:minExclusive"
                              ]?.$_value ?? "",
                          },
                        },
                      })
                    }
                  />
                )}
              </BoxRow>
            )}
          {attributeRestrictionType &&
            ["range", "lengthRange"].includes(attributeRestrictionType) && (
              <BoxRow
                sx={{
                  alignItems: "center",
                  flexGrow: 1,
                  boxSizing: "box-border",
                  paddingRight: theme.sppx.compactV,
                  overflow: "hidden",
                }}
              >
                <CleanTextField
                  label={"Max " + label}
                  value={
                    attributeXSRestriction?.[
                      (attributeRestrictionType == "lengthRange" && "xs:maxLength") ||
                        (isMaxInclusive ? "xs:maxInclusive" : "xs:maxExclusive")
                    ]?.$_value ?? ""
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateValue({
                      "xs:restriction": {
                        [(attributeRestrictionType == "lengthRange" && "xs:maxLength") ||
                        (isMaxInclusive ? "xs:maxInclusive" : "xs:maxExclusive")]: {
                          $_value: event.target.value,
                        },
                      },
                    })
                  }
                  isRequired={isRequired}
                  {...props}
                />
                {attributeRestrictionType == "range" && (
                  <CleanDropdown
                    values={[
                      ["exlusive", "Exclusive"],
                      ["inclusive", "Inclusive"],
                    ]}
                    value={isMaxInclusive ? "inclusive" : "exlusive"}
                    onChange={(event: SelectChangeEvent<unknown>) =>
                      updateValue({
                        "xs:restriction": {
                          [event.target.value == "inclusive"
                            ? "xs:maxInclusive"
                            : "xs:maxExclusive"]: {
                            $_value:
                              attributeXSRestriction?.[
                                isMaxInclusive ? "xs:maxInclusive" : "xs:maxExclusive"
                              ]?.$_value ?? "",
                          },
                        },
                      })
                    }
                  />
                )}
              </BoxRow>
            )}
          {xsRestrictionType == "xs:enumeration" && (
            <BoxCol
              sx={{
                flexGrow: 1,
                marginTop: "4px",
              }}
            >
              <List
                sx={{
                  minHeight: "9px",
                  maxHeight: "13rem",
                  overflowY: "auto",
                  overflowX: "hidden",
                  border: `1px solid ${theme.palette.subtleGreyBorder}`,
                  paddingTop: theme.sppx.compactV,
                  padding: theme.sppx.compactV,
                  borderRadius: "5px",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: "none",
                }}
              >
                {attributeXSRestriction?.["xs:enumeration"] &&
                  attributeXSRestriction?.["xs:enumeration"].map(
                    (x: XSEnumeration, index: number) => (
                      <ListItem
                        disablePadding
                        key={index}
                        ref={
                          attributeXSRestriction["xs:enumeration"].length - 1 == index
                            ? lastEnumValueRef
                            : undefined
                        }
                      >
                        <CleanTextField
                          label={label}
                          options={options}
                          value={x?.$_value ?? ""}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            updateValue({
                              "xs:restriction": {
                                //@ts-ignore
                                "xs:enumeration": [index, { $_value: event.target.value }],
                              },
                            })
                          }
                          isRequired={isRequired}
                          {...(idsUIError?.affectedEnumIndexes != null &&
                            idsUIError.affectedEnumIndexes[index] && {
                              hasError: true,
                              helperText: helperText,
                            })}
                          {...props}
                        />
                        <Tooltip placement="top" arrow title="Delete">
                          <IconButton
                            variant="inline"
                            onClick={() =>
                              updateValue({
                                "xs:restriction": {
                                  //@ts-ignore
                                  "xs:enumeration": [index, undefined],
                                },
                              })
                            }
                            sx={{ margin: "-8px 5px -8px 5px", padding: "8px" }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    )
                  )}
              </List>
              <Tooltip title="Add restriction" placement="right">
                <Button
                  variant="outlined"
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    border: "1px solid #ddd",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    boxSizing: "border-box",
                  }}
                  onClick={() => {
                    updateValue({
                      "xs:restriction": {
                        //@ts-ignore
                        "xs:enumeration": [undefined, { $_value: "" }],
                      },
                    });
                    setlastEnumValueScrollQueued(lastEnumValueScrollQueued + 1);
                  }}
                >
                  <AddIcon
                    sx={{
                      color: "inherit",
                      width: `${theme.sp.iconSize + 7}px`,
                      height: `${theme.sp.iconSize + 7}px`,
                      marginTop: "-3px",
                      marginBottom: "-3px",
                    }}
                  />
                </Button>
              </Tooltip>
            </BoxCol>
          )}
        </BoxRow>
      )}
    </BoxRow>
  );
}
