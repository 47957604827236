import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
// import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";

import "./index.css";
import App from "./App";
import { store } from "./app/state/store";
import theme from "./app/theme";

/* eslint-disable */
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// this version breaks the threejs size updates
// //@ts-ignore
// createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Provider store={store}>
//         <ThemeProvider theme={theme}>
//           <App />
//         </ThemeProvider>
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>
// );
