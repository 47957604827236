import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import LogoutIcon from "@mui/icons-material/Logout";
import SignalCellularNodataIcon from "@mui/icons-material/SignalCellularNodata";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { MODALS } from "app/common/types";
import { BoxRow } from "app/components/common/BoxRow";
import { Heavy } from "app/components/common/Heavy";
import { LocalIFCPicker } from "app/components/common/LocalIFCPicker";
import { useFileLoad } from "app/components/common/useFileLoad";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  accountSignOut,
  listModels,
  selectAccountIsLoggedIn,
  selectAccountName,
  selectAreChangesCloudSynced,
  selectFileName,
  selectIsServerNotReachable,
  selectModelCloudId,
  selectModelsDetails,
  setActiveAccountField,
  setIsWaitingServerResponseAccount,
  setSelectedModal,
  setlastSignUpEventSource,
} from "app/state/slices/ifcManagerSlice";
import SortdeskIcon from "app/static/images/logo_only_blue.svg";
import theme from "app/theme";
import { useState } from "react";

export default function Navbar() {
  const dispatch = useAppDispatch();
  const fileName = useAppSelector(selectFileName);
  const onFullFileLoad = useFileLoad(false);
  const accountIsLoggedIn = useAppSelector(selectAccountIsLoggedIn);
  const isLoggedIn = accountIsLoggedIn || localStorage.getItem("isLoggedIn") == "true";
  const areChangesCloudSynced = useAppSelector(selectAreChangesCloudSynced);
  const modelCloudId = useAppSelector(selectModelCloudId);
  const accountName = useAppSelector(selectAccountName) || localStorage.getItem("loginName");
  const isServerNotReachable = useAppSelector(selectIsServerNotReachable);
  const modelsDetails = useAppSelector(selectModelsDetails);

  const modelCount = Object.keys(modelsDetails).length;

  const [accountsMenuAnchor, setAccountsMenuAnchor] = useState<null | HTMLElement>(null);
  const isAccountsMenuOpen = Boolean(accountsMenuAnchor);
  const openAccountsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAccountsMenuAnchor(event.currentTarget);
  };
  const closeAccountsMenu = () => {
    setAccountsMenuAnchor(null);
  };

  const signOut = () => {
    setAccountsMenuAnchor(null);
    dispatch(accountSignOut());
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: theme => theme.zIndex.drawer + 2, height: theme.sppx.headerHeight }}
    >
      <Toolbar
        sx={{
          backgroundColor: "white",
          borderBottom: `1px solid ${theme.palette.subtlerGreyBorder}`,
          color: "black",
          paddingLeft: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
          minHeight: theme.sppx.headerHeight,
        }}
      >
        <Button onClick={() => window.location.reload()}>
          <Box
            component="img"
            sx={{
              ml: "4px",
              height: "30px",
            }}
            alt="Sortesk company logo"
            src={SortdeskIcon}
          />
          <Chip
            label="BETA"
            color="primary"
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "none",
              marginLeft: "0.5em",
              height: "2em",
              "& span": {
                paddingLeft: "0.7em",
                paddingRight: "0.7em",
              },
              marginTop: "0.2em", // it feels visually unbalanced without this
            }}
          />
        </Button>

        {
          // IFC File state
        }
        <Box
          sx={{
            flexGrow: 0,
            ml: "auto",
            flex: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {fileName && (
            <Button
              variant="outlined"
              size="small"
              sx={{
                textTransform: "none",
                color: "rgba(0, 0, 20, 0.85)",
                width: "650px",
                p: 0,
                zIndex: 100, // above dropdown shadow
                background: "white",
                border: "1px solid rgba(0, 0, 100, 0.2)",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              onClick={() => {
                if (isLoggedIn) {
                  dispatch(setIsWaitingServerResponseAccount());
                  dispatch(listModels());
                  dispatch(setSelectedModal(MODALS.CLOUDPICKER));
                } else {
                  dispatch(setSelectedModal(MODALS.LOGIN));
                }
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}
              >
                {isLoggedIn && isServerNotReachable && (
                  <Tooltip title="Can't reach the Sortdesk server">
                    <SignalCellularNodataIcon sx={{ pl: "10px" }} />
                  </Tooltip>
                )}
                {fileName &&
                  ((modelCloudId && areChangesCloudSynced && (
                    <>
                      <CloudDoneIcon sx={{ color: theme.palette.cloudDoneGreen, pl: "15px" }} />
                      <Typography
                        sx={{ color: theme.palette.cloudDoneGreen, pl: "10px", flexShrink: "0" }}
                      >
                        <Heavy>Synced to Cloud</Heavy>
                      </Typography>
                    </>
                  )) ||
                    (modelCloudId && !areChangesCloudSynced && (
                      <>
                        <CloudIcon sx={{ color: theme.palette.cloudSyncingYellow, pl: "15px" }} />
                        <Typography
                          sx={{
                            color: theme.palette.cloudSyncingYellow,
                            pl: "10px",
                            flexShrink: "0",
                          }}
                        >
                          <Heavy>Edits not synced</Heavy>
                        </Typography>
                      </>
                    )) ||
                    (!modelCloudId && (
                      <>
                        <CloudOffIcon sx={{ color: theme.palette.cloudDesyncedRed, pl: "15px" }} />
                        <Typography
                          sx={{
                            color: theme.palette.cloudDesyncedRed,
                            pl: "10px",
                            flexShrink: "0",
                          }}
                        >
                          <Heavy>Not Synced</Heavy>
                        </Typography>
                      </>
                    )))}
                <Box sx={{ flex: "1" }} />
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    fontSize: "1rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      overflowX: "auto",
                      textOverflow: "inherit",
                    },
                    marginLeft: theme.sppx.compactH,
                    marginRight: theme.sppx.compactH,
                  }}
                >
                  {Object.entries(modelsDetails)?.[0]?.[1]?.fileName ?? "No file loaded"}
                </Typography>
                {modelCount >= 2 && (
                  <Typography
                    sx={{ fontWeight: "500", marginRight: theme.sppx.compactH, flexShrink: "0" }}
                  >
                    <span>&amp; </span>
                    {modelCount - 1}
                    <span> more</span>
                  </Typography>
                )}
                <Box sx={{ flex: "1" }} />
              </Box>
            </Button>
          )}
          <Tooltip title="Open local file">
            <Button
              variant="outlined"
              startIcon={<LaptopChromebookIcon />}
              size="small"
              color="primary"
              component="label"
              sx={{
                [theme.breakpoints.down("sm")]: { display: "none" },
                paddingRight: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                ...(fileName != null && {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }),
              }}
            >
              {!fileName && (
                <Typography variant="button" sx={{ mr: "12px", textTransform: "none" }}>
                  Open local .ifc
                </Typography>
              )}
              <LocalIFCPicker onChange={onFullFileLoad} />
            </Button>
          </Tooltip>

          {!fileName && (
            <Divider
              variant="middle"
              sx={{
                [theme.breakpoints.down("sm")]: { display: "none" },
                border: `1px solid ${theme.palette.subtleGreyBorder}`,
                m: "0px 7px",
                height: "40px",
              }}
            />
          )}
          <Tooltip title="Open cloud file">
            <Button
              variant="outlined"
              endIcon={<CloudIcon />}
              size="small"
              color="primary"
              component="label"
              onClick={() => {
                if (isLoggedIn) {
                  dispatch(setIsWaitingServerResponseAccount());
                  dispatch(listModels());
                  dispatch(setSelectedModal(MODALS.CLOUDPICKER));
                } else {
                  dispatch(setSelectedModal(MODALS.LOGIN));
                }
              }}
              sx={{
                [theme.breakpoints.down("sm")]: { display: "none" },
                minWidth: "25px",
                height: "32px",
                padding: "5px 10px 5px 0px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {!fileName && (
                <Typography variant="button" sx={{ ml: "10px", textTransform: "none" }}>
                  Open cloud .ifc
                </Typography>
              )}
            </Button>
          </Tooltip>
        </Box>

        {
          // LOGIN PART
        }

        {!accountName && (
          <BoxRow sx={{ flexGrow: 0, ml: "auto", alignItems: "center", position: "relative" }}>
            <Typography
              sx={{
                position: "absolute",
                color: theme.palette.greyText,
                marginRight: "1em",
                width: "max-content",
                right: "100%",
                [theme.breakpoints.down(fileName != null ? 1500 : "md")]: {
                  display: "none",
                },
              }}
            >
              Start using the Viewer for free
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                dispatch(setlastSignUpEventSource("navbar"));
                dispatch(setSelectedModal(MODALS.LOGIN));
              }}
            >
              <Typography variant="button" sx={{ textTransform: "none" }}>
                Sign up
              </Typography>
            </Button>
          </BoxRow>
        )}
        {accountName && (
          <>
            <Tooltip title="Account settings">
              <IconButton
                onClick={openAccountsMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={isAccountsMenuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isAccountsMenuOpen ? "true" : undefined}
              >
                <Avatar variant="rounded" sx={{ bgcolor: "#0d47a1", width: 40, height: 32 }}>
                  {/* extract all initials */}
                  {accountName.replace(/[^A-Z]/g, "")}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Box sx={{ flexGrow: 0, ml: "auto" }}>
              <Menu
                anchorEl={accountsMenuAnchor}
                id="account-menu"
                open={isAccountsMenuOpen}
                onClose={closeAccountsMenu}
                onClick={closeAccountsMenu}
                sx={{
                  paddingTop: "0px",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Typography
                  sx={{
                    pl: "10px",
                    pr: "10px",
                    pt: "3px",
                    pb: "5px",
                    background: "#0d47a1",
                    color: "white",
                    minWidth: "150px",
                    // borderBottom: "1px solid #0f8001",
                    borderTop: "1px solid #333333",
                  }}
                >
                  <Heavy>{accountName}</Heavy>
                </Typography>
                <Divider />
                <MenuItem
                  onClick={() => {
                    dispatch(setActiveAccountField(null));
                    dispatch(setSelectedModal(MODALS.ACCOUNT));
                  }}
                >
                  Account Settings
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
