import { styled } from "app/theme";
import { ReactNode, FC } from "react";
import { Divider, Typography, Box, Link, List, ListItem, ListItemIcon } from "@mui/material";
import ContentCut from "@mui/icons-material/ContentCut";
import Straighten from "@mui/icons-material/Straighten";
import Description from "@mui/icons-material/Description";
import Category from "@mui/icons-material/Category";
import ThreeSixty from "@mui/icons-material/ThreeSixty";
import OpenWith from "@mui/icons-material/OpenWith";
import ZoomIn from "@mui/icons-material/ZoomIn";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import theme from "app/theme";
import { TOOLS } from "app/common/types";

import SidebarHeader from "../ToolsSideBar/SidebarHeader";
import FeedbackButton from "../FeedbackButton";

const CompactList = styled(List)({
  padding: 0,
  ...theme.vPad.compact2,
  "& .MuiListItem-root": {
    ...theme.vPad.compact,
  },
});

const ToolItem = ({ Icon, children }: { Icon: FC; children: ReactNode }) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: "auto" }}>
        <Icon />
      </ListItemIcon>
      <Typography sx={{ ml: "1em" }}>{children}</Typography>
    </ListItem>
  );
};

const SectionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 500, fontSize: "1.1.em", marginTop: theme.sppx.spaciousV }}>
        {children}
      </Typography>
      <Divider />
    </>
  );
};

export default function HelpDrawer() {
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <SidebarHeader
        placedOnLeftSidebar={true}
        title="Feedback & Help"
        hasHelpIcon={false}
        selectedTool={TOOLS.HELP}
      />
      <Box
        sx={{
          flex: "1 1 100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          ...theme.hPad.compact2,
          ...theme.vPad.spacious,
        }}
      >
        <Typography sx={{ whiteSpace: "normal", textAlign: "justify" }} component="div">
          <b>Sortdesk IFC Viewer</b> is a free, browser-based local tool which enables BIM engineers
          to explore models, currently in open beta. It builds on openBIM using{" "}
          <Link target="_blank" href="https://github.com/ThatOpen/engine_components">
            Open BIM Components
          </Link>{" "}
          and{" "}
          <Link target="_blank" href="https://github.com/IfcOpenShell/IfcOpenShell">
            IfcOpenShell
          </Link>
          .<SectionHeader>Features</SectionHeader>
          <CompactList>
            <ToolItem Icon={Category}>
              <b>Property picking</b> - quantity sets
            </ToolItem>
            <ToolItem Icon={ContentCut}>
              <b>Section tool</b> - cut content with a plane
            </ToolItem>
            <ToolItem Icon={Straighten}>
              <b>Dimension tool</b> - measure objects
            </ToolItem>
            <ToolItem Icon={Description}>
              <b>Plan manager</b> - top view of any floor
            </ToolItem>
            <ToolItem Icon={AccountTreeOutlinedIcon}>
              <b>Object Tree</b> - IFC project hierarchy
            </ToolItem>
          </CompactList>
          <Typography sx={{ marginTop: theme.sppx.compact2V, paddingBottom: theme.sppx.compactV }}>
            <b>Load an IFC file to start using them!</b>
          </Typography>
          <SectionHeader>Viewport movement</SectionHeader>
          <Divider />
          <CompactList>
            <ToolItem Icon={ThreeSixty}>
              <b>Orbit</b>: Left click & drag
            </ToolItem>
            <ToolItem Icon={OpenWith}>
              <b>Pan</b>: Right click & drag
            </ToolItem>
            <ToolItem Icon={ZoomIn}>
              <b>Zoom</b>: Middle click & drag or Scroll
            </ToolItem>
          </CompactList>
          <br />
          <br />
        </Typography>
        <Box sx={{ flexShrink: 1, flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            ...theme.vPad.compact2,
          }}
        >
          <FeedbackButton />
          <Link
            sx={({ typography: { body1 } }) => ({ my: 2, ...body1 })}
            target="_blank"
            href="mailto:support@sortdesk.com"
          >
            or send us an email
          </Link>
          <Typography sx={{ whiteSpace: "normal", textAlign: "justify" }}>
            <i>All saved configurations will be deleted after reloading the page.</i>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
