import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Typography } from "@mui/material";
import { arePathsEqual } from "app/common/idsSpec";
import { BoxRow } from "app/components/common/BoxRow";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  highlightByID,
  moveToIDSResult,
  selectIDSResultItem,
  selectIDSSpecSelectedRestrictionPath,
  selectModelUUID,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { NodeRendererProps } from "react-arborist";

import { IDSSpecTreeNode, nodeHeight } from "./common";

export const NodeResultItem = ({ node, style, dragHandle }: NodeRendererProps<IDSSpecTreeNode>) => {
  const dispatch = useAppDispatch();

  //todo: update for federated
  const modelUUID = useAppSelector(selectModelUUID);
  // const idsRestriction = useAppSelector(selectIDSRestriction(node.id));
  const restrictionPath = node.data.metadata.restrictionPath;
  const resultItem = useAppSelector(selectIDSResultItem(restrictionPath));

  const selectedRestrictionPath = useAppSelector(selectIDSSpecSelectedRestrictionPath);
  const isSelected = arePathsEqual(restrictionPath, selectedRestrictionPath);

  return (
    <div
      ref={dragHandle}
      className="TreeItemHover"
      style={{
        ...style,
        height: `${nodeHeight}px`,
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginLeft: theme.sppx.spaciousV,
        overflow: "hidden",
        border: "2px solid",
        borderColor: isSelected ? theme.palette.primary.main : "transparent",
        paddingLeft: theme.sppx.compactH,
        paddingRight: theme.sppx.spaciousH,
        paddingTop: theme.sppx.gapTiny,
        paddingBottom: theme.sppx.gapTiny,
      }}
      onPointerEnter={() => {
        if (!resultItem) return;
        dispatch(
          highlightByID({
            modelUUID: modelUUID,
            expressID: String(resultItem.expressID),
            isTempHover: true,
            isSpatiallyReccursive: true,
          })
        );
      }}
      onPointerLeave={() => {
        if (!resultItem) return;
        dispatch(
          highlightByID({
            modelUUID: null,
            expressID: null,
            isTempHover: true,
            isSpatiallyReccursive: true,
          })
        );
      }}
      onClick={() => {
        if (!resultItem) return;
        dispatch(moveToIDSResult(node.data.metadata.restrictionPath ?? null));
        dispatch(
          highlightByID({
            modelUUID: modelUUID,
            expressID: String(resultItem.expressID),
            isSpatiallyReccursive: true,
          })
        );
      }}
    >
      <BoxRow
        sx={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          boxSizing: "border-box",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <FiberManualRecordIcon
          sx={{
            color:
              (resultItem &&
                (resultItem?.failedRequirements?.length > 0
                  ? theme.palette.softFail
                  : theme.palette.softPass)) ||
              theme.palette.inactiveGrey,
            padding: `${theme.sp.compactH + theme.sp.gapTiny}px`,
            margin: "0 5px 0 0",
            width: `${theme.sppx.compact2H}`,
            height: `${theme.sppx.compact2H}`,
          }}
        />
        <Typography
          sx={{
            "&:hover": {
              overflowX: "auto",
            },
          }}
        >
          {(resultItem && (resultItem?.name ?? <i>No Name</i>)) || <i>{node.data.name}</i>}
        </Typography>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            "&:hover": {
              overflowX: "auto",
              textOverflow: "inherit",
            },
          }}
        >
          <Typography>
            {resultItem?.failedRequirements?.length > 0 ? <b>failed item</b> : <b>passed item</b>}
          </Typography>
        </Box> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}
      </BoxRow>
    </div>
  );
};
