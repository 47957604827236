import { POLICIES } from "app/common/types";

import { policy_ACCEPTABLE_USE_FREE } from "./ACCEPTABLE_USE_FREE";
import { policy_ACCEPTABLE_USE_OPEN } from "./ACCEPTABLE_USE_OPEN";
import { policy_COOKIES_FREE } from "./COOKIES_FREE";
import { policy_COOKIES_OPEN } from "./COOKIES_OPEN";
import { policy_PRIVACY_FREE } from "./PRIVACY_FREE";
import { policy_PRIVACY_OPEN } from "./PRIVACY_OPEN";
import { policy_TOS_FREE } from "./TOS_FREE";
import { policy_TOS_OPEN } from "./TOS_OPEN";

export const policies = {
  [POLICIES.ACCEPTABLE_USE_FREE]: policy_ACCEPTABLE_USE_FREE,
  [POLICIES.ACCEPTABLE_USE_OPEN]: policy_ACCEPTABLE_USE_OPEN,
  [POLICIES.COOKIES_FREE]: policy_COOKIES_FREE,
  [POLICIES.COOKIES_OPEN]: policy_COOKIES_OPEN,
  [POLICIES.PRIVACY_FREE]: policy_PRIVACY_FREE,
  [POLICIES.PRIVACY_OPEN]: policy_PRIVACY_OPEN,
  [POLICIES.TOS_FREE]: policy_TOS_FREE,
  [POLICIES.TOS_OPEN]: policy_TOS_OPEN,
};
