import {
  Camera,
  Color,
  Vector3,
  Vector2,
  WebGLRenderer,
  Scene,
  BufferGeometry,
  Line,
  MeshBasicMaterial,
  Mesh,
  Quaternion,
  CircleGeometry,
  InstancedMesh,
  Matrix3,
  Matrix4,
  Object3D,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
} from "three";
import { Component, Updateable, Disposable, Event } from "app/components/ifcjs/core";
import { ViewerAPI, viewerAPI } from "app/common/ViewerAPI";
import { VertexPicker } from "./VertexPicker";
import { GraphicVertexPicker } from "app/components/ifcjs/front";

export class TargetCursor extends Component implements Updateable, Disposable {
  // OBC requirements
  enabled = true;
  static readonly uuid = "de86ffd1-f5bd-46ca-b0a5-b6c4274793c3";
  readonly onAfterUpdate = new Event();
  readonly onBeforeUpdate = new Event();
  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  renderer: WebGLRenderer;
  camera: Camera;
  cursorObject: Object3D;
  cursorMaterial: MeshBasicMaterial;
  mousePos: Vector2;
  frameCounter: number;
  _vertexPicker: VertexPicker;

  private viewer: ViewerAPI;

  constructor(viewer: ViewerAPI) {
    super(viewer._components);
    this.viewer = viewer;
    viewer._components.add(TargetCursor.uuid, this);
    this.frameCounter = 0;
    this._vertexPicker = viewer._vertexPicker;

    this.camera = viewer._camera.three;
    this.renderer = viewer._renderer.three;

    // middle of screen => not selected
    this.mousePos = new Vector2(0, 0);

    const dom = viewer._renderer.container;
    dom.addEventListener("mousemove", e => {
      this.mousePos.set(
        (e.clientX / dom.clientWidth) * 2.0 - 1,
        (1 - e.clientY / dom.clientHeight) * 2.0 - 1
      );
    });

    {
      /* Cursor hexagon */
      const radius = 0.5;
      const geometry = new CircleGeometry(radius, 16);
      this.cursorMaterial = new MeshBasicMaterial({
        color: 0xfc6d04,
        opacity: 0.45,
        transparent: true,
        polygonOffset: true,
        polygonOffsetFactor: 1, // positive value pushes polygon further away
        polygonOffsetUnits: 1,
      });
      this.cursorObject = new Mesh(geometry, this.cursorMaterial);

      // wireframe
      const geo = new EdgesGeometry(geometry); // or WireframeGeometry
      const lineMaterial = new LineBasicMaterial({ color: 0x000000 });
      const wireframe = new LineSegments(geo, lineMaterial);
      this.cursorObject.add(wireframe);

      {
        const geometry = new BufferGeometry().setFromPoints([
          new Vector3(-radius, 0, 0),
          new Vector3(radius, 0, 0),
        ]);
        const lineX = new Line(geometry, lineMaterial);
        this.cursorObject.add(lineX);
      }
      {
        const geometry = new BufferGeometry().setFromPoints([
          new Vector3(0, -radius, 0),
          new Vector3(0, radius, 0),
        ]);
        const lineY = new Line(geometry, lineMaterial);
        this.cursorObject.add(lineY);
      }

      this.cursorObject.visible = false;
      this.cursorObject.renderOrder = Number.MAX_SAFE_INTEGER;
      lineMaterial.depthTest = false;
      this.cursorMaterial.depthTest = false;
      this.cursorMaterial.transparent = true;

      viewer._scene.three.add(this.cursorObject);
    }
  }
  async dispose() {
    this.enabled = false;
    this.onBeforeUpdate.reset();
    this.onAfterUpdate.reset();
  }

  get() {
    return {};
  }

  updateCamera() {
    this.camera = this.viewer._camera.three.clone();
  }

  interact() {
    const mousePos = new Vector2().copy(this.mousePos);

    // if outside viewport, no use doing the intersections
    if (Math.abs(mousePos.x) >= 1 || Math.abs(mousePos.y) >= 1) {
      return;
    }

    if (!this._vertexPicker) {
      return;
    }

    this._vertexPicker.enabled = true;
    const found = this._vertexPicker.get(this.viewer._world);
    if (!found) return;
    this.cursorObject.position.copy(found);

    const worldNormal = this._vertexPicker.pickedNormal;
    if (worldNormal) {
      this.cursorObject.setRotationFromQuaternion(
        new Quaternion().setFromUnitVectors(new Vector3(0, 0, 1), worldNormal)
      );

      // rgb, oklch(67.94, 0.2009, (23.5 | 140.2 | 241.4))
      const color =
        0xfc5759 * Math.abs(worldNormal.x) +
        0x44b427 * Math.abs(worldNormal.y) +
        0x00a1f0 * Math.abs(worldNormal.z);
      this.cursorMaterial.color = new Color(Math.floor(color));
    }

    if (this._vertexPicker.pickedCameraDepth) {
      const scaleFactor = this._vertexPicker.pickedCameraDepth * 30 + 0.5;
      this.cursorObject.scale.set(scaleFactor, scaleFactor, 1);
    }
  }

  needsRedraw() {
    // follow renderer.ts
    return this.enabled;
  }

  async update() {
    if (!this.needsRedraw()) return;

    if (viewerAPI()) {
      this.viewer = viewerAPI();
    }

    this.updateCamera();
    if (this.frameCounter % 4 == 0) {
      // update raycasting every 4 frames to avoid lag
      this.interact();
    }
  }
}
