import { Box, BoxProps } from "@mui/material";
import theme from "app/theme";

/* An edge to edge row which fills the parent container */
export const BoxRowE2E = ({ ...props }: BoxProps) => {
  const { sx, ...otherProps } = props;
  return (
    <Box
      sx={{
        padding: `${theme.sppx.spaciousV} ${theme.sppx.spaciousH}`,
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        ...sx,
      }}
      {...otherProps}
    />
  );
};
