import { Snackbar, Alert } from "@mui/material";
import {
  selectSnackbarMessage,
  selectSnackbarSeverity,
  dismissSnackbar,
} from "app/state/slices/ifcManagerSlice";
import { useAppDispatch, useAppSelector } from "app/state/hooks";

export default function SnackbarModal() {
  const dispatch = useAppDispatch();

  const message = useAppSelector(selectSnackbarMessage);
  const severity = useAppSelector(selectSnackbarSeverity);

  return (
    <Snackbar
      sx={{ mb: { xs: 1, sm: 2 }, ml: { xs: 1, sm: 2 } }}
      open={Boolean(message)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={() => dispatch(dismissSnackbar())}
    >
      <Alert onClose={() => dispatch(dismissSnackbar())} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
