import { Box } from "@mui/material";
import { styled } from "app/theme";

export const BoxDynCol = styled(Box)({
  flexGrow: 1,
  flexShrink: 1,
  minHeight: 0,
  display: "flex",
  flexDirection: "column",
});
