import { Dimension, DimensionId } from "app/common/types";
import { ViewerSingleton } from "app/common/ViewerAPI";
import { Line, LineBasicMaterial, Vector3 } from "three";

export const _turnOnDimensionTool = () => {
  const viewer = ViewerSingleton.getInstance();
  viewer._dimensions.enabled = true;
};
export const _turnOffDimensionTool = () => {
  const viewer = ViewerSingleton.getInstance();
  viewer._dimensions.enabled = false;
};

export const _isDimensionToolEnabled = () => {
  const viewer = ViewerSingleton.getInstance();
  return viewer._dimensions.enabled;
};

export const _createOrFinishDimension = () => {
  const viewer = ViewerSingleton.getInstance();

  const oldListCount = viewer._dimensions.list.length;
  viewer._dimensions.enabled = true;
  viewer._dimensions.create();
  // viewer._dimensions.createOnPoints(new Vector3(0, 0, 0), new Vector3(5, 5, 5));
  const createdDimension = viewer._dimensions.list.at(-1);
  if (viewer._dimensions.list.length > oldListCount && createdDimension != null) {
    const dimensionId = viewer._dimensionsNextId;
    viewer._dimensionsNextId += 1;
    viewer._dimensionsMap[dimensionId] = createdDimension;
    return dimensionId;
  }

  return null;
};

export const _deleteDimension = (dimension: DimensionId) => {
  const viewer = ViewerSingleton.getInstance();
  viewer._dimensions.deleteMeasurement(viewer._dimensionsMap[dimension]);
};

export const _deleteAllDimensions = () => {
  ViewerSingleton.getInstance()._dimensions.deleteAll();
};

export const _setDimensionVisibility = (dimension: DimensionId, value: boolean) => {
  const viewer = ViewerSingleton.getInstance();

  viewer._dimensionsMap[dimension].visible = value;
};

export const _setIsDimensionHighlighted = (dimension: DimensionId, value: boolean) => {
  const viewer = ViewerSingleton.getInstance();
  const createdDimension = viewer._dimensionsMap[dimension];
  //@ts-ignore
  const line = createdDimension._line as Line;
  const material = line.material as LineBasicMaterial;

  const css2dObj = viewer._dimensionsMap[dimension].label.three;

  css2dObj.element.style.color = value ? "#000000" : "#000000";
  css2dObj.element.style.background = value ? "#ffff00" : "#ff00ff";
  material.color.setHex(value ? 0xffff00 : 0xff00ff);
  // viewer._dimensionsMap[dimension].update();
};

export const _getDimensionLength = (dimension: DimensionId) => {
  const viewer = ViewerSingleton.getInstance();

  //@ts-ignore
  return viewer._dimensionsMap[dimension].getLength();
};

export const _getDimensionStartPoint = (dimension: DimensionId) => {
  const viewer = ViewerSingleton.getInstance();

  const point = viewer._dimensionsMap[dimension].startPoint;
  return [point.x, point.y, point.z];
};

export const _getDimensionEndPoint = (dimension: DimensionId) => {
  const viewer = ViewerSingleton.getInstance();

  const point = viewer._dimensionsMap[dimension].endPoint;
  return [point.x, point.y, point.z];
};

export const _restoreDimensions = (dimensions: Dimension[]) => {
  const viewer = ViewerSingleton.getInstance();
  ViewerSingleton.getInstance()._dimensions.deleteAll();
  viewer._dimensionsMap = {};

  dimensions.forEach(dimension => {
    viewer._dimensionsNextId = Math.max(viewer._dimensionsNextId, dimension.id + 1);
    viewer._dimensions.createOnPoints(
      new Vector3(...dimension.startPoint),
      new Vector3(...dimension.endPoint)
    );
    const createdDimension = viewer._dimensions.list.at(-1);
    if (createdDimension) {
      viewer._dimensionsMap[dimension.id] = createdDimension;

      _setDimensionVisibility(dimension.id, dimension.isVisible);
      _setIsDimensionHighlighted(dimension.id, dimension.isHighlighted);
    }
  });
};
