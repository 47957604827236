import { refreshAutosave } from "app/utils/autoSaveTrigger";

import { IIfcMangerState } from "../ifcManagerSlice";

export const invalidateCloudStateReducer = (state: IIfcMangerState) => {
  /* batching the update here avoids the extra dispatch from invalidateAutosave */
  state.areChangesCloudSynced = false;
  refreshAutosave();
};

export const commonReducers = {
  invalidateCloudState: invalidateCloudStateReducer,
};
