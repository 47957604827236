import { ListItemButton } from "@mui/material";
import theme from "app/theme";
import { forwardRef } from "react";

export const ToolsListItem = forwardRef(function ToolsListItem(props: any, ref) {
  const { sx, ...otherProps } = props;
  return (
    <ListItemButton
      disableRipple
      ref={ref}
      sx={{
        "&": {
          boxSizing: "border-box",
          borderRadius: "8px",
          border: "transparent 2px solid",
        },
        "&.Mui-selected": {
          boxSizing: "border-box",
          borderRadius: "8px",
          border: "#0d47a1 2px solid",
          backgroundColor: theme.palette.selectedBackground,
        },
        overflow: "hidden",
        ...sx,
      }}
      {...otherProps}
    />
  );
});

export default ToolsListItem;
