import { memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import {
  highlightByID,
  selectIsToolActive,
  selectIsToolSelected,
  selectModelId,
  selectModelsDetails,
  selectPropertiesExpressID,
  // selectpropertiesModelUUID,
  setVisibilityByID,
} from "app/state/slices/ifcManagerSlice";
import { ToolsSidebarHeader, ToolsSidebarBody } from "app/components/ToolsSideBar/common";
import theme from "app/theme";
import { viewerAPI } from "app/common/ViewerAPI";
import { Tree, NodeRendererProps, TreeApi } from "react-arborist";
import FillFlexParent from "app/components/FillFlexParent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { fullIdFrom } from "app/common/ViewerAPI/properties";
import { SpatialNode, TOOLS } from "app/common/types";
import NoModelLoaded from "app/components/NoModelLoaded";
import { useDebouncedValue } from "app/utils/useDebouncedValue";

const nodeHeight = 6 * theme.sp.compactH;

const CustomNode = ({ tree, node, style, dragHandle }: NodeRendererProps<SpatialNode>) => {
  const dispatch = useAppDispatch();
  // note: this duplicates the internal state inside fragments/instances
  //       the commands are set(absolute) so after toggling it will always reflect the true state
  const visibilityState = node.data.metadata.isVisible;
  const propertiesId = useAppSelector(selectPropertiesExpressID);

  const [pointerHasEntered, setPointerHasEntered] = useState(false);

  return (
    <div
      ref={dragHandle}
      id="selectableTreeItem"
      className="TreeItemHover"
      style={{
        ...style,
        height: `${nodeHeight}px`,
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginRight: "8px",
        border: "transparent 2px solid",
        ...(node.isSelected && {
          backgroundColor: theme.palette.selectedBackground,
          border: "#0d47a1 2px solid",
        }),
      }}
      onPointerEnter={() => {
        if (pointerHasEntered) return;
        setTimeout(() => {
          dispatch(
            highlightByID({
              modelUUID: node.data.metadata.modelUUID,
              expressID: node.data.metadata.expressID,
              isTempHover: true,
              isSpatiallyReccursive: true,
            })
          );
        }, 5);
        setPointerHasEntered(true);
      }}
      onPointerLeave={() => {
        setTimeout(() => {
          dispatch(
            highlightByID({
              modelUUID: null,
              expressID: null,
              isTempHover: true,
              isSpatiallyReccursive: true,
            })
          );
        }, 5);
        setPointerHasEntered(false);
      }}
      onClick={() => {
        if (!node.isSelected) {
          node.select();

          // auto show visible when selecting object
          // note: this applies reccursively to all children and mutates their metadata.isVisible
          dispatch(
            setVisibilityByID({
              modelUUID: node.data.metadata.modelUUID,
              expressID: node.data.metadata.expressID,
              value: true,
            })
          ).then(() => {
            dispatch(
              highlightByID({
                modelUUID: node.data.metadata.modelUUID,
                expressID: node.data.metadata.expressID,
                isSpatiallyReccursive: true,
              })
            ).then(() => {
              // trigger rerender for the eyes state change
              tree.edit(node.id);
            });
          });
        } else {
          dispatch(
            highlightByID({
              modelUUID: null,
              expressID: null,
              isSpatiallyReccursive: true,
            })
          ).then(() => {
            node.deselect();
          });
        }
      }}
    >
      {node.isLeaf ? (
        <FiberManualRecordIcon
          sx={{
            color: theme.palette.softBulletGrey,
            padding: `${theme.sp.compactH + theme.sp.gapTiny}px`,
            margin: "0 5px",
            width: `${theme.sppx.compact2H}`,
            height: `${theme.sppx.compact2H}`,
          }}
        />
      ) : (
        <Tooltip placement="top" arrow title="Toggle Expansion">
          <IconButton
            color={node.isOpen ? "primary" : "default"}
            onClick={e => {
              e.stopPropagation();
              node.toggle();
            }}
            variant="inline"
            sx={{
              padding: `${theme.sppx.compactH}`,
              marginLeft: theme.sppx.gapTiny,
              marginRight: theme.sppx.gapTiny,
            }}
          >
            {node.isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Tooltip>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            overflowX: "auto",
            textOverflow: "inherit",
          },
        }}
      >
        <Typography>{node.data.metadata?.type}</Typography>
        {!node.data.name ? (
          <Typography sx={{ color: theme.palette.inactiveGrey, fontStyle: "italic" }}>
            Unnamed
          </Typography>
        ) : (
          <Typography sx={{ color: theme.palette.inactiveGrey }}>{node.data.name}</Typography>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip placement="top" arrow title="Toggle visibility">
        <IconButton
          color={visibilityState ? "primary" : "default"}
          onClick={e => {
            e.stopPropagation();
            dispatch(
              setVisibilityByID({
                modelUUID: node.data.metadata.modelUUID,
                expressID: node.data.metadata.expressID,
                value: !visibilityState,
              })
            ).then(() => {
              // auto unhighlight when hiding object
              if (visibilityState && propertiesId && String(propertiesId) == node.data.id) {
                dispatch(
                  highlightByID({
                    modelUUID: null,
                    expressID: null,
                    isSpatiallyReccursive: true,
                  })
                ).then(() => {
                  node.deselect();
                });
              } else {
                // trigger rerender for the eyes state change
                tree.edit(node.id);
              }
            });
          }}
          variant="inline"
          sx={{
            padding: `${theme.sppx.compactH}`,
            marginLeft: theme.sppx.gapTiny,
            marginRight: theme.sppx.gapTiny,
          }}
        >
          {visibilityState ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

function Content({ height, searchText }: { height: number; searchText: string }) {
  const dispatch = useAppDispatch();

  const [tree, setTree] = useState([] as SpatialNode[]);
  const treeAPI = useRef<TreeApi<SpatialNode>>();
  const modelsDetails = useAppSelector(selectModelsDetails);
  const isSectionOpen = useAppSelector(selectIsToolSelected(TOOLS.SECTION));
  const isPlansOpen = useAppSelector(selectIsToolSelected(TOOLS.PLAN_MANAGER));
  const isVisible = useAppSelector(selectIsToolActive(TOOLS.OBJECTTREE));

  const searchRegex = new RegExp(
    searchText
      .split(" ")
      .filter(x => x != null && x.length > 0)
      .map(s => `(?=.*${s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`)
      .join("")
      .replace("\\*", ".*"),
    "i"
  );

  const viewer = viewerAPI();
  useEffect(() => {
    const roots = viewer?._fragments?.groups?.values?.()?.next?.().value?.spatialTreeRoots;
    if (roots) {
      setTree(roots);

      if (roots.length > 0) {
        // note: the tree doesn't update after adding roots
        // so this piece of code forces an update of new nodes
        setTimeout(() => {
          for (const node of roots) {
            if (!treeAPI.current?.isOpen(node.id)) {
              treeAPI.current?.open(node.id);
              treeAPI.current?.close(node.id);
            }
          }
        }, 0);
      }
    }
  }, [treeAPI.current, modelsDetails]);

  useEffect(() => {
    // used in favour of selectpropertiesModelUUID to avoid rerenders
    const propertiesModelUUID = viewerAPI()?.highlighterModelUUID;
    const propertiesExpressID = viewerAPI()?.highlighterExpressId;
    if (isVisible && propertiesExpressID && treeAPI?.current && propertiesModelUUID) {
      treeAPI.current.select(fullIdFrom(String(propertiesModelUUID), String(propertiesExpressID)));
    }
  }, [isVisible]);

  // auto set visible & unhighlight to avoid interactions with sections & plans
  // see #209 for the plane clipping bug
  useEffect(() => {
    if (treeAPI?.current && tree && !isVisible && (isSectionOpen || isPlansOpen)) {
      for (const root of tree) {
        treeAPI.current.deselect(root.id);
        dispatch(
          setVisibilityByID({
            modelUUID: root.metadata.modelUUID,
            expressID: root.metadata.expressID,
            value: true,
          })
        );
      }
    }
  }, [isVisible, treeAPI.current, isSectionOpen, isPlansOpen]);

  return (
    <Tree
      ref={treeAPI}
      disableEdit
      disableDrag
      disableDrop
      indent={theme.sp.compact2H}
      data={tree}
      searchTerm={searchText}
      searchMatch={node => searchRegex.test(String(node.data.metadata?.type) + node.data.name)}
      width={theme.sp.sidepanelWidth - theme.sp.compact2V - 1}
      height={height}
      openByDefault={false}
      rowHeight={nodeHeight}
      className="objectTreeContainer"
    >
      {CustomNode}
    </Tree>
  );
}

const MemoContent = memo(Content);

export function ObjectTreeSidebar() {
  const dispatch = useAppDispatch();
  const modelId = useAppSelector(selectModelId);
  const isVisible = useAppSelector(selectIsToolActive(TOOLS.OBJECTTREE));
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebouncedValue(searchText, 200);

  if (!modelId) {
    return isVisible ? <NoModelLoaded /> : <></>;
  }

  return (
    <Box
      id="TheBox"
      sx={{
        minHeight: "100%", // provide clicking space for hide all
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        overflow: "hidden",
      }}
      onClick={e => {
        if ((e.target as HTMLDivElement)?.className?.includes?.("objectTreeContainer")) {
          dispatch(
            highlightByID({ modelUUID: null, expressID: null, isSpatiallyReccursive: true })
          );
        }
      }}
    >
      <ToolsSidebarHeader title="Object Tree"></ToolsSidebarHeader>
      <ToolsSidebarBody
        sx={{
          "& ul": {
            listStyleType: "none",
            padding: 0,
          },
          overflowX: "hidden",
          paddingRight: 0, // stick the tree scrollbar to the edge
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          sx={{
            marginRight: theme.sppx.compact2V,
            marginTop: theme.sppx.compactV,
            marginBottom: theme.sppx.compact2V,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          InputProps={{
            endAdornment: searchText.length > 0 && (
              <>
                <IconButton
                  color="default"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setSearchText("");
                  }}
                  sx={{
                    width: theme => theme.spacing(5),
                    height: theme => theme.spacing(5),
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ),
          }}
        />
        <FillFlexParent>
          {({ height }) => <MemoContent height={height} searchText={debouncedSearchText} />}
        </FillFlexParent>
      </ToolsSidebarBody>
    </Box>
  );
}
const MemoObjectTreeSidebar = memo(ObjectTreeSidebar);

export default MemoObjectTreeSidebar;
