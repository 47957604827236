import { Divider, ListItem, ListItemText } from "@mui/material";
import { convertIfcString } from "app/utils";
import theme from "app/theme";
import { QuantityValue } from "app/common/ViewerAPI/properties";

const getQuantityStringifiedValues = (
  value: QuantityValue
): { propName: string | null; propValue: string } => {
  return {
    propName: convertIfcString(value.name),
    propValue: value.values
      .map(value => {
        if (!["string", "number", "boolean"].includes(typeof value) && value != null) {
          console.error("Quantity value is NOT a base type:", value, typeof value);
        }
        return value == null ? null : convertIfcString(value.toString());
      })
      .join(", "),
  };
};

interface Props {
  qset: QuantityValue[];
}

export default function BaseQuantities({ qset }: Props) {
  return (
    <>
      <Divider />
      {qset
        .flatMap(prop => getQuantityStringifiedValues(prop))
        .map(({ propName, propValue }, index) => (
          <ListItem key={index} divider={true} sx={{ ...theme.pad.spacious }}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: "1em",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
              secondaryTypographyProps={{
                color: "#000000ff",
                paddingLeft: theme.sppx.spaciousH,
                lineHeight: "1.5",
              }}
              sx={{ textWrap: "wrap" }}
              primary={propName ?? "no name"}
              secondary={propValue ?? "no value"}
            />
          </ListItem>
        ))}
    </>
  );
}
