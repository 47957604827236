// https://gist.github.com/xeoncross/5381806b18de1f395187
// filtered for things that are appropriate with animals
const positiveAdjectives = [
  "abundant",
  "accomplished",
  "accurate",
  "adaptive",
  "adequate",
  "adjustable",
  "admirable",
  "adorable",
  "adored",
  "advanced",
  "advantageous",
  "adventurous",
  "affable",
  "affectionate",
  "agile",
  "agreeable",
  "alluring",
  "altruistic",
  "amazing",
  "ambitious",
  "amiable",
  "amicable",
  "amusing",
  "appreciated",
  "appreciative",
  "appropriate",
  "ardent",
  "articulate",
  "artistic",
  "assuring",
  "astonished",
  "astonishing",
  "astounded",
  "astounding",
  "athletic",
  "attentive",
  "audible",
  "authentic",
  "available",
  "avid",
  "awed",
  "awesome",
  "awestruck",
  "balanced",
  "beautiful",
  "believable",
  "beloved",
  "beneficent",
  "benevolent",
  "best",
  "blameless",
  "blissful",
  "boundless",
  "brainy",
  "brave",
  "breathtaking",
  "bright",
  "brightest",
  "brilliant",
  "brisk",
  "buoyant",
  "calm",
  "calming",
  "capable",
  "captivating",
  "carefree",
  "catchy",
  "celebrated",
  "charismatic",
  "charming",
  "cheerful",
  "cheery",
  "cherished",
  "chivalrous",
  "classic",
  "classy",
  "clean",
  "clever",
  "coherent",
  "colorful",
  "comfortable",
  "commendable",
  "compact",
  "compassionate",
  "compatible",
  "competitive",
  "compliant",
  "concise",
  "confident",
  "conscientious",
  "considerate",
  "consistent",
  "constructive",
  "convenient",
  "convincing",
  "cool",
  "coolest",
  "cooperative",
  "correct",
  "courageous",
  "cozy",
  "creative",
  "credible",
  "cute",
  "daring",
  "dashing",
  "dauntless",
  "dazzling",
  "decent",
  "decisive",
  "dedicated",
  "undefeated",
  "deft",
  "delicate",
  "delighted",
  "delightful",
  "dependable",
  "desirable",
  "dexterous",
  "diligent",
  "diplomatic",
  "distinctive",
  "distinguished",
  "durable",
  "dynamic",
  "eager",
  "earnest",
  "easygoing",
  "economical",
  "ecstatic",
  "educated",
  "effective",
  "efficient",
  "effusive",
  "elegant",
  "eloquent",
  "eminent",
  "enchanted",
  "enchanting",
  "encouraging",
  "endearing",
  "energetic",
  "engaging",
  "enjoyable",
  "enterprising",
  "entertaining",
  "enthusiastic",
  "enticing",
  "entrancing",
  "enviable",
  "equitable",
  "ethical",
  "evaluative",
  "everlasting",
  "excellent",
  "exceptional",
  "excited",
  "exciting",
  "exemplary",
  "exquisite",
  "extraordinary",
  "exuberant",
  "eye-catching",
  "fabulous",
  "fair",
  "faithful",
  "famous",
  "fancy",
  "fantastic",
  "fascinating",
  "fashionable",
  "fast",
  "faultless",
  "favorable",
  "fearless",
  "feasible",
  "feature-rich",
  "festive",
  "fine",
  "flattering",
  "flexible",
  "flourishing",
  "fluent",
  "fond",
  "formidable",
  "fresh",
  "friendly",
  "fun",
  "funny",
  "generous",
  "gentle",
  "genuine",
  "gifted",
  "glad",
  "glamorous",
  "gleeful",
  "glimmering",
  "glorious",
  "glowing",
  "golden",
  "good",
  "gorgeous",
  "graceful",
  "gracious",
  "grand",
  "grateful",
  "great",
  "greatest",
  "handsome",
  "handy",
  "happy",
  "hard-working",
  "harmless",
  "harmonious",
  "healthy",
  "heartwarming",
  "helpful",
  "heroic",
  "hilarious",
  "honest",
  "honorable",
  "hopeful",
  "hospitable",
  "humble",
  "humorous",
  "ideal",
  "imaginative",
  "immaculate",
  "immense",
  "impartial",
  "impeccable",
  "important",
  "impressed",
  "impressive",
  "incredible",
  "indulgent",
  "industrious",
  "infallible",
  "influential",
  "ingenious",
  "innocuous",
  "innovative",
  "insightful",
  "inspirational",
  "instructive",
  "intelligent",
  "interesting",
  "intricate",
  "intriguing",
  "invaluable",
  "inventive",
  "invigorating",
  "invincible",
  "invulnerable",
  "irreplaceable",
  "jolly",
  "jovial",
  "joyful",
  "jubilant",
  "judicious",
  "kind",
  "knowledgeable",
  "lavish",
  "law-abiding",
  "lawful",
  "legendary",
  "likable",
  "lively",
  "logical",
  "long-lasting",
  "lovable",
  "lovely",
  "loving",
  "loyal",
  "luckiest",
  "lucky",
  "magic",
  "magical",
  "magnificent",
  "majestic",
  "manageable",
  "maneuverable",
  "marvelous",
  "masterful",
  "mature",
  "memorable",
  "merciful",
  "merry",
  "mesmerizing",
  "meticulous",
  "mighty",
  "modest",
  "motivated",
  "multi-purpose",
  "neat",
  "nice",
  "nicest",
  "nifty",
  "nimble",
  "noble",
  "non-violent",
  "noteworthy",
  "observant",
  "optimistic",
  "organized",
  "outstanding",
  "overjoyed",
  "passionate",
  "patient",
  "peaceful",
  "phenomenal",
  "playful",
  "pleasant",
  "plush",
  "poetic",
  "poised",
  "polite",
  "portable",
  "posh",
  "positive",
  "powerful",
  "praiseworthy",
  "precious",
  "precise",
  "prestigious",
  "pretty",
  "principled",
  "privileged",
  "proactive",
  "problem-free",
  "productive",
  "proficient",
  "proper",
  "prosperous",
  "protective",
  "proud",
  "prudent",
  "punctual",
  "qualified",
  "quick",
  "quiet",
  "radiant",
  "rational",
  "ready",
  "realistic",
  "reasonable",
  "receptive",
  "relaxed",
  "reliable",
  "remarkable",
  "renowned",
  "reputable",
  "resilient",
  "resourceful",
  "respectable",
  "respectful",
  "responsive",
  "robust",
  "satisfied",
  "selective",
  "self-sufficient",
  "sensible",
  "sharp",
  "shiny",
  "silent",
  "simple",
  "sincere",
  "skilled",
  "skillful",
  "smart",
  "smartest",
  "smiling",
  "smooth",
  "snappy",
  "snazzy",
  "sociable",
  "soft",
  "softest",
  "sophisticated",
  "soulful",
  "sparkling",
  "spectacular",
  "splendid",
  "spontaneous",
  "spotless",
  "stable",
  "steady",
  "straightforward",
  "streamlined",
  "striking",
  "strong",
  "strongest",
  "stunning",
  "stylish",
  "suave",
  "sublime",
  "successful",
  "suitable",
  "super",
  "superb",
  "superior",
  "supple",
  "supportive",
  "sweet",
  "swift",
  "talented",
  "tenacious",
  "thankful",
  "thoughtful",
  "thrilled",
  "thriving",
  "tidy",
  "timely",
  "tolerable",
  "tough",
  "toughest",
  "tranquil",
  "trendy",
  "trouble-free",
  "trusted",
  "trustworthy",
  "truthful",
  "unaffected",
  "unbeatable",
  "unbiased",
  "uncomplicated",
  "understandable",
  "unmatched",
  "unquestionable",
  "upbeat",
  "user-friendly",
  "verifiable",
  "versatile",
  "vibrant",
  "vigilant",
  "visionary",
  "vivid",
  "well-behaved",
  "well-informed",
  "well-mannered",
  "wise",
  "witty",
  "wonderful",
  "wondrous",
];

// https://gist.github.com/borlaym/585e2e09dd6abd9b0d0a
const animals = [
  "Aardvark",
  "Albatross",
  "Alligator",
  "Alpaca",
  "Ant",
  "Anteater",
  "Antelope",
  "Ape",
  "Armadillo",
  "Donkey",
  "Baboon",
  "Badger",
  "Barracuda",
  "Bat",
  "Bear",
  "Beaver",
  "Bee",
  "Bison",
  "Boar",
  "Buffalo",
  "Butterfly",
  "Camel",
  "Capybara",
  "Caribou",
  "Cassowary",
  "Cat",
  "Caterpillar",
  "Cattle",
  "Chamois",
  "Cheetah",
  "Chicken",
  "Chimpanzee",
  "Chinchilla",
  "Chough",
  "Clam",
  "Cobra",
  "Cockroach",
  "Cod",
  "Cormorant",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Curlew",
  "Deer",
  "Dinosaur",
  "Dog",
  "Dogfish",
  "Dolphin",
  "Dotterel",
  "Dove",
  "Dragonfly",
  "Duck",
  "Dugong",
  "Dunlin",
  "Eagle",
  "Echidna",
  "Eel",
  "Eland",
  "Elephant",
  "Elk",
  "Emu",
  "Falcon",
  "Ferret",
  "Finch",
  "Fish",
  "Flamingo",
  "Fly",
  "Fox",
  "Frog",
  "Gaur",
  "Gazelle",
  "Gerbil",
  "Giraffe",
  "Gnat",
  "Gnu",
  "Goat",
  "Goldfinch",
  "Goldfish",
  "Goose",
  "Gorilla",
  "Goshawk",
  "Grasshopper",
  "Grouse",
  "Guanaco",
  "Gull",
  "Hamster",
  "Hare",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Herring",
  "Hippopotamus",
  "Hornet",
  "Horse",
  "Human",
  "Hummingbird",
  "Hyena",
  "Ibex",
  "Ibis",
  "Jackal",
  "Jaguar",
  "Jay",
  "Jellyfish",
  "Kangaroo",
  "Kingfisher",
  "Koala",
  "Kookabura",
  "Kouprey",
  "Kudu",
  "Lapwing",
  "Lark",
  "Lemur",
  "Leopard",
  "Lion",
  "Llama",
  "Lobster",
  "Locust",
  "Loris",
  "Louse",
  "Lyrebird",
  "Magpie",
  "Mallard",
  "Manatee",
  "Mandrill",
  "Mantis",
  "Marten",
  "Meerkat",
  "Mink",
  "Mole",
  "Mongoose",
  "Monkey",
  "Moose",
  "Mosquito",
  "Mouse",
  "Mule",
  "Narwhal",
  "Newt",
  "Nightingale",
  "Octopus",
  "Okapi",
  "Opossum",
  "Oryx",
  "Ostrich",
  "Otter",
  "Owl",
  "Oyster",
  "Panther",
  "Parrot",
  "Partridge",
  "Peafowl",
  "Pelican",
  "Penguin",
  "Pheasant",
  "Pig",
  "Pigeon",
  "Pony",
  "Porcupine",
  "Porpoise",
  "Quail",
  "Quelea",
  "Quetzal",
  "Rabbit",
  "Raccoon",
  "Rail",
  "Ram",
  "Rat",
  "Raven",
  "Red deer",
  "Red panda",
  "Reindeer",
  "Rhinoceros",
  "Rook",
  "Salamander",
  "Salmon",
  "Sand Dollar",
  "Sandpiper",
  "Sardine",
  "Scorpion",
  "Seahorse",
  "Seal",
  "Shark",
  "Sheep",
  "Shrew",
  "Skunk",
  "Snail",
  "Snake",
  "Sparrow",
  "Spider",
  "Spoonbill",
  "Squid",
  "Squirrel",
  "Starling",
  "Stingray",
  "Stinkbug",
  "Stork",
  "Swallow",
  "Swan",
  "Tapir",
  "Tarsier",
  "Termite",
  "Tiger",
  "Toad",
  "Trout",
  "Turkey",
  "Turtle",
  "Viper",
  "Vulture",
  "Wallaby",
  "Walrus",
  "Wasp",
  "Weasel",
  "Whale",
  "Wildcat",
  "Wolf",
  "Wolverine",
  "Wombat",
  "Woodcock",
  "Woodpecker",
  "Worm",
  "Wren",
  "Yak",
  "Zebra",
];

const nameId = (adjIndex: number, animalIndex: number) => {
  return adjIndex * 1000 + animalIndex;
};

// there are about 100K combinations
const usedCombinations = new Set<number>();
export const randomUniqueName = () => {
  for (let i = 0; i < 50; i++) {
    const adjIndex = Math.floor(Math.random() * positiveAdjectives.length);
    const animalIndex = Math.floor(Math.random() * animals.length);
    const id = nameId(adjIndex, animalIndex);

    if (!usedCombinations.has(id)) {
      usedCombinations.add(id);
      const adjective = positiveAdjectives[adjIndex];
      const animal = animals[animalIndex];

      return `${
        //
        adjective.charAt(0).toUpperCase() + adjective.substring(1)
      } ${
        //
        animal.charAt(0).toUpperCase() + animal.substring(1)
      }`;
    }
  }
  return "Tired Bunny";
};

export const resetRandomUniqueNames = () => {
  usedCombinations.clear();
};

export const appendRandomUniqueName = (name: string) => {
  const [adjective, animal] = name.split(" ");

  const adjIndex = positiveAdjectives.indexOf(adjective);
  const animalIndex = animals.indexOf(animal);
  const id = nameId(adjIndex, animalIndex);
  if (!usedCombinations.has(id)) {
    usedCombinations.add(id);
  }
};
