import * as THREE from "three";
import {
  Fragment,
  FragmentMesh,
  FragmentIdMap,
  FragmentsGroup,
} from "app/components/ifcjs/fragments";
// import { BVH } from "app/components/ifcjs/fragments/dist/bvh"
import {
  Component,
  Disposable,
  Updateable,
  Event,
  Configurable,
  Raycasters,
} from "app/components/ifcjs/core";
import { FragmentsManager } from "app/components/ifcjs/core";
// import { FragmentBoundingBox } from "app/components/ifcjs/FragmentBoundingBox";
import { BoundingBoxer } from "app/components/ifcjs/core";
import { Components, SimpleCamera } from "app/components/ifcjs/core";
// import { toCompositeID } from "openbim-components";
import { PostproductionRenderer, RendererWith2D } from "app/components/ifcjs/front";
import { FragmentExtended, ViewerAPI } from "app/common/ViewerAPI";

// TODO: Clean up and document

interface HighlightEvents {
  [highlighterName: string]: {
    onHighlight: Event<FragmentIdMap>;
    onClear: Event<null>;
  };
}

interface HighlightMaterials {
  [name: string]: THREE.Material[] | undefined;
}

export interface FragmentHighlighterConfig {
  selectName: string;
  hoverName: string;
  selectionMaterial: THREE.Material;
  hoverMaterial: THREE.Material;
  autoHighlightOnClick: boolean;
  autoHighlightOnHover: boolean;
  cullHighlightMeshes: boolean;
}

export class FragmentHighlighter extends Component implements Disposable, Updateable {
  static readonly uuid = "cb8a76f2-654a-4b50-80c6-66fd83cafd77" as const;

  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  /** {@link Updateable.onBeforeUpdate} */
  readonly onBeforeUpdate = new Event<FragmentHighlighter>();

  /** {@link Updateable.onAfterUpdate} */
  readonly onAfterUpdate = new Event<FragmentHighlighter>();

  postproductionEnabled = false;
  enabled = true;
  highlightMats: HighlightMaterials = {};
  events: HighlightEvents = {};

  multiple: "none" | "shiftKey" | "ctrlKey" = "ctrlKey";
  zoomFactor = 1.5;
  zoomToSelection = false;

  selection: {
    [selectionID: string]: FragmentIdMap;
  } = {};

  selectionFragments: {
    [selectionID: string]: Fragment[];
  } = {};

  // excludeOutline = new Set<string>();

  fillEnabled = true;

  outlineMaterial = new THREE.MeshBasicMaterial({
    color: "white",
    transparent: true,
    depthTest: false,
    depthWrite: false,
    opacity: 0.4,
  });

  private _eventsActive = false;

  private _renderer: RendererWith2D;
  private _viewer: ViewerAPI;
  // private _outlineEnabled = false;

  private _outlinedMeshes: { [fragID: string]: THREE.InstancedMesh } = {};
  private _invisibleMaterial = new THREE.MeshBasicMaterial({ visible: false });

  private _tempMatrix = new THREE.Matrix4();

  config: Required<FragmentHighlighterConfig> = {
    selectName: "select",
    hoverName: "hover",
    selectionMaterial: new THREE.MeshBasicMaterial({
      color: "#BCF124",
      transparent: true,
      opacity: 0.85,
      depthTest: false,
      depthWrite: false,
      side: THREE.FrontSide,
      forceSinglePass: true,
    }),
    hoverMaterial: new THREE.MeshBasicMaterial({
      color: "#6528D7",
      transparent: true,
      opacity: 0.2,
      depthTest: false,
      depthWrite: false,
      side: THREE.FrontSide,
      forceSinglePass: true,
    }),
    autoHighlightOnClick: true,
    autoHighlightOnHover: true,
    cullHighlightMeshes: true,
  };

  private _mouseState = {
    down: false,
    moved: false,
  };

  // get outlineEnabled() {
  //   return this._outlineEnabled;
  // }

  // set outlineEnabled(value: boolean) {
  //   // this._outlineEnabled = value;
  //   if (!this.postproductionEnabled) return;

  //   if (!value) {
  //     delete this._postproduction.customEffects.outlinedMeshes.fragments;
  //   }
  // }

  private get _postproduction() {
    if (!(this._renderer instanceof PostproductionRenderer)) {
      throw new Error("Postproduction renderer is needed for outlines!");
    }
    const renderer = this._renderer as PostproductionRenderer;
    return renderer.postproduction;
  }

  constructor(components: Components, viewer: ViewerAPI) {
    super(components);
    this._viewer = viewer;
    this.components.add(FragmentHighlighter.uuid, this);
    const fragmentManager = components.get(FragmentsManager);
    fragmentManager.onFragmentsDisposed.add(this.onFragmentsDisposed);

    this._renderer = viewer._renderer;
  }

  private onFragmentsDisposed = (data: { groupID: string; fragmentIDs: string[] }) => {
    this.disposeOutlinedMeshes(data.fragmentIDs);
  };

  get(): HighlightMaterials {
    return this.highlightMats;
  }

  getHoveredSelection() {
    return this.selection[this.config.hoverName];
  }

  getSelectedSelection() {
    return this.selection[this.config.selectName];
  }

  getDefaultSelection() {
    return this.selection["default"];
  }

  private disposeOutlinedMeshes(fragmentIDs: string[]) {
    for (const id of fragmentIDs) {
      const mesh = this._outlinedMeshes[id];
      if (!mesh) continue;
      mesh.geometry.dispose();
      delete this._outlinedMeshes[id];
    }
  }

  async dispose() {
    this.setupEvents(false);
    this.config.hoverMaterial.dispose();
    this.config.selectionMaterial.dispose();
    this.onBeforeUpdate.reset();
    this.onAfterUpdate.reset();
    for (const matID in this.highlightMats) {
      const mats = this.highlightMats[matID] || [];
      for (const mat of mats) {
        mat.dispose();
      }
    }
    this.disposeOutlinedMeshes(Object.keys(this._outlinedMeshes));
    this.outlineMaterial.dispose();
    this._invisibleMaterial.dispose();
    this.highlightMats = {};
    this.selection = {};
    for (const name in this.events) {
      this.events[name].onClear.reset();
      this.events[name].onHighlight.reset();
    }
    this.onSetup.reset();
    const fragmentManager = this.components.get(FragmentsManager);
    fragmentManager.onFragmentsDisposed.remove(this.onFragmentsDisposed);
    this.events = {};
    await this.onDisposed.trigger(FragmentHighlighter.uuid);
    this.onDisposed.reset();
  }

  async add(name: string, material?: THREE.Material[]) {
    if (this.highlightMats[name]) {
      throw new Error("A highlight with this name already exists.");
    }

    this.highlightMats[name] = material;
    this.selection[name] = {};
    this.events[name] = {
      onHighlight: new Event(),
      onClear: new Event(),
    };

    await this.update();
  }

  /** {@link Updateable.update} */
  async update() {
    return;

    // if (!this.fillEnabled) {
    //   return;
    // }
    // this.onBeforeUpdate.trigger(this);
    // const fragments = this.components.get(FragmentsManager);
    // for (const fragmentID in fragments.list) {
    //   const fragment = fragments.list.get(fragmentID);
    //   if (!fragment) continue;
    //   this.addHighlightToFragment(fragment);
    //   const outlinedMesh = this._outlinedMeshes[fragmentID];
    //   if (outlinedMesh) {
    //     fragment.mesh.updateMatrixWorld(true);
    //     outlinedMesh.applyMatrix4(fragment.mesh.matrixWorld);
    //   }
    // }
    // this.onAfterUpdate.trigger(this);
  }

  highlight(name: string, removePrevious = true, zoomToSelection = this.zoomToSelection) {
    if (!this.enabled) return null;
    this.checkSelection(name);
    const fragments = this.components.get(FragmentsManager);
    const fragList: Fragment[] = [];
    const meshes = fragments.meshes;

    const casters = this.components.get(Raycasters);
    const caster = casters.get(this._viewer._world);
    const result = caster.castRay(meshes);

    if (!result) {
      this.clear(name);
      return null;
    }

    const mesh = result.object as FragmentMesh;
    const geometry = mesh.geometry;
    const index = result.face?.a;
    const instanceID = result.instanceId;
    if (!geometry || index === undefined || instanceID === undefined) {
      return null;
    }

    if (removePrevious) {
      this.clear(name);
    }

    if (!this.selection[name][mesh.uuid]) {
      this.selection[name][mesh.uuid] = new Set<number>();
    }

    fragList.push(mesh.fragment);

    const itemID = mesh.fragment.getItemID(instanceID);
    if (!itemID) return null;

    this.highlightByID(name, { [mesh.fragment.id]: new Set([itemID]) });

    // await this.events[name].onHighlight.trigger(this.selection[name]);

    if (zoomToSelection) {
      this.zoomSelection(name);
    }

    return { id: itemID, fragments: fragList };
  }

  highlightByID(
    name: string,
    ids: FragmentIdMap,
    removePrevious = true,
    zoomToSelection = this.zoomToSelection,
    useQuickHighlight?: boolean
  ) {
    if (!this.enabled) return;
    let needsUpdate = false;

    if (removePrevious) {
      needsUpdate ||= this.clear(name, true) ?? false;
    }

    const styles = this.selection[name];
    for (const fragID in ids) {
      if (!styles[fragID]) {
        styles[fragID] = new Set<number>();
      }

      const fragments = this._viewer._ifcStreamer.allLoadedFragments;
      const fragment = fragments.get(fragID) as FragmentExtended | null;
      if (!fragment) continue;

      const instanceIDs = new Set<number>();
      ids[fragID].forEach(itemID => {
        styles[fragID].add(itemID);
        // idsNum.add(itemID);
        const instances = fragment.itemToInstances.get(itemID);
        instances?.forEach(value => instanceIDs.add(value));
      });
      // idsNum.forEach(id => {
      //   this.addComposites(fragment.mesh, id, name);
      // });
      const material = this.highlightMats[name]?.[0];
      if (material) {
        if (useQuickHighlight && ids[fragID].size == fragment.ids.size) {
          if (!fragment.selectionStack) {
            fragment.selectionStack = [];
          }
          if (!fragment.originalMaterial) {
            const orginalHiddenItems = new Set<number>();
            const orginalVisibleItems = new Set<number>();
            for (const id of fragment.ids) {
              if (fragment.hiddenItems.has(id)) {
                orginalHiddenItems.add(id);
              } else {
                orginalVisibleItems.add(id);
              }
            }

            this._viewer._ifcStreamer.addFullHighlight(fragment);
            fragment.originalMaterial = fragment.mesh.material;
            fragment.originalHiddenItems = orginalHiddenItems;
            fragment.originalVisibleItems = orginalVisibleItems;
          }

          fragment.mesh.material = fragment.mesh.material.map(() => material);

          fragment.selectionStack.push({ name, material: fragment.mesh.material });
          if (!this.selectionFragments[name]) {
            this.selectionFragments[name] = [];
          }
          this.selectionFragments[name].push(fragment);
        } else {
          this.addHighlightToFragment(name, fragment, instanceIDs);
        }
      }
      fragment.setVisibility(true, styles[fragID]);
      needsUpdate = true;
      // await this.regenerate(name, fragID);
    }

    this.selection[name] = ids;

    // await this.events[name].onHighlight.trigger(this.selection[name]);
    if (this._viewer._renderer && needsUpdate) {
      this._viewer._renderer.needsUpdate = true;
      this._viewer._navGizmo.needsUpdate = true;
    }
    if (zoomToSelection) {
      this.zoomSelection(name);
    }
  }

  threeBulkDispose(
    parent: THREE.Object3D,
    toBeRemoved: Set<THREE.Object3D<THREE.Object3DEventMap>>
  ) {
    const newChildren = [];
    for (const child of parent.children) {
      if (toBeRemoved.has(child)) continue;
      newChildren.push(child);
    }

    for (const object of toBeRemoved) {
      object.parent = null;
    }

    parent.children = newChildren;
  }

  /**
   * Clears any selection previously made by calling {@link highlight}.
   */
  clear(name?: string, deferRederUpdates?: boolean) {
    if (!name) return;
    let shouldUpdate = false;
    try {
      const fragments = this.components.get(FragmentsManager);
      const styles = this.selection[name];

      const selectedFragments = new Set(this.selectionFragments[name] ?? []);
      // federated fixme: split by group
      let selectedGroup: FragmentsGroup | undefined = undefined;
      const meshSetToBeRemoved = new Set<THREE.Object3D<THREE.Object3DEventMap>>();

      if (selectedFragments) {
        for (const frag of selectedFragments) {
          const fragment = frag as FragmentExtended;
          if (
            fragment.originalMaterial &&
            fragment.selectionStack &&
            fragment.originalVisibleItems &&
            fragment.originalHiddenItems
          ) {
            const index =
              (fragment.selectionStack && fragment.selectionStack.findIndex(x => x.name == name)) ??
              -1;
            if (index >= 0) {
              if (fragment.selectionStack.length == 1) {
                fragment.setVisibility(true, fragment.originalVisibleItems);
                fragment.setVisibility(false, fragment.originalHiddenItems);
                fragment.mesh.material = fragment.originalMaterial;
                this._viewer._ifcStreamer.removeFullHighlight(fragment);

                if (!deferRederUpdates) {
                  this._viewer._ifcStreamer.culler.needsUpdate = true;
                }
                shouldUpdate = true;

                fragment.originalMaterial = null;
                fragment.originalHiddenItems = null;
                fragment.originalVisibleItems = null;
              } else if (fragment.selectionStack.length - 1 == index) {
                fragment.mesh.material = fragment.selectionStack[index - 1].material;
              }

              fragment.selectionStack.splice(index, 1);
            }
          } else {
            const group = fragment.group;
            if (group) {
              meshSetToBeRemoved.add(fragment.mesh);
              selectedGroup = group;
              // group.remove(fragment.mesh);
            }
          }
        }

        if (selectedGroup) {
          this.threeBulkDispose(selectedGroup, meshSetToBeRemoved);
        }

        // renderLists.get(scene, renderListStack.length);
        for (const mesh of meshSetToBeRemoved) {
          //@ts-ignore
          const fragment = mesh.fragment;
          // fragment.mesh.dispose();
          //@ts-ignore
          // fragment.mesh = null;
          fragment.dispose();
          // fragments.list.delete(fragment.id);
        }

        this.selectionFragments[name]?.splice?.(0, this.selectionFragments[name]?.length);

        if (this._viewer._renderer && !deferRederUpdates) {
          this._viewer._renderer.needsUpdate = true;
          this._viewer._navGizmo.needsUpdate = true;
        }
        shouldUpdate = true;
      } else {
        this.selectionFragments[name] = [];
      }
    } catch (ex) {
      console.error(ex);
    }

    return shouldUpdate;
    // await this.clearFills(name);
    // if (!name || !this.excludeOutline.has(name)) {
    //   await this.clearOutlines();
    // }
  }

  readonly onSetup = new Event<FragmentHighlighter>();
  async setup(config?: Partial<FragmentHighlighterConfig>) {
    if (config?.selectionMaterial) {
      this.config.selectionMaterial.dispose();
    }
    if (config?.hoverMaterial) {
      this.config.hoverMaterial.dispose();
    }
    this.config = { ...this.config, ...config };
    this.outlineMaterial.color.set(0xf0ff7a);
    // this.excludeOutline.add(this.config.hoverName);
    await this.add(this.config.selectName, [this.config.selectionMaterial]);
    await this.add(this.config.hoverName, [this.config.hoverMaterial]);
    this.setupEvents(true);
    this.enabled = true;
    this.onSetup.trigger(this);
  }

  private async regenerate(name: string, fragID: string) {
    if (this.fillEnabled) {
      await this.updateFragmentFill(name, fragID);
    }

    if (!this.postproductionEnabled) return;
    // if (this._outlineEnabled) {
    //   await this.updateFragmentOutline(name, fragID);
    // }
  }

  private async zoomSelection(name: string) {
    // old version
    {
      // if (!this.fillEnabled && !this._outlineEnabled) {
      if (!this.fillEnabled) {
        return;
      }

      const bbox = this.components.get(BoundingBoxer);
      const fragments = this.components.get(FragmentsManager);
      bbox.reset();

      const selected = this.selection[name];
      if (!Object.keys(selected).length) {
        return;
      }

      // for (const fragID in selected) {
      //   const fragment = fragments.list.get(fragID);
      //   if (!fragment) continue;
      //   if (this.fillEnabled) {
      //     const highlight = fragment.fragments[name];
      //     if (highlight) {
      //       bbox.addMesh(highlight.mesh);
      //     }
      //   }
      // }

      for (const fragID in selected) {
        const fragment = fragments.list.get(fragID);
        if (!fragment) continue;
        const ids = selected[fragID];
        bbox.addMesh(fragment.mesh, ids);
      }

      const sphere = bbox.getSphere();
      const i = Infinity;
      const mi = -Infinity;
      const { x, y, z } = sphere.center;
      const isInf = sphere.radius === i || x === i || y === i || z === i;
      const isMInf = sphere.radius === mi || x === mi || y === mi || z === mi;
      const isZero = sphere.radius === 0;
      if (isInf || isMInf || isZero) {
        return;
      }
      sphere.radius *= this.zoomFactor;
      const camera = this._viewer._camera;
      await camera.controls.fitToSphere(sphere, true);
    }
  }

  // private addComposites(mesh: FragmentMesh, itemID: number, name: string) {
  //   const composites = mesh.fragment.itemToInstances.get(itemID);
  //   if (composites) {
  //     for (const instanceID of composites) {
  //       this.selection[name][mesh.uuid].add(instanceID);
  //     }
  //   }
  // }

  // private async clearStyle(name: string) {
  //   const fragments = this.components.get(FragmentsManager);

  //   // fixme: delete duplicated fragments
  //   // for (const fragID in this.selection[name]) {
  //   //   const fragment = fragments.list.get(fragID);
  //   //   if (!fragment) continue;
  //   //   const selection = fragment;
  //   //   if (selection) {
  //   //     selection.mesh.removeFromParent();
  //   //   }
  //   // }

  //   await this.events[name].onClear.trigger(null);
  //   this.selection[name] = {};
  // }

  private async updateFragmentFill(name: string, fragmentID: string) {
    const fragments = this.components.get(FragmentsManager);

    const ids = this.selection[name][fragmentID];
    const fragment = fragments.list.get(fragmentID);
    if (!fragment) return;
    const selection = fragment;
    if (!selection) return;

    const fragmentParent = fragment.mesh.parent;
    if (!fragmentParent) return;
    fragmentParent.add(selection.mesh);

    // const isBlockFragment = selection.blocks.count > 1;
    // if (isBlockFragment) {
    fragment.mesh.getMatrixAt(0, this._tempMatrix);
    selection.mesh.setMatrixAt(0, this._tempMatrix);
    selection.mesh.instanceMatrix.needsUpdate = true;
    // selection.setInstance(0, {
    //   ids: Array.from(fragment.ids),
    //   transform: this._tempMatrix,
    // });

    // Only highlight visible blocks
    // const visibleIDs = new Set<number>();
    // ids.forEach(id => {
    //   if (!fragment.hiddenItems.has(id)) {
    //     visibleIDs.add(id);
    //   }
    // });

    // selection.setVisibility(true, visibleIDs);
    // selection.blocks.setVisibility(true, visibleIDs, true);
    // } else {
    // let i = 0;
    // ids.forEach(id => {
    //   selection.mesh.count = i + 1;
    //   const { instanceID } = fragment.getInstanceAndBlockID(id);
    //   fragment.getInstance(instanceID, this._tempMatrix);
    //   selection.setInstance(i, { ids: [id], transform: this._tempMatrix });
    //   i++;
    // });
    // }
  }

  private checkSelection(name: string) {
    if (!this.selection[name]) {
      throw new Error(`Selection ${name} does not exist.`);
    }
  }

  private addHighlightToFragment(name: string, fragment: Fragment, instanceIDS: Set<number>) {
    try {
      const fragments = this.components.get(FragmentsManager);
      const material = this.highlightMats[name]?.[0];
      if (fragment && material) {
        // const geometry = fragment.mesh.geometry;
        const geometry = fragment.mesh.geometry.clone();
        const materials: THREE.Material[] = [];
        fragment.mesh.material.forEach(() => materials.push(material));

        if (!fragment.mesh.geometry.boundingSphere) {
          // it is computed either way for projection, at least this way is reused
          fragment.mesh.geometry.computeBoundingSphere();
        }

        geometry.boundingSphere = fragment.mesh.geometry.boundingSphere?.clone?.() ?? null;

        const newFragment = new Fragment(geometry, material, instanceIDS.size);

        // the build-in one is not needed
        newFragment.mesh?.dispose?.();

        // const newFragmentMesh = fragment.mesh.clone();
        const newFragmentMesh = new FragmentMesh(
          geometry,
          materials,
          instanceIDS.size,
          newFragment
        );
        newFragment.mesh = newFragmentMesh;
        newFragment.id = newFragmentMesh.uuid;
        newFragmentMesh.fragment = newFragment;
        // fixme?: this is also run in the Fragment constructor
        // BVH.apply(newFragmentMesh.geometry);

        newFragment.mesh.renderOrder = 2;
        newFragment.mesh.frustumCulled = false;

        let newInstanceID = 0;
        instanceIDS.forEach(instanceID => {
          const transform = new THREE.Matrix4();
          fragment.mesh.getMatrixAt(instanceID, transform);
          newFragmentMesh.setMatrixAt(newInstanceID, transform);

          // color is not used, creates a non-pleasant effect
          // const color = new THREE.Color();
          // fragment.mesh.getColorAt(instanceID, color);
          // newFragmentMesh.setColorAt(newInstanceID, color);

          newInstanceID++;
        });

        newFragmentMesh.visible = true;
        newFragmentMesh.matrixWorld.copy(fragment.mesh.matrixWorld);
        newFragmentMesh.matrixWorldNeedsUpdate = true;
        newFragmentMesh.instanceMatrix.needsUpdate = true;

        if (!this.selectionFragments[name]) {
          this.selectionFragments[name] = [];
        }
        this.selectionFragments[name].push(newFragment);
        const group = fragment.group;
        if (group) {
          newFragment.group = group;
          group.add(newFragment.mesh);
          // group.items.push(newFragment);
          // this._viewer._world.meshes.add(newFragment.mesh);
          // fragments.list.set(fragment.id, fragment);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  // private async clearFills(name: string | undefined) {
  //   const names = name ? [name] : Object.keys(this.selection);
  //   for (const name of names) {
  //     await this.clearStyle(name);
  //   }
  // }

  // private async clearOutlines() {
  //   if (!this.postproductionEnabled) return;
  //   const fragments = this.components.get(FragmentsManager);

  //   const effects = this._postproduction.customEffects;
  //   const fragmentsOutline = effects.outlinedMeshes.fragments;
  //   if (fragmentsOutline) {
  //     fragmentsOutline.meshes.clear();
  //   }
  //   for (const fragID in this._outlinedMeshes) {
  //     const fragment = fragments.list.get(fragID);
  //     if (!fragment) continue;
  //     const isBlockFragment = fragment.blocks.count > 1;
  //     const mesh = this._outlinedMeshes[fragID];
  //     if (isBlockFragment) {
  //       mesh.geometry.setIndex([]);
  //     } else {
  //       mesh.count = 0;
  //     }
  //   }
  // }

  // private async updateFragmentOutline(name: string, fragmentID: string) {
  //   if (!this.postproductionEnabled) return;
  //   const fragments = this.components.get(FragmentsManager);

  //   if (!this.selection[name][fragmentID]) {
  //     return;
  //   }

  //   if (this.excludeOutline.has(name)) {
  //     return;
  //   }

  //   const ids = this.selection[name][fragmentID];
  //   const fragment = fragments.list.get(fragmentID);
  //   if (!fragment) return;

  //   const geometry = fragment.mesh.geometry;
  //   const customEffects = this._postproduction.customEffects;

  //   if (!customEffects.outlinedMeshes.fragments) {
  //     customEffects.outlinedMeshes.fragments = {
  //       meshes: new Set(),
  //       material: this.outlineMaterial,
  //     };
  //   }

  //   const outlineEffect = customEffects.outlinedMeshes.fragments;

  //   // Create a copy of the original fragment mesh for outline
  //   if (!this._outlinedMeshes[fragmentID]) {
  //     const newGeometry = new THREE.BufferGeometry();

  //     newGeometry.attributes = geometry.attributes;
  //     newGeometry.index = geometry.index;
  //     const newMesh = new THREE.InstancedMesh(
  //       newGeometry,
  //       this._invisibleMaterial,
  //       fragment.capacity
  //     );
  //     newMesh.frustumCulled = false;
  //     newMesh.renderOrder = 999;
  //     fragment.mesh.updateMatrixWorld(true);
  //     newMesh.applyMatrix4(fragment.mesh.matrixWorld);
  //     this._outlinedMeshes[fragmentID] = newMesh;

  //     const scene = this._viewer._scene.three;
  //     scene.add(newMesh);
  //   }

  //   const outlineMesh = this._outlinedMeshes[fragmentID];
  //   outlineEffect.meshes.add(outlineMesh);

  //   const isBlockFragment = fragment.blocks.count > 1;

  //   if (isBlockFragment) {
  //     const indices = fragment.mesh.geometry.index.array;
  //     const newIndex: number[] = [];
  //     const idsSet = new Set(ids);
  //     for (let i = 0; i < indices.length - 2; i += 3) {
  //       const index = indices[i];
  //       const blockID = fragment.mesh.geometry.attributes.blockID.array;
  //       const block = blockID[index];
  //       const itemID = fragment.mesh.fragment.getItemID(0, block);
  //       if (idsSet.has(itemID)) {
  //         newIndex.push(indices[i], indices[i + 1], indices[i + 2]);
  //       }
  //     }

  //     outlineMesh.geometry.setIndex(newIndex);
  //   } else {
  //     let counter = 0;
  //     ids.forEach(id => {
  //       const { instanceID } = fragment.getInstanceAndBlockID(id);
  //       fragment.mesh.getMatrixAt(instanceID, this._tempMatrix);
  //       outlineMesh.setMatrixAt(counter++, this._tempMatrix);
  //     });
  //     outlineMesh.count = counter;
  //     outlineMesh.instanceMatrix.needsUpdate = true;
  //   }
  // }

  private setupEvents(active: boolean) {
    const container = this._renderer.container;

    if (active === this._eventsActive) {
      return;
    }

    this._eventsActive = active;

    if (active) {
      container.addEventListener("mousedown", this.onMouseDown);
      container.addEventListener("mouseup", this.onMouseUp);
      container.addEventListener("mousemove", this.onMouseMove);
    } else {
      container.removeEventListener("mousedown", this.onMouseDown);
      container.removeEventListener("mouseup", this.onMouseUp);
      container.removeEventListener("mousemove", this.onMouseMove);
    }
  }

  private onMouseDown = () => {
    if (!this.enabled) return;
    this._mouseState.down = true;
  };

  private onMouseUp = async (event: MouseEvent) => {
    if (!this.enabled) return;
    if (event.target !== this._renderer.container) return;
    this._mouseState.down = false;
    if (this._mouseState.moved || event.button !== 0) {
      this._mouseState.moved = false;
      return;
    }
    this._mouseState.moved = false;
    if (this.config.autoHighlightOnClick) {
      const mult = this.multiple === "none" ? true : !event[this.multiple];
      this.highlight(this.config.selectName, mult, this.zoomToSelection);
    }
  };

  private onMouseMove = async () => {
    if (!this.enabled) return;
    if (!this.config.autoHighlightOnHover) return;

    // if (this._mouseState.moved) {
    //   await this.clearFills(this.config.hoverName);
    //   return;
    // }

    this._mouseState.moved = this._mouseState.down;
    await this.highlight(this.config.hoverName, true, false);
  };
}
