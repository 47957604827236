export type PromiseLock = {
  reset: () => void;
  resolve: (value: void | PromiseLike<void>) => void;
  done: Promise<void>;
};

export const promiseLock = () => {
  const state: Partial<PromiseLock> = {};

  const reset = () => {
    state.done = new Promise<void>(resolve => {
      state.resolve = resolve;
    });
  };

  state.reset = reset;

  return state as PromiseLock;
};
