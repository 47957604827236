import { Typography, Box, Fab, Tooltip } from "@mui/material";
import { styled } from "app/theme";
import theme from "app/theme";

export const ObjectTitle = styled(Typography)({
  whiteSpace: "break-spaces",
  overflowWrap: "break-word",
  maxWidth: "100%",
});

export const SecondaryObjectTitle = styled(Typography)({
  whiteSpace: "break-spaces",
  overflowWrap: "break-word",
  maxWidth: "100%",
  color: theme.palette.inactiveGrey,
  fontStyle: "italic",
});

export const LoadingContainer = styled(Box)({
  margin: "32px auto auto",
});

export const GizmoButton = (props: {
  Icon: any;
  action: () => void;
  sx: Record<string, string | number>;
  isHighlighted: boolean;
  disabled: boolean;
  tootltipText: string;
}) => {
  return (
    // MUI complains they start disabled, but they work after being enabled
    <Tooltip placement="bottom" arrow title={props.tootltipText}>
      <Box
        sx={{
          position: "absolute",
          padding: 0,
          ...props.sx,
        }}
      >
        <Fab
          color={props.isHighlighted ? "primary" : "secondary"}
          disabled={props.disabled}
          onClick={props.action}
          sx={{
            width: "42px",
            height: "42px",
          }}
        >
          <props.Icon
            color={props.isHighlighted ? "secondary" : "primary"}
            sx={{
              // the default size looks comically small inside the button
              width: "75%",
              height: "75%",
            }}
          />
        </Fab>
      </Box>
    </Tooltip>
  );
};
