import { ReactNode } from "react";
import { Typography, Box, BoxProps } from "@mui/material";
import theme from "app/theme";

export const UsageHint = ({
  height,
  children,
  sx,
  ...props
}: BoxProps & { height?: string; children: ReactNode }) => {
  return (
    <Box
      sx={{
        padding: `${theme.sppx.compactV} ${theme.sppx.compactH}`,
        height: height || "4em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          textAlign: "center",
          lineHeight: "1.7",
          maxWidth: "100%",
          overflowX: "auto",
          overflowY: "hidden",
        }}
        component="div"
      >
        {children}
      </Typography>
    </Box>
  );
};

export default UsageHint;
