import CloseIcon from "@mui/icons-material/Close";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { MODALS } from "app/common/types";
import { BoxRow } from "app/components/common/BoxRow";
import { Heavy } from "app/components/common/Heavy";
import InlineIcon from "app/components/common/InlineIcon";
import { LocalIFCPicker } from "app/components/common/LocalIFCPicker";
import { useFileLoad } from "app/components/common/useFileLoad";
import { ToolsListItem } from "app/components/ToolsSideBar/common";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { confirmEmail } from "app/state/slices/ifcManager/cloud";
import {
  deleteModel,
  dismissModal,
  loadIfcFile,
  selectAccountIsLoggedIn,
  selectAreChangesCloudSynced,
  selectCloudStorageQuota,
  selectCloudTotalSize,
  selectFileName,
  selectIsEmailConfirmed,
  selectIsWaitingForModelIdsDeletion,
  selectIsWaitingForUpload,
  selectIsWaitingServerResponse,
  selectModelCloudId,
  selectSavedModels,
  selectSelectedModal,
  setSelectedModal,
  uploadModel,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";

export default function CloudPickerModal() {
  const dispatch = useAppDispatch();

  const onFileLoad = useFileLoad();

  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.CLOUDPICKER === selectedModal;

  const fileName = useAppSelector(selectFileName);
  const models = useAppSelector(selectSavedModels);
  const totalSize = useAppSelector(selectCloudTotalSize);
  const storageQuota = useAppSelector(selectCloudStorageQuota);
  const isWaitingForUpload = useAppSelector(selectIsWaitingForUpload);
  const isWaitingForModelIdsDeletion = useAppSelector(selectIsWaitingForModelIdsDeletion);

  const isLoggedIn = useAppSelector(selectAccountIsLoggedIn);
  const areChangesCloudSynced = useAppSelector(selectAreChangesCloudSynced);
  const isEmailConfirmed = useAppSelector(selectIsEmailConfirmed);
  const modelCloudId = useAppSelector(selectModelCloudId);
  const isWaitingServerResponse = useAppSelector(selectIsWaitingServerResponse);

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      sx={{ p: 2 }}
      onClose={() => dispatch(dismissModal())}
      open={isOpen}
    >
      <DialogTitle>
        <b>Cloud Library</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(dismissModal())}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ maxHeight: 900, textAlign: "justify" }}>
        {!isEmailConfirmed && (
          <Alert severity="error">
            Can&apos;t access library, email has not been confirmed.{" "}
            <Link
              href="#"
              onClick={() => {
                dispatch(confirmEmail());
                dispatch(dismissModal());
              }}
            >
              Click here to resend
            </Link>{" "}
            a verification email.
          </Alert>
        )}
        <Typography sx={{ p: "10px 10px 10px 30px" }}>
          <InlineIcon icon={LaptopChromebookIcon} sx={{ pr: "10px" }} />
          <Heavy>Current file</Heavy>
        </Typography>

        <ListItem
          sx={{
            display: "flex",
            justifyContent: fileName ? "flex-start" : "center",
            minHeight: "60px",
            height: "max-content",
            maxHeight: "5rem",
            overflow: "hidden",
            padding: `${theme.sppx.compact2V} 0 ${theme.sppx.compact2V} ${theme.sppx.spaciousH}`,
          }}
          onClick={e => e.stopPropagation()}
        >
          {fileName &&
            ((modelCloudId && areChangesCloudSynced && (
              <>
                <CloudDoneIcon
                  sx={{ color: theme.palette.cloudDoneGreen, mr: theme.sppx.spaciousV }}
                />
              </>
            )) ||
              (modelCloudId && !areChangesCloudSynced && (
                <>
                  <CloudIcon
                    sx={{ color: theme.palette.cloudSyncingYellow, mr: theme.sppx.spaciousV }}
                  />
                </>
              )) ||
              (!modelCloudId && (
                <>
                  <CloudOffIcon
                    sx={{ color: theme.palette.cloudDesyncedRed, mr: theme.sppx.spaciousV }}
                  />
                </>
              )))}
          {fileName && (
            <Typography
              sx={{
                pl: theme.sppx.compact2H,
                textWrap: "wrap",
                flexShrink: "2",
                overflowX: "hidden",
                overflowY: "auto",
                overflowWrap: "anywhere",
                mr: theme.sppx.spaciousH,
                maxHeight: "5rem",
              }}
            >
              {fileName}
            </Typography>
          )}
          {fileName && !modelCloudId && <Box flexGrow={1} />}
          {fileName && !modelCloudId && (
            // <Tooltip title="Upload to cloud">
            <Button
              disabled={isWaitingForUpload}
              variant="contained"
              endIcon={
                isWaitingForUpload ? (
                  <CircularProgress size="1em" />
                ) : (
                  <CloudUploadIcon
                    sx={{
                      color: theme.palette.cloudDoneGreen,
                      width: "1.2em",
                      height: "1.2em",
                    }}
                  />
                )
              }
              size="large"
              color="secondary"
              onClick={() => dispatch(uploadModel())}
              sx={{
                minWidth: "max-content",
                height: "32px",
                padding: "5px 10px 5px 10px",
                textTransform: "none",
                background: "none",
                marginRight: theme.sppx.gapTiny,
                border: `1px solid ${theme.palette.subtlerGreyBorder}`,
              }}
            >
              Upload to cloud
            </Button>
            // </Tooltip>
          )}
          {!fileName && <Typography sx={{ flex: "1", pl: "12px" }}>No file opened</Typography>}
        </ListItem>

        <br />
        <Divider />

        <Typography
          sx={{
            pl: "10px",
            pr: "10px",
            pt: "3px",
            pb: "5px",
            color: "black",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            p: "10px 10px 10px 30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heavy>Cloud</Heavy>{" "}
          <Typography component="span" sx={{ color: "#444", pr: "20px" }}>
            {Math.round(totalSize / (1024 * 1024))}/{storageQuota}MB used
          </Typography>
        </Typography>
        {models.map(x => (
          <BoxRow key={x.id}>
            <Tooltip arrow title="Load locally" placement="bottom">
              <ToolsListItem
                onClick={() => {
                  dispatch(loadIfcFile({ modelCloudId: x.id }));
                }}
                selected={x.id == modelCloudId}
                disabled={
                  isWaitingServerResponse ||
                  isWaitingForUpload ||
                  isWaitingForModelIdsDeletion.includes(x.id)
                }
                sx={{ padding: theme.pad.spacious, height: "60px" }}
              >
                <CloudDoneIcon sx={{ color: theme.palette.cloudDoneGreen, mr: "15px" }} />
                <ListItemText
                  primary={x.name}
                  sx={{
                    paddingLeft: theme.sppx.compact2H,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      overflowX: "auto",
                    },
                  }}
                />
                <Typography
                  component="span"
                  sx={{
                    color: "#444",
                    paddingLeft: "12px",
                    width: "50px",
                    flexGrow: "0",
                    flexShrink: "0",
                  }}
                >
                  {Math.round(x.size / (1024 * 1024))} MB
                </Typography>
              </ToolsListItem>
            </Tooltip>
            <Tooltip key={x.id} arrow title="Delete" placement="right">
              <BoxRow sx={{ alignItems: "center" }}>
                <IconButton
                  disabled={
                    isWaitingServerResponse ||
                    isWaitingForUpload ||
                    isWaitingForModelIdsDeletion.includes(x.id)
                  }
                  onClick={e => {
                    e.stopPropagation();
                    dispatch(deleteModel({ targetModelCloudId: x.id }));
                  }}
                >
                  {isWaitingForModelIdsDeletion.includes(x.id) ? (
                    <CircularProgress size="0.8em" />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </BoxRow>
            </Tooltip>
          </BoxRow>
        ))}
        {
          /* currently shouldn't be reached */ !isLoggedIn && (
            <Box sx={{ p: "20px 10px 10px 15px", display: "flex", alignItems: "center" }}>
              <Tooltip title="Sign in">
                <Button
                  variant="contained"
                  startIcon={<CloudIcon />}
                  size="small"
                  color="primary"
                  component="label"
                  onClick={() => dispatch(setSelectedModal(MODALS.LOGIN))}
                  sx={{
                    paddingLeft: theme.sppx.compact2H,
                    paddingRight: theme.sppx.compact2H,
                    marginRight: "0.5em",
                  }}
                >
                  <Typography variant="button" sx={{ textTransform: "none", whiteSpace: "nowrap" }}>
                    Sign in
                  </Typography>
                </Button>
              </Tooltip>
              <Typography sx={{ textTransform: "none" }} component="span">
                to sync <b>files</b> & <b>scenes</b> online and <Heavy>access</Heavy> them from{" "}
                <b>anywhere</b>!
              </Typography>
            </Box>
          )
        }
        {isLoggedIn && models.length == 0 && (
          <Box
            sx={{
              p: "10px 10px 10px 15px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ ml: "5px", mb: "10px", textTransform: "none" }} component="div">
              No files in the cloud yet.
            </Typography>
            <Typography sx={{ ml: "5px", textTransform: "none" }} component="div">
              Press the <InlineIcon icon={CloudUploadIcon} active={true} /> to <b>upload</b> the
              current file to your <b>cloud</b> account
            </Typography>
          </Box>
        )}
        {isWaitingServerResponse && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: "10px" }}>
            <CircularProgress size="1em" />
            <Typography sx={{ pl: "15px" }}>Loading model updates...</Typography>
          </Box>
        )}
        <Divider sx={{ pt: "30px" }} />
        <Box
          sx={{
            padding: "10px 0 0px 0",
            // height: "4em",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              lineHeight: "1.7",
            }}
          >
            Open a file from your computer instead?
          </Typography>
          <Button
            variant="contained"
            startIcon={<LaptopChromebookIcon />}
            size="small"
            color="primary"
            component="label"
            sx={{
              minWidth: "25px",
              height: "32px",
              padding: "5px 0px 5px 9px",
            }}
          >
            <Typography variant="button" sx={{ mr: "12px", textTransform: "none" }}>
              Open local .ifc
            </Typography>
            <LocalIFCPicker onChange={onFileLoad} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
