import { SpatialNode, SpatialNodesMap } from "app/common/types";
import {
  Clipper,
  Components,
  Cullers,
  // FragmentBoundingBox,
  FragmentsManager,
  Grids,
  Hider,
  IfcGeometryTiler,
  IfcLoader,
  IfcPropertiesTiler,
  IfcRelationsIndexer,
  MeshCullerRenderer,
  OrthoPerspectiveCamera,
  SimplePlane,
  // SimpleRenderer,
  SimpleScene,
  StreamedAsset,
  World,
  // SimpleRaycaster,
  // Disposer,
  Worlds,
} from "app/components/ifcjs/core";
import { Fragment, FragmentIdMap, FragmentsGroup } from "app/components/ifcjs/fragments";
import {
  ClipEdges,
  EdgesPlane,
  IfcStreamer,
  // Highlighter,
  Plans,
  RendererWith2D,
} from "app/components/ifcjs/front";
import { Color, FrontSide, LineBasicMaterial, Material, MeshBasicMaterial } from "three";
// import { FragmentsGroup } from "bim-fragment";
import { FragmentHighlighter } from "app/components/ifcjs/FragmentHighlighter";
// import { OrthoPerspectiveCamera } from "app/components/ifcjs/OrthoPerspectiveCamera";
// import { FragmentPlans } from "app/components/ifcjs/FragmentPlans";
import { LengthMeasurement } from "app/components/ifcjs/LengthMeasurement";
import { SimpleDimensionLine } from "app/components/ifcjs/SimpleDimensionLine";
// import { IfcLoader } from "app/components/ifcjs/IfcLoader";
import { NavGizmo } from "app/components/ifcjs/NavGizmo";
import { TargetCursor } from "app/components/ifcjs/TargetCursor";
// import { FragmentBoundingBox } from "app/components/ifcjs/FragmentBoundingBox";
// import { FragmentsManager } from "app/components/ifcjs/FragmentsManager";
import { VertexPicker } from "app/components/ifcjs/VertexPicker";
import { promiseLock, PromiseLock } from "app/utils/promiseLock";
import * as WEBIFC from "web-ifc";

import {
  _cameraGoHome,
  _fitModelToFrame,
  _isOrthoEnabled,
  _refreshCursor,
  _refreshSize,
  _rotateToAngle,
  _setIsOrthoEnabled,
  _toggleOrtho,
} from "./camera";
import {
  _createOrFinishDimension,
  _deleteAllDimensions,
  _deleteDimension,
  _getDimensionEndPoint,
  _getDimensionLength,
  _getDimensionStartPoint,
  _isDimensionToolEnabled,
  _restoreDimensions,
  _setDimensionVisibility,
  _setIsDimensionHighlighted,
  _turnOffDimensionTool,
  _turnOnDimensionTool,
} from "./dimensions";
import { _loadIfcFile, _unloadAll, _unloadAllSync, _unloadOne } from "./loadUnloadFile";
import { _exitPlanView, _goToPlan } from "./plans";
import {
  _getIfcAttributes,
  _getIfcProperties,
  _highlightByID,
  _pickHoveredItemProps,
  _setVisibilityByID,
} from "./properties";
import {
  _createSection,
  _deleteCustomSection,
  _deleteSection,
  _getLastSection,
  _getSectionNormal,
  _getSectionOrigin,
  _isSectionToolEnabled,
  _restoreSections,
  _setSectionEnabled,
  _setSectionVisibility,
  _turnOffSectionTool,
  _turnOnSectionTool,
} from "./section";

export type FragmentsGroupExtended = FragmentsGroup & {
  spatialNodesMap: SpatialNodesMap;
  spatialTreeRoots: SpatialNode[];
};

export type FragmentExtended = Fragment & {
  originalMaterial?: Material[] | null;
  originalHiddenItems?: Set<number> | null;
  originalVisibleItems?: Set<number> | null;
  selectionStack?: { name: string; material: Material[] }[];
};

export type ViewerOptions = {
  container: HTMLElement;
};

export class ViewerAPI {
  public _components: Components;
  public _worlds: Worlds;
  public _world: World;
  public _fragments: FragmentsManager;
  public _ifcLoader: IfcLoader;
  public _ifcStreamer: IfcStreamer;
  public _geometryTiler: IfcGeometryTiler;
  public _geometryTilerState: {
    // note: StreamedGeometries type doesn't match geometry.data.value
    geometriesData: Record<
      string,
      {
        boundingBox: Float32Array;
        hasHoles: boolean;
        geometryFile?: string;
      }
    >;
    geometryFilesCount: number;
    assetsData: StreamedAsset[];
  };
  public _geometryTilerDone: Promise<void> | null = null;
  public _geometryTilerIsProcessing: PromiseLock;
  public _propsTiler: IfcPropertiesTiler;
  public _propsTilerIsProcessing: PromiseLock;
  public _bimTileState: {
    geometryFiles: Record<string, Uint8Array>;
    geometryGroup: Record<string, Uint8Array>;
    geometryDescriptor: Record<string, any>; // value is json
    propertyIndex: Record<string, string>;
    propertyFiles: Record<string, string>; // value is json
    propertyDescriptor: Record<string, string>; // value is json
  };
  public _grid: Grids;
  public _navGizmo: NavGizmo;
  public _targetCursor: TargetCursor;
  public _plans: Plans;
  public _hider: Hider;
  // public _highlighter: Highlighter;
  public _highlighter: FragmentHighlighter;
  public _vertexPicker: VertexPicker;
  // these are temporary buffers between onHighlight and getIfcProperties
  public _highlighterSelection: FragmentIdMap | null = null;
  public _highlighterModel: FragmentsGroupExtended | null = null;
  public highlighterModelUUID: number | null = null;
  public highlighterExpressId: number | null = null;
  public _highlighterFragmentID: string | null = null;
  // public _fragmentBoundingBox: FragmentBoundingBox | null = null;
  // public _classifier: FragmentClassifier;
  public _clipper: Clipper;
  public _edges: ClipEdges;
  public _cullers: Cullers | null = null;
  public _culler: MeshCullerRenderer | null = null;
  public _customClippingPlanesNextID = 1;
  public _customClippingPlanes: { [key: number]: SimplePlane } = {};
  public _dimensions: LengthMeasurement;
  public _dimensionsNextId = 1;
  public _dimensionsMap: { [key: number]: SimpleDimensionLine } = {};
  // public _propsProcessor: IfcPropertiesProcessor;
  // public _propsManager: IfcPropertiesManager;
  public _propsIndexer: IfcRelationsIndexer;
  public _arePlansActive = false;
  // this is for the empty scene before loading a model
  public _boundingSphereCenter: number[] = [0, 0, 0];
  public _boundingSphereRadius = 10;
  // public _dispatch: any = null;

  public modelSrc: Uint8Array | null = null;

  // Provide our chosen types to the base classes
  get _scene() {
    return this._world.scene as SimpleScene;
  }
  get _renderer() {
    // return this.components.renderer as OBC.PostproductionRenderer;
    // return this._world.renderer as SimpleRenderer;
    return this._world.renderer as RendererWith2D;
  }
  get _camera() {
    return this._world.camera as OrthoPerspectiveCamera;
  }
  // get _raycaster() {
  //   return this._components.raycaster as SimpleRaycaster;
  // }
  // get _tools() {
  //   return this._components.tools;
  // }

  constructor(options: ViewerOptions) {
    this._components = new Components();
    // note: tool dependencies should be respected
    this._worlds = this._components.get(Worlds);
    this._world = this._worlds.create<SimpleScene, OrthoPerspectiveCamera, RendererWith2D>();
    // this._components.uiEnabled = false;
    // this._fragmentBoundingBox = new FragmentBoundingBox(this._components);
    this._world.scene = new SimpleScene(this._components);
    // this._world.renderer = new SimpleRenderer(this._components, options.container);
    this._world.renderer = new RendererWith2D(this._components, options.container);
    // note: the ambient occlusion uses n8ao which has been excluded from the build
    //       see vite.config.js rollupOptions > external
    // this.components.renderer = new OBC.PostproductionRenderer(this.components, options.container);
    this._world.camera = new OrthoPerspectiveCamera(this._components);
    // (
    //   this._components.camera as OrthoPerspectiveCamera
    // )._projectionManager.matchOrthoDistanceEnabled = true;
    // this._components.raycaster = new SimpleRaycaster(this._components);
    this._fragments = new FragmentsManager(this._components);
    this._ifcLoader = new IfcLoader(this._components);
    this._ifcStreamer = this._components.get(IfcStreamer);
    this._geometryTiler = this._components.get(IfcGeometryTiler);
    this._geometryTilerIsProcessing = promiseLock();
    // fixme: dispose & init
    this._geometryTilerState = {
      // note: StreamedGeometries type doesn't match geometry.data.value
      geometriesData: {},
      geometryFilesCount: 1,
      assetsData: [],
    };
    this._propsTiler = this._components.get(IfcPropertiesTiler);
    this._propsTilerIsProcessing = promiseLock();
    this._bimTileState = {
      geometryFiles: {},
      geometryGroup: {},
      geometryDescriptor: {},
      propertyIndex: {},
      propertyFiles: {},
      propertyDescriptor: {},
    };
    this._grid = this._components.get(Grids);

    // used by LengthMeasurement & Clipper
    this._vertexPicker = new VertexPicker(this._components, this);
    this._dimensions = new LengthMeasurement(this._components, this);

    this._plans = this._components.get(Plans);
    this._hider = this._components.get(Hider);
    this._highlighter = new FragmentHighlighter(this._components, this);
    // this._classifier = new FragmentClassifier(this._components);
    this._clipper = this._components.get(Clipper);
    this._edges = this._components.get(ClipEdges);
    // this._cullers = this._components.get(Cullers);
    // this._culler = this._cullers.create(this._world);

    // this._propsProcessor = new IfcPropertiesProcessor(this._components);
    // this._propsManager = new IfcPropertiesManager(this._components);
    // this._propsProcessor.propertiesManager = this._propsManager;
    this._propsIndexer = this._components.get(IfcRelationsIndexer);

    this._navGizmo = new NavGizmo(this);
    this._targetCursor = new TargetCursor(this);

    // defer the first update until initialize() is done
    this._components.init(false);
    this._components.enabled = true;
  }

  async initialize() {
    /* clipper */ {
      const sectionMaterial = new LineBasicMaterial({ color: "black" });
      const fillMaterial = new MeshBasicMaterial({ color: "gray", side: 2 });
      const fillOutline = new MeshBasicMaterial({
        color: "black",
        side: 1,
        opacity: 0.5,
        transparent: true,
      });
      // this._clipper.styles.create("filled", new Set(), sectionMaterial, fillMaterial, fillOutline);
      // this._clipper.styles.create("projected", new Set(), sectionMaterial);

      this._clipper.Type = EdgesPlane;
      this._edges.styles.create(
        "Default",
        new Set(),
        this._world,
        sectionMaterial,
        fillMaterial,
        fillOutline
      );
      this._edges.styles.create(
        "IFCSPACEStyle",
        new Set(),
        this._world,
        sectionMaterial,
        undefined,
        fillOutline
      );
      this._edges.visible = true;
    }
    const wasm = {
      path: "/",
      absolute: true,
      logLevel: WEBIFC.LogLevel.LOG_LEVEL_OFF,
    };
    /* loader */ {
      this._ifcLoader.settings.wasm = wasm;
      this._ifcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;

      // const excludedCats = [WEBIFC.IFCSPACE];
      const excludedCats: number[] = [];

      for (const cat of excludedCats) {
        this._ifcLoader.settings.excludedCategories.add(cat);
      }
      // this._IfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;
      // this._IfcLoader.settings.webIfc.USE_FAST_BOOLS = false;
    }
    /* streamer */ {
      this._ifcStreamer.world = this._world;
      this._ifcStreamer.dbCleaner.enabled = false;
      this._ifcStreamer.settings.disableRamCacheEviction = true;
      this._ifcStreamer.url = "/";
      this._ifcStreamer.settings.groupArrayFetcher = async (baseUrl: string, file: string) => {
        if (!(file in this._bimTileState.geometryGroup))
          throw new Error("groupArrayFetcher: bad file name");
        return this._bimTileState.geometryGroup[file];
      };
      this._ifcStreamer.settings.indexFetcher = async (baseUrl: string, file: string) => {
        if (!(file in this._bimTileState.propertyIndex))
          throw new Error("indexFetcher: bad file name");
        return this._bimTileState.propertyIndex[file];
      };
      this._ifcStreamer.settings.geometryFileFetcher = async (baseUrl: string, file: string) => {
        if (!(file in this._bimTileState.geometryFiles))
          throw new Error("geometryFileFetcher: bad file name");
        return this._bimTileState.geometryFiles[file];
      };
      this._ifcStreamer.settings.propertyFileFetcher = async (baseUrl: string, file: string) => {
        if (!(file in this._bimTileState.propertyFiles))
          throw new Error("propertyFileFetcher: bad file name");
        return this._bimTileState.propertyFiles[file];
      };
      // breaks the tile fetch
      this._ifcStreamer.useCache = false;
      // they cause flickering for no significant performance
      this._ifcStreamer.culler.maxLostTime = Number.MAX_SAFE_INTEGER;
      this._ifcStreamer.culler.maxHiddenTime = Number.MAX_SAFE_INTEGER;
      // missing geometry is confusing when inspecting models
      this._ifcStreamer.culler.threshold = 1;
    }
    /* geometryTiler */ {
      this._geometryTiler.settings.wasm = wasm;
      this._geometryTiler.settings.minGeometrySize = 20;
      this._geometryTiler.settings.minAssetsSize = 1000;
      // completely ignore IFCSPACEs for now
      // todo: generate the geometry, but don't render it unless highlighted
      // this._geometryTiler.settings.excludedCategories.add(WEBIFC.IFCSPACE);

      this._geometryTiler.onGeometryStreamed.add(geometry => {
        const { buffer, data } = geometry;
        const bufferFileName = `small.ifc-processed-geometries-${this._geometryTilerState.geometryFilesCount}`;
        for (const expressID in data) {
          const value = data[expressID];
          value.geometryFile = bufferFileName;
          this._geometryTilerState.geometriesData[expressID] = value;
        }
        this._bimTileState.geometryFiles[bufferFileName] = buffer;
        this._geometryTilerState.geometryFilesCount++;
      });

      this._geometryTiler.onAssetStreamed.add(assets => {
        this._geometryTilerState.assetsData = [...this._geometryTilerState.assetsData, ...assets];
      });

      this._geometryTiler.onIfcLoaded.add(groupBuffer => {
        this._bimTileState.geometryGroup["small.ifc-processed-global"] = groupBuffer;
      });

      this._geometryTiler.onProgress.add(progress => {
        if (progress !== 1) return;
        setTimeout(async () => {
          const processedData = {
            geometries: this._geometryTilerState.geometriesData,
            assets: this._geometryTilerState.assetsData,
            globalDataFileId: "small.ifc-processed-global",
          };
          this._bimTileState.geometryDescriptor["small.ifc-processed.json"] = processedData;
          this._geometryTilerIsProcessing.resolve();
          this._geometryTilerState.assetsData = [];
          this._geometryTilerState.geometriesData = {};
          this._geometryTilerState.geometryFilesCount = 1;
        });
      });
    }
    /* propertiesTiles */ {
      // fixme: use me or delete me
      this._propsTiler.settings.wasm = wasm;
      let counter = 0;
      interface StreamedProperties {
        types: {
          [typeID: number]: number[];
        };

        ids: {
          [id: number]: number;
        };

        indexesFile: string;
      }
      const jsonFile: StreamedProperties = {
        types: {},
        ids: {},
        indexesFile: "small.ifc-processed-properties-indexes",
      };

      this._propsTiler.onPropertiesStreamed.add(async props => {
        if (!jsonFile.types[props.type]) {
          jsonFile.types[props.type] = [];
        }
        jsonFile.types[props.type].push(counter);

        for (const id in props.data) {
          jsonFile.ids[id] = counter;
        }

        const name = `small.ifc-processed-properties-${counter}`;
        this._bimTileState.propertyFiles[name] = JSON.stringify(props.data);

        counter++;
      });

      this._propsTiler.onProgress.add(async progress => {
        console.log(progress);
      });

      this._propsTiler.onIndicesStreamed.add(async props => {
        this._bimTileState.propertyDescriptor["small.ifc-processed-properties.json"] =
          JSON.stringify(jsonFile);

        const relations = this._components.get(IfcRelationsIndexer);
        const serializedRels = relations.serializeRelations(props, true) as string;

        this._bimTileState.propertyIndex["small.ifc-processed-properties-indexes"] = serializedRels;

        this._propsTilerIsProcessing.resolve();
      });
    }
    /* plans */ {
      this._plans.world = this._world;
    }
    /* camera */ {
      this._camera.projection.matchOrthoDistanceEnabled = true;
      this._camera.controls.infinityDolly = false;
      this._camera.controls.dollyToCursor = true;
      // this._camera.controls.minDistance = Number.EPSILON;
      this._camera.controls.minDistance = Number.NEGATIVE_INFINITY;
      this._camera.controls.smoothTime = 0.001;
      this._camera.controls.draggingSmoothTime = 0.05;

      // current prod values
      // const midAction = CameraControls.ACTION.DOLLY;
      // this._camera.controls.mouseButtons.wheel = midAction;
      // this._camera.controls.mouseButtons.middle = midAction;
      // this._camera.controls.azimuthRotateSpeed = 1;
      // this._camera.controls.boundaryFriction = 0;
      // this._camera.controls.dampingFactor = 0.2;
      // this._camera.controls.dollySpeed = 1;
      // this._camera.controls.dollyToCursor = true;
      // this._camera.controls.dragToOffset = false;
      // this._camera.controls.draggingDampingFactor = 0.25;
      // this._camera.controls.infinityDolly = true;
      // this._camera.controls.maxAzimuthAngle = Number.POSITIVE_INFINITY;
      // this._camera.controls.maxDistance = 300;
      // this._camera.controls.maxPolarAngle = Math.PI;
      // this._camera.controls.minDistance = 1;
      // this._camera.controls.minPolarAngle = 0;
      // this._camera.controls.minZoom = 0.01;
      // this._camera.controls.polarRotateSpeed = 1;
      // this._camera.controls.restThreshold = 0.01;
      // this._camera.controls.truckSpeed = 2;
      // this._camera.controls.verticalDragToForward = false;
    }
    /* scene */ {
      //@ts-ignore some bad types somewhere along the way
      this._scene.three.background?.copy(new Color(0xffffff));
      this._scene.config;
    }
    /* renderer */ {
      // this._renderer.postproduction.enabled = true;
      // this._renderer.postproduction.customEffects.outlineEnabled = true;
      // this._renderer.postproduction.customEffects.glossEnabled = false;
    }
    /* grid */ {
      // this._grid.config.color.setHex(0x666666);
      this._grid.create(this._world);
      // grid.three.position.y -= 1;
      // this._renderer.postproduction.customEffects.excludedMeshes.push(grid.three);
    }
    /* culler */ {
      if (this._culler) {
        this._culler.threshold = 1;
      }
    }
    /* highlighter */ {
      // this._highlighter.setup({ world: this._world, autoHighlightOnClick: false });
      await this._highlighter.setup({ autoHighlightOnClick: true });
      const highlightMat = new MeshBasicMaterial({
        depthWrite: false,
        depthTest: false,
        color: 0xc835d0,
        transparent: true,
        opacity: 0.5,
        side: FrontSide,
        forceSinglePass: true,
      });
      // await this._highlighter.add("default", new Color(0xc835d0));
      await this._highlighter.add("default", [highlightMat]);
      // // note: the outline is not modular, postproduction must exist when disabled
      // this._highlighter.outlineEnabled = false;
      // this._highlighter.fillEnabled = true;
      // this._highlighter.update();
    }
    /* dimensions */ {
      this._dimensions.snapDistance = 2.0;
      this._dimensions.world = this._world;
    }
    /* properties */ {
      // this._propsManager.wasm = {
      //   path: "/",
      //   absolute: true,
      // };
    }
    /* targetCursor */ {
      this._targetCursor.enabled = false;
    }
  }

  async dispose() {
    try {
      // disposing clipper automatically doesn't work
      {
        // this._clipper.dispose();
        // this._components.tools.list.delete(EdgesClipper.uuid);
      }
      // just this line would be too easy:
      await this._components.dispose();

      // const disposer = this._components.tools.get(Disposer);
      // this._components.enabled = false;
      // await this._components.tools.dispose();
      // this._components.onInitialized.reset();
      // //@ts-ignore
      // this._components._clock.stop();
      // for (const mesh of this._components.meshes) {
      //   disposer.destroy(mesh);
      // }
      // this._components.meshes.length = 0;
      // if (this._components.renderer.isDisposeable()) {
      //   await this._components.renderer.dispose();
      // }
      // if (this._components.scene.isDisposeable()) {
      //   await this._components.scene.dispose();
      // }
      // if (this._components.camera.isDisposeable()) {
      //   await this._components.camera.dispose();
      // }
      // if (this._components.raycaster.isDisposeable()) {
      //   await this._components.raycaster.dispose();
      // }
      // await this._components.onDisposed.trigger();
      // this._components.onDisposed.reset();
    } catch (ex) {
      console.error("OpenBIM dispose is broken:", ex);
    }
  }

  /* reverse redux updates */
  /* warning: reverse updates disabled in favour of on-demand access 
       due to Sentry issue "s._dispatch is not a function" 
       https://sortdesk.sentry.io/issues/4832734922/ */
  // todo: find why reverse updates can fail
  // public injectDispatcher = (dispatch: any) => (this._dispatch = dispatch);

  /* camera */
  public cameraGoHome = _cameraGoHome;
  public toggleOrtho = _toggleOrtho;
  public refreshSize = _refreshSize;
  public refreshCursor = _refreshCursor;
  public isOrthoEnabled = _isOrthoEnabled;
  public setIsOrthoEnabled = _setIsOrthoEnabled;
  public fitModelToFrame = _fitModelToFrame;
  public rotateToAngle = _rotateToAngle;

  /* dimensions */
  public turnOnDimensionTool = _turnOnDimensionTool;
  public turnOffDimensionTool = _turnOffDimensionTool;
  public isDimensionToolEnabled = _isDimensionToolEnabled;
  public createOrFinishDimension = _createOrFinishDimension;
  public deleteDimension = _deleteDimension;
  public deleteAllDimensions = _deleteAllDimensions;
  public setDimensionVisibility = _setDimensionVisibility;
  public setIsDimensionHighlighted = _setIsDimensionHighlighted;
  public getDimensionLength = _getDimensionLength;
  public getDimensionStartPoint = _getDimensionStartPoint;
  public getDimensionEndPoint = _getDimensionEndPoint;
  public restoreDimensions = _restoreDimensions;

  /* loadUnloadFile */
  public loadIfcFile = _loadIfcFile;
  public unloadAll = _unloadAll;
  public unloadAllSync = _unloadAllSync;
  public unloadOne = _unloadOne;

  /* plans */
  public goToPlan = _goToPlan;
  public exitPlanView = _exitPlanView;

  /* properties */
  public getIfcProperties = _getIfcProperties;
  public getIfcAttributes = _getIfcAttributes;
  public pickHoveredItemProps = _pickHoveredItemProps;
  public highlightByID = _highlightByID;
  public setVisibilityByID = _setVisibilityByID;

  /* section */
  public turnOnSectionTool = _turnOnSectionTool;
  public turnOffSectionTool = _turnOffSectionTool;
  public isSectionToolEnabled = _isSectionToolEnabled;
  public createSection = _createSection;
  public getLastSection = _getLastSection;
  public deleteSection = _deleteSection;
  public setSectionVisibility = _setSectionVisibility;
  public setSectionEnabled = _setSectionEnabled;
  public deleteCustomSection = _deleteCustomSection;
  public getSectionOrigin = _getSectionOrigin;
  public getSectionNormal = _getSectionNormal;
  public restoreSections = _restoreSections;
  // public updateClippingPlanes = _updateClippingPlanes;

  /* navGizmo */
  public rotateToNavGizmoAxis = () => this._navGizmo.rotateToAxis();
  public isNavGizmoActive = () => this._navGizmo.isHovering;
  public setIsRightPanelOpen = (isOpen: boolean) => (this._navGizmo.isRightPanelOpen = isOpen);

  /* targetCursor */
  public turnOnTargetCursor = () => (
    (this._targetCursor.enabled = true), (this._targetCursor.cursorObject.visible = true)
  );
  public turnOffTargetCursor = () => (
    (this._targetCursor.enabled = false), (this._targetCursor.cursorObject.visible = false)
  );
}

export enum VieweAPIState {
  UNINITIALIZED = "UNINITIALIZED",
  LOADING = "LOADING",
  INIT_FAILED = "INIT_FAILED",
  INITIALIZED = "INITIALIZED",
}

export class ViewerSingleton {
  /**
   * Singleton which should provide one instance of IFCViewer
   * It is not book-definition singleton - ref is needed to
   * create one, and passing it through component tree is
   * not practical. Thus we really need to create that one
   * time, because pattern does not provide that by default.
   */
  private static instance: ViewerAPI;

  private static options: ViewerOptions;
  public static state: VieweAPIState = VieweAPIState.UNINITIALIZED;

  constructor(options: ViewerOptions) {
    ViewerSingleton.options = options;
  }

  public static getInstance(): ViewerAPI {
    return this.instance;
  }

  public static async initialize() {
    ViewerSingleton.state = VieweAPIState.LOADING;
    try {
      const viewer = new ViewerAPI(this.options);
      this.instance = viewer;

      await viewer.initialize();
      viewer._components.enabled = true;
      viewer._components.update();
    } catch (ex) {
      ViewerSingleton.state = VieweAPIState.INIT_FAILED;
      throw ex;
    }
    ViewerSingleton.state = VieweAPIState.INITIALIZED;
  }

  public static reset() {
    this.instance.dispose();
    this.initialize();
  }
  public static async resetSync() {
    await this.instance.dispose();
    await this.initialize();
  }
}

/*
 * The UI/redux layer should only interact through these
 * and avoid touching any _underscroreMember
 * those are for app/common/ViewerAPI
 * add there a new method named by missing intention
 */
export const viewerAPI = () => ViewerSingleton.getInstance();
// note: there is a short time slice during first load before this gets called when
// viewerAPI() returns undefined, use ?? default values if used in the UI
export const initializeViewerAPI = (options: ViewerOptions) => {
  new ViewerSingleton(options);
  return ViewerSingleton.initialize();
};
