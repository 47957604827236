import theme, { styled } from "app/theme";

export const ToolsSidebarBody = styled("div")({
  width: "100%",
  padding: theme.sppx.compact2V,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflowY: "auto",
});

export default ToolsSidebarBody;
