import { PayloadAction } from "@reduxjs/toolkit";
import { viewerAPI } from "app/common/ViewerAPI";
import { TOOLS, Tool } from "app/common/types";
import { IIfcMangerState } from "app/state/slices/ifcManagerSlice";
import { RootState } from "app/state/store";
import { firebase } from "app/utils/firebase";

import { cancelMeasurementCreationReducer } from "./dimensions";
import { deselectPlanReducer } from "./plans";
import { cancelSectionCreationReducer, hideAllSectionsReducer } from "./sections";

export interface IToolsState {
  // selectedTool: Tool;
  activeTools: { [key: string]: boolean };
  selectedTools: { [key: string]: boolean };
  isNavGizmoEnabled: boolean;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
}

export const initialToolsState: IToolsState = {
  activeTools: {},
  selectedTools: {},
  isNavGizmoEnabled: false,
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
};

export const setToolReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ selectedTool: Tool; isToggle?: boolean }>
) => {
  const viewer = viewerAPI();
  const tool =
    payload.isToggle && payload.selectedTool
      ? state.activeTools[payload.selectedTool]
        ? null
        : payload.selectedTool
      : payload.selectedTool;

  // sections don't play well with selected plans
  if (tool === TOOLS.SECTION) {
    deselectPlanReducer(state);
  }

  if (tool === TOOLS.PLAN_MANAGER) {
    hideAllSectionsReducer(state);
  }

  if (tool !== TOOLS.SECTION) {
    // auto disable section editing
    hideAllSectionsReducer(state);
    // auto disable creation on change
    if (state.isSectionCreationActive) {
      cancelSectionCreationReducer(state);
    }
  }

  if (tool !== TOOLS.DIMENSION) {
    // keep the highlight might be useful
    // auto disable creation on change
    if (state.isMeasurementCreationActive) {
      cancelMeasurementCreationReducer(state);
    }
  }

  if (tool === TOOLS.IDSEDITOR) {
    // auto deselect query because panels overlap
    state.selectedTools[TOOLS.QUERY] = false;
    state.activeTools[TOOLS.QUERY] = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(TOOLS).forEach(([_key, value]) => {
    if (payload.isToggle) {
      if (value == TOOLS.QUERY || value == TOOLS.PROPERTIES) {
        // only deactivated by its own button
        if (value == payload.selectedTool && !tool) {
          state.activeTools[value] = false;
        }
      } else {
        // properties doesn't not deactivate other tools
        if (payload.selectedTool != TOOLS.PROPERTIES) {
          state.activeTools[value] = false;
        }
      }
      state.selectedTools[value] = false;
    } else if (value != tool) {
      if (value != TOOLS.QUERY && value != TOOLS.PROPERTIES) {
        state.activeTools[value] = false;
      }
      state.selectedTools[value] = false;
    }
  });

  if (tool) {
    state.activeTools[tool] = true;
    state.selectedTools[tool] = true;

    firebase.ga_event("tool_click", {
      label: tool,
    });
  }
  state.isLeftPanelOpen =
    state.activeTools[TOOLS.SECTION] ||
    state.activeTools[TOOLS.DIMENSION] ||
    state.activeTools[TOOLS.PLAN_MANAGER] ||
    state.activeTools[TOOLS.OBJECTTREE] ||
    state.activeTools[TOOLS.IDSEDITOR] ||
    state.activeTools[TOOLS.HELP] ||
    false;

  state.isRightPanelOpen =
    state.selectedTools[TOOLS.PROPERTIES] || state.activeTools[TOOLS.PROPERTIES] || false;
  viewer?.setIsRightPanelOpen?.(state.isRightPanelOpen);
};

export const toggleToolReducer = (
  state: IIfcMangerState,
  action: PayloadAction<{ selectedTool: TOOLS }>
) => {
  const tool = action.payload.selectedTool;

  setToolReducer(state, {
    ...action,
    payload: {
      selectedTool: tool,
      isToggle: true,
    },
  });
};

export const hideToolReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ tool: TOOLS }>
) => {
  const tool = payload.tool;

  state.activeTools[tool] = false;
  state.selectedTools[tool] = false;

  state.isLeftPanelOpen =
    state.selectedTools[TOOLS.SECTION] ||
    state.selectedTools[TOOLS.DIMENSION] ||
    state.selectedTools[TOOLS.PLAN_MANAGER] ||
    state.selectedTools[TOOLS.OBJECTTREE] ||
    state.selectedTools[TOOLS.HELP] ||
    false;

  state.isRightPanelOpen = state.selectedTools[TOOLS.PROPERTIES] || false;
};

export const toolsSelectors = {
  selectIsToolSelected: (tool: TOOLS) => (state: RootState) =>
    state.ifcManager.selectedTools[tool] ?? false,
  selectIsToolActive: (tool: TOOLS) => (state: RootState) =>
    state.ifcManager.activeTools[tool] ?? false,
  selectSelectedTools: (state: RootState) => state.ifcManager.selectedTools,
  selectActiveTools: (state: RootState) => state.ifcManager.activeTools,
  selectIsNavGizmoEnabled: (state: RootState) => state.ifcManager.isNavGizmoEnabled,
  selectIsLeftPanelOpen: (state: RootState) => state.ifcManager.isLeftPanelOpen,
  selectIsRightPanelOpen: (state: RootState) => state.ifcManager.isRightPanelOpen,
};

export const toolsReducers = {
  setTool: setToolReducer,
  hideTool: hideToolReducer,
  toggleTool: toggleToolReducer,
};
