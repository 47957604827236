import { styled } from "app/theme";
import { Typography, Link, Box, ListItem, List } from "@mui/material";
import { POLICIES } from "app/common/types";
import { policies } from "app/modals/policies/generator/results";

const ToSCompactList = styled(List)({
  padding: 0,
  marginTop: "0.25em",
  marginBottom: "0.5em",
  marginLeft: "1.5em",
  "& .MuiListItem-root": {
    paddingLeft: "0em",
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
});

const PolicyModule = ({ policy, id }: { policy: any; id: string }) => {
  return (
    <>
      <Typography component="h2" sx={{ fontSize: "1.5em", fontWeight: "700" }} id={id}>
        {policy.title}
      </Typography>
      <Typography component="div" sx={{ pl: "15px" }}>
        {policy.content}
      </Typography>
      <br />
      <br />
    </>
  );
};

export default function FullTermsAccountPage() {
  return (
    <main>
      <Box
        sx={{
          height: "100vh",
          boxSizing: "border-box",
          // width: "100%",
          overflowY: "scroll",
          padding: "15px 35px 15px 15px",
          "& p": {
            pl: "15px",
          },
        }}
      >
        <Typography variant="h1" sx={{ fontSize: "2.0em", fontWeight: "700" }}>
          Terms & Conditions
        </Typography>
        <Typography component="div" sx={{ pl: "15px", pt: "1em", pb: "1em" }}>
          This page comprises the Terms & Conditions of Sortdesk GmbH for use <b>with an account</b>
          . It consists of the following four sections:
          <ToSCompactList>
            <ListItem>
              <Link href="#Terms-of-Service">Terms of Service</Link>
            </ListItem>
            <ListItem>
              <Link href="#Acceptable-Use">Acceptable Use</Link>
            </ListItem>
            <ListItem>
              <Link href="#Privacy-Policy">Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="#Cookie-Policy">Cookie Policy</Link>
            </ListItem>
          </ToSCompactList>
        </Typography>

        <PolicyModule id="Terms-of-Service" policy={policies[POLICIES.TOS_FREE]} />
        <PolicyModule id="Acceptable-Use" policy={policies[POLICIES.ACCEPTABLE_USE_FREE]} />
        <PolicyModule id="Privacy-Policy" policy={policies[POLICIES.PRIVACY_FREE]} />
        <PolicyModule id="Cookie-Policy" policy={policies[POLICIES.COOKIES_FREE]} />
      </Box>
    </main>
  );
}
