import { PayloadAction } from "@reduxjs/toolkit";
import { viewerAPI } from "app/common/ViewerAPI";
import { Plan } from "app/common/types";
import { RootState } from "app/state/store";

import { IIfcMangerState } from "../ifcManagerSlice";
import { setIsOrthoEnabledReducer } from "../ifcManager/camera";

export interface IPlansState {
  plans: Plan[];
  selectedPlan: string | null;
}

export const initialPlansState: IPlansState = {
  plans: [],
  selectedPlan: null,
};

export const goToPlanReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ planId: string }>
) => {
  if (!state.modelID) {
    state.selectedPlan = null;
    console.error("A plan was selected but there is no model");
    return;
  }

  state.selectedPlan = payload.planId;
  // nav gizmo can do weird things with plans enabled
  state.isNavGizmoEnabled = false;
  viewerAPI().goToPlan(payload.planId);
};

export const deselectPlanReducer = (state: IIfcMangerState) => {
  state.selectedPlan = null;
  state.isNavGizmoEnabled = true;
  viewerAPI()?.exitPlanView();

  // restore previous state
  setIsOrthoEnabledReducer(state, { payload: state.isOrthoEnabled, type: "" });
};

export const plansSelectors = {
  selectPlans: (state: RootState) => state.ifcManager.plans,
  selectSelectedPlan: (state: RootState) => state.ifcManager.selectedPlan,
};

export const plansReducers = {
  goToPlan: goToPlanReducer,
  deselectPlan: deselectPlanReducer,
};
