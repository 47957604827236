import { IconButton } from "@mui/material";
import { styled } from "app/theme";

export const CircledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== "isCircled",
})(({ isCircled }) => ({
  ...(isCircled == true && {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "primary.main",
    padding: "8px",
  }),
}));
