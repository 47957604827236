import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { viewerAPI } from "app/common/ViewerAPI";
import { TOOLS } from "app/common/types";
import { toggleToolReducer } from "app/state/slices/ifcManager/tools";
import { IIfcMangerState } from "app/state/slices/ifcManagerSlice";
import { RootState } from "app/state/store";

export interface IPropertiesState {
  propertiesModelUUID: number | null;
  propertiesExpressID: number | null;
  propertiesSelectedSetName: string | null;
  lastSelectedPropertyName: string | null;
  selectPropertiesItemName: string | null;
}

export const initialPropertiesState: IPropertiesState = {
  propertiesModelUUID: null,
  propertiesExpressID: null,
  lastSelectedPropertyName: null,
  propertiesSelectedSetName: null,
  selectPropertiesItemName: null,
};

export const pickIFCItemPropsReducer = (state: IIfcMangerState, action: PayloadAction<void>) => {
  const viewer = viewerAPI();

  viewer.pickHoveredItemProps();
  if (!(viewer.highlighterModelUUID && viewer.highlighterExpressId)) {
    return { ...state };
  }

  state.propertiesModelUUID = viewer.highlighterModelUUID;
  state.propertiesExpressID = viewer.highlighterExpressId;
  if (state.activeTools[TOOLS.PROPERTIES] != true) {
    toggleToolReducer(state, {
      ...action,
      payload: {
        selectedTool: TOOLS.PROPERTIES,
      },
    });
  }
};

export const highlightByID = createAsyncThunk(
  "ifcManager/highlightByID",
  async (payload: {
    modelUUID: string | null;
    expressID: string | string[] | null;
    isTempHover?: boolean;
    isSpatiallyReccursive?: boolean;
  }) => {
    const viewer = viewerAPI();

    await viewer.highlightByID(payload);

    return {
      ...(!payload.isTempHover && {
        propertiesModelUUID: viewer.highlighterModelUUID,
        propertiesExpressID: viewer.highlighterExpressId,
      }),
    };
  }
);

export const setVisibilityByID = createAsyncThunk(
  "ifcManager/setVisibilityByID",
  async (payload: { modelUUID: string | null; expressID: string | null; value: boolean }) => {
    const viewer = viewerAPI();

    await viewer.setVisibilityByID(payload);

    return {};
  }
);

export const setLastSelectedPropertyNameReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  return {
    ...state,
    lastSelectedPropertyName: payload,
  };
};

export const setPropertiesSelectedSetNameReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  state.propertiesSelectedSetName = payload;
};

export const setPropertiesItemNameReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string | null>
) => {
  state.selectPropertiesItemName = payload;
};

export const selectLastSelectedPropertyName = (root: RootState) =>
  root.ifcManager.lastSelectedPropertyName;
export const selectPropertiesExpressID = (root: RootState) => root.ifcManager.propertiesExpressID;
export const selectpropertiesModelUUID = (root: RootState) => root.ifcManager.propertiesModelUUID;
export const selectPropertiesSelectedSetName = (root: RootState) =>
  root.ifcManager.propertiesSelectedSetName;
export const selectPropertiesItemName = (root: RootState) =>
  root.ifcManager.selectPropertiesItemName;

export const propertiesReducers = {
  pickIFCItemProps: pickIFCItemPropsReducer,
  setLastSelectedPropertyName: setLastSelectedPropertyNameReducer,
  setPropertiesSelectedSetName: setPropertiesSelectedSetNameReducer,
  setPropertiesItemName: setPropertiesItemNameReducer,
};

export const propertiesThunks = {
  highlightByID,
  setVisibilityByID,
};
