import { Box, InputBase, InputBaseProps } from "@mui/material";

/* reccomended use:
 * const onFileLoad = useFileLoad();
 * ...
 * <LocalIFCPicker onChange={onFileLoad}/>
 */
export const LocalIFCPicker = (props: InputBaseProps) => (
  <Box sx={{ display: "none" }}>
    <InputBase
      id="fileInput"
      inputProps={{ accept: ".ifc,.IFC", multiple: false }}
      type="file"
      // this forces onChange to trigger on every file selection to cover same name reup:
      value={""}
      {...props}
    />
  </Box>
);
